import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Modal from "react-modal";
import { useAuth0 } from "@auth0/auth0-react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

import { useSaveOrderPatientMutation, useUpdateOrderPatientMutation } from "../../services/orders";
import { GetToast } from "../Toast-Comps/GetToast";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import { Checkbox } from "../InputField-Comps/CheckboxField";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import TextInputField from "../InputField-Comps/TextInputField";
import LocationInputV2 from "../InputField-Comps/LocationInputV2";
import NumberInputField from "../InputField-Comps/NumberInputField";
import DateInputFieldV2 from "../InputField-Comps/DateInputFieldV2";
import ActiveToggleButton from "../UserManagement-Comps/ActiveToggleButton";
import moment from "moment";

export const PatientDetailsSection = ({
  setActiveTab,
  orderPatientData,
  submitOrderData,
  serviceObjId,
  orderByData,
  orderObjId,
}) => {
  // debugger
  const navigate = useNavigate();
  const [patientName, setPatientName] = useState(orderPatientData?.patientInitials || "");
  const [anonymizedDetails, setAnonymizedDetails] = useState(
    orderPatientData?.detailsAnonymized === null
      ? "No"
      : orderPatientData?.detailsAnonymized
      ? "Yes"
      : "No"
  );
  const [DOB, setDOB] = useState(orderPatientData?.dob ?? "");
  const [genderValue, setGenderValue] = useState(orderPatientData?.gender);
  const [address, setAddress] = useState(orderPatientData?.address);
  const [location, setLocation] = useState(
    Object.keys(orderPatientData?.location)?.length > 0
      ? orderPatientData?.location
      : { lat: 37.7577, lng: -122.4376 }
  );
  const [country, setCountry] = useState(orderPatientData?.country);
  const [pinCode, setPinCode] = useState(orderPatientData?.pinCode);
  const [isPriority, setIsPriority] = useState(orderPatientData?.isPriority ?? false);
  const [expectedDelivery, setExpectedDelivery] = useState(
    orderPatientData?.expectedDelivery ?? ""
  );

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const mapLibraries = ["places"];

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: mapLibraries,
  });

  const handleGenderCheckboxChange = (event) => {
    setGenderValue(event.target.value);
  };

  // eslint-disable-next-line no-unused-vars
  const [isPatientInitialFormValid, setIsPatientInitialFormValid] = useState(false);
  const [isPatientInitialFormSubmitted, setIsPatientInitialFormSubmitted] = useState(false);

  const validatePatientName = (value) => {
    return value.trim().length > 0;
  };
  const validateAddress = (value) => {
    return value.trim().length > 0;
  };
  const validateCountry = (value) => {
    return value.trim().length > 0;
  };
  const validatePinCode = (value) => {
    return value?.trim().length > 0;
  };
  const validateDOB = (value) => {
    return value ? true : false;
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = ["patientName", "DOB", "gender", "address", "country", "pinCode"];

    return requiredFields.includes(fieldName);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "patientName") setPatientName(value);
    if (name === "expectedDelivery") setExpectedDelivery(value);
    if (name === "DOB") setDOB(value);
    if (name === "address") setAddress(value);
    if (name === "country") setCountry(value);
    if (name === "pinCode") setPinCode(value);
  };

  const handleAnonymizedDetailsChange = (value, checked) => {
    if (checked) {
      setAnonymizedDetails("Yes");
    } else {
      setAnonymizedDetails("No");
    }
  };

  const submitPatientInitialForm = async () => {
    // Perform validation for each input

    const isPatientInitialValid =
      !isFieldRequired("patientName") || validatePatientName(patientName);

    const isDOBValid = !isFieldRequired("DOB") || validateDOB(DOB);

    // Set overall form validity
    setIsPatientInitialFormValid(isPatientInitialValid && isDOBValid);
    setIsPatientInitialFormSubmitted(true);

    if (isPatientInitialValid && isDOBValid) {
      // Submit the form or perform any other action
      // const accessToken = await getAccessTokenSilently({
      //   authorizationParams: {
      //     audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      //   },
      // });
      // if (
      //   orderByData?.orderpatientdetails?.length === 0 &&
      //   orderByData?.orderclinicalconditions?.length === 0
      // ) {
      //   const respPatient = await saveOrderPatient({
      //     accessToken,
      //     orderObjId: orderByData?._id,
      //     patientInitials: patientName,
      //     dob: DOB,
      //     gender: genderValue,
      //   });

      //   if (respPatient?.data?.type === 1) {
      //     GetToast(
      //       "Your Order Successfully Initiated and in Draft Stage, you can continue submitting other details or come back and edit this order from Orders List !!",
      //       "success"
      //     );
      //     setActiveTab("Case Details");
      //   } else {
      //     GetToast(
      //       "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
      //       "error"
      //     );
      //   }
      // } else {
      //   if (
      //     initialPatientName !== patientName ||
      //     initialDOB !== DOB ||
      //     initialGenderValue !== genderValue
      //   ) {
      //     const respPatient = await updateOrderPatient({
      //       accessToken,
      //       _id: orderByData?.orderpatientdetails[0]?._id,
      //       patientInitials: patientName,
      //       dob: DOB,
      //       gender: genderValue,
      //     });
      //     setResPatient(respPatient);
      //   }
      //   if (resPatient) {
      //     if (resPatient?.data?.type === 1) {
      //       GetToast(
      //         "Your Order Successfully Initiated and in Draft Stage, you can continue submitting other details or come back and edit this order from Orders List !!",
      //         "success"
      //       );
      //       setActiveTab("Case Details");
      //     } else {
      //       GetToast(
      //         "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
      //         "error"
      //       );
      //     }
      //   } else {
      //     setActiveTab("Case Details");
      //   }
      // }
      submitOrderData({
        isPriority: isPriority,
        expectedDelivery: expectedDelivery,
        patientInitials: patientName,
        detailsAnonymized: anonymizedDetails === "Yes",
        dob: DOB,
        gender: genderValue,
        address: address,
        country: country,
        pinCode: pinCode,
        location: location,
      });
      setActiveTab("Case Details");
    } else {
      console.log("Form Not Valid");
    }
  };

  return (
    <div>
      <div className="">
        <div className="flex flex-col gap-4">
          <div className="flex items-end gap-8">
            <DateInputFieldV2
              labelColor={"#777"}
              labelText={"Expected Delivery Date of Order"}
              errorMessage={""}
              placeholder={"DD-MM-YYYY"}
              htmlFor={"Expected Delivery Date of Order"}
              name={"expectedDelivery"}
              value={expectedDelivery}
              handleInputChange={handleInputChange}
              validationFunctionName={(value) => (value.trim() === "" ? false : true)}
              min={moment(new Date()).format("YYYY-MM-DD")}
            />
            <div className="bg-primary px-4 py-2 flex items-center justify-center gap-4 text-white text-[17px] rounded-md h-fit mb-4">
              <p> Need This order on Priority</p>
              <ActiveToggleButton
                currentState={isPriority}
                setState={(value) => {
                  if (value) setIsPopupOpen(true);
                  setIsPriority(value);
                }}
                color={"#4E81F4"}
                className="drop-shadow-standard"
              />
            </div>
          </div>
          <div className=" flex md:flex-row flex-col gap-8">
            <TextInputField
              labelColor={"#777"}
              labelText={"Patient Initials"}
              errorMessage={""}
              placeholder={"Enter here"}
              isFieldRequired={isFieldRequired("patientName")}
              htmlFor={"patientName"}
              name={"patientName"}
              value={patientName}
              handleInputChange={handleInputChange}
              validationFunctionName={validatePatientName}
              isSubmitted={isPatientInitialFormSubmitted}
              margin={"0px"}
            />
            <DateInputFieldV2
              labelColor={"#777"}
              labelText={"Patient Date of Birth"}
              errorMessage={""}
              placeholder={"DD-MM-YYYY"}
              isFieldRequired={isFieldRequired("DOB")}
              htmlFor={"DOB"}
              name={"DOB"}
              value={DOB}
              handleInputChange={handleInputChange}
              validationFunctionName={validateDOB}
              isSubmitted={isPatientInitialFormSubmitted}
              max={moment(new Date()).format("YYYY-MM-DD")}
            />
          </div>
          <div className="relative text-white text-sm">
            <Checkbox
              label="Patient details have been anonymized"
              value="Yes"
              checked={anonymizedDetails === "Yes"}
              onChange={handleAnonymizedDetailsChange}
            />
          </div>
          <p className="text-[16px] text-[#777] mb-3">
            (It is the dentist's responsibility to ensure this if local privacy laws require this as
            a part of compliance. Digitaldentition.com accepts no liability for non compliance with
            local regulatory or privacy laws.)
          </p>
          <div>
            <label className="text-[16px] text-white" htmlFor="gender">
              Gender {isFieldRequired("gender") ? <span className="text-red-500">*</span> : ""}
            </label>
            <div className="flex-1 mt-4 mb-4 flex md:flex-row flex-col md:gap-12 gap-4">
              <div className="flex">
                <CircularCheckbox
                  value="Male"
                  checked={genderValue === "Male"}
                  onChange={handleGenderCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">Male</p>
              </div>
              <div className="flex">
                <CircularCheckbox
                  value="Female"
                  checked={genderValue === "Female"}
                  onChange={handleGenderCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">Female</p>
              </div>
              <div className="flex">
                <CircularCheckbox
                  value="Others"
                  checked={genderValue === "Others"}
                  onChange={handleGenderCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">Others</p>
              </div>
            </div>
          </div>

          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <>
              <p className="text-[16px] text-[#777]">
                Please start typing below and click on an appropriate address once options are
                generated. Please enter address manually under shipping address if your address is
                not automatically generated. A shipping address is required even if only digital
                services are requested.
              </p>
              <div className=" col-span-2">
                <LocationInputV2
                  setValue={setAddress}
                  placeholder={"Search for a Shipping Address Location"}
                  name={"addressSearch"}
                  setCountry={setCountry}
                  setLocation={setLocation}
                  setPinCode={setPinCode}
                />
              </div>
              <div className="col-span-2 row-span-2 mb-2">
                <GoogleMap
                  mapContainerClassName="h-[140px]"
                  center={location}
                  zoom={10}
                  onClick={(e) => {
                    setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                  }}
                  clickableIcons={true}
                >
                  <Marker position={location} />
                </GoogleMap>
              </div>
            </>
          )}

          <div className="col-span-2 row-span-2">
            <TextAreaInputField
              labelColor={"#777"}
              labelText={"Shipping Address"}
              errorMessage={"Enter Valid Address"}
              placeholder={"Enter Shipping Address Manually"}
              isFieldRequired={isFieldRequired("address")}
              htmlFor={"address"}
              name={"address"}
              value={address}
              handleInputChange={handleInputChange}
              validationFunctionName={validateAddress}
              isSubmitted={isPatientInitialFormSubmitted}
              rows={4}
              cols={5}
            />
          </div>
          <div className="flex gap-8">
            <div className="col-span-1">
              <TextInputField
                labelColor={"#777"}
                labelText={"Country "}
                errorMessage={"Please enter a country name"}
                placeholder={"Country name"}
                htmlFor={"country"}
                name={"country"}
                value={country}
                handleInputChange={handleInputChange}
                validationFunctionName={validateCountry}
                isSubmitted={isPatientInitialFormSubmitted}
                isFieldRequired={isFieldRequired("country")}
              />
            </div>
            <div className="col-span-1">
              <NumberInputField
                labelColor={"#777"}
                labelText={"Postal code (Zip code/Pin code)"}
                errorMessage={"Please Enter Valid Pin Code"}
                placeholder={"Please Enter your Pin Code"}
                isFieldRequired={isFieldRequired("pinCode")}
                htmlFor={"pinCode"}
                name={"pinCode"}
                value={pinCode}
                handleInputChange={handleInputChange}
                validationFunctionName={validatePinCode}
                isSubmitted={isPatientInitialFormSubmitted}
              />
            </div>
          </div>
          <div>
            <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
              <button
                onClick={() => {
                  navigate("/orders");
                }}
                className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
              >
                Back
              </button>
              <button
                onClick={() => {
                  // submit form
                  submitPatientInitialForm();
                }}
                className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
              >
                Save & Continue
              </button>
            </div>
          </div>
          <Modal
            isOpen={isPopupOpen}
            onRequestClose={() => {
              setIsPopupOpen(false);
              setIsPriority(false);
            }}
            contentLabel="Custom Modal"
            className="popup-modal !bg-backgroundV2 text-white !h-[25vh]"
            // style={customStyles}
            ariaHideApp={false}
          >
            <>
              <div className="MODAL-BODY flex flex-col h-full">
                <p className="flex-1 text-xl font-medium mb-5">
                  You have requested a priority order, this will incur a 20% surcharge
                </p>
                <div className="flex items-center justify-end gap-8">
                  <button
                    className="px-4 py-2 bg-slate-400 rounded-md text-white font-medium"
                    onClick={() => {
                      setIsPriority(false);
                      setIsPopupOpen(false);
                    }}
                  >
                    Change
                  </button>
                  <button
                    className="px-4 py-2 bg-orange-600 rounded-md text-white font-medium"
                    onClick={() => {
                      // handleSavePriorityV2();
                      GetToast(
                        "Priority orders will charge 20% extra on the regular price",
                        "success"
                      );
                      setIsPopupOpen(false);
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </>
          </Modal>
        </div>
      </div>
    </div>
  );
};
