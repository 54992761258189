import { useState } from "react";
import DetailTile from "./DetailTile";
import profileIcon from "../../Assets/images/profileIcon.svg";
import TabComponentProfile from "./TabComponentProfile";
import InputFieldsProfile from "./InputFieldsProfile";
import InputFieldsAddress from "./InputFieldsAddress";
import hut from "../../Assets/images/hut.svg";
import { IoIosArrowForward } from "react-icons/io";
import bell from "../../Assets/images/bellIcon.svg";
import CustomTabsProfile from "./CustomTabsProfile";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
export const ChangePasswordTab = () => {
  // modal state and styles
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // dummy data for profile section
  const detailTileListOne = [
    <DetailTile
      heading={"First Name"}
      subHeading={"John"}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Last Name"}
      subHeading={"Mayer"}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Education"}
      subHeading={"National"}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Last Name"}
      subHeading={"Mayer"}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Education"}
      subHeading={"National"}
      icon={profileIcon}
    />,
  ];
  const detailTileListTwo = [
    <DetailTile
      heading={"First Name"}
      subHeading={"John"}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Last Name"}
      subHeading={"Mayer"}
      icon={profileIcon}
    />,
  ];

  const tileWrapperOne = (
    <TabComponentProfile detailTileList={detailTileListOne} />
  );
  const tileWrapperTwo = (
    <TabComponentProfile detailTileList={detailTileListTwo} />
  );

  const tabNamesArray = ["Personal Details", "Address Details"];
  const tileObject = {
    "Personal Details": tileWrapperOne,
    "Address Details": tileWrapperTwo,
  };
  //   list of input fields for modal
  const InputFieldsCompOne = [
    <InputFieldsProfile setModalIsOpen={setModalIsOpen} />,
  ];
  const InputFieldsCompTwo = [
    <InputFieldsAddress setModalIsOpen={setModalIsOpen} />,
  ];

  const tileObjectModal = {
    "Personal Details": InputFieldsCompOne,
    "Address Details": InputFieldsCompTwo,
  };

  return (
    <>
      Integration Under Progress
      {/* <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">
            Profile <IoIosArrowForward />{" "}
            <span className="font-medium text-[15px] text-primary">
              {" "}
              Change Password
            </span>
          </span>
        </p>
        <div className="flex gap-4 ">
          <img src={bell} alt="" />
        </div>
      </div>
      <p className="HEADING font-medium text-[34px] leading-7 mr-8 md:my-8 my-4">
        Change Password
      </p>
      <div className="WHITE-SECTION bg-white md:p-8 p-4 md:h-[70vh] overflow-auto">
        <div className="flex flex-col gap-4 md:gap-0 md:flex-row md:items-center justify-between mb-8">
          <div className="rounded-full bg-black h-[72px] w-[72px]"></div>
          <div className="flex-1 md:pl-4">
            <p className="font-semibold leading-4 pb-2">John Doe</p>
            <p className="leading-4 text-[#898989]">johndoe007@email.com</p>
          </div>
          <div className="text-left md:text-right ">
            <button
              onClick={() => setModalIsOpen(true)}
              className="text-[16px] font-medium text-white bg-[#FF7E00] rounded-md px-4 py-2 mb-2"
            >
              Edit Profile
            </button>
            <p className="text-[12px] leading-4 text-[#898989]">
              Last Updated on Dec 09, 2021
            </p>
          </div>
        </div>
        <div className="CUSTOM-TAB">
          <CustomTabsProfile
            tabNamesArray={tabNamesArray}
            outputObject={tileObject}
          />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY">
            <button
              className="w-full text-right"
              onClick={() => setModalIsOpen(false)}
            >
              <AiOutlineClose className="ml-auto" />
            </button>
            <p className="text-[26px] font-medium text-black leading-8 mb-12">
              Edit Profile
            </p>
            <div className="MODAL-TAB-SECTION">
              <CustomTabsProfile
                tabNamesArray={tabNamesArray}
                outputObject={tileObjectModal}
              />
            </div>
          </div>
        </>
      </Modal> */}
    </>
  );
};
