import { configureStore } from '@reduxjs/toolkit'
import { categoryApi } from './services/category'
import { userApi } from './services/user'
import { registrationSlice } from './reducers/registrationReducer'
import { personalDetailsSlice } from './reducers/PersonalDetailsReducer'
import { serviceApi } from './services/services'
import { serviceCategoryApi } from './services/servicesCategory'
import { softwareCategoryApi } from './services/softwareCategory'
import { userServiceApi } from './services/userServices'
import { uploadFileApi } from './services/uploadFiles'
import { userManagementSlice } from './reducers/UserManagementReducer'
import { ordersApi } from './services/orders'
import { utilApi } from './services/util'
import { spServiceDetailsSlice } from './reducers/SPServiceDetailsReducer'
import { orderFormSlice } from './reducers/orderFormReducer'
import loadingReducer from './services/loadingSlice';

export const store = configureStore({
  reducer: {
    orderFormDetails: orderFormSlice.reducer,
    registrationDetails: registrationSlice.reducer,
    personalDetails: personalDetailsSlice.reducer,
    userManagementDetails: userManagementSlice.reducer,
    servicesDetails: spServiceDetailsSlice.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer, 
    [userApi.reducerPath]: userApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [serviceCategoryApi.reducerPath]: serviceCategoryApi.reducer,
    [softwareCategoryApi.reducerPath]: softwareCategoryApi.reducer,
    [uploadFileApi.reducerPath]: uploadFileApi.reducer,
    [userServiceApi.reducerPath]: userServiceApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [utilApi.reducerPath]: utilApi.reducer, 
    loading: loadingReducer,
    devTools: true,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([categoryApi.middleware, userApi.middleware, serviceApi.middleware, serviceCategoryApi.middleware, softwareCategoryApi.middleware,uploadFileApi.middleware,
      userServiceApi.middleware, ordersApi.middleware, utilApi.middleware
    ]),
})
