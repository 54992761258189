import React, { useEffect, useState, Fragment } from "react";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";
import hut from "../../Assets/images/hut.svg";
import bell from "../../Assets/images/bellIcon.svg";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import DynamicTable from "../../Components/Services-Comps/DynamicTable";
import circle from "../../Assets/images/circle.png";
import searchIcon from "../../Assets/images/searchInputIcon.svg";
import { useNavigate } from "react-router-dom";
import dummyImage from "../../Assets/images/dummyImage.png";
import ServiceCarouselTile from "../../Components/UserManagement-Comps/ServiceCarouselTile";
import CustomCarousel from "../../Components/UserManagement-Comps/CustomCarousel";
import ServiceProviderCard from "../../Components/OrderAdmin-comps/ServiceProviderCard";
import ticket from "../../Assets/icons/ticket.svg";
import replyIcon from "../../Assets/icons/replyIcon.svg";
import { AiOutlineArrowUp, AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import TextInputField from "../../Components/InputField-Comps/TextInputField";
import TextAreaInputField from "../../Components/InputField-Comps/TextAreaInputField";
import assignedIcon from "../../Assets/icons/orderAssignedIcon.svg";
import assignSuccess from "../../Assets/icons/assignSuccess.svg";
import { LoginButton } from "../Login/Login";
import { useAuth0 } from "@auth0/auth0-react";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import { useLocation } from "react-router-dom";
import { useGetOrderByIdMutation, useReadCommOrdersMutation, useSaveCommOrdersMutation, useUpdateCommOrdersMutation, useUpdateOrderByIdMutation } from "../../services/orders";
import { Converter } from "easy-currencies";
import { Tooltip } from 'react-tooltip';
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import DeliveryTracker from "../../Components/Order-Comps/DeliveryTracker";
import AttachUpload from "../../Components/Order-Comps/AttachUpload";
import ConcernCard from "../../Components/Order-Comps/ConcernCard";
import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";

const converter = new Converter();

const displayOptions = require("../../Utils/orders-form1-options.json");

export const OrderDetailSummary = ({ data }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [getOrderById] = useGetOrderByIdMutation();
  const [orderDetails, setOrderDetails] = useState([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");


  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };


  const getOrderData = async (accessToken, orderId) => {
    const resp = await getOrderById({ accessToken, orderId }).unwrap();
    if (resp) {
      // debugger
      setOrderDetails(resp.data[0]);
      // debugger
    }
  };

  const readOrderInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (accessToken) {
      await getOrderData(accessToken, data.orderObjId);
    }
  };


  useEffect(() => {
    readOrderInfo();
  }, []);

  return (
    // "Error in performing this action - reducer failed"
    <>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4">
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Patient Initials</p>
          <p className="text-[#555] font-medium mt-2">{orderDetails?.orderpatientdetails?.[0]?.patientInitials}</p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Date of Birth</p>
          <p className="text-[#555] font-medium mt-2">{orderDetails?.orderpatientdetails?.[0]?.dob}</p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Gender</p>
          <p className="text-[#555] font-medium mt-2">{orderDetails?.orderpatientdetails?.[0]?.gender}</p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Options</p>
          <p className="text-[#555] font-medium mt-2">
            {orderDetails?.orderpatientdetails?.[0]?.options}
          </p>
        </div>
        <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Clinical Conditions</p>
          <p className="text-[#555] font-medium mt-2">
            {Object.keys(orderDetails?.orderclinicalconditions?.[0] || {}).map((element, index) => (
              (orderDetails?.orderclinicalconditions?.[0][element]) ? (<span key={index}>
                {displayOptions.options[0][element]}
                <br /> {/* Add a line break */}
              </span>) : null
            ))}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Patient Concern </p>
          <p className="text-[#555] font-medium mt-2">
            {orderDetails?.orderpatientdetails?.[0]?.patientConcerns}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Upload photographs</p>
          <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
            {orderDetails?.assets?.map(function (key, index) {
              if (key.fileType === "uploadData") {
                return (
                  <div className="cursor-pointer" onClick={() => openImageModal(key.s3Url)}>
                    <img
                      key={index} // Don't forget to provide a unique key for each element in the array
                      className="h-7 w-7 object-contain"
                      src={key.s3Url}
                      alt=""
                    />
                  </div>
                );
              }
              return null; // Return null if there's no image URL for the key
            })}
          </div>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Intra Oral Scans</p>
          <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
            {orderDetails?.assets?.map(function (key, index) {
              if (key.fileType === "internalScan") {
                return (
                  <div className="cursor-pointer" onClick={() => openImageModal(key.s3Url)}>
                    <img
                      key={index} // Don't forget to provide a unique key for each element in the array
                      className="h-7 w-7 object-contain"
                      src={key.s3Url}
                      alt=""
                    />
                  </div>
                );
              }
              return null; // Return null if there's no image URL for the key
            })}
          </div>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Intra Oral Scans</p>
          <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
            {orderDetails?.assets?.map(function (key, index) {
              if (key.fileType === "internalScan2") {
                return (
                  <div className="cursor-pointer" onClick={() => openImageModal(key.s3Url)}>
                    <img
                      key={index} // Don't forget to provide a unique key for each element in the array
                      className="h-7 w-7 object-contain"
                      src={key.s3Url}
                      alt=""
                    />
                  </div>
                );
              }
              return null; // Return null if there's no image URL for the key
            })}
          </div>
        </div>
        <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Treatment Goals</p>
          <p className="text-[#555] font-medium mt-2">
            {Object.keys(orderDetails?.ordertreatmentgoals?.[0] || {}).map((element, index) => (
              (orderDetails?.ordertreatmentgoals?.[0][element]) ? (<span key={index}>
                {displayOptions.treatmentGoals[0][element]}{displayOptions.midline[0][element]}
                <br /> {/* Add a line break */}
              </span>) : ''
            ))}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">
            Tooth Movement Restrictions (UTN)
          </p>
          <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
            {orderDetails?.ordertooth?.map((element, index) => (
              <span key={index}>
                {element.toothNumber}
                <br /> {/* Add a line break */}
              </span>
            ))}
          </div>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">
            Inter Proximal Reduction (IPR)
          </p>
          <p className="text-[#555] font-medium mt-2">
            {Object.keys(orderDetails?.ordertoothmovement?.[0] || {}).map((element, index) =>
              orderDetails?.ordertoothmovement?.[0][element] ? (
                <Fragment key={index}>
                  {displayOptions.tootmovementInternal[0][element]}
                  {/* Add a line break */}
                </Fragment>
              ) : null
            )}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Overjet Correction</p>
          <p className="text-[#555] font-medium mt-2">
            {Object.keys(orderDetails?.ordertoothmovement?.[0] || {}).map((element, index) =>
              orderDetails?.ordertoothmovement?.[0][element] ? (
                <Fragment key={index}>
                  {displayOptions.tootmovementOverJet[0][element]}
                  {/* Add a line break */}
                </Fragment>
              ) : null
            )}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Overbite Correction</p>
          <p className="text-[#555] font-medium mt-2">
            {Object.keys(orderDetails?.ordertoothmovement?.[0] || {}).map((element, index) =>
              orderDetails?.ordertoothmovement?.[0][element] ? (
                <Fragment key={index}>
                  {displayOptions.tootmovementOverBite[0][element]}
                  {/* Add a line break */}
                </Fragment>
              ) : null
            )}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Crossbite Correction</p>
          <p className="text-[#555] font-medium mt-2">
            {Object.keys(orderDetails?.ordertoothmovement?.[0] || {}).map((element, index) =>
              orderDetails?.ordertoothmovement?.[0][element] ? (
                <Fragment key={index}>
                  {displayOptions.tootmovementCrossBite[0][element]}
                  {/* Add a line break */}
                </Fragment>
              ) : null
            )}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Special Instrcutions</p>
          <p className="text-[#555] font-medium mt-2">
            {orderDetails?.ordertoothmovement?.[0].specialInstructions}
          </p>
        </div>
        {isImageModalOpen && (
          <div className="image-modal">
            <Modal
              isOpen={isImageModalOpen}
              onRequestClose={() => setIsImageModalOpen(false)}
              contentLabel="Custom Modal"
              className="image-modal"
              // style={customStyles}
              ariaHideApp={false}
            >
              <>
                <div className="MODAL-BODY flex flex-col h-full">
                  <button
                    className="w-full text-right"
                    onClick={() => closeImageModal()}
                  >
                    <AiOutlineClose className="ml-auto" />
                  </button>
                  <img
                    className="h-full w-full object-contain"
                    src={selectedImage}
                    alt=""
                  />
                </div>
              </>
            </Modal>
          </div>
        )}
      </div>
    </>
  );
};

export const OrderCommunications = ({ data }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [saveCommOrders] = useSaveCommOrdersMutation();
  const [readCommOrders] = useReadCommOrdersMutation();
  const [commData, setCommData] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [concern, setConcern] = useState("");
  const [concernDescription, setConcernDescription] = useState("");
  const [isConcernSubmitted, setIsConcernSubmitted] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  console.log(commData, "commData")

  const isFieldRequired = (fieldName) => {
    const requiredFields = [

    ];
    return requiredFields.includes(fieldName);
  };

  const getReadCommData = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await readCommOrders({
      accessToken,
      orderObjId: data?.orderObjId,
      typofCommunication: 1,
    });
    if (resp.data.type === 1) {
      console.log(resp.data, 'setCommData')
      setCommData(resp.data.data)
    }

  }

  const submitConcern = async () => {

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await saveCommOrders({
      accessToken,
      orderObjId: data?.orderObjId,
      typofCommunication: 1,
      commStatus: 1,
      readStatus: 0,
      imageurl: imageUrl,
      //parentObjId: null,
      communicationTile: concern,
      communicationText: concernDescription
    })
    if (resp?.data?.type === 1) {
      GetToast("Conren Saved");
      setModalIsOpen(false);
      getReadCommData();
    }

    // const data = {
    //   concern: concern,
    //   concernDescription: concernDescription,
    // };
    // console.log(data);

  };

  const validateConcern = (value) => {
    return value?.trim().length > 0;
  };
  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "concern") setConcern(value);
    if (name === "concernDescription") setConcernDescription(value);
  };

  useEffect(() => {
    getReadCommData();
  }, [])

  return (
    <div className="h-full">
      <div className="flex justify-between items-center w-full py-4">
        <p className="text-white text-2xl font-medium">Concern</p>
        <button
          onClick={() => setModalIsOpen(true)}
          className="bg-[#FF7E00] text-white px-4 py-2 font-medium rounded-lg"
        >
          Add New Concern
        </button>
      </div>
      {commData?.map((item) => (
        <ConcernCard
          item={item}
          getReadCommData={getReadCommData}
          orderObjId={data?.orderObjId}
        />
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button
                  className="w-full text-right"
                  onClick={() => setModalIsOpen(false)}
                >
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-black leading-8 mb-0">
                  Add New Concern
                </p>
              </div>
              <div className="flex-1 py-4">
                <TextInputField
                  labelColor={"#777"}
                  labelText={"Concern"}
                  errorMessage={"Concern is required"}
                  placeholder={"Enter your Concern"}
                  isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concern"}
                  name={"concern"}
                  value={concern}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                />
                <TextAreaInputField
                  labelColor={"#777"}
                  labelText={"Concern Description"}
                  errorMessage={"Concern is required"}
                  placeholder={"Concern Description"}
                  isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concernDescription"}
                  name={"concernDescription"}
                  value={concernDescription}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                  rows={6}
                />
                <div>
                  <p className="text-[#777] text-[16px] mb-2">Attachment</p>
                  <AttachUpload
                    setImageUrl={setImageUrl}
                    imageUrl={imageUrl}
                  />
                </div>
              </div>
              <div className="flex items-center justify-end gap-4">
                <button
                  onClick={() => setModalIsOpen(false)}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>
                <button
                  onClick={() => submitConcern()}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};
export const CustomerSupport = ({ data }) => {
  return (
    <div className="h-full">
      <div className="flex justify-between items-center w-full py-4">
        <p className="text-[#333333] text-2xl font-medium">Tickets</p>
        <button
          //onClick={() => setModalIsOpen(true)}
          className="bg-[#FF7E00] text-white px-4 py-2 font-medium rounded-lg"
        >
          +{" "}Add New Ticket
        </button>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-12 sm:grid-cols-12 gap-4">
        <div className="border border-[#D2D2D2] rounded-lg p-4">
          <div className="flex gap-4 items-center">
            <div className="bg-[#DCE6FD] h-12 w-12 rounded-full flex items-center justify-center">
              <img src={ticket} alt="" />
            </div>
            <p className="text-[#555] font-medium text-xl">Ticket#901900</p>
          </div>
          <p className="text-[#555] text-[17px] font-medium my-4">
            Orthodontic Service
          </p>
          <p className="text-[#555] text-[16px]">
            Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und
            visuelle Mockups in der Druck- Lorem Ipsum ist ein Platzhaltertext der
            häufig wird um Layouts und visuelle Mockups in der Druck- Lorem Ipsum
            ist ein Platzhaltertext der häufig wird um Layouts und visuelle
            Mockups in der Druck-
          </p>
          <div
            className="flex justify-between items-center text-[#4E81F4] my-4 cursor-pointer"
          //onClick={() => setIsReplyBoxOpen((prev) => !prev)}
          >
            <div>
              johndoe@abc.com
            </div>
            <div className="flex gap-3">
              <img src={replyIcon} alt="" />
              <p className="">Reply</p>
            </div>
          </div>
        </div>
        <div className="border border-[#D2D2D2] rounded-lg p-4">
          <div className="flex gap-4 items-center">
            <div className="bg-[#DCE6FD] h-12 w-12 rounded-full flex items-center justify-center">
              <img src={ticket} alt="" />
            </div>
            <p className="text-[#555] font-medium text-xl">Ticket#901900</p>
          </div>
          <p className="text-[#555] text-[17px] font-medium my-4">
            Orthodontic Service
          </p>
          <p className="text-[#555] text-[16px]">
            Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und
            visuelle Mockups in der Druck- Lorem Ipsum ist ein Platzhaltertext der
            häufig wird um Layouts und visuelle Mockups in der Druck- Lorem Ipsum
            ist ein Platzhaltertext der häufig wird um Layouts und visuelle
            Mockups in der Druck-
          </p>
          <div
            className="flex justify-between items-center text-[#4E81F4] my-4 cursor-pointer"
          //onClick={() => setIsReplyBoxOpen((prev) => !prev)}
          >
            <div>
              johndoe@abc.com
            </div>
            <div className="flex gap-3">
              <img src={replyIcon} alt="" />
              <p className="">Reply</p>
            </div>
          </div>
        </div>
        <div className="border border-[#D2D2D2] rounded-lg p-4">
          <div className="flex gap-4 items-center">
            <div className="bg-[#DCE6FD] h-12 w-12 rounded-full flex items-center justify-center">
              <img src={ticket} alt="" />
            </div>
            <p className="text-[#555] font-medium text-xl">Ticket#901900</p>
          </div>
          <p className="text-[#555] text-[17px] font-medium my-4">
            Orthodontic Service
          </p>
          <p className="text-[#555] text-[16px]">
            Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und
            visuelle Mockups in der Druck- Lorem Ipsum ist ein Platzhaltertext der
            häufig wird um Layouts und visuelle Mockups in der Druck- Lorem Ipsum
            ist ein Platzhaltertext der häufig wird um Layouts und visuelle
            Mockups in der Druck-
          </p>
          <div
            className="flex justify-between items-center text-[#4E81F4] my-4 cursor-pointer"
          //onClick={() => setIsReplyBoxOpen((prev) => !prev)}
          >
            <div>
              johndoe@abc.com
            </div>
            <div className="flex gap-3">
              <img src={replyIcon} alt="" />
              <p className="">Reply</p>
            </div>
          </div>
        </div>
        <div className="border border-[#D2D2D2] rounded-lg p-4">
          <div className="flex gap-4 items-center">
            <div className="bg-[#DCE6FD] h-12 w-12 rounded-full flex items-center justify-center">
              <img src={ticket} alt="" />
            </div>
            <p className="text-[#555] font-medium text-xl">Ticket#901900</p>
          </div>
          <p className="text-[#555] text-[17px] font-medium my-4">
            Orthodontic Service
          </p>
          <p className="text-[#555] text-[16px]">
            Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und
            visuelle Mockups in der Druck- Lorem Ipsum ist ein Platzhaltertext der
            häufig wird um Layouts und visuelle Mockups in der Druck- Lorem Ipsum
            ist ein Platzhaltertext der häufig wird um Layouts und visuelle
            Mockups in der Druck-
          </p>
          <div
            className="flex justify-between items-center text-[#4E81F4] my-4 cursor-pointer"
          //onClick={() => setIsReplyBoxOpen((prev) => !prev)}
          >
            <div>
              johndoe@abc.com
            </div>
            <div className="flex gap-3">
              <img src={replyIcon} alt="" />
              <p className="">Reply</p>
            </div>
          </div>
        </div>
      </div>
      {/* {isReplyBoxOpen && (
        <textarea
          type="text"
          className="bg-[#EEF3FE] h-32 w-full rounded-lg p-4 my-4 text-[12px] text-start"
          placeholder="Type your concern here..."
        />
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button
                  className="w-full text-right"
                  onClick={() => setModalIsOpen(false)}
                >
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-black leading-8 mb-0">
                  Add New Concern
                </p>
              </div>
              <div className="flex-1 py-4">
                <TextInputField
                  labelColor={"#777"}
                  labelText={"Concern"}
                  errorMessage={"Concern is required"}
                  placeholder={"Enter your Concern"}
                  //   isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concern"}
                  name={"concern"}
                  value={concern}
                  handleInputChange={handleInputChange}
                  //   validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                />
                <TextAreaInputField
                  labelColor={"#777"}
                  labelText={"Concern Description"}
                  errorMessage={"Concern is required"}
                  placeholder={"Concern Description"}
                  //   isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concernDescription"}
                  name={"concernDescription"}
                  value={concernDescription}
                  handleInputChange={handleInputChange}
                  //   validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                  rows={6}
                />
                <div>
                  <p className="text-[#777] text-[16px] mb-2">Attachment</p>
                  <div className="relative">
                    <input
                      type="file"
                      className="w-20 opacity-0 cursor-pointer"
                    />
                    <button className="pointer-events-none absolute left-0 border border-[#4E81F4] rounded-full px-4 py-2 text-[12px] font-medium text-[#4E81F4]">
                      <AiOutlineArrowUp className=" inline-block" /> Upload
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end gap-4">
                <button
                  onClick={() => setModalIsOpen(false)}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>
                <button
                  onClick={() => submitConcern()}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal> */}
    </div>
  );
};

export const OrderTrackingDetails = ({ data }) => {
  return (
    <div className="h-full">
      <DeliveryTracker />
    </div>
  )
}

export const Delivery = ({ data }) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [updateOrderById] = useUpdateOrderByIdMutation();

  const handleSubmitDelivery = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await updateOrderById({
      accessToken,
      orderId: data?.orderObjId,
      currentStatus: "7"
    });
    if (resp) {
      GetToast("Thanks for accepting the order you will get confirmation in a day or two");
      navigate("/orders");
    }
  }

  return (
    <div className="flex-col">
      <div>
        <div className="border bg-gray-100 border-[#c7e4ea] rounded-lg p-4">
          <div className="flex gap-4 items-center">
            <div className="bg-[#DCE6FD] h-12 w-12 rounded-full flex items-center justify-center">
              <img src={ticket} alt="" />
            </div>
            <p className="text-[#555] font-medium text-xl">Orthodontic Service</p>
          </div>
          <p className="text-[#555] text-[16px] mt-4">
            Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und
            visuelle Mockups in der Druck- Lorem Ipsum ist ein Platzhaltertext der
            häufig wird um Layouts und visuelle Mockups in der Druck- Lorem Ipsum
            ist ein Platzhaltertext der häufig wird um Layouts und visuelle
            Mockups in der Druck-
          </p>
        </div>
        <div className="mt-6">
          <p className="text-gray-500 mb-2 text-base">Upload New Delivery</p>
          <div className="flex gap-4">
            <div className="p-3 w-full border border-gray-300 rounded-md text-gray-400 text-base">
              Delivery Details
            </div>
            <div className="h-11 w-11 rounded-md bg-blue-600">
            </div>
            <div className="h-11 w-11 rounded-md bg-blue-600">
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-6 items-center md:justify-end gap-4 pt-4 flex-wrap">
        <button

          className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
        >
          Cancel
        </button>

        <button
          onClick={handleSubmitDelivery}
          className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
        >
          Submit Delivery
        </button>
      </div>
    </div>
  )
}

function OrderDetailsServiceProvider({ userRole, userInfo }) {

  const location = useLocation();

  return (
    <div className="flex bg-black">
      <Navbar
        iconArray={userRole === "89uij7" ? superAdminIconArray : userRole === "676dza2" ? iconArray : spIconArray}
        navHoverCss={navHoverCss}
        navActiveCss={navActiveCss}
        middleSection={false}
        middleTabTitleArray={[""]}
        middleTabContentObject={{}}
        userRole={userRole}
        active='Orders'
      />
      <OrderTab userInfo={userInfo} userRole={userRole} data={location.state} />
    </div>
  );
}
export default OrderDetailsServiceProvider;

export const OrderTab = ({ userInfo, userRole, data }) => {
  console.log(data, "dataaaa")
  const navigate = useNavigate();
  const personalDetails = useSelector((state)=> state.personalDetails);
  const tabNamesArray = []
  if (data.statusCode === '106' || data.statusCode === '109' || data.statusCode === '112') {
    tabNamesArray.push("Order Details");
    tabNamesArray.push("Order Communications");
  } else {
    tabNamesArray.push("Order Details");
    tabNamesArray.push("Order Communications");
    tabNamesArray.push("Order Tracking Details");
    tabNamesArray.push("Customer Support");
    tabNamesArray.push("Delivery");
  }
  //["Order Details", "Order Communications", "Order Tracking Details", "Customer Support", "Delivery"];
  const outputObject = {
    "Order Details": <OrderDetailSummary data={data} />,
    "Order Communications": <OrderCommunications data={data} />,
    "Customer Support": <CustomerSupport data={data} />,
    "Delivery": <Delivery data={data} />,
    "Order Tracking Details": <OrderTrackingDetails data={data} />
  };
  const { getAccessTokenSilently } = useAuth0();
  const { user, isAuthenticated } = useAuth0();
  const [activeTab, setActiveTab] = useState(tabNamesArray[0]);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const [isOrderAccepted, setIsOrderAccepted] = useState(false);
  const [localPrice, setLocalPrice] = useState('');
  const [updateOrderById] = useUpdateOrderByIdMutation();

  const handleTabSuper = (tabName) => {
    setActiveTab(tabName);
  };

  const handleAcceptSubmit = async (actionType) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    if (actionType === "accept") {
      const resp = await updateOrderById({
        accessToken,
        orderId: data?.orderObjId,
        currentStatus: "4"
      });
      if (resp) {
        GetToast("Thanks for accepting the order you will get confirmation in a day or two");
        navigate("/orders");
        setIsOrderAccepted(true);
        setAcceptModalOpen(false);
      }

    } else {
      const resp = await updateOrderById({
        accessToken,
        orderId: data?.orderObjId,
        currentStatus: "3"
      });
      if (resp) {
        GetToast("Order rejected, you can contact Administrator for any queries.");
        navigate("/orders");
      }

    }
  }

  const priceConverter = async (userCurrency, amount) => {
    if (userCurrency && amount) {
      const value = await converter.convert(amount, "USD", userCurrency);
      setLocalPrice(value.toFixed(2));
    }
  }

  console.log(data.statusCode, "fwefwefew")

  useEffect(() => {
    priceConverter(data?.orderCurrency, data?.orderValue);
  }, []);

  console.log(localPrice, "localPrice");


  return (
    <div className="MAIN-DASH bg-black p-4 w-full min-h-screen h-full flex flex-col md:py-4 pt-4 pb-20">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {personalDetails.data[0]?.firstName ?? user.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {
            userRole !== "89uij7" ?
              (userInfo?.length > 0) ?
                <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">

                  {
                    userInfo[0]?.active === 1 ?
                      <>
                        {userInfo[0]?.crowdHireId}
                        <img
                          className="relative top-[4px] right-0"
                          src={greenTick}
                          alt=""
                        />
                      </>
                      :
                      <div
                        className="flex"
                        data-tooltip-id="status"
                        data-tooltip-content={"Your registration is under review and you will be notified once admin approves your registration."}
                      >
                        <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                        <img
                          className="relative top-[0px] ml-2 right-0 h-5 w-5"
                          src={redCross}
                          alt=""
                        />
                        <Tooltip id="status" place="bottom" />
                      </div>
                  }

                </button>
                :
                <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                  Account Not Active
                  <img
                    className="relative top-[0px] right-0 h-5 w-5"
                    src={redCross}
                    alt=""
                  />
                </button>
              :
              <>
              </>
          }

          <Notification />
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:items-center items-start justify-between my-4 md:gap-96 gap-4">
        <div className="flex flex-1 gap-4 items-center justify-between w-full">
          <p className="font-medium text-[34px] text-white">
            {data.serviceName}
          </p>
          {
            data?.statusCode === "112" ?
              <p className="px-4 py-2 text-[#1DB569] rounded-lg font-medium text-[17px]">{data.status}</p>
              :
              data?.statusCode === "113" ?
                <p className="px-4 py-2 text-[#F35F5D] rounded-lg font-medium text-[17px]">{data.status}</p>
                :
                data?.statusCode === "115" ?
                  <p className="px-4 py-2 text-[#ff9e4f] rounded-lg font-medium text-[17px]">{data.status}</p>
                  :
                  data?.statusCode === "124" ?
                    <p className="px-4 py-2 text-[#ff9e4f] rounded-lg font-medium text-[17px]">{data.status}</p>
                    :
                    data?.statusCode === "121" ?
                      <p className="px-4 py-2 text-[#39c44e] rounded-lg font-medium text-[17px]">{data.status}</p>
                      :
                      <div className="flex gap-2 items-center">
                        <button
                          onClick={() => setAcceptModalOpen(true)}
                          className="px-4 py-2 bg-[#1DB569] text-white rounded-lg font-medium text-[17px]"
                        >
                          Accept
                        </button>
                        <button
                          onClick={() => handleAcceptSubmit("reject")}
                          className="px-4 py-2 bg-[#F35F5D] text-white rounded-lg font-medium text-[17px]">
                          Reject
                        </button>
                      </div>
          }

        </div>
      </div>

      <div className={data.isPriority === 1 || "True" ? "card shadow-xl" : "hadow-xl"} data-label={data.isPriority === 1 || "True" ? "Priority Order" : ''}>
        <div
          className={data.isPriority === 1 || "True" ?
            "card__container bg-secondary p-4 rounded-md flex-1 flex flex-col"
            :
            "bg-white p-4 rounded-md flex-1 flex flex-col"
          }
        >
          <p
            className="text-xl font-medium text-white my-4"
          >
            Price: <span
              data-tooltip-id="my-tooltip"
              data-tooltip-content={data.orderCurrency + " may vary according to the USD rate"}
              className="text-white font-bold"
            > {data?.orderValue} USD <span className="text-white text-base">( {localPrice} {data?.orderCurrency} )</span>
            </span>{" "}
          </p>
          <Tooltip id="my-tooltip" place="right" />
          <div className="Tabs flex-1 h-full">
            {/* Tab nav */}
            <ul className="NAV flex items-center gap-4 text-[16px] leading-4 font-medium border-b-2 border-[#222026] border-opacity-10 py-4">
              {tabNamesArray.map((title, i) => {
                return (
                  <li
                    key={i + 1}
                    className="relative"
                    onClick={() => handleTabSuper(title)}
                  >
                    <div
                      className={
                        activeTab === title
                          ? "text-primary before:content-[''] before:absolute before:h-1 before:w-full before:bg-primary before:left-0 before:-bottom-4"
                          : "hover:text-primary cursor-pointer text-[#999] font-normal"
                      }
                    >
                      {title}
                    </div>
                  </li>
                );
              })}
            </ul>

            <div className="outlet md:h-fit h-[50vh] overflow-auto pt-4 ">
              {Object.keys(outputObject).map((key) => {
                if (tabNamesArray.includes(key)) {
                  return (
                    <>{activeTab === key ? <>{outputObject[key]}</> : null}</>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={acceptModalOpen}
        onRequestClose={() => setAcceptModalOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button
                  className="w-full text-right"
                  onClick={() => setAcceptModalOpen(false)}
                >
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-black leading-8 mb-0">
                  Accept Order
                </p>
              </div>

              <div className="flex-1 flex flex-col items-center justify-center">
                {isOrderAccepted ? (
                  <>
                    <img src={assignSuccess} alt="" />
                    <p className="text-2xl font-semibold">Congratulations!</p>
                    <p className="text-center my-0 md:px-12">
                      Your order is successfully accepted.
                    </p>
                  </>
                ) : (
                  <>
                    {" "}
                    <img src={assignedIcon} alt="" />
                    <p className="text-center my-4 md:px-12">
                      By clicking confirm this order will be accepted for
                      <span className="text-xl text-[#4E81F4]"> {data.orderValue} USD ( {localPrice} {data.orderCurrency} )</span>
                    </p>
                  </>
                )}
              </div>

              <div className="flex items-center justify-end gap-4">
                {isOrderAccepted ? null : (
                  <button
                    onClick={() => setAcceptModalOpen(false)}
                    className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                  >
                    Cancel
                  </button>
                )}

                <button
                  onClick={() => {
                    handleAcceptSubmit("accept");
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  {isOrderAccepted ? "Okay" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};
