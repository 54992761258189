import * as React from "react"
export const Teeth22 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={28} height={85} fill="none">
            <path
                fill="#EFD1D0"
                d="M5.38 54.345c.062-.244.062-.487.123-.792.487-3.408.973-6.147 1.521-8.643.426-2.008.974-4.017 1.461-5.964.852-3.165 1.704-6.452 2.252-9.8.365-2.374.548-4.747.73-7.121.183-2.374.366-4.748.792-7.121C13.354 7.842 15.485 3.46 18.77 1.512c.487-.243.913-.426 1.279-.426.67 0 1.156.426 1.4 1.217.365.974.121 2.374-.122 3.652-.122.609-.244 1.157-.244 1.704-.365 4.261-.182 8.522.122 13.513.304 5.417.791 10.895 1.278 16.13 0 2.13.244 4.382.487 6.512.122 1.035.244 2.13.305 3.165.06.974.121 2.008.182 3.043.06 1.34.183 2.617.304 3.956l-4.747-4.017c-1.34-1.095-2.678-1.704-4.383-1.278-1.886.487-3.043 1.826-4.443 3.043-.608.548-1.46 1.035-2.19 1.461-.244.183-2.436.974-2.618 1.157Z"
            />
            <path
                fill="#fff"
                fillOpacity={0.8}
                d="M26.501 75.525c.305 3.226-.67 5.296-2.982 6.27-1.096.121-18.99 1.947-19.112 2.008-.974.122-1.643-.122-2.07-.609-1.034-1.156-.73-3.834-.486-5.964.06-.61.121-1.157.182-1.644.244-3.59.609-7.182 1.157-10.712.243-1.644.67-3.287 1.034-4.93.366-1.461.73-2.922.974-4.383 0-.121.061-.304.061-.426 2.74-.852 5.052-2.252 6.878-4.139.365-.365.73-.67 1.156-.913 2.13-1.156 3.896-1.034 5.357.487l5.295 4.504c.06.548.122 1.157.244 1.705l.06.487c1.157 6.147 2.192 11.99 2.252 18.26Z"
            />
            <path
                fill="#000"
                fillOpacity={0.4}
                d="M5.503 53.553c-.061.243-.061.487-.122.791.183-.183 2.374-.974 2.678-1.096.73-.426 1.522-.852 2.191-1.46 1.4-1.218 2.557-2.557 4.444-3.044 1.765-.426 3.104.122 4.382 1.279l4.747 4.017c-.121-1.34-.243-2.617-.304-3.956-.06-1.035-.122-2.01-.183-3.044a55.697 55.697 0 0 1-.304-3.165c-.243-2.13-.487-4.321-.487-6.513a691.924 691.924 0 0 1-1.278-16.129c-.304-4.991-.426-9.252-.122-13.512.061-.548.122-1.096.244-1.704.243-1.279.487-2.618.122-3.652-.305-.792-.792-1.218-1.4-1.218a2.28 2.28 0 0 0-1.279.426c-3.286 1.948-5.417 6.33-6.512 13.39-.366 2.375-.548 4.748-.792 7.122a148.732 148.732 0 0 1-.73 7.121c-.548 3.348-1.4 6.635-2.252 9.8-.487 1.948-1.035 3.956-1.46 5.965-.61 2.434-1.096 5.173-1.583 8.582ZM3.98 84.473c-.913 0-1.643-.305-2.13-.852-1.278-1.4-.913-4.2-.67-6.513.061-.609.122-1.157.183-1.583.243-3.59.609-7.243 1.156-10.773.244-1.704.67-3.347 1.035-4.93.365-1.4.73-2.922.974-4.382.122-.67.243-1.279.304-1.948.487-3.409.974-6.208 1.522-8.704.426-2.008.974-4.017 1.522-5.965.852-3.165 1.704-6.452 2.19-9.738.366-2.313.549-4.748.731-7.06.183-2.374.426-4.81.791-7.183C12.746 7.538 14.998 3.034 18.406.965 20.05-.01 21.511.478 22.058 2.12c.426 1.157.122 2.678-.122 4.017-.121.609-.182 1.157-.243 1.644-.365 4.26-.183 8.46.122 13.451a671.62 671.62 0 0 0 1.278 16.19c0 2.13.244 4.322.487 6.452.122 1.035.243 2.13.365 3.165.061 1.035.122 2.009.244 3.044.121 2.19.304 4.443.67 6.573l.06.487c1.096 6.208 2.13 12.112 2.191 18.381.365 3.53-.791 5.843-3.408 6.94h-.122S4.529 84.411 4.407 84.472h-.426Zm-.791-19.6c-.548 3.531-.974 7.183-1.157 10.713 0 .487-.121 1.035-.182 1.644-.244 2.13-.548 4.808.487 5.965.426.487 1.095.73 2.07.608.06 0 18.016-1.887 19.111-2.008 2.313-.974 3.287-3.044 2.983-6.27-.061-6.269-1.096-12.112-2.192-18.32l-.06-.487c-.122-.548-.183-1.157-.244-1.704l-5.295-4.505c-1.522-1.521-3.226-1.643-5.357-.486a5.457 5.457 0 0 0-1.156.913c-1.826 1.886-4.139 3.286-6.878 4.139 0 .121-.06.304-.06.425-.244 1.522-.61 2.983-.975 4.383-.426 1.643-.852 3.287-1.095 4.99Z"
            />
        </svg>
    )
}
