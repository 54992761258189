import React, { useState } from "react";
import Navbar from "../../Components/Navbar-Comps/Navbar";
// inner section imports
// modal imports
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";
import hut from "../../Assets/images/hut.svg";
import bell from "../../Assets/images/bellIcon.svg";
import CustomCarousel from "../../Components/UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../../Components/Dashboard-Comps/QuickOrderCard";
import { Tooltip } from "react-tooltip";
import { CategoriesTab } from "../../Components/Services-Comps/CategoriesTab";
import { ServicesTab } from "../../Components/Services-Comps/ServicesTab";
import { ProfileInfoTab } from "../../Components/Profile-Comps/ProfileInfoTab";
import { ChangePasswordTab } from "../../Components/Profile-Comps/ChangePasswordTab";
import { TestominalsAndRatingsTab } from "../../Components/Profile-Comps/TestominalsAndRatingsTab";
import toothPic from "../../Assets/images/toothPic.svg";
import priceSlip from "../../Assets/images/priceSlip.svg";
import video from "../../Assets/videos/video.mp4";
import Modal from "react-modal";
import { ServiceGallery, ServiceInformationTab } from "../Orders/AddNewOrder";
import addIcon from "../../Assets/icons/addCircle.svg";
import { useNavigate } from "react-router-dom";
import StageBox from "../../Components/Order-Comps/StageBox";
import EstimatedServicePrice from "../Orders/EstimatedServicePrice";
import editIcon from "../../Assets/icons/editIcon.svg";
import infoIcon from "../../Assets/icons/infoIcon.png";
import moneyIcon from "../../Assets/icons/money.png";
import DetailTile from "../../Components/Profile-Comps/DetailTile";
import { AiOutlineClose } from "react-icons/ai";
import Accordion from "../../Components/InputField-Comps/Accordion";
import completeIcon from "../../Assets/icons/completeIcon.png";
import TextInputField from "../../Components/InputField-Comps/TextInputField";
import { useEffect } from "react";
import TextInputFieldV2 from "../../Components/InputField-Comps/TextInputFieldV2";
import {
  useGetServicesMutation,
  useGetServicesForCCMutation,
} from "../../services/services";
import {
  useSaveUserServiceMutation,
  useGetUserServiceMutation,
  useUpdateUserServiceMutation,
  useDeleteUserServiceMutation,
} from "../../services/userServices";
import { useAuth0 } from "@auth0/auth0-react";
import MyStatefulEditor from "../../Components/InputField-Comps/MyStatefulEditor";
import RichTextEditor from "react-rte";
import DetailTileHtml from "../../Components/Profile-Comps/DetailTileHtml";
import { LoginButton } from "../Login/Login";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import {
  setAllServiceDetails,
  setUserServiceDetails,
} from "../../reducers/SPServiceDetailsReducer";
import { useSelector, useDispatch } from "react-redux";
import { Convert, Converter } from "easy-currencies";
import PriceInputField from "../../Components/InputField-Comps/PriceInputField";
import PriceDetailTile from "../../Components/Profile-Comps/PriceDetailTile";
import ActiveToggleButton from "../../Components/UserManagement-Comps/ActiveToggleButton";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import Notification from "../../Components/Notification/Notification";
import { SoftwareTab } from "../../Components/Software-Comps/SoftwareTab";
import { QuestionsTab } from "../../Components/Questions-Comps/QuestionsTab";

const converter = new Converter();

function Services({
  userRole,
  userInfo,
  emailFromUserProfile,
  profileId,
  setProfileId,
}) {
  const selectedTitlesArray = [];
  const middleTabTitleArrayAdmin = [
    "Service Categories",
    "Provider Levels",
    "Software Categories",
    "Questions",
  ];

  const middleTabContentObjectAdmin = {
    "Service Categories": <ServicesTab />,
    "Provider Levels": <CategoriesTab />,
    "Software Categories": <SoftwareTab />,
    Questions: <QuestionsTab />,
  };
  const middleTabTitleArrayDentist = ["Service Categories", "Provider Levels"];

  const middleTabContentObjectDentist = {
    "Service Categories": <ServicesTab />,
    "Provider Levels": <CategoriesTab />,
  };

  const middleServiceProviderTabTitleArray = [
    "Profile Info",
    "Change Password",
    //"Services",
    // "Testominals & Ratings",
  ];
  const middleServiceProviderTabContentObject = {
    "Profile Info": (
      <ProfileInfoTab
        emailFromUserProfile={emailFromUserProfile}
        profileId={profileId}
        setProfileId={setProfileId}
      />
    ),
    "Change Password": <ChangePasswordTab />,
    "Service Categories": (
      <ServicesServiceProvider userInfo={userInfo} userRole={userRole} />
    ),
    // "Testominals & Ratings": <TestominalsAndRatingsTab />,
  };
  return (
    <div className="flex min-h-screen max-h-fit">
      {userRole === "34fs3" ? (
        //service provider
        <div className="flex min-h-screen max-h-fit w-full">
          <Navbar
            navActiveCss={navActiveCss}
            navHoverCss={navHoverCss}
            iconArray={
              userRole === "89uij7"
                ? superAdminIconArray
                : userRole === "676dza2"
                ? iconArray
                : spIconArray
            }
            middleSection={true}
            middleNavHeading="Services"
            middleNavPosition={2}
            middleTabTitleArray={middleServiceProviderTabTitleArray}
            middleTabContentObject={middleServiceProviderTabContentObject}
            userRole={userRole}
            active="Service Categories"
          />
        </div>
      ) : userRole === "89uij7" ? (
        //admin
        <Navbar
          navActiveCss={navActiveCss}
          navHoverCss={navHoverCss}
          iconArray={
            userRole === "89uij7"
              ? superAdminIconArray
              : userRole === "676dza2"
              ? iconArray
              : spIconArray
          }
          middleSection={true}
          middleNavHeading="Services"
          middleNavPosition={0}
          middleTabTitleArray={middleTabTitleArrayAdmin}
          middleTabContentObject={middleTabContentObjectAdmin}
          userRole={userRole}
          active="Service Categories"
        />
      ) : (
        // dentist
        <Navbar
          navActiveCss={navActiveCss}
          navHoverCss={navHoverCss}
          iconArray={
            userRole === "89uij7"
              ? superAdminIconArray
              : userRole === "676dza2"
              ? iconArray
              : spIconArray
          }
          middleSection={true}
          middleNavHeading="Services"
          middleNavPosition={0}
          middleTabTitleArray={middleTabTitleArrayDentist}
          middleTabContentObject={middleTabContentObjectDentist}
          userRole={userRole}
          active="Service Categories"
        />
      )}
    </div>
  );
}

export default Services;

export const ServicesServiceProvider = ({ userInfo, userRole }) => {
  const personalDetailsStore = useSelector(
    (state) => state.personalDetails.data
  );

  console.log(personalDetailsStore, "personalDetailsStore1111");
  const dispatch = useDispatch();
  const { allService, userService } = useSelector(
    (state) => state.servicesDetails
  );
  const { user, isAuthenticated } = useAuth0();
  const [isServicesUpdated, setIsServicesUpdated] = useState(false);
  const [servicesData, setServicesData] = useState(allService);
  const [userServicesData, setUserServicesData] = useState(userService);
  const [allItems, setAllItems] = useState([]);
  // get query can be here
  const { getAccessTokenSilently } = useAuth0();
  const [getServicesForCC] = useGetServicesForCCMutation();
  const [saveUserService] = useSaveUserServiceMutation();
  const [updateUserService] = useUpdateUserServiceMutation();
  const [deleteUserService] = useDeleteUserServiceMutation();
  const [getUserService, { isLoading: getUserServiceIsLoading }] =
    useGetUserServiceMutation();

  const getServicesData = async (accessToken) => {
    const response = await getServicesForCC(accessToken).unwrap();
    dispatch(setAllServiceDetails(response?.data));
    setServicesData(response?.data);
  };

  const getUserServicesData = async (accessToken) => {
    const response = await getUserService({ accessToken }).unwrap();
    dispatch(setUserServiceDetails(response?.data));
    setUserServicesData(response?.data);
  };

  const readServicesInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getServicesData(accessToken);
    await getUserServicesData(accessToken);
  };

  console.log(!userServicesData?.length || !servicesData?.length, "WHAT");

  useEffect(() => {
    if (isServicesUpdated === true) {
      readServicesInfo();
    }
    setIsServicesUpdated(false);
  }, [isServicesUpdated]);

  useEffect(() => {
    if (!userServicesData?.length || !servicesData?.length) {
      readServicesInfo();
    }
    setIsServicesUpdated(false);
  }, []);

  // Prepare the HTML element once servicesData has data
  // array of components with data for carousel here
  const prepareServicesElements = () => {
    if (userServicesData?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      userServicesData.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            id={eachService._id}
            active={eachService.active !== undefined ? eachService.active : 0}
            image={eachService.serviceObjId.image}
            bgColor={"#fff"}
            textColor={"#555"}
            title={eachService.serviceObjId.serviceName}
            serviceDescription={eachService.serviceObjId.serviceDescription}
            serviceSpecilization={eachService.specialisation}
            price={eachService.actualPrice ? eachService.actualPrice : ""}
            offerPrice={eachService.offerPrice ? eachService.offerPrice : ""}
          />
        )
      );
      console.log(userServicesData, "arrayOfCards");
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  console.log(userServicesData, "userServicesData");

  // array of components with data for carousel here (mobile)
  const arrayCardsMobile = [
    // <QuickOrderCard
    //   id={"1"}
    //   image={toothPic}
    //   bgColor={"#fff"}
    //   textColor={"#555"}
    //   title={"Orthodontic Service"}
    //   serviceDescription={
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    //   }
    //   serviceSpecilization={
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    //   }
    //   price={"500"}
    //   offerPrice={"299"}
    //   tagsArray={[
    //     { name: "tags1", initials: "ta", bg: "#FB6E52" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //   ]}
    // />,
    // <QuickOrderCard
    //   id={"1"}
    //   image={toothPic}
    //   bgColor={"#fff"}
    //   textColor={"#555"}
    //   title={"Orthodontic Service"}
    //   serviceDescription={
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    //   }
    //   serviceSpecilization={
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    //   }
    //   price={"500"}
    //   offerPrice={"299"}
    //   tagsArray={[
    //     { name: "tags1", initials: "ta", bg: "#FB6E52" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //     { name: "tags2", initials: "ta", bg: "#FBA352" },
    //   ]}
    // />,
  ];

  // array of object for accordion modal here
  // const itemsInAccordion = [
  //   {
  //     id: 1,
  //     title: "Section 1",
  //     content:[]
  //   },
  //   {
  //     id: 2,
  //     title: "Section 2",
  //     content: [
  //       { id: 4, text: "Item 4" },
  //       { id: 5, text: "Item 5" },
  //       { id: 6, text: "Item 6" },
  //     ],
  //   },
  //   // Add more items as needed
  // ];

  // Prepare the HTML element once servicesData has data
  // array of components with data for carousel here
  const prepareServicesList = () => {
    if (servicesData?.length) {
      const arrayOfServiceList = [];
      // Create HTML elements based on servicesData
      servicesData.map((eachService) =>
        arrayOfServiceList.push({
          id: eachService._id,
          title: eachService.serviceName,
          sectionChecked:
            eachService?.userSelectedService?.length > 0 ? true : false,
          sectionId:
            eachService?.userSelectedService?.length > 0
              ? eachService?.userSelectedService[0]._id
              : "",
          content: [],
          active: eachService.active,
        })
      );
      return arrayOfServiceList;
    }
    return [];
    // Return null if servicesData is empty or null
  };
  const prepareServicesListSelected = () => {
    if (servicesData?.length) {
      const arrayOfServiceListSelected = [];
      // Create HTML elements based on servicesData
      servicesData.map((eachService) =>
        arrayOfServiceListSelected.push({ id: eachService._id })
      );

      return { items: arrayOfServiceListSelected };
    }
    return [];
    // Return null if servicesData is empty or null
  };
  const [activePage, setActivePage] = useState({
    title: "InitialLoading",
    id: "",
    active: 0,
    serviceDescription: "",
    serviceSpecilization: "",
    price: "",
    offerPrice: "",
    tagsArray: [],
  });

  const [arrayOfQuickOrderCards, setArrayOfQuickOrderCards] = useState([]);
  //   const htmlelements = prepareServicesElements();
  // // debugger
  // if(htmlelements.length > 0){
  //   setArrayOfQuickOrderCards(htmlelements);
  // }

  const [arrayOfQuickOrderCardsMobile, setArrayOfQuickOrderCardsMobile] =
    useState(arrayCardsMobile);

  // modal states
  const [addServiceModalIsOpen, setAddServiceModalIsOpen] = useState(false);
  const [editServiceModalIsOpen, setEditServiceModalIsOpen] = useState(false);
  const [isServiceAdded, setIsServiceAdded] = useState(false);
  // edit service modal states
  const [serviceDescription, setServiceDescription] = useState(
    activePage?.serviceDescription
  );
  const [id, setid] = useState(activePage?.id);
  const [title, setTitle] = useState(activePage?.title);
  const [serviceSpecilization, setServiceSpecilization] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [serviceNewSpecilization, setServiceNewSpecilization] = useState();
  const [price, setPrice] = useState(activePage.price);
  const [userPrice, setUserPrice] = useState("-");
  const [userOfferPrice, setUserOfferPrice] = useState("-");
  const [offerPrice, setOfferPrice] = useState(activePage.offerPrice);
  const [isEditServiceSubmitted, setIsEditServiceSubmitted] = useState(false);
  const [selectedSer, setselectedSer] = useState([]);
  const [unselectedSer, setunselectedSer] = useState([]);
  const [active, setActive] = useState(activePage?.active === 1 || false);
  const personalDetails = useSelector((state) => state.personalDetails);
  // const [selectedItems, setSelectedItems] = useState([]);
  const [cardClicked, setCardClick] = useState(false);
  console.log(active, "userPrice");

  const handleCardOnClick = async (card) => {
    setActivePage({
      title: card.props.title,
      serviceDescription: card.props.serviceDescription,
      serviceSpecilization: card.props.serviceSpecilization,
      price: card.props.price,
      offerPrice: card.props.offerPrice,
      tagsArray: card.props.tagsArray,
      id: card.props.id,
      active: card.props.active,
    });
    setCardClick(true);

    // console.log(card.prods.active, "activePage");

    setUserPrice(
      (
        await Convert(card.props.price)
          .from("USD")
          .to(personalDetailsStore[0]?.bankDetails[0]?.currency)
      ).toFixed(2)
    );
    setUserOfferPrice(
      (
        await Convert(card.props.offerPrice)
          .from("USD")
          .to(personalDetailsStore[0]?.bankDetails[0]?.currency)
      ).toFixed(2)
    );
  };
  // remove quick card func here
  const removeQuickCardHandler = async (id) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await deleteUserService({
      accessToken,
      _id: id,
    }).unwrap();
    GetToast(resp.data["message"]);
    await getUserServicesData(accessToken);

    // removing from desktop
    setArrayOfQuickOrderCards((prevComponents) =>
      prevComponents.filter((component) => component.props.id !== id)
    );
    // removing from mobile array
    setArrayOfQuickOrderCardsMobile((prevComponents) =>
      prevComponents.filter((component) => component.props.id !== id)
    );
    // setting the page to initial state
    setActivePage({
      title: "InitialLoading",
      id: "",
      active: 0,
      serviceDescription: "",
      serviceSpecilization: "",
      price: "",
      offerPrice: "",
      tagsArray: [],
    });
  };
  const addServiceHandler = async () => {
    // saveUserService
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    if (selectedSer.length > 0) {
      selectedSer.forEach((element) => {
        handleEachServiceSave(accessToken, element);
      });
      setIsServicesUpdated(true);
    } else {
      GetToast("Please select any one service to save !!!");
    }

    if (unselectedSer.length > 0) {
      unselectedSer.forEach((element) => {
        handleEachServiceDelete(accessToken, element.id);
      });
    }
    // else {
    //   GetToast("Please unselect any one service to delete !!!");
    // }
    setIsServicesUpdated(true);
    setIsServiceAdded(true);
  };
  const handleEachServiceSave = async (accessToken, serviceObjId) => {
    // debugger;
    const resp = await saveUserService({
      accessToken,
      serviceObjId,
      active: 1,
    }).unwrap();

    //await getUserServicesData(accessToken);
    //await getServicesData(accessToken);
  };

  const handleEachServiceDelete = async (accessToken, _id) => {
    // debugger;
    await deleteUserService({
      accessToken,
      _id,
    }).unwrap();
    //await getUserServicesData(accessToken);
    //await getServicesData(accessToken);
  };
  // functions for accordion modal
  const handleSelectionChange = (selectedTitlesArray) => {
    const preSelectedItems = allItems
      .filter((item) => item.sectionChecked === true)
      .map((item) => {
        // const selectedData = selectedTitlesArray.find(
        //   (selected) => selected.all === true
        // );

        // const selectedIds = selectedData?.items || [];
        // const unselected = item.content.filter(
        //   (contentItem) => !selectedIds.includes(contentItem.id)
        // );

        // const titleSelected = selectedData ? selectedData.all : false;

        return {
          id: item.sectionId,
          title: item.title,
          // unselected,
          // titleSelected,
        };
      });

    const newSelectedItems = selectedTitlesArray.filter(
      (item) => item.all === true
    );

    const arrayOfUnselectedServicesOffered = preSelectedItems.filter(
      (preItems) => {
        const titleValue = preItems.title;
        return !newSelectedItems.some(
          (selectedItem) => selectedItem.title === titleValue
        );
      }
    );

    setunselectedSer(arrayOfUnselectedServicesOffered);

    const arrayOfServicesOffered = selectedTitlesArray.reduce((acc, curr) => {
      if (curr.all === true) {
        acc.push(curr.id);
      }
      if (curr.items && curr.items.length > 0) {
        curr.items.forEach((i) => {
          acc.push(i);
        });
      }

      return acc;
    }, []);
    // pass on the data to the backend (arrayOfServicesOffered)
    setselectedSer(arrayOfServicesOffered);
  };
  // edit service modal functions
  const editServiceHandler = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await updateUserService({
      accessToken,
      _id: id,
      offerPrice: offerPrice,
      actualPrice: price,
      specialisations: serviceSpecilization,
      active: active === true ? 1 : 0,
    }).unwrap();
    if (resp?.type === 1) {
      setActivePage([]);
      setCardClick(false);
    }
    setIsServicesUpdated(true);
    setActivePage({
      title: title,
      id: id,
      //active: active,
      price: price,
      offerPrice: offerPrice,
      serviceSpecilization: serviceSpecilization,
    });
    setIsEditServiceSubmitted(true);
  };

  // functions for edit service modal
  const validateServiceDescription = (value) => {
    return value?.trim().length > 0;
  };
  const validateServiceSpecilization = (value) => {
    return value?.trim().length > 0;
  };
  const validatePrice = (value) => {
    return value?.trim() > 0;
  };
  const validateOfferPrice = (value) => {
    return value?.trim() > 0;
  };
  const customLabelHandler = (id) => {
    console.log(id);
    setArrayOfQuickOrderCards((prevComponents) =>
      prevComponents.map((component) => {
        if (component.props.id === id) {
          setActivePage({
            ...activePage,
            serviceSpecilization: "",
          });
          // Change the prop value for the matching component
          return React.cloneElement(component, { serviceSpecilization: "" });
        }
        return component;
      })
    );
    setArrayOfQuickOrderCardsMobile((prevComponents) =>
      prevComponents.map((component) => {
        if (component.props.id === id) {
          // Change the prop value for the matching component
          return React.cloneElement(component, { serviceDescription: "" });
        }
        return component;
      })
    );
  };
  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      // "serviceDescription",
      "serviceSpecilization",
      "price",
      "offerPrice",
    ];
    return requiredFields.includes(fieldName);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "serviceDescription") setServiceDescription(value);
    if (name === "serviceSpecilization")
      setServiceSpecilization(value.toString("html"));
    if (name === "price") setUserPrice(value);
    if (name === "offerPrice") setUserOfferPrice(value);
  };
  useEffect(() => {
    setid(activePage?.id);
    setServiceDescription(activePage?.serviceDescription);
    setTitle(activePage?.title);
    setPrice(activePage?.price);
    setOfferPrice(activePage?.offerPrice);
    setServiceSpecilization(activePage?.serviceSpecilization);
    setActive(activePage?.active === 1 || false);
  }, [
    activePage?.id,
    activePage?.title,
    activePage?.offerPrice,
    activePage?.price,
    activePage?.serviceDescription,
    activePage?.serviceSpecilization,
    activePage?.active,
  ]);

  const priceConverter = async (userCurrency, amount) => {
    if (userCurrency && amount) {
      const value = await converter.convert(amount, userCurrency, "USD");
      setPrice(value.toFixed(2));
    }
  };

  const offerPriceConverter = async (userCurrency, amount) => {
    if (userCurrency && amount) {
      const value = await converter.convert(amount, userCurrency, "USD");
      setOfferPrice(value.toFixed(2));
    }
  };

  useEffect(() => {
    priceConverter(
      personalDetailsStore[0]?.bankDetails[0]?.currency,
      userPrice
    );
    offerPriceConverter(
      personalDetailsStore[0]?.bankDetails[0]?.currency,
      userOfferPrice
    );
  }, [userPrice, userOfferPrice]);

  console.log(userPrice, userOfferPrice, "DWDHUDWN");

  return (
    <div className="MAIN-DASH bg-black p-4 w-full min-h-screen h-full flex flex-col md:py-4 pt-4 pb-20">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {personalDetails.data[0]?.firstName ?? user.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img
                      className="relative top-[4px] right-0"
                      src={greenTick}
                      alt=""
                    />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">
                      {userInfo[0]?.crowdHireId}
                    </span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img
                  className="relative top-[0px] right-0 h-5 w-5"
                  src={redCross}
                  alt=""
                />
              </button>
            )
          ) : (
            <></>
          )}

          <Notification />
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:items-center items-start justify-between my-4 md:gap-96 gap-4">
        <div className="flex flex-1 gap-4 items-center w-full">
          <p className="font-medium text-[34px] text-[#555]">Services</p>
        </div>
      </div>
      <div className="bg-white py-4 px-4 rounded-md">
        <div className="flex w-full justify-between items-center">
          <p className="text-xl font-semibold text-[#2E2C34]">Your Services</p>
          <button
            className="bg-[#FF7E00] px-4 py-2 font-sm text-white rounded-md flex items-center gap-2"
            onClick={() => {
              setAddServiceModalIsOpen(true);
              setIsServiceAdded(false);
            }}
          >
            <img className="" src={addIcon} alt="" />
            Add Service
          </button>
        </div>
        {userServicesData?.length === 0 ? (
          <div
            role="status"
            className="animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
          >
            <div className="w-full flex gap-5">
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
            </div>
          </div>
        ) : (
          <div className="CAROUSEL mb-8">
            <div className="md:block hidden">
              <CustomCarousel
                rows={1}
                cols={8}
                gapY={20}
                gapX={20}
                // numberOfSliders={[
                //   // these are three slides and three arrays of cards for each slide
                //   { 0: prepareServicesElements() },
                // ]}
                // CHANGE DATA HERE
                completeSliders={prepareServicesElements()}
                numberOfCardsShownInSingleSlide={5}
                handleCardOnClick={handleCardOnClick}
              />
            </div>
            <div className="md:hidden block">
              <CustomCarousel
                rows={1}
                cols={2}
                gapY={20}
                gapX={20}
                completeSliders={prepareServicesElements()}
                numberOfCardsShownInSingleSlide={5}
                handleCardOnClick={handleCardOnClick}
              />
            </div>
          </div>
        )}

        <div className="bg-black bg-opacity-10 w-full h-[1px]"></div>
        {activePage.title !== "InitialLoading" && (
          <ServiceInformationTabAdmin
            title={activePage.title}
            serviceDescription={activePage.serviceDescription}
            serviceSpecilization={activePage.serviceSpecilization}
            tagsArray={activePage.tagsArray}
            price={activePage.price}
            offerPrice={activePage.offerPrice}
            removeQuickCardHandler={removeQuickCardHandler}
            id={activePage.id}
            setEditServiceModalIsOpen={setEditServiceModalIsOpen}
            userPrice={userPrice}
            userOfferPrice={userOfferPrice}
            cardClicked={cardClicked}
          />
        )}
      </div>
      <Modal
        isOpen={addServiceModalIsOpen}
        onRequestClose={() => setAddServiceModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <div className="h-full">
          <div className="MODAL-BODY flex flex-col h-full">
            <button
              className="w-full text-right"
              onClick={() => setAddServiceModalIsOpen(false)}
            >
              <AiOutlineClose className="ml-auto" />
            </button>
            <p className="text-[26px] font-medium text-black leading-8 mb-12">
              Add Services
            </p>
            <div className="flex-1">
              {isServiceAdded ? (
                <div className="h-full flex flex-col">
                  <div className="flex-1 flex flex-col items-center justify-center">
                    <img src={completeIcon} alt="saved" />
                    <p className="text-white font-medium text-2xl my-4">
                      Service Updated
                    </p>
                    <p className="text-[#777777] text-center">
                      Your Service has been Updated successfully.
                    </p>
                  </div>
                  <div className="flex items-center justify-end gap-4 pt-4">
                    <button
                      onClick={() => setAddServiceModalIsOpen(false)}
                      className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                    >
                      Okay
                    </button>
                  </div>
                </div>
              ) : (
                <div className="h-full flex flex-col">
                  <div className=" flex-1 border-b border-[#000] border-opacity-20">
                    <Accordion
                      items={prepareServicesList()}
                      setAllItems={setAllItems}
                      // items={itemsDummy}
                      onSelectionChange={handleSelectionChange}
                    />
                    {/* <CustomTabsProfile
                tabNamesArray={tabNamesArray}
                outputObject={tileObjectModal}
              /> */}
                  </div>
                  <div className="flex items-center justify-end gap-4 pt-4">
                    <button
                      onClick={() => setAddServiceModalIsOpen(false)}
                      className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => addServiceHandler()}
                      type="submit"
                      className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                    >
                      Update
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={editServiceModalIsOpen}
        onRequestClose={() => setEditServiceModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <div className="h-full">
          <div className="MODAL-BODY flex flex-col h-full">
            <button
              className="w-full text-right"
              onClick={() => setEditServiceModalIsOpen(false)}
            >
              <AiOutlineClose className="ml-auto" />
            </button>
            <div className="mb-8 mt-2 flex justify-between">
              <p className="text-[26px] font-medium text-black leading-8 ">
                Edit Services
              </p>
              {isEditServiceSubmitted ? (
                <></>
              ) : (
                <div className="p-2 flex items-center">
                  <p className="mr-4 text-base text-gray-500">Active</p>
                  <ActiveToggleButton
                    currentState={active}
                    setState={setActive}
                  />
                </div>
              )}
            </div>
            <div className="flex-1 MODAL-CONTENT">
              {isEditServiceSubmitted ? (
                <div className="h-full">
                  <div className=" h-full flex flex-col items-center justify-center">
                    <img src={completeIcon} alt="saved" />
                    <p className="text-black font-medium text-2xl my-4">
                      Service Edited
                    </p>
                    <p className="text-[#777777]">
                      Your Service has been edited successfully.
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  {/* <TextInputField
                    labelColor={"#777"}
                    labelText={"Service Description"}
                    errorMessage={"Please enter a valid service description"}
                    placeholder={"Enter service description"}
                    htmlFor={"serviceDescription"}
                    name={"serviceDescription"}
                    value={serviceDescription}
                    handleInputChange={handleInputChange}
                    validationFunctionName={validateServiceDescription}
                    isSubmitted={isEditServiceSubmitted}
                    isFieldRequired={isFieldRequired("serviceDescription")}
                  /> */}
                  {/* <TextInputFieldV2
                    labelColor={"#777"}
                    labelText={"Service Specilization"}
                    errorMessage={"Please enter a valid service specilization"}
                    placeholder={"Enter service specilization"}
                    htmlFor={"serviceSpecilization"}
                    name={"serviceSpecilization"}
                    value={serviceNewSpecilization}
                    handleInputChange={handleInputChange}
                    validationFunctionName={validateServiceSpecilization}
                    isSubmitted={isEditServiceSubmitted}
                    isFieldRequired={isFieldRequired("serviceSpecilization")}
                    customLabelData={activePage.serviceSpecilization}
                    customLabelHandler={() => customLabelHandler(activePage.id)}
                  /> */}
                  <label className="text-xl font-semibold">{title}</label>
                  <br></br>
                  <br></br>
                  <label>{serviceDescription}</label>
                  <br></br>
                  <br></br>
                  <MyStatefulEditor
                    labelColor={"#777"}
                    labelText={"Service Specialization"}
                    errorMessage={"Please enter a valid service specilization"}
                    placeholder={"Enter service specilization"}
                    htmlFor={"serviceSpecilization"}
                    name={"serviceSpecilization"}
                    value={serviceSpecilization}
                    handleInputChange={handleInputChange}
                    validationFunctionName={validateServiceSpecilization}
                    isSubmitted={isEditServiceSubmitted}
                    isFieldRequired={isFieldRequired("serviceSpecilization")}
                    customLabelData={activePage.serviceSpecilization}
                    customLabelHandler={() => customLabelHandler(activePage.id)}
                  />
                  {/* <TextInputField
                    labelColor={"#777"}
                    labelText={"Price"}
                    errorMessage={"Please enter a Price"}
                    placeholder={"Enter Price"}
                    htmlFor={"price"}
                    name={"price"}
                    value={price}
                    handleInputChange={handleInputChange}
                    validationFunctionName={validatePrice}
                    isSubmitted={isEditServiceSubmitted}
                    isFieldRequired={isFieldRequired("price")}
                  /> */}
                  <PriceInputField
                    labelColor={"#777"}
                    labelText={"Price"}
                    errorMessage={"Please enter a Price"}
                    placeholder={"Enter Price"}
                    htmlFor={"price"}
                    name={"price"}
                    value={userPrice}
                    handleInputChange={handleInputChange}
                    validationFunctionName={validatePrice}
                    isSubmitted={isEditServiceSubmitted}
                    isFieldRequired={isFieldRequired("price")}
                    currency={personalDetailsStore[0]?.bankDetails[0]?.currency}
                    userValue={price}
                  />
                  <PriceInputField
                    labelColor={"#777"}
                    labelText={"Offer Price"}
                    errorMessage={"Please enter a valid offer price"}
                    placeholder={"Enter offer price"}
                    htmlFor={"offerPrice"}
                    name={"offerPrice"}
                    value={userOfferPrice}
                    handleInputChange={handleInputChange}
                    validationFunctionName={validateOfferPrice}
                    isSubmitted={isEditServiceSubmitted}
                    isFieldRequired={isFieldRequired("offerPrice")}
                    currency={personalDetailsStore[0]?.bankDetails[0]?.currency}
                    userValue={offerPrice}
                  />
                  {/* <TextInputField
                    labelColor={"#777"}
                    labelText={"Offer Price"}
                    errorMessage={"Please enter a valid offer price"}
                    placeholder={"Enter offer price"}
                    htmlFor={"offerPrice"}
                    name={"offerPrice"}
                    value={offerPrice}
                    handleInputChange={handleInputChange}
                    validationFunctionName={validateOfferPrice}
                    isSubmitted={isEditServiceSubmitted}
                    isFieldRequired={isFieldRequired("offerPrice")}
                  />
                  <p>{userOfferPrice}</p> */}
                </div>
              )}
            </div>
            {isEditServiceSubmitted ? (
              <div className="flex items-center justify-end gap-4 pt-4">
                <button
                  onClick={() => {
                    setEditServiceModalIsOpen(false);
                    setIsEditServiceSubmitted(false);
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Update
                </button>
              </div>
            ) : (
              <div className="flex items-center justify-end gap-4 pt-4">
                <button
                  onClick={() => setEditServiceModalIsOpen(false)}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>
                <button
                  onClick={() => editServiceHandler()}
                  type="submit"
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Update
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export const ServiceInformationTabAdmin = ({
  id,
  removeQuickCardHandler,
  title,
  serviceDescription,
  serviceSpecilization,
  tagsArray,
  price,
  offerPrice,
  setEditServiceModalIsOpen,
  userPrice,
  userOfferPrice,
  cardClicked,
}) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white p-4 rounded-md md:flex-1 md:h-auto h-[50vh] overflow-auto">
      <div className="flex md:flex-row flex-col w-full justify-between md:items-center md:gap-0 gap-2">
        <div className="flex gap-2 items-center">
          <p className=" font-semibold text-xl text-[#2E2C34]">{title}</p>
          {/* <p className="text-[#2E2C34]">
            If You Are Not Providing this service{" "}
            <span
              className="text-[#F35F5D] font-semibold cursor-pointer"
              onClick={() => removeQuickCardHandler(id)}
            >
              Remove
            </span>
          </p> */}
        </div>
        <button
          onClick={() => {
            setEditServiceModalIsOpen(true);
          }}
          disabled={!cardClicked}
          className={
            cardClicked
              ? "bg-white px-4 py-2 border border-[#4E81F4] rounded-md flex gap-2 items-center text-[#4E81F4] text-base font-semibold w-fit"
              : "bg-white px-4 py-2 border border-[#4E81F4] opacity-50 rounded-md flex gap-2 items-center text-[#4E81F4] text-base font-semibold w-fit"
          }
        >
          <span>
            <img src={editIcon} alt="" />
          </span>
          Edit Service
        </button>
      </div>
      <div className="grid md:grid-cols-3 grid-rows-2 gap-4 md:my-8 my-4">
        <DetailTile
          heading={"Service Description"}
          subHeading={
            cardClicked ? serviceDescription : "Click on a card to view data..."
          }
          icon={infoIcon}
        />
        <DetailTileHtml
          heading={"Service Specialization"}
          subHeading={
            cardClicked
              ? serviceSpecilization
              : "Click on a card to view data..."
          }
          icon={infoIcon}
        />

        {/* <DetailTile
          heading={"Tags"}
          subHeading={" Location of dummy, address of dummy, location ma..."}
          tags={true}
          tagsArray={tagsArray}
          icon={editIcon}
        /> */}
        <PriceDetailTile
          heading={"Price"}
          subHeading={cardClicked ? price : "Click on a card to view data..."}
          icon={infoIcon}
        />
        <PriceDetailTile
          heading={"Offer Price"}
          subHeading={
            cardClicked ? offerPrice : "Click on a card to view data..."
          }
          icon={infoIcon}
        />
      </div>
    </div>
  );
};
