import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useSaveCategoryMutation,
  useUpdateCategoryMutation,
} from "../../services/category";
import {
  useSaveFileMutation
} from "../../services/uploadFiles";
import { useEffect } from "react";
// import DropzoneComponent from "../InputField-Comps/DropzoneField";
import FileUploadComponent from '../InputField-Comps/FilePondField';
import { GetToast } from "../Toast-Comps/GetToast";
import { BiLoaderAlt } from "react-icons/bi";



const InputFieldsCategory = ({
  setModalIsOpen,
  setIsCategoriesUpdated,
  isEditing,
  editingCategoryRow,
  getData,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [saveCategories] = useSaveCategoryMutation();
  const [updateCategories] = useUpdateCategoryMutation();
  const [saveFile] = useSaveFileMutation();

  const [categoryName, setCategoryName] = useState("");
  const [categoryCode, setCategoryCode] = useState("");
  // const [categoryIcon, setCategoryIcon] = useState([]);
  const [categoryIcon, setCategoryIcon] = useState("");
  const [categoryIconLoading, setCategoryIconLoading] = useState(false);
  const [categoryTextArea, setCategoryTextArea] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isValid, setIsValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "categoryName") setCategoryName(value);
    if (name === "categoryCode") setCategoryCode(value);
    if (name === "categoryIcon") {
      const file = event.target.files[0];
      // setCategoryIcon(file);
      validateFile(file);
    }
    if (name === "categoryTextArea") setCategoryTextArea(value);
  };
  const validateFile = (file) => {
    const errors = [];

    if (!file) {
      errors.push("No file selected.");

      return;
    }

    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSizeInBytes) {
      errors.push("File size exceeds the maximum allowed limit.");
    }

    // Example validation: check file type
    const allowedTypes = ["image/jpeg", "image/png"];
    if (!allowedTypes.includes(file.type)) {
      errors.push("Invalid file type. Only JPEG and PNG files are allowed.");
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Perform validation for each input

    const isCategoryNameValid =
      !isFieldRequired("categoryName") || validateCategoryName(categoryName);

    const isCategoryCodeValid =
      !isFieldRequired("categoryCode") || validateCategoryCode(categoryCode);

    const isCategoryIconValid =
      !isFieldRequired("categoryIcon") || validateCategoryIcon(categoryIcon);

    const isCategoryTextAreaValid =
      !isFieldRequired("categoryTextArea") ||
      validateCategoryTextArea(categoryTextArea);

    // Set overall form validity
    setIsValid(
      isCategoryNameValid && isCategoryCodeValid && isCategoryIconValid
    );
    setIsSubmitted(true);

    if (
      isCategoryCodeValid &&
      isCategoryNameValid &&
      isCategoryIconValid &&
      isCategoryTextAreaValid
    ) {
      // calling any kind of submission api here
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      if (isEditing) {
        const resp = await updateCategories({
          accessToken,
          categoryName,
          categoryCode,
          categoryDescription: categoryTextArea,
          categoryIcon,
          _id: editingCategoryRow._id,
        });
        if (resp?.data?.type === 1) {
          GetToast("Level Successfully Updated !!");
        } else {
          GetToast("OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.", "error");
        }
      } else {
        const resp = await saveCategories({
          accessToken,
          categoryName,
          categoryCode,
          categoryIcon,
          categoryDescription: categoryTextArea,
        }).unwrap();
        if (resp?.type === 1) {
          GetToast("Level Successfully Saved !!");
        } else {
          GetToast("OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.", "error");
        }
      }

      await getData(accessToken);
      setModalIsOpen(false);
    }
  };
 
  const handleFileUpload = async (uploadedFiles) => {
    setCategoryIconLoading(true);
    const file = uploadedFiles[0];
    // Access and use the uploaded files here
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await saveFile({
      accessToken,
      file,
    });
    if(resp){
      // debugger
      if(resp.data.url){
        setCategoryIcon(resp.data.url);
      }
    }
    setCategoryIconLoading(false);
    
    // Perform any further operations with the files, such as saving to state, sending to the server, etc.
  };
  

  const validateCategoryName = (value) => {
    return value?.trim().length > 0;
  };
  const validateCategoryCode = (value) => {
    return value?.trim().length > 5;
  };

  const validateCategoryIcon = (file) => {
    if (file) {
      return true;
    }
    return false;
  };
  const validateCategoryTextArea = (value) => {
    return value?.trim().length > 0;
  };
  const isFieldRequired = (fieldName) => {
    // change the required fields from here
    const requiredFields = [
      "categoryName",
      // "categoryCode",
      // "categoryIcon",
      // "categoryTextArea",
    ];
    return requiredFields.includes(fieldName);
  };

  useEffect(() => {
    if (isEditing && editingCategoryRow) {
      setCategoryName(editingCategoryRow.categoryName);
      setCategoryCode(editingCategoryRow.categoryCode);
      setCategoryTextArea(editingCategoryRow.categoryDescription);
    }
  }, [editingCategoryRow, isEditing]);

  return (
    <div className="h-full">
      <form onSubmit={handleSubmit} className=" h-full flex flex-col">
        <div className="flex-1 pt-8 pb-8">
          <div className="relative">
            <CategoryNameInputField
              labelText={"Level Name *"}
              labelColor={"#777"}
              errorMessage={"Please enter a valid Level Name"}
              placeholder={"Level Name"}
              categoryName={categoryName}
              handleInputChange={handleInputChange}
              isSubmitted={isSubmitted}
              validateCategoryName={validateCategoryName}
              isFieldRequired={isFieldRequired("categoryName")}
            />
          </div>
          <div className="relative">
            <CategoryCodeInputField
              labelText={"Level Code *"}
              labelColor={"#777"}
              errorMessage={"Level Code must be at least 5 characters long"}
              placeholder={"Level Code"}
              categoryCode={categoryCode}
              handleInputChange={handleInputChange}
              isSubmitted={isSubmitted}
              validateCategoryCode={validateCategoryCode}
              isFieldRequired={isFieldRequired("categoryCode")}
            />
          </div>
          <div className="relative">
            <CategoryIconInputField
              labelText={"Level Icon"}
              labelColor={"#777"}
              errorMessage={""}
              placeholder={"Select Level Icon"}
              categoryIcon={categoryIcon}
              isFieldRequired={isFieldRequired("categoryIcon")}
              handleFileUpload = {handleFileUpload}
              isEditing = {isEditing}
              initialFiles={editingCategoryRow?.image ?? ""}
            />
          </div>
          <div className="relative">
            <CategoryTextAreaField
              labelText={"Level Description"}
              labelColor={"#777"}
              errorMessage={""}
              placeholder={"Enter your description here"}
              categoryTextArea={categoryTextArea}
              handleInputChange={handleInputChange}
              isSubmitted={isSubmitted}
              validateCategoryTextArea={validateCategoryTextArea}
              isFieldRequired={isFieldRequired("categoryTextArea")}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-4">
          <button
            onClick={() => setModalIsOpen(false)}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={categoryIconLoading}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl h-full"
          >
            {categoryIconLoading ? <BiLoaderAlt className="animate-spin"/> : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputFieldsCategory;

export function CategoryNameInputField({
  labelText,
  labelColor,
  errorMessage,
  placeholder,
  isFieldRequired,
  categoryName,
  handleInputChange,
  isSubmitted,
  validateCategoryName,
}) {
  return (
    <>
      <label
        htmlFor="categoryName"
        style={{
          color: `${
            isSubmitted && !validateCategoryName(categoryName)
              ? "border-red-500"
              : ""
          }`,
        }}
        className="text-[16px] text-white leading-4"
      >
        {labelText}
      </label>

      <input
        type="text"
        id="categoryName"
        name="categoryName"
        value={categoryName}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={`border p-2 w-full text-white text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 bg-secondary${
          isSubmitted && !validateCategoryName(categoryName) && isFieldRequired
            ? "border-red-500"
            : ""
        }`}
        required={isFieldRequired}
      />
      {isSubmitted &&
        !validateCategoryName(categoryName) &&
        isFieldRequired && (
          <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
            {errorMessage}
          </p>
        )}
    </>
  );
}
export function CategoryCodeInputField({
  labelText,
  labelColor,
  errorMessage,
  placeholder,
  isFieldRequired,
  categoryCode,
  handleInputChange,
  isSubmitted,
  validateCategoryCode,
}) {
  return (
    <>
      <label
        htmlFor="categoryCode"
        style={{
          color: `${
            isSubmitted && !validateCategoryCode(categoryCode)
              ? "border-red-500"
              : ""
          }`,
        }}
        className="text-[16px] text-white leading-4"
      >
        {labelText}
      </label>

      <input
        type="phone"
        id="categoryCode"
        name="categoryCode"
        value={categoryCode}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={`border p-2 w-full text-white text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 bg-secondary ${
          isSubmitted && !validateCategoryCode(categoryCode) && isFieldRequired
            ? "border-red-500"
            : ""
        }`}
        required={isFieldRequired}
      />
      {isSubmitted &&
        !validateCategoryCode(categoryCode) &&
        isFieldRequired && (
          <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
            {errorMessage}
          </p>
        )}
    </>
  );
}
export function CategoryIconInputField({
  labelText,
  labelColor,
  handleFileUpload,
  isEditing,
  initialFiles
}) {
  return (   
    <>
    <label
        htmlFor="categoryIcon"
        className="text-[16px] text-white leading-4"
      >
        {labelText}
      </label>
      <FileUploadComponent maxFiles={1} acceptedFileTypes={['image/jpeg', 'image/png']} onUpload={handleFileUpload} allowMultipleFiles={false} initialFiles={initialFiles ? [{ source: initialFiles, options: { type: "local" }}] : []}/>
    </>
  );
}
export function CategoryTextAreaField({
  labelText,
  labelColor,
  errorMessage,
  placeholder,
  isFieldRequired,
  categoryTextArea,
  handleInputChange,
  isSubmitted,
  validateCategoryTextArea,
}) {
  return (
    <>
      <label
        htmlFor="categoryTextArea"
        style={{
          color: `${
            isSubmitted && !validateCategoryTextArea(categoryTextArea)
              ? "border-red-500"
              : ""
          }`,
        }}
        className="text-[16px] text-white leading-4"
      >
        {labelText}
      </label>

      <textarea
        rows={10}
        type="file"
        id="categoryTextArea"
        name="categoryTextArea"
        value={categoryTextArea}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={`border p-2 w-full text-white text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 bg-secondary ${
          isSubmitted &&
          !validateCategoryTextArea(categoryTextArea) &&
          isFieldRequired
            ? "border-red-500"
            : ""
        }`}
        required={isFieldRequired}
      ></textarea>
      {isSubmitted &&
        !validateCategoryTextArea(categoryTextArea) &&
        isFieldRequired && (
          <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
            {errorMessage}
          </p>
        )}
    </>
  );
}
