import React from "react";
import ReactDOM from "react-dom"; // Keep this import
import "./Assets/global-css.css";
import 'react-tooltip/dist/react-tooltip.css';
import 'react-toastify/dist/ReactToastify.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { Auth0Provider } from "@auth0/auth0-react";
//import GlobalLoader from './Components/Dashboard-Comps/GlobalLoader';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

ReactDOM.render(
  
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUDIENCE_URL,
    }}
    scope="openid email"
  >
    {/* <GlobalLoader> */}
    <Provider store={store}>
      <Router>
        {/* Wrap the <App /> with the <GlobalLoader> */}
        {/* <GlobalLoader> */}
          <App />
        {/* </GlobalLoader> */}
      </Router>
    </Provider>
    {/* </GlobalLoader> */}
  </Auth0Provider>,
  document.getElementById("root")
);

reportWebVitals();
