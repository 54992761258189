import React, { useState } from 'react';
import ticket from "../../Assets/icons/ticket.svg";
import replyIcon from "../../Assets/icons/replyIcon.svg";
import { useSaveCommOrdersMutation, useUpdateCommOrdersMutation } from '../../services/orders';
import { useAuth0 } from '@auth0/auth0-react';
import TextAreaInputField from '../InputField-Comps/TextAreaInputField';
import { GetToast } from '../Toast-Comps/GetToast';

const ConcernCard = ({ item, getReadCommData, orderObjId }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [isReplyBoxOpen, setIsReplyBoxOpen] = useState(false);
    const [saveCommOrders] = useSaveCommOrdersMutation();
    const [concernDescription, setConcernDescription] = useState('');


    const handleReply = async () => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
            },
        });

        const resp = await saveCommOrders({
            accessToken,
            orderObjId: orderObjId,
            typofCommunication: 1,
            commStatus: 1,
            readStatus: 0,
            //imageurl: imageUrl,
            parentObjId: item._id,
            communicationTile: item.communicationTile,
            communicationText: concernDescription
        })
        if (resp.data.type === 1) {
            GetToast("Upload success");
            getReadCommData();
            setConcernDescription('')
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "concernDescription") setConcernDescription(value);
    };

    return (
        <div key={item._id} className="border border-[#D2D2D2] rounded-lg p-4 mb-4">
            <div className="flex gap-4 items-center mb-4">
                <div className="bg-[#DCE6FD] h-12 w-12 rounded-full flex items-center justify-center">
                    <img src={ticket} alt="" />
                </div>
                <p className="text-white font-medium text-xl">{item.communicationTile}</p>
            </div>
            <p className="text-[#555] text-[16px]">
                {item.communicationText}
            </p>
            <div
                className="flex gap-2 items-center justify-end text-primary mt-4 cursor-pointer"
                onClick={() => setIsReplyBoxOpen((prev) => !prev)}
            >
                <p className="font-bold">Comment</p>
                <img src={replyIcon} alt="" />
            </div>

            {isReplyBoxOpen && (
                <>
                    {
                        item?.replies?.userInfo.length > 0 ?
                            <div className="p-3">
                                <div className="flex gap-2 items-center">
                                    <div className="bg-[#DCE6FD] h-7 w-7 rounded-full flex items-center justify-center">
                                    </div>
                                    <p className="text-white text-base font-medium">{item?.replies?.userInfo[0]?.firstName}{" "}{item?.replies?.userInfo[0]?.lastName}</p>
                                </div>
                                <p className="text-[#555] text-[15px] p-2">
                                    {item.replies.communicationText}
                                </p>
                            </div>
                            :
                            <></>
                    }

                    <TextAreaInputField
                        labelColor={"#777"}
                        //labelText={"Concern Description"}
                        errorMessage={"Concern is required"}
                        //placeholder={"Concern Description"}
                        //isFieldRequired={isFieldRequired("concern")}
                        htmlFor={"concernDescription"}
                        name={"concernDescription"}
                        value={concernDescription}
                        handleInputChange={handleInputChange}
                        //validationFunctionName={validateConcern}
                        //isSubmitted={isConcernSubmitted}
                        rows={6}
                    />
                    <div className='flex justify-end'>
                        <button
                            type='button'
                            onClick={() => handleReply()}
                            className="bg-[#FF7E00] text-white px-4 py-2 font-medium rounded-lg"
                        >
                            Reply
                        </button>
                    </div>
                </>
            )}
        </div>
    )
}

export default ConcernCard