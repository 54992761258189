import React from "react";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";
import ServiceCarouselTile from "../../Components/UserManagement-Comps/ServiceCarouselTile";
import { DentistTab } from "../../Components/UserManagement-Comps/DentistTab";
import { ServiceProviderTab } from "../../Components/UserManagement-Comps/ServiceProviderTab";
function Dentist({ emailFromUserProfile, profileId, setProfileId, userRole, userInfo }) {
  const arrayOfCards = [
    <ServiceCarouselTile value={295} header={"basic"} />,
    <ServiceCarouselTile value={295} header={"Advanced"} />,
    <ServiceCarouselTile value={295} header={"intermediate"} />,
    <ServiceCarouselTile value={295} header={"basic"} />,
  ];
  const arrayOfCardsMobile = [<ServiceCarouselTile value={295} />];

  const middleTabTitleArray = ["Service providers", "Dentist"];
  const middleTabContentObject = {
    Dentist: <DentistTab />,
    "Service providers": (
      <ServiceProviderTab
        arrayOfCards={arrayOfCards}
        arrayOfCardsMobile={arrayOfCardsMobile}
      />
    ),
  };
  console.log("dentPage")
  return (
    <div className="flex min-h-screen max-h-fit">
      <Navbar
        navActiveCss={navActiveCss}
        navHoverCss={navHoverCss}
        iconArray={userRole === "89uij7" ? superAdminIconArray : userRole === "676dza2" ? iconArray : spIconArray}
        middleSection={true}
        middleNavPosition={1}
        middleNavHeading="User Management"
        middleTabTitleArray={middleTabTitleArray}
        middleTabContentObject={middleTabContentObject}
        userRole={userRole}
        active={'User Management'}
      />
    </div>
  );
}

export default Dentist;
