import React from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";

function PriceDetailTile({ icon, heading, subHeading, tags, tagsArray, iconTooltipId, iconTooltipContent, iconTooltipPosition }) {

  console.log(subHeading, 'TESTING');

  return (
    <div className="flex gap-4 border-dotted pb-8 border-b border-white border-opacity-10] h-full">
      <div className="rounded-full h-[44px] w-[44px] flex items-center justify-center bg-primary bg-opacity-10"  data-tooltip-id={iconTooltipId ?? ""} data-tooltip-content={iconTooltipContent ?? ""}>
        <img className="w-[14px] h-[17px] object-contain" src={icon} alt="" />
      </div>
      <div className="w-full">
        <p className="font-semibold text-[15px] left-4 text-white mb-2 flex items-center">
          {heading}
          {iconTooltipId && (
            <span data-tooltip-id={iconTooltipId ?? ""} data-tooltip-content={iconTooltipContent ?? ""} data-tooltip-place={iconTooltipPosition ?? "bottom"}>
              <AiFillInfoCircle className="ml-2" />
              <Tooltip id={iconTooltipId ?? ""} />
            </span>
          )}
        </p>
        <div className="text-[15px] left-4 text-[#c2c2c2] w-full">
          {tags ? (
            <div className="flex items-center max-w-full overflow-auto">
              {tagsArray?.map((tag, index) => {
                return (
                  <span
                    key={index}
                    className="bg-[#8796B1] bg-opacity-10 text-[#555] text-[12px] pr-4 rounded-full mr-2 flex items-center gap-2"
                  >
                    <div
                      style={{ background: `${tag.bg}` }}
                      className="h-6 w-6 rounded-full flex items-center justify-center uppercase text-white font-bold text-[12px]"
                    >
                      {tag.initials}
                    </div>
                    {tag.name}
                  </span>
                );
              })}
            </div>
          ) : (
            <p>$ {subHeading}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default PriceDetailTile;
