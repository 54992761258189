import { createSlice } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
  name: 'loading',
  initialState: {},
  reducers: {
    setLoading: (state, action) => {
      state[action.payload] = true;
    },
    clearLoading: (state, action) => {
      state[action.payload] = false;
    },
  },
});

export const { setLoading, clearLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
