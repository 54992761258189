import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";

const AccordionItem = ({
  index,
  title,
  content,
  selected,
  onSelectAll,
  onSelectItem,
  isOpen,
  onToggleAccordion,
  id,
}) => {
  const handleTitleCheckboxChange = () => {
    onSelectAll(title, id, isOpen);
  };

  const handleContentCheckboxChange = (itemId) => {
    onSelectItem(title, itemId, id);
  };

  return (
    <div
      className={`accordion-item bg-[#2f2f2f8d] ${isOpen ? "border-white-500" : "border-none"
        } p-4 transition-all border rounded-lg mb-2 text-white`}
    >
      <div
        className={`accordion-header ${isOpen ? "border-b" : ""
          } py-2 flex items-center justify-between`}
      >
        <div className="flex w-full gap-0">
          <input
            type="checkbox"
            checked={selected.all}
            onChange={handleTitleCheckboxChange}
            className="mr-4"
          />
          <button
            onClick={() => {
              return content.length > 0 ? onToggleAccordion(index) : () => { };
            }}
            className="accordion-title text-[17px] text-white font-medium w-full text-left"
          >
            {title}
          </button>
        </div>
        {content.length > 0 ? (
          <>{isOpen ? <FaAngleDown /> : <FaAngleRight />}</>
        ) : null}
      </div>
      {isOpen && (
        <div className="accordion-content my-4 text-[16px]">
          {content.map((item) => (
            <div key={item.id} className="mt-2">
              <input
                type="checkbox"
                checked={selected.items.includes(item.id)}
                onChange={() => handleContentCheckboxChange(item.id)}
                className="mr-4"
              />
              <span>{item.text}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Accordion = ({ items, onSelectionChange, setAllItems }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [initialSelectedItems, setInitialSelectedItems] = useState([]);
  const [unSelectedItems, setUnselectedItems] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [selectAllItems, setSelectAllItems] = useState(false);

  const handleSelectAll = (title, id) => {
    const selectedItemIndex = selectedItems.findIndex(
      (item) => item.title === title
    );
    let updatedSelectedItems = [...selectedItems];

    // const unselectedItemIndex = initialSelectedItems.findIndex(
    //   (item) => item.
    // )

    if (selectedItemIndex !== -1) {
      updatedSelectedItems.splice(selectedItemIndex, 1);
      updatedSelectedItems.push({
        title,
        id,
        all: !selectedItems.find((item) => item.title === title).all,
        items: items
          .find((item) => item.title === title)
          .content.map((item) => item.id),
      });
    }
    // else {
    //   updatedSelectedItems.push({
    //     title,
    //     id,
    //     all: false,
    //     items: items
    //       .find((item) => item.title === title)
    //       .content.map((item) => item.id),
    //   });
    // }
    const areAllItemsTrue = updatedSelectedItems.every(obj => obj.all === true);
    if (areAllItemsTrue === true) {
      setSelectAllItems(true);
    } else {
      setSelectAllItems(false);
    }
    console.log(updatedSelectedItems, "updatedSelectedItems");
    setSelectedItems(updatedSelectedItems);
    onSelectionChange(updatedSelectedItems);
  };

  const handleSelectItem = (title, itemId, id) => {
    const selectedItem = selectedItems.find((item) => item.title === title);
    let updatedSelectedItems = [...selectedItems];
    const content = items.find((item) => item.title === title).content;
    if (selectedItem) {
      if (selectedItem.items.includes(itemId)) {
        selectedItem.items = selectedItem.items.filter((id) => id !== itemId);
      } else {
        selectedItem.items.push(itemId);
      }
      selectedItem.all = selectedItem.items.length === content.length;

      if (selectedItem.items.length === 0) {
        // Remove the item from selectedItems if it becomes empty
        updatedSelectedItems = updatedSelectedItems.filter(
          (item) => item.title !== title
        );
      }
    } else {
      updatedSelectedItems.push({
        title,
        id,
        all: false,
        items: [itemId],
      });
    }

    setSelectedItems(updatedSelectedItems);
    onSelectionChange(updatedSelectedItems);
  };

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const handleSelectionAllChange = (event) => {
    setSelectAllItems(preState => preState === false ? true : true);
  }

  // Prepare initial selected items based on the new data structure
  useEffect(() => {
    const initialSelectedItems = items.map((item) => {
      const selectedIds = item.sectionChecked
        ? item.content.map((contentItem) => contentItem.id)
        : item.content
          .filter((contentItem) => contentItem.checked)
          .map((contentItem) => contentItem.id);
      return {
        title: item.title,
        id: item.id,
        all: item.sectionChecked || false,
        items: selectedIds,
      };
    });
    setSelectedItems(initialSelectedItems);
    setInitialSelectedItems(initialSelectedItems);
    setAllItems(items);
  }, []);

  useEffect(() => {
    if (selectAllItems === true) {
      const initialSelectedItems = items.map((item) => {
        const selectedIds = item.sectionChecked
          ? item.content.map((contentItem) => contentItem.id)
          : item.content
            .filter((contentItem) => contentItem.checked)
            .map((contentItem) => contentItem.id);
        return {
          title: item.title,
          id: item.id,
          all: item.sectionChecked || true,
          items: selectedIds,
        };
      });
      setSelectedItems(initialSelectedItems);
      setInitialSelectedItems(initialSelectedItems);
      onSelectionChange(initialSelectedItems);
      setAllItems(items);
    }
  }, [selectAllItems]);

  return (
    <div className="accordion">
      <label className="mb-6 flex justify-end items-center space-x-2 cursor-pointer">
        <input
          type="checkbox"
          className="form-checkbox"
          checked={selectAllItems}
          onChange={(event) => handleSelectionAllChange(event)}
        />
        <span className="text-white">Select All</span>
      </label>
      {items.map((item, index) => (
        <AccordionItem
          key={item.id}
          index={index}
          title={item.title}
          id={item.id}
          content={item.content}
          selected={
            selectedItems.find((selected) => selected.title === item.title) || {
              all: false,
              items: [],
            }
          }
          onSelectAll={handleSelectAll}
          onSelectItem={handleSelectItem}
          isOpen={openIndex === index}
          onToggleAccordion={toggleAccordion}
        />
      ))}
    </div>
  );
};

export default Accordion;
