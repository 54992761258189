export const crownTermsAndConditions = [
  "All Dental Restoration planning services are ultimately to be evaluated and accepted by the treating dentist. Progression of patient treatment implies that the treating dentist has made an independent assessment of the treatment plan and design, considered all options available and is conscious of the risks involved.",
  "The portal does not accept ANY liability for its treatment planning and design services and all responsibility is assumed only by the treating dentist",
  "The portal also does not accept any liability for treatment outcomes as a result of the treatment plan, as outcomes are multi-factorial and are affected by patient compliance as well.",
];

export const implantTermsAndConditions = [
  "All Implant Restoration planning services are ultimately to be evaluated and accepted by the treating dentist. Progression of patient treatment implies that the treating dentist has made an independent assessment of the treatment plan and design, considered all options available and is conscious of the risks involved.",
  "The portal does not accept ANY liability for its treatment planning and design services and all responsibility is assumed only by the treating dentist",
  "The portal also does not accept any liability for treatment outcomes as a result of the treatment plan, as outcomes are multi-factorial and are affected by patient compliance as well.",
];

export const orthodonticTermsAndConditions = [
  "All Orthodontic Treatment Planning services are ultimately to be evaluated and accepted by the treating dentist. Progression of patient treatment implies that the treating dentist has made an independent assessment of the treatment plan and design, considered all options available and is conscious of the risks involved.",
  "The portal does not accept ANY liability for its treatment planning, design and fabrication services and all responsibility is assumed only by the treating dentist",
  "The portal also does not accept any liability for treatment outcomes as a result of the treatment plan, as outcomes are multi-factorial and are affected by patient compliance as well.",
];

export const surgicalTermsAndConditions = [
  "All Surgical Guide/Endodotnic Guide/Regenerative Mesh planning and fabrication services are ultimately to be evaluated and accepted by the treating dentist. Progression of patient treatment implies that the treating dentist has made an independent assessment of the treatment plan and design, considered all options available and is conscious of the risks involved.",
  "The portal does not accept ANY liability for its treatment planning, design and fabrication services and all responsibility is assumed only by the treating Dentist",
  "The portal also does not accept any liability for treatment outcomes as a result of the treatment plan, as outcomes are multi-factorial and are affected by patient compliance as well. The fit of planned guides or regenerative meshes cannot be guaranteed either as they are created on supplied digital files and the accuracy of supplied intraoral scans/CBCT files directly affects fit. 3D printing also affects the quality of guides or regnerative meshes and fit can only be verifed for guides that are fabricated and supplied by the portal in some geographic locations",
];

export const removablesTermsAndConditions = [
  "All Removable planning services are ultimately to be evaluated and accepted by the treating dentist. Progression of patient treatment implies that the treating dentist has made an independent assessment of the treatment plan and design, considered all options available and is conscious of the risks involved.",
  "The portal does not accept ANY liability for its treatment planning, design and fabrication services and all responsibility is assumed only by the treating dentist",
  "The portal also does not accept any liability for treatment outcomes as a result of the treatment plan, as outcomes are multi-factorial and are affected by patient compliance as well.",
];

export const smileTermsAndConditions = [
  "All Smile Design planning services are ultimately to be evaluated and accepted by the treating dentist. Progression of patient treatment implies that the treating dentist has made an independent assessment of the treatment plan and design, considered all options available and is conscious of the risks involved.",
  "The portal does not accept ANY liability for its treatment planning, design and fabrication services and all responsibility is assumed only by the treating dentist",
  "The portal also does not accept any liability for treatment outcomes as a result of the treatment plan, as outcomes are multi-factorial and are affected by patient compliance as well.",
];

export const modelsTermsAndConditions = [
  "All Models for Printing services are ultimately to be evaluated and accepted by the treating dentist. Progression of patient treatment implies that the treating dentist has made an independent assessment of the treatment plan and design, considered all options available and is conscious of the risks involved.",
  "The portal does not accept ANY liability for its treatment planning, design and fabrication services and all responsibility is assumed only by the treating dentist",
  "The portal also does not accept any liability for treatment outcomes as a result of the treatment plan, as outcomes are multi-factorial and are affected by patient compliance as well.",
];
