import * as React from "react";
export const Teeth41Disabled = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={85} fill="none">
      <path
        fill="#fff"
        fillOpacity={0.3}
        d="M8.81 2.016c2.922-.487 5.843-.547 8.765-.243 2.8.304 4.565.67 4.687 3.348l.06 1.521c.061 3.713.183 7.487.061 11.26-.06 1.583-.121 3.348-.426 5.052-.122.852-.426 1.644-.67 2.496-.121.487-.304.913-.425 1.4-1.887.121-3.044 2.07-3.713 3.104l-.061.122c-.73 1.156-1.46 2.434-2.678 2.921-1.218.548-2.8.304-4.26-.67-.914-.608-1.705-1.338-2.435-2.069-.366-.365-.73-.73-1.157-1.095a10.795 10.795 0 0 0-2.191-1.583c-1.157-.548-1.765-.67-2.13-.609-.974-5.782-.792-12.355.608-20.572 0-.183.061-.305.061-.487.183-1.096.304-2.07 1.157-2.74.973-.669 2.556-.912 3.895-1.034.243 0 .548-.06.852-.122Z"
      />
      <path
        fill="#EFD1D0"
        fillOpacity={0.3}
        d="M2.298 27.703c.243 0 .791.121 1.704.547.67.305 1.34.791 2.07 1.461.365.365.73.67 1.095 1.035.791.791 1.583 1.521 2.556 2.19 1.096.731 2.313 1.096 3.409 1.036.548 0 1.035-.122 1.522-.305 1.4-.608 2.252-2.008 2.982-3.226l.06-.06c.792-1.34 1.766-2.557 2.983-2.74v.062c-1.826 8.764-3.104 17.711-3.712 26.659-.122 1.887-.122 3.773-.183 5.6 0 2.19 0 4.504-.243 6.755-.61 5.6-1.827 10.652-3.652 15.034-.853 1.279-1.583 1.948-2.313 1.887-.609 0-1.218-.608-1.826-1.704.06-18.99-1.887-35.972-5.904-51.919 0-.121-.061-.304-.122-.608v-.061c-.061-.183-.122-.426-.183-.609l-.243-1.034Z"
      />
      <path
        fill="#000002"
        fillOpacity={0.3}
        d="M4.002 3.233c-.852.609-1.035 1.643-1.157 2.739 0 .183-.06.304-.06.487-1.34 8.217-1.522 14.851-.61 20.573.366-.061.975.06 2.131.608.73.365 1.461.852 2.191 1.583.366.365.73.67 1.157 1.095.73.73 1.582 1.522 2.434 2.07 1.461.974 3.044 1.217 4.261.67 1.217-.548 1.948-1.766 2.678-2.922l.061-.122c.67-1.035 1.826-2.982 3.713-3.104.122-.487.304-.974.426-1.4.304-.852.548-1.644.67-2.496.243-1.704.365-3.408.426-5.052.121-3.773.06-7.547-.061-11.26L22.2 5.181c-.122-2.678-1.826-3.044-4.687-3.348-2.921-.304-5.843-.183-8.765.244-.304.06-.608.06-.913.121-1.278.122-2.86.365-3.834 1.035Zm-1.4 26.233c.06.244.122.487.122.609C6.74 46.022 8.689 63.003 8.628 81.993c.608 1.096 1.217 1.644 1.826 1.705.67 0 1.46-.609 2.313-1.887 1.825-4.382 3.104-9.434 3.652-15.034.243-2.252.243-4.565.243-6.756 0-1.826 0-3.713.183-5.6.67-8.947 1.886-17.894 3.712-26.659v-.06c-1.217.243-2.13 1.46-2.982 2.738l-.06.061c-.731 1.217-1.583 2.556-2.983 3.226-.487.182-.974.304-1.522.304-1.096 0-2.252-.304-3.408-1.035-.974-.608-1.766-1.4-2.557-2.19-.365-.366-.73-.731-1.095-1.035-.73-.67-1.4-1.157-2.07-1.461-.913-.426-1.46-.609-1.704-.548l.122 1.096c.182.121.243.365.304.608Zm-.67.122c-.243-.852-.304-1.34-.304-1.765 0-.183 0-.426-.06-.609-.975-5.843-.792-12.538.547-20.877 0-.182.06-.304.06-.487.183-1.095.366-2.374 1.462-3.165 1.095-.791 2.8-.974 4.2-1.156.304-.061.608-.061.852-.122 2.982-.487 5.964-.548 8.947-.243 2.495.243 5.113.486 5.295 3.956v1.582c.061 3.713.183 7.548.061 11.26-.06 1.644-.122 3.348-.426 5.113-.122.913-.426 1.765-.67 2.617-.243.67-.426 1.4-.608 2.13-1.826 8.705-3.104 17.652-3.713 26.6-.122 1.825-.122 3.712-.183 5.538 0 2.252 0 4.565-.243 6.817-.609 5.66-1.826 10.834-3.713 15.277-.974 1.522-1.887 2.252-2.8 2.252h-.121c-.913 0-1.705-.73-2.435-2.13l-.061-.06v-.062c0-19.233-1.948-36.397-6.087-52.466Z"
      />
    </svg>
  );
};
