import React from "react";
import { AiOutlineCheck } from "react-icons/ai";

function StageBox({
  titleOne,
  isStepOneChecked,
  titleTwo,
  isStepTwoChecked,
  titleThree,
  isStepThreeChecked,
}) {
  return (
    <div className="STAGE-BOX bg-backgroundV2 flex flex-row items-center gap-4 md:justify-around justify-between py-4 md:px-0 px-4 my-4 rounded-xl">
      {isStepOneChecked ? (
        <div className="flex items-center gap-4">
          <div className="bg-[#8ED259] h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
            <AiOutlineCheck className=" " />
          </div>
          <p
            className={` ${
              isStepOneChecked ? "" : "font-semibold"
            }  text-white md:block hidden capitalize`}
          >
            {titleOne}
          </p>
        </div>
      ) : (
        <div className="flex items-center gap-4">
          <div className="bg-orange-500 h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
            1
          </div>
          <p
            className={` ${
              isStepOneChecked ? "" : "font-semibold"
            }  text-white md:block hidden capitalize`}
          >
            {titleOne}
          </p>
        </div>
      )}

      <div className="md:w-[200px] w-[40px] h-[1px] border-b border-dashed border-black border-opacity-30"></div>
      {isStepTwoChecked ? (
        <div className="flex items-center gap-4">
          <div className="bg-[#8ED259] h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
            <AiOutlineCheck className=" " />
          </div>
          <p
            className={`text-white md:block hidden ${
              isStepTwoChecked ? "" : "font-semibold"
            } `}
          >
            {titleTwo}
          </p>
        </div>
      ) : (
        <div className="flex items-center gap-4">
          <div
            className={` h-8 w-8 rounded-full border  font-medium flex items-center justify-center ${
              isStepOneChecked
                ? "bg-orange-500 text-white font-semibold"
                : "bg-white text-[#999] font-medium"
            } `}
          >
            2
          </div>
          <p
            className={`text-white md:block hidden ${
              isStepOneChecked ? "font-semibold" : ""
            } `}
          >
            {titleTwo}
          </p>
        </div>
      )}

      <div className="md:w-[200px] w-[40px] h-[1px] border-b border-dashed border-black border-opacity-30"></div>
      {isStepThreeChecked ? (
        <div className="flex items-center gap-4">
          <div className="bg-[#8ED259] h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
            <AiOutlineCheck className=" " />
          </div>
          <p className="font-semibold text-white md:block hidden ">
            {titleThree}
          </p>
        </div>
      ) : (
        <div className="flex items-center gap-4">
          <div
            className={` h-8 w-8 rounded-full border  font-medium flex items-center justify-center ${
              isStepTwoChecked
                ? "bg-orange-500 text-white font-semibold"
                : "bg-white text-[#999] font-medium"
            } `}
          >
            3
          </div>
          <p
            className={`text-white md:block hidden ${
              isStepTwoChecked ? "font-semibold" : ""
            } `}
          >
            {titleThree}
          </p>
        </div>
      )}
    </div>
  );
}

export default StageBox;
