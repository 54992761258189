import { useEffect, useState } from "react";
import { TeethV2 } from "../Teeth-Comps/TeethV2";
import { Checkbox } from "../InputField-Comps/CheckboxField";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import classes from "../Teeth-Comps/Teeth.module.scss";
import interproximalContactsBroad from "../../Assets/images/interproximalContactsBroad.jpg";
import interproximalContactsNormal from "../../Assets/images/interproximalContactsNormal.jpg";
import interproximalContactsPoint from "../../Assets/images/interproximalContactsPoint.jpg";
import occlusalContactsPositive from "../../Assets/images/occlusalContactsPositive.jpg";
import occlusalContactsFoilRelief1 from "../../Assets/images/occlusalContactsFoilRelief1.jpg";
import occlusalContactsFoilRelief2 from "../../Assets/images/occlusalContactsFoilRelief2.jpg";
import occlusalContactsFoilRelief3 from "../../Assets/images/occlusalContactsFoilRelief3.jpg";
import ponticDesignSanitary from "../../Assets/images/ponticDesignSanitary.png";
import ponticDesignSaddle from "../../Assets/images/ponticDesignSaddle.png";
import ponticDesignConical from "../../Assets/images/ponticDesignConical.png";
import ponticDesignModified from "../../Assets/images/ponticDesignModified.png";
import ponticDesignOvate from "../../Assets/images/ponticDesignOvate.png";
import RangeInputField from "../InputField-Comps/RangeInputField";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";
import { useGetSoftwareCategoryByNameMutation } from "../../services/softwareCategory";
import { findExtraElement, updateTeethMapping } from "../../Utils/utility";
import occlusalOption1 from "../../Assets/images/removables/occlusalOption1.jpg";
import occlusalOption2 from "../../Assets/images/removables/occlusalOption2.jpg";
import occlusalOption3 from "../../Assets/images/removables/occlusalOption3.jpg";
import mouldShapeOption1 from "../../Assets/images/removables/mouldShapeOption1.jpg";
import mouldShapeOption2 from "../../Assets/images/removables/mouldShapeOption2.jpg";
import mouldShapeOption3 from "../../Assets/images/removables/mouldShapeOption3.jpg";
import setupStyle1 from "../../Assets/images/removables/setupStyle1.jpg";
import setupStyle2 from "../../Assets/images/removables/setupStyle2.jpg";
import setupStyle3 from "../../Assets/images/removables/setupStyle3.jpg";
import {
  useDeleteOrderMissingTeethMutation,
  useSaveOrderMissingTeethMutation,
} from "../../services/orders";

export const RemovablesCaseDetailsSection = ({
  setActiveTab,
  orderCaseData,
  orderByData,
  setOrderUpdated,
  serviceObjId,
  submitOrderData,
  selectedServiceCategory,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [saveOrderMissingTeeth] = useSaveOrderMissingTeethMutation();
  const [deleteOrderMissingTeeth] = useDeleteOrderMissingTeethMutation();

  const [hasMissingTeeth, setHasMissingTeeth] = useState(
    orderCaseData?.missingTeeth?.hasMissingTeeth === null
      ? ""
      : orderCaseData?.missingTeeth?.hasMissingTeeth
      ? "Yes"
      : "No"
  );
  const [missingTeethSelectedTeeth, setMissingTeethSelectedTeeth] = useState(
    orderCaseData?.missingTeeth?.teeth || []
  );
  // eslint-disable-next-line no-unused-vars
  const [notation, setNotation] = useState("FDI");
  const [occlusionSetUp, setOcclusionSetUp] = useState(orderCaseData?.occlusionSetUp ?? "");
  const [teethMoldShape, setTeethMoldShape] = useState(orderCaseData?.teethMoldShape ?? "");
  const [teethArrangementStyle, setTeethArrangementStyle] = useState(
    orderCaseData?.teethArrangementStyle ?? ""
  );
  const [teethSize, setTeethSize] = useState(orderCaseData?.teethSize ?? "");
  const [majorConnector, setMajorConnector] = useState(orderCaseData?.setMajorConnector || []);
  const [crossbiteCreated, setCrossbiteCreated] = useState(orderCaseData?.crossbiteCreated || "");
  const [splintDesign, setSplintDesign] = useState(orderCaseData?.splintDesign || "");
  const [requireHandle, setRequireHandle] = useState(
    orderCaseData?.requireHandle === null ? "" : orderCaseData?.requireHandle ? "Yes" : "No"
  );
  const [biteRegistrationDesired, setBiteRegistrationDesired] = useState(
    orderCaseData?.biteRegistrationDesired === null
      ? ""
      : orderCaseData?.biteRegistrationDesired
      ? "Yes"
      : "No"
  );

  const [preferredSoftwares, setPreferredSoftwares] = useState([]);
  const [dentureWorkflowDesired, setDentureWorkflowDesired] = useState(
    orderCaseData?.dentureWorkflowDesired === null
      ? ""
      : orderCaseData?.dentureWorkflowDesired
      ? "Yes"
      : "No"
  );
  const [creatingImplantDentures, setCreatingImplantDentures] = useState(
    orderCaseData?.creatingImplantDentures === null
      ? ""
      : orderCaseData?.creatingImplantDentures
      ? "Yes"
      : "No"
  );
  const [holesInImpressionTray, setHolesInImpressionTray] = useState(
    orderCaseData?.holesInImpressionTray === null
      ? ""
      : orderCaseData?.holesInImpressionTray
      ? "Yes"
      : "No"
  );
  const [monoblocPrintDesired, setMonoblocPrintDesired] = useState(
    orderCaseData?.monoblocPrintDesired === null
      ? ""
      : orderCaseData?.monoblocPrintDesired
      ? "Yes"
      : "No"
  );
  const [dentureSetUpTeeth, setDentureSetUpTeeth] = useState(
    orderCaseData?.dentureSetUpTeeth || []
  );
  const [claspTeeth, setClaspTeeth] = useState(orderCaseData?.claspTeeth || []);
  const [impressionMaterialSpace, setImpressionMaterialSpace] = useState(
    orderCaseData?.impressionMaterialSpace || 0
  );
  const [nightguardArch, setNightguardArch] = useState(orderCaseData?.nightguardArch || "");
  const [archOption, setArchOption] = useState(orderCaseData?.archOption || "");
  const [noHandlesDesired, setNoHandlesDesired] = useState(orderCaseData?.noHandlesDesired || 0);
  const [dentureThickness, setDentureThickness] = useState(orderCaseData?.dentureThickness || 0);
  const [splintThickness, setSplintThickness] = useState(orderCaseData?.splintThickness || 0);
  const [hardTissueRecontouringThickness, setHardTissueRecontouringThickness] = useState(
    orderCaseData?.hardTissueRecontouringThickness || 0
  );
  const [intraoralScansProvided, setIntraoralScansProvided] = useState(
    orderCaseData?.intraoralScansProvided || ""
  );
  const [splintArch, setSplintArch] = useState(orderCaseData?.splintArch || "");
  const [impressionsProvided, setImpressionsProvided] = useState(
    orderCaseData?.impressionsProvided || ""
  );
  const [recontouringReductionGuideOn, setRecontouringReductionGuideOn] = useState(
    orderCaseData?.recontouringReductionGuideOn || ""
  );
  const [hardTissueRecontouring, setHardTissueRecontouring] = useState(
    orderCaseData?.hardTissueRecontouring || ""
  );
  const [dentureBasePrintingType, setDentureBasePrintingType] = useState(
    orderCaseData?.dentureBasePrintingType || ""
  );
  const [splintPrintingType, setSplintPrintingType] = useState(
    orderCaseData?.splintPrintingType || ""
  );
  const [recontouringReductionPrintingType, setRecontouringReductionPrintingType] = useState(
    orderCaseData?.recontouringReductionPrintingType || ""
  );
  const [teethPrintingType, setTeethPrintingType] = useState(
    orderCaseData?.teethPrintingType || ""
  );
  const [printingNightguard, setPrintingNightguard] = useState(
    orderCaseData?.printingNightguard === null
      ? ""
      : orderCaseData?.printingNightguard
      ? "Yes"
      : "No"
  );
  const [selectedService, setSelectedService] = useState("");
  const [restorativeTypeTeethSelected, setRestorativeTypeTeethSelected] = useState(
    orderCaseData?.services?.reduce(
      (acc, curr) => ({ ...acc, [curr?.serviceObjId]: curr?.teeth }),
      {}
    )
  );

  const [preferredDesignSoftware, setPreferredDesignSoftware] = useState(
    orderCaseData?.preferredSoftware || ""
  );

  const [teethExtractionSelectedTeeth, setTeethExtractionSelectedTeeth] = useState(
    orderCaseData?.teethExtractionSelectedTeeth || []
  );

  const [splintsToExtendSelectedTeeth, setSplintsToExtendSelectedTeeth] = useState(
    orderCaseData?.splintsToExtendSelectedTeeth || []
  );
  const [recontouringToExtendSelectedTeeth, setRecontouringToExtendSelectedTeeth] = useState(
    orderCaseData?.recontouringToExtendSelectedTeeth || []
  );

  console.log("restorativeTypeTeethSelected", restorativeTypeTeethSelected);
  console.log("selectedService", selectedService);

  const [getSoftwareCategoryByName] = useGetSoftwareCategoryByNameMutation();

  const readSoftwareCategoryInfo = async (accessToken) => {
    const response = await getSoftwareCategoryByName({
      accessToken,
      softwareCategoryName: "CAD",
    }).unwrap();
    if (response?.data) {
      const softwaresList = response?.data;
      if (softwaresList?.length > 0) setPreferredSoftwares(softwaresList[0]?.softwares);
      else setPreferredSoftwares([]);
    }
  };

  const readSoftwareCategoriesInfoDeatails = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await readSoftwareCategoryInfo(accessToken);
  };

  useEffect(() => {
    readSoftwareCategoriesInfoDeatails();
  }, []);

  const handleNotationChange = (event) => {
    setNotation(event.target.value);
    setMissingTeethSelectedTeeth(updateTeethMapping(missingTeethSelectedTeeth, event.target.value));
    setDentureSetUpTeeth(updateTeethMapping(dentureSetUpTeeth, event.target.value));
    setClaspTeeth(updateTeethMapping(claspTeeth, event.target.value));
    setTeethExtractionSelectedTeeth(
      updateTeethMapping(teethExtractionSelectedTeeth, event.target.value)
    );
    setRecontouringToExtendSelectedTeeth(
      updateTeethMapping(recontouringToExtendSelectedTeeth, event.target.value)
    );
    setSplintsToExtendSelectedTeeth(
      updateTeethMapping(splintsToExtendSelectedTeeth, event.target.value)
    );
    if (restorativeTypeTeethSelected[selectedService?.serviceObjId]?.length > 0) {
      setRestorativeTypeTeethSelected({
        ...restorativeTypeTeethSelected,
        [selectedService?.serviceObjId]: updateTeethMapping(
          restorativeTypeTeethSelected[selectedService?.serviceObjId],
          event.target.value
        ),
      });
    }
  };

  console.log("selectedServiceCategory", selectedServiceCategory);

  const prepareServicesElements = () => {
    if (selectedServiceCategory?.catservices?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      selectedServiceCategory?.catservices?.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            selectedIcon={
              restorativeTypeTeethSelected.hasOwnProperty(eachService?.serviceObjId) &&
              restorativeTypeTeethSelected[eachService?.serviceObjId]?.length > 0
            }
            handleSelectedIcon={() => {
              console.log("restorativeTypeTeethSelected", restorativeTypeTeethSelected);
              setRestorativeTypeTeethSelected((prev) => {
                const copy = JSON.parse(JSON.stringify(prev));
                delete copy[eachService?.serviceObjId];
                console.log("eachService?.serviceObjId", eachService?.serviceObjId);
                console.log("copy", copy);
                console.log("prev", prev);
                return copy;
              });
            }}
            id={serviceObjId}
            active={selectedService?.serviceObjId === eachService?.serviceObjId}
            image={eachService?.serviceIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serviceName}
            serviceDescription={eachService?.serviceDescription}
            clickHandler={() => setSelectedService(eachService)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  const getRestrictedTeeth = (serviceObjId) => {
    const restrictedTeethData = [];
    Object.keys(restorativeTypeTeethSelected)
      .filter((d) => d !== serviceObjId)
      .forEach((d) => {
        restrictedTeethData.push(...restorativeTypeTeethSelected[d]);
      });
    return restrictedTeethData;
  };

  const validateInput = (value) => {
    return value?.trim().length > 0;
  };

  const validateNumber = (value) => {
    return Number(value) ? true : false;
  };

  const handleHasMissingTeethChange = (event) => {
    setHasMissingTeeth(event.target.value);
  };

  const handleRequireHandleCheckboxChange = (event) => {
    setRequireHandle(event.target.value);
  };
  const handleBiteRegistrationDesiredCheckboxChange = (event) => {
    setBiteRegistrationDesired(event.target.value);
  };
  const handleDentureWorkflowDesiredCheckboxChange = (event) => {
    setDentureWorkflowDesired(event.target.value);
  };
  const handleSplintDesignCheckboxChange = (event) => {
    setSplintDesign(event.target.value);
  };
  const handleCreatingImplantDenturesCheckboxChange = (event) => {
    setCreatingImplantDentures(event.target.value);
  };
  const handleRecontouringReductionGuideOnCheckboxChange = (event) => {
    setRecontouringReductionGuideOn(event.target.value);
  };
  const handlehardTissueRecontouringCheckboxChange = (event) => {
    setHardTissueRecontouring(event.target.value);
  };
  const handleOcclusionSetUpCheckboxChange = (event) => {
    setOcclusionSetUp(event.target.value);
  };
  const handleTeethMoldShapeCheckboxChange = (event) => {
    setTeethMoldShape(event.target.value);
  };
  const handleTeethArrangementStyleCheckboxChange = (event) => {
    setTeethArrangementStyle(event.target.value);
  };
  const handleMajorConnectorCheckboxChange = (event) => {
    const { value } = event.target;
    if (majorConnector.includes(value)) {
      setMajorConnector(majorConnector.filter((d) => d !== value));
    } else {
      setMajorConnector([...majorConnector, value]);
    }
  };
  const handleCrossbiteCreatedCheckboxChange = (event) => {
    setCrossbiteCreated(event.target.value);
  };
  const handlePreferredDesignSoftwareCheckboxChange = (event) => {
    setPreferredDesignSoftware(event.target.value);
  };
  const handleTeethSizeCheckboxChange = (event) => {
    setTeethSize(event.target.value);
  };
  const handleHolesInImpressionTrayCheckboxChange = (event) => {
    setHolesInImpressionTray(event.target.value);
  };
  const handleNightguardArchCheckboxChange = (event) => {
    setNightguardArch(event.target.value);
  };
  const handleArchOptionCheckboxChange = (event) => {
    setArchOption(event.target.value);
  };
  const handleMonoblocPrintDesiredCheckboxChange = (event) => {
    setMonoblocPrintDesired(event.target.value);
  };

  const handleIntraoralScansProvidedCheckboxChange = (event) => {
    setIntraoralScansProvided(event.target.value);
  };

  const handleSplintArchCheckboxChange = (event) => {
    setSplintArch(event.target.value);
  };
  const handleImpressionsProvidedCheckboxChange = (event) => {
    setImpressionsProvided(event.target.value);
  };
  const handleDentureBasePrintingTypeCheckboxChange = (event) => {
    setDentureBasePrintingType(event.target.value);
  };
  const handleSplintPrintingTypeCheckboxChange = (event) => {
    setSplintPrintingType(event.target.value);
  };
  const handleRecontouringReductionPrintingTypeCheckboxChange = (event) => {
    setRecontouringReductionPrintingType(event.target.value);
  };
  const handleTeethPrintingTypeCheckboxChange = (event) => {
    setTeethPrintingType(event.target.value);
  };
  const handlePrintingNightguardCheckboxChange = (event) => {
    setPrintingNightguard(event.target.value);
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      "additionalInstructions",
      "hasMissingTeeth",
      "noHandlesDesired",
      "dentureThickness",
      "splintThickness",
      "hardTissueRecontouringThickness",
      "impressionMaterialSpace",
      "intraoralScansProvided",
      "impressionsProvided",
      "dentureBasePrintingType",
      "teethPrintingType",
    ];

    return requiredFields.includes(fieldName);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "noHandlesDesired") setNoHandlesDesired(value);
    if (name === "dentureThickness") setDentureThickness(value);
    if (name === "impressionMaterialSpace") setImpressionMaterialSpace(value);
    if (name === "splintThickness") setSplintThickness(value);
    if (name === "hardTissueRecontouringThickness") setHardTissueRecontouringThickness(value);
  };
  const submitCaseDetailsForm = async () => {
    // eslint-disable-next-line no-unused-vars
    // if (toothData?.length > 0) {
    // const overBiteSelectOptionsObj = {
    //   overBiteCorrtOptimize: false,
    //   overBiteCorrtMaintain: false,
    //   overBiteCorrtImprove1mm: false,
    //   overBiteCorrtImprove2mm: false,
    //   overBiteCorrtImprove3mm: false,
    //   overBiteCorrtImprove4mm: false,
    // };
    // const overjetSelectOptionsObj = {
    //   overJetCorrtOptimize: false,
    //   overJetCorrtMaintain: false,
    //   overJetCorrtImprove1mm: false,
    //   overJetCorrtImprove2mm: false,
    //   overJetCorrtImprove3mm: false,
    //   overJetCorrtImprove4mm: false,
    // };
    // for (const key of toothData) {
    //   if (overBiteSelectOptionsObj.hasOwnProperty(key)) {
    //     overBiteSelectOptionsObj[key] = true;
    //   }
    // }
    // for (const key of toothData) {
    //   if (overjetSelectOptionsObj.hasOwnProperty(key)) {
    //     overjetSelectOptionsObj[key] = true;
    //   }
    // }
    // const isUpdated = arraysHaveSameValues(initialToothData, toothData);
    // const data = {
    //teeth: selectedTeeth,
    // iPRValue:
    //   iPRValue === "iprPosterior"
    //     ? { iprPosterior: true, iprAnterior: false }
    //     : { iprPosterior: false, iprAnterior: true },
    // overjetSelectOptions: overjetSelectOptionsObj,
    // overBiteSelectOptions: overBiteSelectOptionsObj,
    // crossBiteValue:
    //   crossBiteValue === "crossBiteCorrtMaintain"
    //     ? { crossBiteCorrtMaintain: true, crossBiteCorrtOptimize: false }
    //     : { crossBiteCorrtMaintain: false, crossBiteCorrtOptimize: true },
    // additionalInstructions: additionalInstructions,
    // };
    // const accessToken = await getAccessTokenSilently({
    //   authorizationParams: {
    //     audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
    //   },
    // });
    // if (orderByData?.ordertoothmovement?.length === 0) {
    //   const resp = await saveOrderToothMovData({
    //     accessToken,
    //     orderObjId: orderByData?._id,
    //     toothMovementData: data,
    //   });
    //   const respTeeth = await saveOrderTeethData({
    //     accessToken,
    //     orderObjId: orderByData?._id,
    //     // teeth: crownSelectedTeeth,
    //   });
    //   setOrderUpdated(true);
    //   setActiveTab("Upload Files");
    // } else {
    //   if (!isUpdated) {
    //     const resp = await updateOrderToothMovData({
    //       accessToken,
    //       _id: orderByData?.ordertoothmovement[0]?._id,
    //       toothMovementData: data,
    //     });
    //     if (resp?.data?.type === 1) {
    //       GetToast("Success", "");
    //       setOrderUpdated(true);
    //       setActiveTab("Upload Files");
    //     } else {
    //       GetToast("Error", "error");
    //     }
    //   } else {
    //     setActiveTab("Upload Files");
    //   }
    // }
    // }

    submitOrderData({
      missingTeeth: {
        hasMissingTeeth: hasMissingTeeth ? hasMissingTeeth === "Yes" : null,
        teeth: missingTeethSelectedTeeth,
      },
      services: Object.keys(restorativeTypeTeethSelected)?.map((item) => ({
        serviceObjId: item,
        teeth: restorativeTypeTeethSelected[item],
      })),
    });
    setActiveTab("Upload Files");
  };

  const handleMissingTeethSelection = async (value) => {
    console.log("value", value);
    const actualMissingTeeth = structuredClone(missingTeethSelectedTeeth);
    let result = value(actualMissingTeeth || []);
    setMissingTeethSelectedTeeth(result);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let teethNo;
    if (result?.length > actualMissingTeeth?.length) {
      teethNo = findExtraElement(actualMissingTeeth, result);
      saveOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    } else if (result?.length < actualMissingTeeth?.length) {
      teethNo = findExtraElement(result, actualMissingTeeth);
      deleteOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    }
  };

  return (
    <div>
      <div className="w-10/12">
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            What tooth numbering system do you prefer?
            <span className="text-red-500">*</span>
          </p>
          <div className="flex">
            <div className={classes.radioButtons}>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="UTN"
                  checked={notation === "UTN"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                UTN
              </label>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="FDI"
                  checked={notation === "FDI"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                FDI
              </label>
            </div>
          </div>
          <p className="text-[16px] text-[#777] mb-8">
            The Universal tooth numbering (UTN) system (1-32) and Fédération Dentaire Internationale
            (FDI) system (11-48) are both internationally recognised numbering systems used in
            different parts of the world. Please select a preference that will then apply to all the
            tooth charts in this form.
          </p>
        </div>
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            Please advise us if the patient has any missing teeth{" "}
            {isFieldRequired("hasMissingTeeth") ? <span className="text-red-500">*</span> : ""}
          </p>
          <div className="flex">
            <div className="flex items-center mr-5">
              <CircularCheckbox
                value="Yes"
                checked={hasMissingTeeth === "Yes"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px] my-3">The patient has missing teeth</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={hasMissingTeeth === "No"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">The patient has no missing teeth</p>
            </div>
          </div>
          <div className="mx-5 mt-4">
            {hasMissingTeeth === "Yes" && (
              <>
                <p className="text-white text-[16px] mb-2">Please select missing teeth</p>
                <TeethV2
                  allTeethData={[]}
                  selectedTeeth={missingTeethSelectedTeeth}
                  setSelectedTeeth={handleMissingTeethSelection}
                  defaultNotation={notation}
                  key={`missingTeethSelectedTeeth${notation}`}
                  notationVisible={false}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="font-medium text-[24px] text-white mb-4">Removables Service Type</p>
          {selectedServiceCategory?._id &&
            Object.keys(restorativeTypeTeethSelected).length !== "" && (
              <>
                <div className="CAROUSEL mb-8">
                  <div className="md:block hidden">
                    <CustomCarousel
                      rows={1}
                      cols={6}
                      gapY={20}
                      gapX={20}
                      completeSliders={prepareServicesElements()}
                      numberOfCardsShownInSingleSlide={6}
                    />
                  </div>
                  <div className="md:hidden block">
                    <CustomCarousel
                      rows={1}
                      cols={2}
                      gapY={20}
                      gapX={20}
                      completeSliders={prepareServicesElements()}
                      numberOfCardsShownInSingleSlide={2}
                    />
                  </div>
                </div>
              </>
            )}
        </div>
        {["Nightguards"].includes(selectedService?.serviceName) && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p className="flex mb-2">
                What arch would you like a nightguard on? <span className="text-red-500">*</span>
              </p>

              <p className="text-[#777] mb-5 text-[16px]">
                We only provide flat plane nightguards that extend only to the height of contour of
                the teeth. We prefer intraoral scans in an open bite relationship for nightguard
                design to reduce the need for adjustments. Please obtain intraoral scans of both
                arches and a bite scan with a leaf gauge that records the bite in centric. This bite
                scan in an open relationship needs to allow sufficient thickness for the nightguard
                material.
              </p>
              <div className="flex">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Upper"
                    checked={nightguardArch === "Upper"}
                    onChange={handleNightguardArchCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Upper</p>
                </div>
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Lower"
                    checked={nightguardArch === "Lower"}
                    onChange={handleNightguardArchCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Lower</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Both"
                    checked={nightguardArch === "Both"}
                    onChange={handleNightguardArchCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Both</p>
                </div>
              </div>
            </div>
          </>
        )}

        {["Custom impression tray", "Custom implant impression tray"].includes(
          selectedService?.serviceName
        ) && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p className="flex mb-2">
                What arch would you like an impression tray designed for?{" "}
                <span className="text-red-500">*</span>
              </p>

              <div className="flex">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Upper"
                    checked={archOption === "Upper"}
                    onChange={handleArchOptionCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Upper</p>
                </div>
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Lower"
                    checked={archOption === "Lower"}
                    onChange={handleArchOptionCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Lower</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Both"
                    checked={archOption === "Both"}
                    onChange={handleArchOptionCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Both</p>
                </div>
              </div>
            </div>
          </>
        )}

        {["Custom impression tray", "Custom implant impression tray"]?.includes(
          selectedService?.serviceName
        ) && (
          <>
            <p className="text-[16px] text-[#777] my-4">
              If there are any teeth you intend to extract, these extractions need to be digitally
              simulated before impression trays are made. Please advise if you intend to extract ANY
              teeth BEFORE the impression. Mobile teeth are certainly best extracted before an
              impression as well and can be marked for extraction on this chart as well.
            </p>
            <div className="mb-8">
              <p className="text-white text-[17px] mb-2">
                Please select any teeth that need to be extracted{" "}
                <span className="text-red-500">*</span>
              </p>
              <div className="">
                <TeethV2
                  allTeethData={[]}
                  selectedTeeth={teethExtractionSelectedTeeth}
                  setSelectedTeeth={setTeethExtractionSelectedTeeth}
                  defaultNotation={notation}
                  key={`teethExtractionSelectedTeeth${notation}`}
                  notationVisible={false}
                  missingTeeth={missingTeethSelectedTeeth}
                />
              </div>
            </div>
          </>
        )}
        {["Custom implant impression tray"].includes(selectedService?.serviceName) && (
          <div className="mb-8">
            <p className="text-white text-[16px] mb-2">
              Please select all implant sites <span className="text-red-500">*</span>
            </p>
            <TeethV2
              key={
                selectedService?.serviceObjId +
                restorativeTypeTeethSelected[selectedService?.serviceObjId]?.length +
                notation
              }
              allTeethData={[]}
              restrictedTeeth={getRestrictedTeeth(selectedService?.serviceObjId)}
              restrictedTeethMessage={"This tooth is already selected in other restorative type"}
              selectedTeeth={restorativeTypeTeethSelected[selectedService?.serviceObjId] || []}
              setSelectedTeeth={(value) => {
                console.log("value", value);
                let result = value(
                  restorativeTypeTeethSelected[selectedService?.serviceObjId] || []
                );
                console.log("result", result);
                console.log("restorativeTypeTeethSelected", restorativeTypeTeethSelected);
                setRestorativeTypeTeethSelected({
                  ...restorativeTypeTeethSelected,
                  [selectedService?.serviceObjId]: result,
                });
              }}
              missingTeethSelectable={true}
              defaultNotation={notation}
              notationVisible={false}
              missingTeeth={missingTeethSelectedTeeth}
            />
          </div>
        )}
        {["Custom impression tray", "Custom implant impression tray"].includes(
          selectedService?.serviceName
        ) && (
          <>
            <div className="text-white flex mb-8">
              Would you like a handle?&nbsp;<span className="text-red-500">*</span>
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="Yes"
                  checked={requireHandle === "Yes"}
                  onChange={handleRequireHandleCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="No"
                  checked={requireHandle === "No"}
                  onChange={handleRequireHandleCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>
            {requireHandle === "Yes" && (
              <div className="w-1/2">
                <RangeInputField
                  labelColor={"#fff"}
                  labelText={"Number of handles desired?"}
                  errorMessage={"Please enter a valid number of handles"}
                  placeholder={""}
                  htmlFor={"noHandlesDesired"}
                  name={"noHandlesDesired"}
                  value={noHandlesDesired}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateNumber}
                  isSubmitted={isFieldRequired}
                  isFieldRequired={isFieldRequired("noHandlesDesired")}
                  min={0}
                  max={3}
                />
              </div>
            )}
            <div className="text-white flex flex-col mb-8">
              <p className="flex mb-2">
                Would you like holes in your{" "}
                {selectedService?.serviceName === "Custom implant impression tray" ? "implant" : ""}{" "}
                impression tray for impression material adhesion? &nbsp;
                <span className="text-red-500">*</span>
              </p>
              {"Custom implant impression tray" === selectedService?.serviceName && (
                <p className="text-[#777] mb-5 text-[16px]">
                  (Holes will be automatically provided for your implant sites)
                </p>
              )}
              <div className="flex">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={holesInImpressionTray === "Yes"}
                    onChange={handleHolesInImpressionTrayCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={holesInImpressionTray === "No"}
                    onChange={handleHolesInImpressionTrayCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <RangeInputField
                labelColor={"#fff"}
                labelText={
                  "How much spacer would you like for your impression material? (µ microns)"
                }
                subtitle="If you are unsure about how much space you require, please leave the setting at 0µ. A default setting will then be applied to the design."
                errorMessage={"Please enter a valid space value"}
                placeholder={""}
                htmlFor={"impressionMaterialSpace"}
                name={"impressionMaterialSpace"}
                value={impressionMaterialSpace}
                handleInputChange={handleInputChange}
                validationFunctionName={validateNumber}
                isSubmitted={isFieldRequired}
                isFieldRequired={isFieldRequired("impressionMaterialSpace")}
                unit="µ"
                min={0}
                max={200}
              />
            </div>
          </>
        )}

        {["Splints", "Deprogrammers"].includes(selectedService?.serviceName) && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p>
                What {selectedService?.serviceName === "Splints" ? "splint" : "deprogrammer"} design
                do you prefer?{" "}
                {isFieldRequired("intraoralScansProvided") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <div className="grid grid-cols-3 gap-y-3 mt-1">
                {selectedService?.serviceName === "Splints" ? (
                  <>
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Michigan style"
                        checked={splintDesign === "Michigan style"}
                        onChange={handleSplintDesignCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Michigan style</p>
                    </div>
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="NTI"
                        checked={splintDesign === "NTI"}
                        onChange={handleSplintDesignCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">NTI</p>
                    </div>
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Tss Style"
                        checked={splintDesign === "Tss Style"}
                        onChange={handleSplintDesignCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Tss Style</p>
                    </div>
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Dawson B splint"
                        checked={splintDesign === "Dawson B splint"}
                        onChange={handleSplintDesignCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Dawson B splint</p>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Anterior full arch deprogrammer"
                        checked={splintDesign === "Anterior full arch deprogrammer"}
                        onChange={handleSplintDesignCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Anterior full arch deprogrammer</p>
                    </div>
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Kois style deprogrammer"
                        checked={splintDesign === "Kois style deprogrammer"}
                        onChange={handleSplintDesignCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Kois style deprogrammer</p>
                    </div>
                  </>
                )}
              </div>

              {selectedService?.serviceName === "Splints" ? (
                <p className="text-[#777] mt-3 text-[16px]">
                  We do not provide Gelb style splints as these require a lingual bar and do not
                  lend themselves to a CAD design and are best fabricated by a laboratory. If you
                  require a repositioning splint, please record and provide an appropriate intraoral
                  bite scan for this. Hard splints are not recommended for protection of cosmetic
                  restorations. Please select a flat plane nightguard (different menu) for this and
                  print in a soft material. Hard splints are only recommended for treatment of
                  bruxism, relief of pain or deprogramming.
                </p>
              ) : (
                <p className="text-[#777] mt-3 text-[16px]">
                  Deprogrammers are only meant to be used mainly for deprogramming an occlusion. Our
                  deprogrammers are specifically designed for this purpose. Our Kois style
                  deprogrammers do not include a labial bow.
                </p>
              )}
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                What arch would you like a{" "}
                {selectedService?.serviceName === "Splints" ? "splint" : "deprogrammer"} on?{" "}
                {isFieldRequired("intraoralScansProvided") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <div className="grid grid-cols-7 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Upper"
                    checked={splintArch === "Upper"}
                    onChange={handleSplintArchCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Upper</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Lower"
                    checked={splintArch === "Lower"}
                    onChange={handleSplintArchCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Lower</p>
                </div>
                {selectedService?.serviceName === "Splints" && (
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Both"
                      checked={splintArch === "Both"}
                      onChange={handleSplintArchCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Both</p>
                  </div>
                )}
              </div>
              {selectedService?.serviceName === "Splints" ? (
                <p className="text-[#777] mb-5 text-[16px]  my-2">
                  We only provide specific splint designs that extend only to the height of contour
                  of the teeth. We prefer intraoral scans in an open bite relationship for splint
                  design to reduce the need for adjustments. Please obtain intraoral scans of both
                  arches and a bite scan with a leaf gauge that records the bite in centric, in an
                  open relationship that allows sufficient thickness for the splint material.
                  Splints for both arches require a lot of space and are only used with the Dawson B
                  splint.
                </p>
              ) : (
                <p className="text-[#777] mb-5 text-[16px]  my-2">
                  We only provide specific deprogrammer designs. We prefer intraoral scans in an
                  open bite relationship for deprogrammer design to reduce the need for adjustments.
                  Please obtain intraoral scans of both arches and a bite scan with a leaf gauge
                  that records the bite in centric. This open relationship intraoral bite scan
                  should allow the correct thickness for the deprogrammer height desired.
                </p>
              )}
            </div>
            <div className="mb-8">
              <p className="text-white text-[17px] mb-2">
                Please select all teeth that you would like your{" "}
                {selectedService?.serviceName === "Splints" ? "splint" : "deprogrammer"} to extend
                to? <span className="text-red-500">*</span>
              </p>
              <div className="">
                <TeethV2
                  allTeethData={[]}
                  selectedTeeth={splintsToExtendSelectedTeeth}
                  setSelectedTeeth={setSplintsToExtendSelectedTeeth}
                  defaultNotation={notation}
                  key={`splintsToExtendSelectedTeeth${notation}`}
                  notationVisible={false}
                  missingTeeth={missingTeethSelectedTeeth}
                />
              </div>
            </div>
            <RangeInputField
              labelColor={"#fff"}
              labelText={`How much thickness would you like for your ${
                selectedService?.serviceName === "Splints (each arch if applicable)"
                  ? "splint"
                  : "deprogrammer"
              }?`}
              subtitle="If you are unsure about how much thickness you require, please leave the setting at 0 mm. A default setting of 2 mm will then be applied to the design."
              errorMessage={"Please enter a valid thickness value"}
              placeholder={""}
              htmlFor={"splintThickness"}
              name={"splintThickness"}
              value={splintThickness}
              handleInputChange={handleInputChange}
              validationFunctionName={validateNumber}
              isSubmitted={isFieldRequired}
              isFieldRequired={isFieldRequired("splintThickness")}
              unit="mm"
              min={0}
              max={4}
            />
            <div className="text-white flex flex-col mb-8">
              <p>
                Will you be printing or milling your{" "}
                {selectedService?.serviceName === "Splints" ? "splint" : "deprogrammer"}?{" "}
                {isFieldRequired("dentureBasePrintingType") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <p className="text-[#777] mb-4 text-[16px]">
                Deprogrammers can be milled with excellent results. If you are printing your
                deprogrammer, please use a hard splint material.
              </p>
              <div className="flex gap-3">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Printing"
                    checked={splintPrintingType === "Printing"}
                    onChange={handleSplintPrintingTypeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Printing</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Milling"
                    checked={splintPrintingType === "Milling"}
                    onChange={handleSplintPrintingTypeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Milling</p>
                </div>
              </div>
            </div>
          </>
        )}
        {selectedService?.serviceName === "Gingival recontouring guide" && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p>
                What arch would you like a soft tissue recontouring reduction guide for?{" "}
                <span className="text-red-500">*</span>
              </p>
              <p className="text-[#777] text-[16px] my-2">
                It is highly recommended that you provide at least photographs with an outline of
                what level of recontouring you require. A face scan is an even better tool to
                provide as we can design in accordance with the patient's lip line.
              </p>
              <div className="grid grid-cols-7 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Upper"
                    checked={recontouringReductionGuideOn === "Upper"}
                    onChange={handleRecontouringReductionGuideOnCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Upper</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Lower"
                    checked={recontouringReductionGuideOn === "Lower"}
                    onChange={handleRecontouringReductionGuideOnCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Lower</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Both"
                    checked={recontouringReductionGuideOn === "Both"}
                    onChange={handleRecontouringReductionGuideOnCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Both</p>
                </div>
              </div>
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                If you would also like a hard tissue recontouring reduction guide, what arch would
                you like the guide on? <span className="text-red-500">*</span>
              </p>
              <div className="grid grid-cols-7 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Upper"
                    checked={hardTissueRecontouring === "Upper"}
                    onChange={handlehardTissueRecontouringCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Upper</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Lower"
                    checked={hardTissueRecontouring === "Lower"}
                    onChange={handlehardTissueRecontouringCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Lower</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Both"
                    checked={hardTissueRecontouring === "Both"}
                    onChange={handlehardTissueRecontouringCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Both</p>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <p className="text-white text-[17px] mb-2">
                Please select all teeth that you would like your recontouring guides to extend to?{" "}
                <span className="text-red-500">*</span>
              </p>
              <div className="">
                <TeethV2
                  allTeethData={[]}
                  selectedTeeth={recontouringToExtendSelectedTeeth}
                  setSelectedTeeth={setRecontouringToExtendSelectedTeeth}
                  defaultNotation={notation}
                  key={`recontouringToExtendSelectedTeeth${notation}`}
                  notationVisible={false}
                  missingTeeth={missingTeethSelectedTeeth}
                />
              </div>
            </div>
            <RangeInputField
              labelColor={"#fff"}
              labelText={
                "If selecting a hard tissue recontouring guide as well, how much distance would you like between the final level of soft tissue and the final level of the hard tissue?"
              }
              errorMessage={"Please enter a valid thickness value"}
              placeholder={""}
              htmlFor={"hardTissueRecontouringThickness"}
              name={"hardTissueRecontouringThickness"}
              value={hardTissueRecontouringThickness}
              handleInputChange={handleInputChange}
              validationFunctionName={validateNumber}
              isSubmitted={isFieldRequired}
              isFieldRequired={isFieldRequired("hardTissueRecontouringThickness")}
              unit="mm"
              min={0}
              max={3}
            />
            <div className="text-white flex flex-col mb-8">
              <p>
                Will you be printing or milling your recontouring reduction guide?
                {isFieldRequired("dentureBasePrintingType") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <p className="text-[#777] mb-4 text-[16px]">
                Recontouring reduction guides are best printed. They do not lend themselves to easy
                milling.Our recontouring reduction guides are designed to be as close to tissue as
                possible to minimise surgical error. Path of insertion constraints may however
                result in a distance between the guide and tissue (soft and hard).
              </p>
              <div className="flex gap-3">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Printing"
                    checked={recontouringReductionPrintingType === "Printing"}
                    onChange={handleRecontouringReductionPrintingTypeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Printing</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Milling"
                    checked={recontouringReductionPrintingType === "Milling"}
                    onChange={handleRecontouringReductionPrintingTypeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Milling</p>
                </div>
              </div>
            </div>
          </>
        )}
        {selectedService?.serviceName === "Upper and/or lower dentures (resin only) " && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p>
                Are upper and/or lower intraoral scans being provided?{" "}
                {isFieldRequired("intraoralScansProvided") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <div className="grid grid-cols-7 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Upper"
                    checked={intraoralScansProvided === "Upper"}
                    onChange={handleIntraoralScansProvidedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Upper</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Lower"
                    checked={intraoralScansProvided === "Lower"}
                    onChange={handleIntraoralScansProvidedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Lower</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Both"
                    checked={intraoralScansProvided === "Both"}
                    onChange={handleIntraoralScansProvidedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Both</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="None"
                    checked={intraoralScansProvided === "None"}
                    onChange={handleIntraoralScansProvidedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">None</p>
                </div>
              </div>
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                Are upper and/or lower impressions being provided for digital inversion?{" "}
                {isFieldRequired("impressionsProvided") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <div className="grid grid-cols-7 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Upper"
                    checked={impressionsProvided === "Upper"}
                    onChange={handleImpressionsProvidedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Upper</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Lower"
                    checked={impressionsProvided === "Lower"}
                    onChange={handleImpressionsProvidedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Lower</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Both"
                    checked={impressionsProvided === "Both"}
                    onChange={handleImpressionsProvidedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Both</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="None"
                    checked={impressionsProvided === "None"}
                    onChange={handleImpressionsProvidedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">None</p>
                </div>
              </div>
            </div>
          </>
        )}
        {[
          "Upper and/or lower dentures (resin only) ",
          "Upper or Lower Partial dentures (resin)",
          "Partial dentures (metal)",
        ]?.includes(selectedService?.serviceName) && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p>
                Is a bite registration base desired? <span className="text-red-500">*</span>
              </p>
              <div className="flex py-3">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={biteRegistrationDesired === "Yes"}
                    onChange={handleBiteRegistrationDesiredCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={biteRegistrationDesired === "No"}
                    onChange={handleBiteRegistrationDesiredCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>

              <p className="text-[#777] mb-5 text-[16px]">
                Our bite registration bases are designed with special alignment markers for a
                digital workflow. Please leave these markers exposed and uncontaminated with wax
                when you print the bases and fabricate occlusal rims on them. Once a bite
                registration has been obtained, please scan only the buccal of upper and/or lower
                bases OR upper or lower antagonist teeth. The scans of the bases must clearly show
                these alignment markers. If a wash impression is obtained in either or both bases,
                please provide 360 degree scans of all bases that have been used for wash
                impressions. All occlusal rims will clearly have to indicate the{" "}
                <strong className="!text-white">
                  height of the teeth desired, occlusal plane, labial fullness, canine lines,
                  midline and interarch relationship.
                </strong>
              </p>
            </div>
          </>
        )}
        {["Partial dentures (metal)"]?.includes(selectedService?.serviceName) && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p>
                Will you be metal printing or casting or milling your denture base?{" "}
                <span className="text-red-500">*</span>
              </p>
              <p className="text-[#777] mb-5 text-[16px]">
                Metal printing of our designs is possible on metal 3D printers. Casting uses casting
                print resin that is then cast into metal. Milling is only available as an option in
                technopolymers.
              </p>
              <div className="flex gap-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Printing"
                    checked={dentureBasePrintingType === "Printing"}
                    onChange={handleDentureBasePrintingTypeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Printing</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Casting"
                    checked={dentureBasePrintingType === "Casting"}
                    onChange={handleDentureBasePrintingTypeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Casting</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Milling"
                    checked={dentureBasePrintingType === "Milling"}
                    onChange={handleDentureBasePrintingTypeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Milling</p>
                </div>
              </div>
            </div>
          </>
        )}

        {[
          "Upper and/or lower dentures (resin only) ",
          "Upper or Lower Partial dentures (resin)",
        ]?.includes(selectedService?.serviceName) && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p>
                Will you be printing or milling your denture base?{" "}
                {isFieldRequired("dentureBasePrintingType") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <div className="flex gap-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Printing"
                    checked={dentureBasePrintingType === "Printing"}
                    onChange={handleDentureBasePrintingTypeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Printing</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Milling"
                    checked={dentureBasePrintingType === "Milling"}
                    onChange={handleDentureBasePrintingTypeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Milling</p>
                </div>
              </div>
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                Will you be printing or milling your teeth?{" "}
                {isFieldRequired("teethPrintingType") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <div className="flex gap-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Printing"
                    checked={teethPrintingType === "Printing"}
                    onChange={handleTeethPrintingTypeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Printing</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Milling"
                    checked={teethPrintingType === "Milling"}
                    onChange={handleTeethPrintingTypeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Milling</p>
                </div>
              </div>
            </div>
            <div className="text-white flex mb-8">
              Is a monobloc print desired for a printed try in?&nbsp;
              <span className="text-red-500">*</span>
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="Yes"
                  checked={monoblocPrintDesired === "Yes"}
                  onChange={handleMonoblocPrintDesiredCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="No"
                  checked={monoblocPrintDesired === "No"}
                  onChange={handleMonoblocPrintDesiredCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>
          </>
        )}
        {[
          "Upper and/or lower dentures (resin only) ",
          "Upper or Lower Partial dentures (resin)",
          "Partial dentures (metal)",
        ]?.includes(selectedService?.serviceName) && (
          <>
            <div className="mb-8">
              <p className="text-white text-[16px] mb-2">
                Please indicate all teeth you would like to see in your{" "}
                {selectedService?.serviceName === "Upper or Lower Partial dentures (resin)"
                  ? "partial "
                  : ""}
                denture set up? <span className="text-red-500">*</span>
              </p>
              <TeethV2
                allTeethData={[]}
                selectedTeeth={dentureSetUpTeeth}
                setSelectedTeeth={setDentureSetUpTeeth}
                defaultNotation={notation}
                missingTeethSelectable={true}
                key={`dentureSetUpTeeth${notation}`}
                notationVisible={false}
                showHoveredIconOnSelect={true}
                missingTeeth={[...missingTeethSelectedTeeth, ...teethExtractionSelectedTeeth]}
              />
            </div>
          </>
        )}
        {["Upper or Lower Partial dentures (resin)", "Partial dentures (metal)"]?.includes(
          selectedService?.serviceName
        ) && (
          <>
            <div className="mb-8">
              <p className="text-white text-[16px] mb-2">
                Please indicate teeth that you DO NOT want to clasp in your design?{" "}
                <span className="text-red-500">*</span>
              </p>
              <TeethV2
                allTeethData={[]}
                selectedTeeth={claspTeeth}
                setSelectedTeeth={setClaspTeeth}
                defaultNotation={notation}
                key={`claspTeeth${notation}`}
                notationVisible={false}
                missingTeeth={[...missingTeethSelectedTeeth, ...teethExtractionSelectedTeeth]}
              />
            </div>
          </>
        )}
        {["Partial dentures (metal)"]?.includes(selectedService?.serviceName) && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p>
                Please indicate the type of major connector you prefer for your design?{" "}
                <small>(You will be advised if you preference is not possible)</small>{" "}
                <span className="text-red-500">*</span>
              </p>
              <div className="grid grid-cols-5 gap-y-3 mt-1">
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Palatal bar"
                    checked={majorConnector.includes("Palatal bar")}
                    onChange={handleMajorConnectorCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Palatal bar</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Palatal strap"
                    checked={majorConnector.includes("Palatal strap")}
                    onChange={handleMajorConnectorCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Palatal strap</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Anteroposterior palatal bar"
                    checked={majorConnector.includes("Anteroposterior palatal bar")}
                    onChange={handleMajorConnectorCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Anteroposterior palatal bar</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Anteroposterior palatal strap"
                    checked={majorConnector.includes("Anteroposterior palatal strap")}
                    onChange={handleMajorConnectorCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Anteroposterior palatal strap</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Full palatal coverage"
                    checked={majorConnector.includes("Full palatal coverage")}
                    onChange={handleMajorConnectorCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Full palatal coverage</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Lingual bar"
                    checked={majorConnector.includes("Lingual bar")}
                    onChange={handleMajorConnectorCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Lingual bar</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Lingual plate"
                    checked={majorConnector.includes("Lingual plate")}
                    onChange={handleMajorConnectorCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Lingual plate</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Lingual plate with cingulum extension"
                    checked={majorConnector.includes("Lingual plate with cingulum extension")}
                    onChange={handleMajorConnectorCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Lingual plate with cingulum extension</p>
                </div>
              </div>
            </div>
          </>
        )}
        {[
          "Upper and/or lower dentures (resin only) ",
          "Upper or Lower Partial dentures (resin)",
          "Partial dentures (metal)",
          "Nightguards for restorative protection",
        ]?.includes(selectedService?.serviceName) && (
          <>
            <div className="w-7/12">
              <RangeInputField
                labelColor={"#fff"}
                labelText={`How much thickness would you like for your ${
                  selectedService?.serviceName === "Nightguards"
                    ? "nightguard"
                    : selectedService?.serviceName === "Partial dentures (metal)"
                    ? " partial framework"
                    : selectedService?.serviceName === "Upper or Lower Partial dentures (resin)"
                    ? "partial denture"
                    : "denture"
                } (s)? (mm)`}
                subtitle="If you are unsure about how much thickness you require, please leave the setting at 0 mm. A default setting will then be applied to the design"
                errorMessage={"Please enter a valid thickness value"}
                placeholder={""}
                htmlFor={"dentureThickness"}
                name={"dentureThickness"}
                value={dentureThickness}
                handleInputChange={handleInputChange}
                validationFunctionName={validateNumber}
                isSubmitted={isFieldRequired}
                isFieldRequired={isFieldRequired("dentureThickness")}
                unit="mm"
                min={0}
                max={3}
              />
            </div>
          </>
        )}

        {["Nightguards"]?.includes(selectedService?.serviceName) && (
          <>
            <div className="text-white flex flex-col mb-8 mt-4">
              <p>
                Will you be printing or milling your nightguard?{" "}
                <span className="text-red-500">*</span>
              </p>
              <div className="flex gap-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={printingNightguard === "Yes"}
                    onChange={handlePrintingNightguardCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="No"
                    checked={printingNightguard === "No"}
                    onChange={handlePrintingNightguardCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            </div>
          </>
        )}

        {selectedService?.serviceName === "Upper and/or lower dentures (resin only) " && (
          <>
            <div className="text-white flex flex-col mb-8 mt-4">
              <p>
                Is a copy denture workflow desired? <span className="text-red-500">*</span>
              </p>
              <p className="text-[#777] mb-0 text-[16px]">
                Please provide a full 360 degree intraoral scanner scan of the upper OR lower
                denture to be copied. If upper AND lower dentures are to be copied, a bite
                registration scan will also be necessary. Impressions are also possible in a copy
                denture workflow and the denture may be used as an impression tray. The same 360
                degree intraoral scanner scan protocols apply even when in denture impressions are
                obtained.
              </p>
              <div className="flex py-3">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={dentureWorkflowDesired === "Yes"}
                    onChange={handleDentureWorkflowDesiredCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={dentureWorkflowDesired === "No"}
                    onChange={handleDentureWorkflowDesiredCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            </div>
          </>
        )}
        {[
          "Upper and/or lower dentures (resin only) ",
          "Upper or Lower Partial dentures (resin)",
          "Partial dentures (metal)",
        ].includes(selectedService?.serviceName) && (
          <>
            <div className="text-white flex flex-col mb-8 mt-4">
              <p>
                What occlusion set up would you like? <span className="text-red-500">*</span>
              </p>
              <div className="grid grid-cols-5 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Class I"
                    checked={occlusionSetUp === "Class I"}
                    onChange={handleOcclusionSetUpCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={occlusalOption1} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      Class I
                    </p>
                  </div>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Class II"
                    checked={occlusionSetUp === "Class II"}
                    onChange={handleOcclusionSetUpCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={occlusalOption2} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      Class II
                    </p>
                  </div>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Class III"
                    checked={occlusionSetUp === "Class III"}
                    onChange={handleOcclusionSetUpCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={occlusalOption3} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      Class III
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                Tooth mould shape <span className="text-red-500">*</span>
              </p>
              <div className="grid grid-cols-5 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Universal"
                    checked={teethMoldShape === "Universal"}
                    onChange={handleTeethMoldShapeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={mouldShapeOption1} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      Universal
                    </p>
                  </div>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Square"
                    checked={teethMoldShape === "Square"}
                    onChange={handleTeethMoldShapeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={mouldShapeOption2} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      Square
                    </p>
                  </div>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Oval"
                    checked={teethMoldShape === "Oval"}
                    onChange={handleTeethMoldShapeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={mouldShapeOption3} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      Oval
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                Teeth setup style <span className="text-red-500">*</span>
              </p>
              <div className="grid grid-cols-5 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Standard"
                    checked={teethArrangementStyle === "Standard"}
                    onChange={handleTeethArrangementStyleCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={setupStyle1} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      Standard
                    </p>
                  </div>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Masculine"
                    checked={teethArrangementStyle === "Masculine"}
                    onChange={handleTeethArrangementStyleCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={setupStyle2} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      Masculine
                    </p>
                  </div>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Feminine"
                    checked={teethArrangementStyle === "Feminine"}
                    onChange={handleTeethArrangementStyleCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={setupStyle3} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      Feminine
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-white flex flex-col mb-8">
              <p>
                Teeth Size <span className="text-red-500">*</span>
              </p>
              <div className="grid grid-cols-6 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Small"
                    checked={teethSize === "Small"}
                    onChange={handleTeethSizeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Small</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Medium"
                    checked={teethSize === "Medium"}
                    onChange={handleTeethSizeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Medium</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Large"
                    checked={teethSize === "Large"}
                    onChange={handleTeethSizeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Large</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Extra Large"
                    checked={teethSize === "Extra Large"}
                    onChange={handleTeethSizeCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Extra Large</p>
                </div>
              </div>
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                Is a crossbite to be created? <span className="text-red-500">*</span>
              </p>
              <div className="grid grid-cols-6 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Anterior"
                    checked={crossbiteCreated === "Anterior"}
                    onChange={handleCrossbiteCreatedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Anterior</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Posterior right"
                    checked={crossbiteCreated === "Posterior right"}
                    onChange={handleCrossbiteCreatedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Posterior right</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Posterior left"
                    checked={crossbiteCreated === "Posterior left"}
                    onChange={handleCrossbiteCreatedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Posterior left</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Both sides"
                    checked={crossbiteCreated === "Both sides"}
                    onChange={handleCrossbiteCreatedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Both sides</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="None"
                    checked={crossbiteCreated === "None"}
                    onChange={handleCrossbiteCreatedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">None</p>
                </div>
              </div>
            </div>
          </>
        )}
        {selectedService?.serviceName === "Upper and/or lower dentures (resin only) " && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p>
                Are you creating dentures for a photogrammetric scan for vertical dimension and bite
                relationship for implants? <span className="text-red-500">*</span>
              </p>
              <p className="text-[#777] mb-0 text-[16px]">
                If yes, please provide intraoral scans with either healing abutments/caps or tissue
                scan bodies or photogrammetric scanbodies depending on the workflow you prefer.
                Dentures made for this purpose are usually useful as indicators of bite or vertical
                dimension and also as a backup conversion prosthesis.
              </p>
              <div className="flex py-3">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={creatingImplantDentures === "Yes"}
                    onChange={handleCreatingImplantDenturesCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={creatingImplantDentures === "No"}
                    onChange={handleCreatingImplantDenturesCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            </div>
          </>
        )}
        {selectedService?.serviceObjId && preferredSoftwares?.length > 0 && (
          <div className="text-white flex flex-col mb-8">
            <p>
              Preferred design software?{" "}
              {isFieldRequired("preferredDesignSoftware") ? (
                <span className="text-red-500">*</span>
              ) : (
                ""
              )}
            </p>
            <div className="grid grid-cols-5 gap-y-3 mt-1">
              {preferredSoftwares
                ?.filter(
                  (d) =>
                    !["Dentbird", "Blender for Dental", "Clinux", "Hypsocad"].includes(
                      d?.softwareName
                    )
                )
                ?.map((item) => {
                  return (
                    <div className="flex items-center mr-3" key={item?.softwareObjId}>
                      <CircularCheckbox
                        value={item?.softwareName}
                        checked={preferredDesignSoftware === item?.softwareName}
                        onChange={handlePreferredDesignSoftwareCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{item?.softwareName}</p>
                    </div>
                  );
                })}
              <div className="flex items-center mr-3 col-span-1">
                <CircularCheckbox
                  value="No Preferance"
                  checked={preferredDesignSoftware === "No Preferance"}
                  onChange={handlePreferredDesignSoftwareCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No Preference</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab("Patient Details");
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              submitCaseDetailsForm();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
};
