import React, { useRef, useState, Fragment } from "react";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import hut from "../../Assets/images/hut.svg";
import logo from "../../Assets/images/LOGO.svg";
import { FaAngleRight } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import printerIcon from "../../Assets/icons/printerIcon.svg";
import { useNavigate } from "react-router-dom";
import orderPlacedIcon from "../../Assets/icons/orderPlacedIcon.svg";
import exitIcon from "../../Assets/icons/exitIcon.svg";
import html2canvas from "html2canvas";
import jsPSF from "jspdf";
import Modal from "react-modal";

import {
  useSaveOrderMutation,
  useUpdateOrderByIdMutation,
  useGetOrderByIdMutation,
  useUpdateOrderMutation,
} from "../../services/orders";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../Login/Login";
import { useEffect } from "react";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import { Tooltip } from "react-tooltip";
import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";
const displayOptions = require("../../Utils/orders-form1-options.json");

function ModelForPrintingOrderConfirmation({
  orderFor,
  orderObjId,
  userInfo,
  userRole,
  setActiveTab,
  setIsDisclaimerAccepted,
}) {
  const [getOrderById] = useGetOrderByIdMutation();
  const { getAccessTokenSilently } = useAuth0();
  const [orderDetails, setOrderDetails] = useState();

  const divideObjects = (originalObject, objectKeys) => {
    return Object.keys(originalObject).reduce((result, key) => {
      if (objectKeys.includes(key)) {
        result[key] = originalObject[key];
      }
      return result;
    }, {});
  };

  // useEffect(() => {
  //   const getSavedData = async () => {
  //     const accessToken = await getAccessTokenSilently({
  //       authorizationParams: {
  //         audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
  //       },
  //     });

  //     const resp = await getOrderById({
  //       accessToken,
  //       orderId: orderObjId,
  //     }).unwrap();
  //     setOrderDetails(resp.data[0]);
  //   };
  //   getSavedData();
  // }, []);

  return (
    <div className="flex w-full">
      <OrderConfirmationTab
        orderObjId={orderObjId}
        userRole={userRole}
        userInfo={userInfo}
        setActiveTab={setActiveTab}
        setIsDisclaimerAccepted={setIsDisclaimerAccepted}
      />
    </div>
  );
}

export default ModelForPrintingOrderConfirmation;

export const OrderConfirmationTab = ({
  userInfo,
  userRole,
  orderObjId,
  setActiveTab,
  setIsDisclaimerAccepted,
}) => {
  const { orthodonticOrderDetails } = useSelector((state) => state.orderFormDetails);
  const { user, isAuthenticated } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };
  const [saveOrder] = useSaveOrderMutation();
  const [updateOrder] = useUpdateOrderMutation();
  const pdfRef = useRef();
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const navigate = useNavigate();
  const personalDetails = useSelector((state) => state.personalDetails);

  const placeOrderHandler = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await updateOrder({
      accessToken,
      data: {
        _id: orthodonticOrderDetails?._id,
        isPriority: orthodonticOrderDetails?.isPriority,
        expectedDelivery: orthodonticOrderDetails?.expectedDelivery,
        serviceObjId: orthodonticOrderDetails?.serviceObjId,
        status: "1",
      },
    }).unwrap();
    if (resp) {
      GetToast(resp["message"]);
    }
    setIsOrderPlaced(true);
  };
  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPSF("p", "mm", "a4", true);
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      const imageWidth = canvas.width;
      const imageHeight = canvas.height;
      const ratio = Math.min(width / imageWidth, height / imageHeight);
      let imgX = (width - imageWidth * ratio) / 2;
      let imgY = (height - imageHeight * ratio) / 2;

      // add margins for the image in x and y axis
      var position = 12;
      pdf.addImage(
        imgData,
        "PNG",
        10,
        position,
        imgX,
        imgY,
        imageWidth * ratio,
        imageHeight * ratio
      );
      pdf.save("download.pdf");
    });
  };
  return (
    <div className="MAIN-DASH bg-black md:p-4 px-4 pt-4 pb-20 w-full min-h-screen h-fit flex flex-col">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <img src={logo} className="mx-auto w-[50px] h-[40px] mr-4 block md:hidden" alt="" />
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {personalDetails.data[0]?.firstName ?? user.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}

          <Notification />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between gap-4 w-full bg-secondary px-4 rounded-t-xl py-4">
        <p className="font-medium text-[34px] text-white">Orthodontic Planning Order</p>
        <div onClick={downloadPDF} className="flex gap-4 items-center">
          <img src={printerIcon} alt="" />
          <button className="px-4 py-2 bg-[#FF7E00] rounded-md flex items-center gap-2 text-white font-medium">
            <img src={exitIcon} alt="" />
            Export
          </button>
        </div>
      </div>

      <div className="ORDER-DETAIL-COMPONENT flex-1 flex flex-col h-full bg-secondary rounded-b-xl px-4 w-full">
        <div className="bg-backgroundV2 flex flex-row flex-wrap items-center gap-4 md:justify-around justify-between py-4 md:px-0 px-4 mb-4 rounded-md">
          <div className="flex items-center gap-4">
            <div className="bg-[#8ED259] h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
              <AiOutlineCheck className=" " />
            </div>
            <p className=" text-white md:block hidden ">Service Details</p>
          </div>
          <div className="md:w-[200px] h-[20px] ">
            <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
          </div>
          <div className="flex items-center gap-4">
            <div className="bg-[#8ED259] h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
              <AiOutlineCheck className=" " />
            </div>
            <p className="text-white md:block hidden ">Order Details</p>
          </div>
          <div className="md:w-[200px] h-[20px] ">
            <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
          </div>
          <div className="flex items-center gap-4">
            {isOrderPlaced ? (
              <div className="bg-[#8ED259] h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
                <AiOutlineCheck className=" " />
              </div>
            ) : (
              <div className="bg-orange-500 h-8 w-8 rounded-full border text-[#fff] font-medium flex items-center justify-center">
                3
              </div>
            )}

            <p className="text-white font-semibold md:block hidden ">Confirmation</p>
          </div>
        </div>
        {isOrderPlaced ? (
          <div className="flex-1 flex flex-col justify-between h-full">
            <div className="h-full w-full flex-1 flex flex-col justify-center items-center gap-2">
              <img src={orderPlacedIcon} alt="" />
              <p className="text-2xl text-white font-semibold">Order Placed Successfully</p>
              <p className="text-[17px] text-white">
                Order has been sent for review will get back to you with the best possible quote in
                48 hours
              </p>
            </div>
            <div className="mb-4">
              <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
                <button
                  onClick={() => {
                    navigate("/orders");
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div ref={pdfRef} className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4">
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Patient Initials</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.patientDetails?.patientInitials || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Patient details have been anonymized</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.patientDetails?.detailsAnonymized ? "Yes" : "No"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Date of Birth</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.patientDetails?.dob || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Gender</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.patientDetails?.gender || "NA"}
                </p>
              </div>
              <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Shipping Address</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.patientDetails?.address || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Country</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.patientDetails?.country || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Postal code (Zip code/Pin code)</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.patientDetails?.pinCode || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Options</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.options || ""}
                </p>
              </div>
              <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Clinical Conditions</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.clinicalConditions?.join(", ") || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Arches to treat</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.archesToTreat?.join(", ") || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Molar Occlusal Goals</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.molarOcclusalGoals || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Midline</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.midline?.join(", ") || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">The patient has missing teeth</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.missingTeeth?.hasMissingTeeth
                    ? "Yes"
                    : "No"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Missing Teeths</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.missingTeeth?.teeth?.join(", ") || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Are any teeth that should not be moved?</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.teethNeedToMove?.needToMove ? "Yes" : "No"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Not to move selected teeths</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.teethNeedToMove?.teeth?.join(", ") || "NA"}
                </p>
              </div>

              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Inter Proximal Reduction (IPR)</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.ipr || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Overjet Correction</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.overjetCorrection?.option || "NA"}
                </p>
              </div>
              {orthodonticOrderDetails?.caseDetails?.overjetCorrection?.option === "Improve" && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Overjet Correction Improve Length (µ microns)
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {orthodonticOrderDetails?.caseDetails?.overjetCorrection?.improveLength || "0"}
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Overbite Correction</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.overbiteCorrection?.option || "NA"}
                </p>
              </div>
              {orthodonticOrderDetails?.caseDetails?.overbiteCorrection?.option === "Improve" && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Overbite Correction Improve Length (µ microns)
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {orthodonticOrderDetails?.caseDetails?.overbiteCorrection?.improveLength || "0"}
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Crossbite Correction</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.crossbiteCorrection || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Attachments allowed?</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.attachmentsAllowed ? "Yes" : "No"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Retainer Required?</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.retainerRequired ? "Yes" : "No"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Special Instructions</p>
                <p className="text-[#555] font-medium mt-2">
                  {orthodonticOrderDetails?.caseDetails?.additionalInstructions}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Photographs</p>
                <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                  {Object.keys(orthodonticOrderDetails?.uploadFiles?.photographs)?.map(function (
                    key,
                    index
                  ) {
                    if (orthodonticOrderDetails?.uploadFiles?.photographs[key]?.s3Url) {
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            openImageModal(
                              orthodonticOrderDetails?.uploadFiles?.photographs[key]?.s3Url
                            )
                          }
                        >
                          <img
                            key={index} // Don't forget to provide a unique key for each element in the array
                            className="h-7 w-7 object-contain"
                            src={orthodonticOrderDetails?.uploadFiles?.photographs[key]?.s3Url}
                            alt=""
                          />
                        </div>
                      );
                    }
                    return null; // Return null if there's no image URL for the key
                  })}
                </div>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Radiographs</p>
                <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                  {Object.keys(orthodonticOrderDetails?.uploadFiles?.radiographs)?.map(function (
                    key,
                    index
                  ) {
                    if (orthodonticOrderDetails?.uploadFiles?.radiographs[key]?.s3Url) {
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            openImageModal(
                              orthodonticOrderDetails?.uploadFiles?.radiographs[key]?.s3Url
                            )
                          }
                        >
                          <img
                            key={index} // Don't forget to provide a unique key for each element in the array
                            className="h-7 w-7 object-contain"
                            src={orthodonticOrderDetails?.uploadFiles?.radiographs[key]?.s3Url}
                            alt=""
                          />
                        </div>
                      );
                    }
                    return null; // Return null if there's no image URL for the key
                  })}
                </div>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Intra Oral Scans</p>
                <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                  {Object.keys(orthodonticOrderDetails?.uploadFiles?.intraOralScans)?.map(function (
                    key,
                    index
                  ) {
                    if (orthodonticOrderDetails?.uploadFiles?.intraOralScans[key]?.s3Url) {
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            openImageModal(
                              orthodonticOrderDetails?.uploadFiles?.intraOralScans[key]?.s3Url
                            )
                          }
                        >
                          <img
                            key={index} // Don't forget to provide a unique key for each element in the array
                            className="h-7 w-7 object-contain"
                            src={orthodonticOrderDetails?.uploadFiles?.intraOralScans[key]?.s3Url}
                            alt=""
                          />
                        </div>
                      );
                    }
                    return null; // Return null if there's no image URL for the key
                  })}
                </div>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Raw DICOM Data</p>
                <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                  {Object.keys(orthodonticOrderDetails?.uploadFiles?.rawDicomData)?.map(function (
                    key,
                    index
                  ) {
                    if (orthodonticOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url) {
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            openImageModal(
                              orthodonticOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url
                            )
                          }
                        >
                          <img
                            key={index} // Don't forget to provide a unique key for each element in the array
                            className="h-7 w-7 object-contain"
                            src={orthodonticOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url}
                            alt=""
                          />
                        </div>
                      );
                    }
                    return null; // Return null if there's no image URL for the key
                  })}
                </div>
              </div>
              {isImageModalOpen && (
                <div className="image-modal">
                  <Modal
                    isOpen={isImageModalOpen}
                    onRequestClose={() => setIsImageModalOpen(false)}
                    contentLabel="Custom Modal"
                    className="image-modal"
                    // style={customStyles}
                    ariaHideApp={false}
                  >
                    <>
                      <div className="MODAL-BODY flex flex-col h-full">
                        <button className="w-full text-right" onClick={() => closeImageModal()}>
                          <AiOutlineClose className="ml-auto" />
                        </button>
                        <img className="h-full w-full object-contain" src={selectedImage} alt="" />
                      </div>
                    </>
                  </Modal>
                </div>
              )}
            </div>
            <div className="mb-4">
              <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
                <button
                  onClick={() => {
                    navigate("/orders");
                  }}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setActiveTab("Disclaimers");
                    setIsDisclaimerAccepted(false);
                  }}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Back
                </button>
                <button
                  onClick={() => {
                    placeOrderHandler();
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Get Quote
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
