import React, { useRef, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import html2canvas from "html2canvas";
import jsPSF from "jspdf";
import Modal from "react-modal";
import { Tooltip } from "react-tooltip";

import { useSaveOrderMutation, useUpdateOrderMutation } from "../../services/orders";
import Notification from "../../Components/Notification/Notification";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import { LoginButton } from "../Login/Login";

import { FaAngleRight } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import hut from "../../Assets/images/hut.svg";
import logo from "../../Assets/images/LOGO.svg";
import exitIcon from "../../Assets/icons/exitIcon.svg";
import printerIcon from "../../Assets/icons/printerIcon.svg";
import orderPlacedIcon from "../../Assets/icons/orderPlacedIcon.svg";

function CrownOrderConfirmation({
  orderFor,
  orderObjId,
  userInfo,
  userRole,
  setActiveTab,
  selectedServiceCategory,
  setIsDisclaimerAccepted,
}) {
  return (
    <div className="flex w-full">
      <OrderConfirmationTab
        orderObjId={orderObjId}
        userRole={userRole}
        userInfo={userInfo}
        setActiveTab={setActiveTab}
        selectedServiceCategory={selectedServiceCategory}
        setIsDisclaimerAccepted={setIsDisclaimerAccepted}
      />
    </div>
  );
}

export default CrownOrderConfirmation;

export const OrderConfirmationTab = ({
  userInfo,
  userRole,
  orderObjId,
  setActiveTab,
  selectedServiceCategory,
  setIsDisclaimerAccepted,
}) => {
  const { crownAndBridgeOrderDetails } = useSelector((state) => state.orderFormDetails);
  const { user, isAuthenticated } = useAuth0();
  const personalDetails = useSelector((state) => state.personalDetails);
  const { getAccessTokenSilently } = useAuth0();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsImageModalOpen(true);
  };

  console.log("selectedServiceCategory", selectedServiceCategory);

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };
  const [updateOrder] = useUpdateOrderMutation();
  const [saveOrder] = useSaveOrderMutation();
  const pdfRef = useRef();
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const navigate = useNavigate();

  console.log("crownAndBridgeOrderDetails", crownAndBridgeOrderDetails);

  const placeOrderHandler = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let resp;
    resp = await updateOrder({
      accessToken,
      data: {
        _id: crownAndBridgeOrderDetails?._id,
        isPriority: crownAndBridgeOrderDetails?.isPriority,
        expectedDelivery: crownAndBridgeOrderDetails?.expectedDelivery,
        serviceObjId: crownAndBridgeOrderDetails?.serviceObjId,
        status: "1",
      },
    }).unwrap();
    if (resp) {
      GetToast(resp["message"]);
    }
    setIsOrderPlaced(true);
  };
  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPSF("p", "mm", "a4", true);
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      const imageWidth = canvas.width;
      const imageHeight = canvas.height;
      const ratio = Math.min(width / imageWidth, height / imageHeight);
      let imgX = (width - imageWidth * ratio) / 2;
      let imgY = (height - imageHeight * ratio) / 2;

      // add margins for the image in x and y axis
      var position = 12;
      pdf.addImage(
        imgData,
        "PNG",
        10,
        position,
        imgX,
        imgY,
        imageWidth * ratio,
        imageHeight * ratio
      );
      pdf.save("download.pdf");
    });
  };

  const getServiceNames = (services) => {
    const serviceNames = [];
    if (selectedServiceCategory?.catservices?.length > 0 && services?.length > 0) {
      services?.every((item) => {
        selectedServiceCategory?.catservices?.every((serItem) => {
          if (item?.serviceObjId === serItem?.serviceObjId) {
            serviceNames.push(serItem?.serviceName);
            return false;
          }
          return true;
        });
        return true;
      });
    }
    return serviceNames.join(", ");
  };

  return (
    <div className="MAIN-DASH bg-black md:p-4 px-4 pt-4 pb-20 w-full min-h-screen h-fit flex flex-col">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <img src={logo} className="mx-auto w-[50px] h-[40px] mr-4 block md:hidden" alt="" />
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {personalDetails.data[0]?.firstName ?? user.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}

          <Notification />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between gap-4 w-full bg-secondary px-4 rounded-t-xl py-4">
        <p className="font-medium text-[34px] text-white">Crown and Bridge</p>
        <div onClick={downloadPDF} className="flex gap-4 items-center">
          <img src={printerIcon} alt="" />
          <button className="px-4 py-2 bg-[#FF7E00] rounded-md flex items-center gap-2 text-white font-medium">
            <img src={exitIcon} alt="" />
            Export
          </button>
        </div>
      </div>

      <div className="ORDER-DETAIL-COMPONENT flex-1 flex flex-col h-full bg-secondary rounded-b-xl px-4 w-full">
        <div className="bg-backgroundV2 flex flex-row flex-wrap items-center gap-4 md:justify-around justify-between py-4 md:px-0 px-4 mb-4 rounded-md">
          <div className="flex items-center gap-4">
            <div className="bg-[#8ED259] h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
              <AiOutlineCheck className=" " />
            </div>
            <p className=" text-white md:block hidden ">Service Details</p>
          </div>
          <div className="md:w-[200px] h-[20px] ">
            <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
          </div>
          <div className="flex items-center gap-4">
            <div className="bg-[#8ED259] h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
              <AiOutlineCheck className=" " />
            </div>
            <p className="text-white md:block hidden ">Order Details</p>
          </div>
          <div className="md:w-[200px] h-[20px] ">
            <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
          </div>
          <div className="flex items-center gap-4">
            {isOrderPlaced ? (
              <div className="bg-[#8ED259] h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
                <AiOutlineCheck className=" " />
              </div>
            ) : (
              <div className="bg-orange-500 h-8 w-8 rounded-full border text-[#fff] font-medium flex items-center justify-center">
                3
              </div>
            )}

            <p className="text-white font-semibold md:block hidden ">Confirmation</p>
          </div>
        </div>
        {isOrderPlaced ? (
          <div className="flex-1 flex flex-col justify-between h-full">
            <div className="h-full w-full flex-1 flex flex-col justify-center items-center gap-2">
              <img src={orderPlacedIcon} alt="" />
              <p className="text-2xl text-white font-semibold">Order Placed Successfully</p>
              <p className="text-[17px] text-white">
                Order has been sent for review will get back to you with the best possible quote in
                48 hours
              </p>
            </div>
            <div className="mb-4">
              <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
                <button
                  onClick={() => {
                    navigate("/orders");
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div ref={pdfRef} className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4">
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Patient Initials</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.patientDetails?.patientInitials || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Patient details have been anonymized</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.patientDetails?.detailsAnonymized ? "Yes" : "No"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Date of Birth</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.patientDetails?.dob || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Gender</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.patientDetails?.gender || "NA"}
                </p>
              </div>
              <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Shipping Address</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.patientDetails?.address || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Country</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.patientDetails?.country || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Postal code (Zip code/Pin code)</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.patientDetails?.pinCode || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">The patient has missing teeth</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.missingTeeth?.hasMissingTeeth
                    ? "Yes"
                    : "No"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Missing Teeths</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.missingTeeth?.teeth?.join(", ") || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Restorative Type</p>
                <p className="text-[#555] font-medium mt-2">
                  {getServiceNames(crownAndBridgeOrderDetails?.caseDetails?.services) || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Restorative Selected Teeth</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.services
                    ?.map((item) => item?.teeth)
                    ?.join(", ")}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Type of Crown</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.crownType?.length > 0
                    ? crownAndBridgeOrderDetails?.caseDetails?.crownType.join(", ")
                    : "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Type of Veneer</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.veneerType?.length > 0
                    ? crownAndBridgeOrderDetails?.caseDetails?.veneerType.join(", ")
                    : "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Cement Gap (µ microns)</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.cementGap || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Interproximal Contacts</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.interproximalContacts || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Occlusal Contacts</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.occlusalContacts || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Pontic Design</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.ponticDesign || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Do you need to see a design preview?</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.needDesignPreview ? "Yes" : "No"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Will you be Milling or Printing?</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.processType?.process || "NA"}
                </p>
              </div>
              {crownAndBridgeOrderDetails?.caseDetails?.processType?.process === "Printing" && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    If you are printing what restorative space do you prefer? (µ microns)
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {
                      crownAndBridgeOrderDetails?.caseDetails?.processType
                        ?.preferredRestorativeSpace
                    }
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Design file to be provided?</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.designFileNeeded ? "Yes" : "No"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Preferred design software?</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.preferredSoftware || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">
                  Restorations are normally provided as .STLS, Please advise if a construction.info
                  file is desired.
                </p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.constructionFileNeeded ? "Yes" : "No"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">
                  Is a printable crown and bridge model desired?
                </p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.printableModelRequired?.modelRequired
                    ? "Yes"
                    : "No"}
                </p>
              </div>
              {crownAndBridgeOrderDetails?.caseDetails?.printableModelRequired?.modelRequired && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Should the crown and bridge model include dies?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {crownAndBridgeOrderDetails?.caseDetails?.printableModelRequired?.includeDies
                      ? "Yes"
                      : "No"}
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">
                  If a certain restoration lacks occlusal clearance? Please advise your preferred
                  course of action
                </p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.restorationLacksOC || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Additonal Instructions</p>
                <p className="text-[#555] font-medium mt-2">
                  {crownAndBridgeOrderDetails?.caseDetails?.additionalInstructions || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Photographs</p>
                <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                  {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.photographs)?.map(function (
                    key,
                    index
                  ) {
                    if (crownAndBridgeOrderDetails?.uploadFiles?.photographs[key]?.s3Url) {
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            openImageModal(
                              crownAndBridgeOrderDetails?.uploadFiles?.photographs[key]?.s3Url
                            )
                          }
                        >
                          <img
                            key={index} // Don't forget to provide a unique key for each element in the array
                            className="h-7 w-7 object-contain"
                            src={crownAndBridgeOrderDetails?.uploadFiles?.photographs[key]?.s3Url}
                            alt=""
                          />
                        </div>
                      );
                    }
                    return null; // Return null if there's no image URL for the key
                  })}
                </div>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Radiographs</p>
                <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                  {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.radiographs)?.map(function (
                    key,
                    index
                  ) {
                    if (crownAndBridgeOrderDetails?.uploadFiles?.radiographs[key]?.s3Url) {
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            openImageModal(
                              crownAndBridgeOrderDetails?.uploadFiles?.radiographs[key]?.s3Url
                            )
                          }
                        >
                          <img
                            key={index} // Don't forget to provide a unique key for each element in the array
                            className="h-7 w-7 object-contain"
                            src={crownAndBridgeOrderDetails?.uploadFiles?.radiographs[key]?.s3Url}
                            alt=""
                          />
                        </div>
                      );
                    }
                    return null; // Return null if there's no image URL for the key
                  })}
                </div>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Intra Oral Scans</p>
                <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                  {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.intraOralScans)?.map(
                    function (key, index) {
                      if (crownAndBridgeOrderDetails?.uploadFiles?.intraOralScans[key]?.s3Url) {
                        return (
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              openImageModal(
                                crownAndBridgeOrderDetails?.uploadFiles?.intraOralScans[key]?.s3Url
                              )
                            }
                          >
                            <img
                              key={index} // Don't forget to provide a unique key for each element in the array
                              className="h-7 w-7 object-contain"
                              src={
                                crownAndBridgeOrderDetails?.uploadFiles?.intraOralScans[key]?.s3Url
                              }
                              alt=""
                            />
                          </div>
                        );
                      }
                      return null; // Return null if there's no image URL for the key
                    }
                  )}
                </div>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Raw DICOM Data</p>
                <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                  {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.rawDicomData)?.map(
                    function (key, index) {
                      if (crownAndBridgeOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url) {
                        return (
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              openImageModal(
                                crownAndBridgeOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url
                              )
                            }
                          >
                            <img
                              key={index} // Don't forget to provide a unique key for each element in the array
                              className="h-7 w-7 object-contain"
                              src={
                                crownAndBridgeOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url
                              }
                              alt=""
                            />
                          </div>
                        );
                      }
                      return null; // Return null if there's no image URL for the key
                    }
                  )}
                </div>
              </div>
              {isImageModalOpen && (
                <div className="image-modal">
                  <Modal
                    isOpen={isImageModalOpen}
                    onRequestClose={() => setIsImageModalOpen(false)}
                    contentLabel="Custom Modal"
                    className="image-modal"
                    // style={customStyles}
                    ariaHideApp={false}
                  >
                    <>
                      <div className="MODAL-BODY flex flex-col h-full">
                        <button className="w-full text-right" onClick={() => closeImageModal()}>
                          <AiOutlineClose className="ml-auto" />
                        </button>
                        <img className="h-full w-full object-contain" src={selectedImage} alt="" />
                      </div>
                    </>
                  </Modal>
                </div>
              )}
            </div>
            <div className="mb-4">
              <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
                <button
                  onClick={() => {
                    navigate("/orders");
                  }}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setActiveTab("Disclaimers");
                    setIsDisclaimerAccepted(false);
                  }}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Back
                </button>
                <button
                  onClick={() => {
                    placeOrderHandler();
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Get Quote
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
