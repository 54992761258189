import React, { useState, useEffect, useMemo } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FileUploadComponent = ({
  maxFiles,
  acceptedFileTypes,
  onUpload,
  allowMultipleFiles = false,
  initialFiles,
  className = "",
  styleItemPanelAspectRatio = null,
}) => {
  const [files, setFiles] = useState();
  //   useEffect(() => {
  //     console.log('FilePond instance has initialized');
  //   }, []);

  const handleUpdateFiles = (fileItems) => {
    // debugger
    setFiles(fileItems.map((fileItem) => fileItem.file));
    // Call the onUpload callback function and pass the uploaded files
    // onUpload(fileItems.map((fileItem) => fileItem.file));
  };

  const handleEvent = (file) => {
    // debugger
    if (file?.file && "_relativePath" in file?.file) {
      // Perform actions when a file upload starts
      onUpload([file.file]);
      console.log("File upload started:", file.filename);
    }
  };

  const mimeTypes = useMemo(() => {
    return {
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      png: "image/png",
      gif: "image/gif",
      bmp: "image/bmp",
      tiff: "image/tiff",
      webp: "image/webp",
      // Add more extensions as needed
    };
  }, []);

  // Function to get the MIME type based on the file extension
  const getMimeType = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    return mimeTypes[extension] || "application/octet-stream";
  };

  return (
    <div>
      <FilePond
        className={className ?? ""}
        files={files}
        onupdatefiles={handleUpdateFiles}
        allowMultiple={allowMultipleFiles}
        {...(maxFiles ? { maxFiles: maxFiles } : {})}
        {...(styleItemPanelAspectRatio
          ? { styleItemPanelAspectRatio: styleItemPanelAspectRatio }
          : {})}
        acceptedFileTypes={acceptedFileTypes}
        oninit={() => {
          console.log("FilePond instance has initialized");
          setFiles(initialFiles?.length > 0 ? initialFiles : []);
        }}
        onaddfilestart={handleEvent}
        server={{
          load: (source, load, error, progress, abort, headers) => {
            var myRequest = new Request(source);
            headers = new Headers(headers);
            fetch(myRequest)
              .then((response) => {
                if (!response.ok) {
                  throw new Error(`Network response was not ok: ${response.statusText}`);
                }

                return response.blob();
              })
              .then((myBlob) => {
                if (myBlob.size > 0) {
                  // Get the MIME type based on the file extension
                  const mimeType = getMimeType(source);
                  const newBlob = new Blob([myBlob], { type: mimeType });
                  load(newBlob);
                } else {
                  error("File not found or empty");
                }
                // load(myBlob);
              })
              .catch((error) => {
                console.log(error);
                load(null);
              });
          },
        }}
      />
    </div>
  );
};

export default FileUploadComponent;
