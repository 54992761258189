/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import TextInputField from "../InputField-Comps/TextInputField";
import NumberInputField from "../InputField-Comps/NumberInputField";
import { AxiosPostHook } from "../../hooks/AxiosPostHook";
import UseAccessToken from "../../hooks/UseAccessToken";
import { useNavigate } from "react-router-dom";
import {
  useSavePersonalDetailsSpMutation,
  useUpdatePersonalDetailsMutation,
  useGetProfileMutation
} from "../../services/user";
import { GetToast } from "../Toast-Comps/GetToast";
import { useAuth0 } from "@auth0/auth0-react";
import { useSaveFileMutation } from "../../services/uploadFiles";
import FileUploadComponent from "../InputField-Comps/FilePondField";
import TimeZoneInputField from "../InputField-Comps/TimeZoneInputField";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import { Checkbox } from "../InputField-Comps/CheckboxField";

import exocad from "../../Assets/icons/softwareIcons/exocad.webp"
import threeShape from "../../Assets/icons/softwareIcons/3shape.png"
import ackuretta from "../../Assets/icons/softwareIcons/ackuretta.webp"
import archForm from "../../Assets/icons/softwareIcons/archForm.png"
import asiga from "../../Assets/icons/softwareIcons/asiga.svg"
import atomicaAI from "../../Assets/icons/softwareIcons/atomicaAI.png"
import B4D from "../../Assets/icons/softwareIcons/B4D.png"
import blueSkyPlan from "../../Assets/icons/softwareIcons/blueSkyPlan.png"
import chituBox from "../../Assets/icons/softwareIcons/chituBox.png"
import clinux from "../../Assets/icons/softwareIcons/clinux.jpg"
import coDiagnotix from "../../Assets/icons/softwareIcons/coDiagnotix.png"
import deltaface from "../../Assets/icons/softwareIcons/deltaface.png"
import dentbird from "../../Assets/icons/softwareIcons/dentbird.jpg"
import DTX from "../../Assets/icons/softwareIcons/DTX.png"
import exoplan from "../../Assets/icons/softwareIcons/exoplan.jpg"
import formLabs from "../../Assets/icons/softwareIcons/formLabs.png"
import graphy from "../../Assets/icons/softwareIcons/graphy.png"
import hypsocad from "../../Assets/icons/softwareIcons/hypsocad.png"
import implaStation from "../../Assets/icons/softwareIcons/implaStation.png"
import invisalign from "../../Assets/icons/softwareIcons/invisalign.png"
import M3D from "../../Assets/icons/softwareIcons/M3D.png"
import medit from "../../Assets/icons/softwareIcons/medit.png"
import neuralHive from "../../Assets/icons/softwareIcons/neuralHive.png"
import nextDent from "../../Assets/icons/softwareIcons/nextDent.png"
import onyxCeph from "../../Assets/icons/softwareIcons/onyxCeph.webp"
import orthup from "../../Assets/icons/softwareIcons/orthup.webp"
import phrozen from "../../Assets/icons/softwareIcons/phrozen.png"
import realGuide from "../../Assets/icons/softwareIcons/realGuide.png"
import sicat from "../../Assets/icons/softwareIcons/sicat.png"
import simPlant from "../../Assets/icons/softwareIcons/simPlant.jpg"
import spark from "../../Assets/icons/softwareIcons/spark.webp"
import sprintray from "../../Assets/icons/softwareIcons/sprintray.jpg"
import sureSmile from "../../Assets/icons/softwareIcons/sureSmile.png"
import ulabSystems from "../../Assets/icons/softwareIcons/ulabSystems.svg"
import dentsplySirona from "../../Assets/icons/softwareIcons/dentsplySirona.svg"

const InputFieldsProfile = ({
  setModalIsOpen,
  emailFromUserProfile,
  setIsProfileUpdated,
  profileDetailData,
  profileId,
  readPersonalDetails,
  role
}) => {
  const [saveFile] = useSaveFileMutation();
  const { getAccessTokenSilently } = useAuth0();
  const accessToken = UseAccessToken();
  const navigate = useNavigate();
  //profile details states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [genderValue, setGenderValue] = useState("");
  const [workingWithLaboratory, setWorkingWithLaboratory] = useState();
  const [provideIndependentDesignService, setProvideIndependentDesignService] = useState();
  const [cadSoftwaresUsed, setCadSoftwaresUsed] = useState([]);
  const [implantPlanningSoftwaresUsed, setImplantPlanningSoftwaresUsed] = useState([]);
  const [orthodonticTreatmentPlanningSoftwaresUsed, setOrthodonticTreatmentPlanningSoftwaresUsed] = useState([]);
  const [printingSoftwaresUsed, setPrintingSoftwaresUsed] = useState([]);

  console.log("genderValue", genderValue)

  //const [companyName, setCompanyName] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");

  const [updatePersonalDetails] = useUpdatePersonalDetailsMutation();
  const [savePersonalDetailsSp] = useSavePersonalDetailsSpMutation();

  const [getProfile, { data: profileData }] = useGetProfileMutation();
  const [isProfileFormSubmitted, setIsProfileFormSubmitted] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isProfileValid, setIsProfileValid] = useState(false);

  console.log(profileDetailData, 'DATA');

  //   FIRST FORM SUBMISSION FUNCTION
  const submitPersonDetails = async (e) => {
    e.preventDefault();
    // Perform validation for each input
    const isFirstNameValid =
      !isFieldRequired("firstName") || validateFirstName(firstName);
    const isLastNameValid =
      !isFieldRequired("lastName") || validateLastName(lastName);
    const isMobileNumberValid =
      !isFieldRequired("mobile") || validateMobileNumber(mobile);
    // const isCompanyNameValid =
    //   !isFieldRequired("companyName") || validateFirstName(companyName);
    const isRegistrationNumberValid =
      !isFieldRequired("registrationNumber") ||
      validateFirstName(registrationNumber);

    // Set overall form validity
    setIsProfileValid(
      isFirstNameValid &&
      isLastNameValid &&
      isMobileNumberValid &&
      //isCompanyNameValid &&
      isRegistrationNumberValid
    );

    setIsProfileFormSubmitted(true);

    if (
      isFirstNameValid &&
      isLastNameValid &&
      isMobileNumberValid &&
      //isCompanyNameValid &&
      isRegistrationNumberValid
    ) {
      // Submit the form or perform any other action
      const profileDetailSave = {
        firstName: firstName,
        lastName: lastName,
        mobile: mobile,
        // companyName: companyName,
        gender: genderValue,
        registrationNumber: registrationNumber,
        email: emailFromUserProfile,
        timeZone: selectedTimeZone,
      };
      const profileDetailUpdate = {
        firstName: firstName,
        lastName: lastName,
        mobile: mobile,
        gender: genderValue,
        registrationNumber: registrationNumber,
        timeZone: selectedTimeZone,
        _id: profileId,
      };
      // updating the profile details here
      if (profileDetailData.length === 0) {
        console.log("SAVE");
        const response = await savePersonalDetailsSp({
          accessToken,
          ...profileDetailSave,
        }).unwrap();
        if (response?.type === 1) {
          GetToast("Profile Saved Successfully!!");
        } else {
          GetToast("OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.", "error");
        }
        setIsProfileUpdated(true);
        setModalIsOpen(false);
        // const _id = (await response).data[0]["_id"];
      } else {
        console.log("UPLOAD");
        const response = await updatePersonalDetails({
          accessToken,
          ...profileDetailUpdate,
        }).unwrap();
        if (response?.type === 1) {
          GetToast("Profile Updated Successfully!!");
        } else {
          GetToast("OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.", "error");
        }
      }

      setIsProfileUpdated(true);
      setModalIsOpen(false);
    } else {
      console.log("Form Not Valid");
    }
  };
  // FIRST FORM VALIDATIONS
  const validateFirstName = (value) => {
    return value?.trim().length > 0;
  };
  const validateLastName = (value) => {
    return value?.trim().length > 0;
  };
  const validateTimeZone = (value) => {
    return value?.trim().length > 0;
  };
  const validateGender = (value) => {
    return value?.trim().length > 0;
  };
  const validateWorkingWithLaboratory = (value) => {
    return value?.trim().length > 0;
  };
  const validateProvideIndependentDesignService = (value) => {
    return value?.trim().length > 0;
  };
  const validateMobileNumber = (value) => {
    const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number
    return phoneRegex.test(value);
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      "firstName",
      // "lastName",
      // "pincode",
      // "textMessages",
      // "emailAlerts",
      // "tnc",
      "gender",

      // "registrationNumber",
      // "regAuthority",

      "address",
      // "country",
      // "pinCode",
    ];

    return requiredFields.includes(fieldName);
  };
  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;

    if (name === "firstName") setFirstName(value);
    if (name === "lastName") setLastName(value);
    if (name === "mobile") setMobile(value);
    //if (name === "companyName") setCompanyName(value);
    if (name === "registrationNumber") setRegistrationNumber(value);
    if (name === "timeZone") setSelectedTimeZone(value);
    if (name === "gender") setGenderValue(value);
    if (name === "workingWithLaboratory") setWorkingWithLaboratory(value);
    if (name === "provideIndependentDesignService") setProvideIndependentDesignService(value);
  };

  const handleCadSoftwaresCheckboxChange = (value, checked) => {
    if (checked) {
      // If the checkbox is checked, add the value to the array
      setCadSoftwaresUsed([...cadSoftwaresUsed, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      setCadSoftwaresUsed(cadSoftwaresUsed.filter((item) => item !== value));
    }
  };

  const handleImplantPlanningSoftwaresCheckboxChange = (value, checked) => {
    if (checked) {
      // If the checkbox is checked, add the value to the array
      setImplantPlanningSoftwaresUsed([...implantPlanningSoftwaresUsed, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      setImplantPlanningSoftwaresUsed(implantPlanningSoftwaresUsed.filter((item) => item !== value));
    }
  };

  const handleOrthodonticSoftwaresCheckboxChange = (value, checked) => {
    if (checked) {
      // If the checkbox is checked, add the value to the array
      setOrthodonticTreatmentPlanningSoftwaresUsed([...orthodonticTreatmentPlanningSoftwaresUsed, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      setOrthodonticTreatmentPlanningSoftwaresUsed(orthodonticTreatmentPlanningSoftwaresUsed.filter((item) => item !== value));
    }
  };

  const handlePrintingSoftwaresCheckboxChange = (value, checked) => {
    if (checked) {
      // If the checkbox is checked, add the value to the array
      setPrintingSoftwaresUsed([...printingSoftwaresUsed, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      setPrintingSoftwaresUsed(printingSoftwaresUsed.filter((item) => item !== value));
    }
  };

  React.useEffect(() => {
    setFirstName(profileDetailData?.[0]?.firstName);
    setLastName(profileDetailData?.[0]?.lastName);
    setMobile(profileDetailData?.[0]?.mobile);
    setSelectedTimeZone(profileDetailData?.[0]?.timeZone);
    //setCompanyName(profileDetailData?.[0]?.clinicalDetails[0]?.regName);
    setRegistrationNumber(
      profileDetailData?.[0]?.registrationNumber
    );
  }, [profileDetailData]);
  // React.useEffect(() => {
  //   if (isUpdatePersonalDetailsSuccess) {
  //     readPersonalDetails(accessToken);
  //   }
  // }, [isUpdatePersonalDetailsSuccess]
  // )
  return (
    <div className="h-full">
      <form onSubmit={submitPersonDetails} className=" h-full flex flex-col">
        <div className="flex-1 pt-8 pb-8">
          <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"First Name "}
              errorMessage={"This field is required"}
              placeholder={"Enter your First Name"}
              isFieldRequired={isFieldRequired("firstName")}
              htmlFor={"firstName"}
              name={"firstName"}
              value={firstName}
              handleInputChange={handleInputChange}
              validationFunctionName={validateFirstName}
              isSubmitted={isProfileFormSubmitted}
            />
          </div>
          <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"Last Name"}
              errorMessage={"Please enter your Last Name"}
              placeholder={"Enter your Last Name"}
              isFieldRequired={isFieldRequired("lastName")}
              htmlFor={"lastName"}
              name={"lastName"}
              value={lastName}
              handleInputChange={handleInputChange}
              validationFunctionName={validateLastName}
              isSubmitted={isProfileFormSubmitted}
            />
          </div>
          <div className="relative">
            <NumberInputField
              labelColor={"#777"}
              labelText={"Mobile Number"}
              errorMessage={"Please enter your Mobile Number"}
              placeholder={"Enter your Mobile Number"}
              isFieldRequired={isFieldRequired("mobile")}
              htmlFor={"mobile"}
              name={"mobile"}
              value={mobile}
              handleInputChange={handleInputChange}
              validationFunctionName={validateMobileNumber}
              isSubmitted={isProfileFormSubmitted}
            />
          </div>
          <div className="relative">
            <TimeZoneInputField
              labelColor={"#777"}
              labelText={"Time Zone "}
              errorMessage={"Please Select a Timezone"}
              placeholder={"Select Timezone"}
              isFieldRequired={isFieldRequired("timezone")}
              htmlFor={"timeZone"}
              name={"timeZone"}
              value={selectedTimeZone}
              handleInputChange={handleInputChange}
              validationFunctionName={validateTimeZone}
              isSubmitted={isProfileFormSubmitted}
            />
          </div>
          <div className="relative">
            <label className="text-[16px] text-white" htmlFor="gender">
              Gender
              {isFieldRequired("gender") ? <span className="text-red-500 ml-1">*</span> : ""}
            </label>
            <div className="flex-1 mt-4 mb-4 flex md:flex-row flex-col md:gap-12 gap-4">
              <div className="flex">
                <CircularCheckbox
                  value="Male"
                  name="gender"
                  checked={genderValue === "Male"}
                  onChange={handleInputChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">Male</p>
              </div>
              <div className="flex">
                <CircularCheckbox
                  value="Female"
                  name="gender"
                  checked={genderValue === "Female"}
                  onChange={handleInputChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">Female</p>
              </div>
              <div className="flex">
                <CircularCheckbox
                  value="Others"
                  name="gender"
                  checked={genderValue === "Others"}
                  onChange={handleInputChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">Others</p>
              </div>
            </div>
            {isFieldRequired("gender") &&
              validateGender(genderValue) === false &&
              isProfileFormSubmitted && (
                <p className="text-red-500 text-[15px]">
                  This field is required
                </p>
            )}
          </div>
          {role === "34fs3" && <div>
            <div className="text-white flex flex-col mb-2 text-[16px]">
              ● Are you working with a dental laboratory?
              {isFieldRequired("workingWithLaboratory") ? <span className="text-red-500 ml-1">*</span> : ""}
              <div className="flex gap-5">
                <div className="flex items-center my-3">
                  <CircularCheckbox 
                    value="Yes" 
                    name="workingWithLaboratory" 
                    checked={workingWithLaboratory === "Yes"} 
                    onChange={handleInputChange} 
                    borderColor={"#FF8503"} 
                    backgroundColor={"#FF8503"}               
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox 
                    value="No"
                    name="workingWithLaboratory"  
                    checked={workingWithLaboratory === "No"} 
                    onChange={handleInputChange} 
                    borderColor={"#FF8503"} 
                    backgroundColor={"#FF8503"} 
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            </div>
            {isFieldRequired("workingWithLaboratory") &&
              validateWorkingWithLaboratory(workingWithLaboratory) === false &&
              isProfileFormSubmitted && (
                <p className="text-red-500 text-[15px]">
                  This field is required
                </p>
            )}
          </div>}
          {role === "34fs3" && <div>
            <div className="text-white flex flex-col mb-2 text-[16px]">
              ● Do you provide independent dental design services?
              {isFieldRequired("provideIndependentDesignService") ? <span className="text-red-500 ml-1">*</span> : ""}
              <div className="flex gap-5">
                <div className="flex items-center my-3">
                  <CircularCheckbox 
                    value="Yes" 
                    name="provideIndependentDesignService" 
                    checked={provideIndependentDesignService === "Yes"} 
                    onChange={handleInputChange} 
                    borderColor={"#FF8503"} 
                    backgroundColor={"#FF8503"} 
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox 
                    value="No" 
                    name="provideIndependentDesignService" 
                    checked={provideIndependentDesignService === "No"} 
                    onChange={handleInputChange} 
                    borderColor={"#FF8503"} 
                    backgroundColor={"#FF8503"} 
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            </div>
            {isFieldRequired("provideIndependentDesignService") &&
              validateProvideIndependentDesignService(provideIndependentDesignService) === false &&
              isProfileFormSubmitted && (
                <p className="text-red-500 text-[15px]">
                  This field is required
                </p>
            )}
          </div>}
          {role === "34fs3" && <div>
            <p className="font-medium text-[24px] text-white mt-2 mb-4">
              What software do you use ?
              {isFieldRequired("cadSoftwaresUsed") ? <span className="text-red-500 ml-1">*</span> : ""}
            </p>
            <div className="ml-3 mb-5">
              <div>
                <p className="font-medium text-[16px] text-[#FF8503] mt-2 mb-4">
                  CAD:
                  {isFieldRequired("cadSoftwaresUsed") ? <span className="text-red-500 ml-1">*</span> : ""}
                </p>
                <div className="grid md:grid-cols-2 grid-cols-1 ml-4 gap-x-8 gap-y-4 text-[16px] text-white">
                  <Checkbox
                    label="Exocad"
                    value="Exocad"
                    icon={exocad}
                    checked={cadSoftwaresUsed.includes("Exocad")}
                    onChange={handleCadSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="3Shape"
                    value="3Shape"
                    icon={threeShape}
                    checked={cadSoftwaresUsed.includes("3Shape")}
                    onChange={handleCadSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Blenderfordental (B4D)"
                    value="Blenderfordental (B4D)"
                    icon={B4D}
                    checked={cadSoftwaresUsed.includes("Blenderfordental (B4D)")}
                    onChange={handleCadSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="inLab"
                    value="inLab"
                    icon={dentsplySirona}
                    checked={cadSoftwaresUsed.includes("inLab")}
                    onChange={handleCadSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="MeditCAD"
                    value="MeditCAD"
                    icon={medit}
                    checked={cadSoftwaresUsed.includes("MeditCAD")}
                    onChange={handleCadSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Dentbird"
                    value="Dentbird"
                    icon={dentbird}
                    checked={cadSoftwaresUsed.includes("Dentbird")}
                    onChange={handleCadSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Clinux"
                    value="Clinux"
                    icon={clinux}
                    checked={cadSoftwaresUsed.includes("Clinux")}
                    onChange={handleCadSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="HypsoCAD"
                    value="HypsoCAD"
                    icon={hypsocad}
                    checked={cadSoftwaresUsed.includes("HypsoCAD")}
                    onChange={handleCadSoftwaresCheckboxChange}
                  />
                </div>
                {isFieldRequired("cadSoftwaresUsed") && cadSoftwaresUsed.length === 0 &&
                  <p className="text-sm text-red-500">Select at least one</p>
                }
              </div>
              <div>
                <p className="font-medium text-[16px] text-[#FF8503] mt-2 mb-4">
                Implant Planning:
                  {isFieldRequired("implantPlanningSoftwaresUsed") ? <span className="text-red-500 ml-1">*</span> : ""}
                </p>
                <div className="grid md:grid-cols-2 grid-cols-1 ml-4 gap-x-8 gap-y-4 text-[16px] text-white">
                  <Checkbox
                    label="Blue Sky Plan"
                    value="Blue Sky Plan"
                    icon={blueSkyPlan}
                    checked={implantPlanningSoftwaresUsed.includes("Blue Sky Plan")}
                    onChange={handleImplantPlanningSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Exoplan"
                    value="Exoplan"
                    icon={exoplan}
                    checked={implantPlanningSoftwaresUsed.includes("Exoplan")}
                    onChange={handleImplantPlanningSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="DTX Studio"
                    value="DTX Studio"
                    icon={DTX}
                    checked={implantPlanningSoftwaresUsed.includes("DTX Studio")}
                    onChange={handleImplantPlanningSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="RealGuide"
                    value="RealGuide"
                    icon={realGuide}
                    checked={implantPlanningSoftwaresUsed.includes("RealGuide")}
                    onChange={handleImplantPlanningSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Implastation"
                    value="Implastation"
                    icon={implaStation}
                    checked={implantPlanningSoftwaresUsed.includes("Implastation")}
                    onChange={handleImplantPlanningSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="CoDiagnostix"
                    value="CoDiagnostix"
                    icon={coDiagnotix}
                    checked={implantPlanningSoftwaresUsed.includes("CoDiagnostix")}
                    onChange={handleImplantPlanningSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="3Shape Implant Studio"
                    value="3Shape Implant Studio"
                    icon={threeShape}
                    checked={implantPlanningSoftwaresUsed.includes("3Shape Implant Studio")}
                    onChange={handleImplantPlanningSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Blenderfordental (B4D)"
                    value="Blenderfordental (B4D)"
                    icon={B4D}
                    checked={implantPlanningSoftwaresUsed.includes("Blenderfordental (B4D)")}
                    onChange={handleImplantPlanningSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Sicat"
                    value="Sicat"
                    icon={sicat}
                    checked={implantPlanningSoftwaresUsed.includes("Sicat")}
                    onChange={handleImplantPlanningSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Simplant"
                    value="Simplant"
                    icon={simPlant}
                    checked={implantPlanningSoftwaresUsed.includes("Simplant")}
                    onChange={handleImplantPlanningSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Atomica AI"
                    value="Atomica AI"
                    icon={atomicaAI}
                    checked={implantPlanningSoftwaresUsed.includes("Atomica AI")}
                    onChange={handleImplantPlanningSoftwaresCheckboxChange}
                  />
                </div>
                {isFieldRequired("implantPlanningSoftwaresUsed") && implantPlanningSoftwaresUsed.length === 0 &&
                  <p className="text-sm text-red-500">Select at least one</p>
                }
              </div>
              <div>
                <p className="font-medium text-[16px] text-[#FF8503] mt-2 mb-4">
                Orthodontic Treatment planning:
                  {isFieldRequired("orthodonticTreatmentPlanningSoftwaresUsed") ? <span className="text-red-500 ml-1">*</span> : ""}
                </p>
                <div className="grid md:grid-cols-2 grid-cols-1 ml-4 gap-x-8 gap-y-4 text-[16px] text-white">
                  <Checkbox
                    label="Maestro 3D"
                    value="Maestro 3D"
                    icon={M3D}
                    checked={orthodonticTreatmentPlanningSoftwaresUsed.includes("Maestro 3D")}
                    onChange={handleOrthodonticSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Archform"
                    value="Archform"
                    icon={archForm}
                    checked={orthodonticTreatmentPlanningSoftwaresUsed.includes("Archform")}
                    onChange={handleOrthodonticSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="OrthUp"
                    value="OrthUp"
                    icon={orthup}
                    checked={orthodonticTreatmentPlanningSoftwaresUsed.includes("OrthUp")}
                    onChange={handleOrthodonticSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="OnyxCeph"
                    value="OnyxCeph"
                    icon={onyxCeph}
                    checked={orthodonticTreatmentPlanningSoftwaresUsed.includes("OnyxCeph")}
                    onChange={handleOrthodonticSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Deltaface"
                    value="Deltaface"
                    icon={deltaface}
                    checked={orthodonticTreatmentPlanningSoftwaresUsed.includes("Deltaface")}
                    onChange={handleOrthodonticSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Neural Hive Kea"
                    value="Neural Hive Kea"
                    icon={neuralHive}
                    checked={orthodonticTreatmentPlanningSoftwaresUsed.includes("Neural Hive Kea")}
                    onChange={handleOrthodonticSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="uLab"
                    value="uLab"
                    icon={ulabSystems}
                    checked={orthodonticTreatmentPlanningSoftwaresUsed.includes("uLab")}
                    onChange={handleOrthodonticSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="3Shape Ortho Studio"
                    value="3Shape Ortho Studio"
                    icon={threeShape}
                    checked={orthodonticTreatmentPlanningSoftwaresUsed.includes("3Shape Ortho Studio")}
                    onChange={handleOrthodonticSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Blue Sky Plan"
                    value="Blue Sky Plan"
                    icon={blueSkyPlan}
                    checked={orthodonticTreatmentPlanningSoftwaresUsed.includes("Blue Sky Plan")}
                    onChange={handleOrthodonticSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Graphy DAD"
                    value="Graphy DAD"
                    icon={graphy}
                    checked={orthodonticTreatmentPlanningSoftwaresUsed.includes("Graphy DAD")}
                    onChange={handleOrthodonticSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Invisalign"
                    value="Invisalign"
                    icon={invisalign}
                    checked={orthodonticTreatmentPlanningSoftwaresUsed.includes("Invisalign")}
                    onChange={handleOrthodonticSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Suresmile"
                    value="Suresmile"
                    icon={sureSmile}
                    checked={orthodonticTreatmentPlanningSoftwaresUsed.includes("Suresmile")}
                    onChange={handleOrthodonticSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Spark"
                    value="Spark"
                    icon={spark}
                    checked={orthodonticTreatmentPlanningSoftwaresUsed.includes("Spark")}
                    onChange={handleOrthodonticSoftwaresCheckboxChange}
                  />
                </div>
                {isFieldRequired("orthodonticTreatmentPlanningSoftwaresUsed") && orthodonticTreatmentPlanningSoftwaresUsed.length === 0 &&
                  <p className="text-sm text-red-500">Select at least one</p>
                }
              </div>
              <div>
                <p className="font-medium text-[16px] text-[#FF8503] mt-2 mb-4">
                Printing software:
                  {isFieldRequired("printingSoftwaresUsed") ? <span className="text-red-500 ml-1">*</span> : ""}
                </p>
                <div className="grid md:grid-cols-2 grid-cols-1 ml-4 gap-x-8 gap-y-4 text-[16px] text-white">
                  <Checkbox
                    label="Sprintray Rayware"
                    value="Sprintray Rayware"
                    icon={sprintray}
                    checked={printingSoftwaresUsed.includes("Sprintray Rayware")}
                    onChange={handlePrintingSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Ackuretta Alpha AI"
                    value="Ackuretta Alpha AI"
                    icon={ackuretta}
                    checked={printingSoftwaresUsed.includes("Ackuretta Alpha AI")}
                    onChange={handlePrintingSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Asiga Composer"
                    value="Asiga Composer"
                    icon={asiga}
                    checked={printingSoftwaresUsed.includes("Asiga Composer")}
                    onChange={handlePrintingSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="NextDent 3D Sprint"
                    value="NextDent 3D Sprint"
                    icon={nextDent}
                    checked={printingSoftwaresUsed.includes("NextDent 3D Sprint")}
                    onChange={handlePrintingSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="FormLabs PreForm"
                    value="FormLabs PreForm"
                    icon={formLabs}
                    checked={printingSoftwaresUsed.includes("FormLabs PreForm")}
                    onChange={handlePrintingSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Chitubox"
                    value="Chitubox"
                    icon={chituBox}
                    checked={printingSoftwaresUsed.includes("Chitubox")}
                    onChange={handlePrintingSoftwaresCheckboxChange}
                  />
                  <Checkbox
                    label="Phrozen DS Slicer"
                    value="Phrozen DS Slicer"
                    icon={phrozen}
                    checked={printingSoftwaresUsed.includes("Phrozen DS Slicer")}
                    onChange={handlePrintingSoftwaresCheckboxChange}
                  />
                </div>
                {isFieldRequired("printingSoftwaresUsed") && printingSoftwaresUsed.length === 0 &&
                  <p className="text-sm text-red-500">Select at least one</p>
                }
              </div>
            </div>
          </div>}
          {/* <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"Company Name"}
              errorMessage={"Please enter your Company Name"}
              placeholder={"Enter your Company Name"}
              isFieldRequired={isFieldRequired("companyName")}
              htmlFor={"companyName"}
              name={"companyName"}
              value={companyName}
              handleInputChange={handleInputChange}
              validationFunctionName={validateLastName}
              isSubmitted={isProfileFormSubmitted}
            />
          </div> */}
          {/* {
            role !== "676dza2" ?
              <div className="relative">
                <NumberInputField
                  labelColor={"#777"}
                  labelText={"Registration Number"}
                  errorMessage={"Please enter your Registration Number"}
                  placeholder={"Enter your Registration Number"}
                  isFieldRequired={isFieldRequired("registrationNumber")}
                  htmlFor={"registrationNumber"}
                  name={"registrationNumber"}
                  value={registrationNumber}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateLastName}
                  isSubmitted={isProfileFormSubmitted}
                />
              </div>
              :
              <></>
          } */}
        </div>

        <div className="flex items-center justify-end gap-4">
          <button
            onClick={() => setModalIsOpen(false)}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputFieldsProfile;

// export function TextInputField({
//   labelText,
//   labelColor,
//   errorMessage,
//   placeholder,
//   isFieldRequired,
//   name,
//   handleInputChange,
//   isSubmitted,
//   validateName,
// }) {
//   return (
//     <>
//       <label
//         htmlFor="name"
//         style={{
//           color: `${
//             isSubmitted && !validateName(name) ? "border-red-500" : labelColor
//           }`,
//         }}
//         className="text-[16px] leading-4"
//       >
//         {labelText}
//       </label>

//       <input
//         type="text"
//         id="name"
//         name="name"
//         value={name}
//         onChange={handleInputChange}
//         placeholder={placeholder}
//         className={`border p-2 w-full text-[#555] text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 ${
//           isSubmitted && !validateName(name) ? "border-red-500" : ""
//         }`}
//         required={isFieldRequired}
//       />
//       {isSubmitted && !validateName(name) && isFieldRequired && (
//         <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
//           {errorMessage}
//         </p>
//       )}
//     </>
//   );
// }
// export function EmailInputField({
//   labelText,
//   labelColor,
//   errorMessage,
//   placeholder,
//   email,
//   handleInputChange,
//   isSubmitted,
//   validateEmail,
//   isFieldRequired,
// }) {
//   return (
//     <>
//       <label
//         htmlFor="email"
//         style={{
//           color: `${
//             isSubmitted && !validateEmail(email) ? "border-red-500" : labelColor
//           }`,
//         }}
//         className="text-[16px] leading-4"
//       >
//         {labelText}
//       </label>
//       <input
//         type="email"
//         id="email"
//         name="email"
//         value={email}
//         placeholder={placeholder}
//         onChange={handleInputChange}
//         className={`border p-2 w-full text-[#555] text-[16px] leading-4 mt-2 mb-4 rounded-md h-10  ${
//           isSubmitted && !validateEmail(email) && isFieldRequired
//             ? "border-red-500 "
//             : ""
//         }`}
//         required={isFieldRequired}
//       />
//       {isSubmitted && !validateEmail(email) && isFieldRequired && (
//         <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
//           {errorMessage}
//         </p>
//       )}
//     </>
//   );
// }
// export function NumberInputField({
//   labelText,
//   errorMessage,
//   labelColor,
//   placeholder,
//   phone,
//   handleInputChange,
//   isSubmitted,
//   validatePhone,
//   isFieldRequired,
// }) {
//   return (
//     <>
//       <label
//         htmlFor="phone"
//         style={{
//           color: `${
//             isSubmitted && !validatePhone(phone) ? "border-red-500" : labelColor
//           }`,
//         }}
//         className="text-[16px] text-[#777] leading-4"
//       >
//         {labelText}
//       </label>
//       <input
//         type="tel"
//         id="phone"
//         name="phone"
//         value={phone}
//         placeholder={placeholder}
//         onChange={handleInputChange}
//         className={`border p-2 w-full text-[#555] text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 ${
//           isSubmitted && !validatePhone(phone) ? "border-red-500" : ""
//         }`}
//         required={isFieldRequired}
//       />
//       {isSubmitted && !validatePhone(phone) && isFieldRequired && (
//         <p className="text-red-500  relative text-[15px] left-0 top-[-10px] ">
//           {errorMessage}
//         </p>
//       )}
//     </>
//   );
// }
