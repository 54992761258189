const patientDetailsData = {
  patientInitials: "",
  detailsAnonymized: false,
  dob: "",
  gender: "",
  address: "",
  country: "",
  pinCode: "",
  location: {},
};

const uploadFilesData = {
  photographs: {
    imageOne: { fileType: "", s3Url: null, _id: "" },
    imageTwo: { fileType: "", s3Url: null, _id: "" },
    imageThree: { fileType: "", s3Url: null, _id: "" },
    imageFour: { fileType: "", s3Url: null, _id: "" },
    imageFive: { fileType: "", s3Url: null, _id: "" },
    imageSix: { fileType: "", s3Url: null, _id: "" },
    imageSeven: { fileType: "", s3Url: null, _id: "" },
    imageEight: { fileType: "", s3Url: null, _id: "" },
    imageNine: { fileType: "", s3Url: null, _id: "" },
    imageTen: { fileType: "", s3Url: null, _id: "" },
  },
  radiographs: {
    orthopantomogram: { fileType: "", s3Url: null, _id: "" },
    periapicalXrays: { fileType: "", s3Url: null, _id: "" },
    cephalogram: { fileType: "", s3Url: null, _id: "" },
  },
  intraOralScans: {
    upperScan: { fileType: "", s3Url: null, _id: "" },
    lowerScan: { fileType: "", s3Url: null, _id: "" },
    biteScan1: { fileType: "", s3Url: null, _id: "" },
    biteScan2: { fileType: "", s3Url: null, _id: "" },
  },
  rawDicomData: {
    fileOne: { fileType: "", s3Url: null, _id: "" },
    fileTwo: { fileType: "", s3Url: null, _id: "" },
    fileThree: { fileType: "", s3Url: null, _id: "" },
  },
};

const disclaimersData = {
  isTncChecked: false,
  tncDocument: null,
};

export const crownAndBridgeInitialOrderDetails = {
  expectedDelivery: "",
  serviceObjId: "",
  isPriority: null,
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    missingTeeth: {
      hasMissingTeeth: null,
      teeth: [],
    },
    services: [
      // {
      //   serviceObjId: "",
      //   teeth: [],
      //   crownType: [],
      //   veneerType: [],
      //   cementGap: 0,
      //   interproximalContacts: "",
      //   occlusalContacts: "",
      //   ponticDesign: "",
      //   needDesignPreview: null,
      //   processType: {
      //     process: "",
      //     preferredRestorativeSpace: 0,
      //   },
      //   designFileNeeded: null,
      //   preferredSoftware: "",
      //   constructionFileNeeded: null,
      //   printableModelRequired: {
      //     modelRequired: null,
      //     includeDies: null,
      //   },
      //   restorationLacksOC: "",
      //   additionalInstructions: "",
      // },
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const dentalImplantInitialOrderDetails = {
  expectedDelivery: "",
  serviceObjId: "",
  isPriority: null,
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    missingTeeth: {
      hasMissingTeeth: null,
      teeth: [],
    },
    services: [
      // {
      //   "serviceObjId": "",
      //   "teeth": [],
      //   "crownType": [],
      //   "restorationDesign": "",
      //   "interproximalContacts": "",
      //   "occlusalContacts": "",
      //   "ponticDesign": "",
      //   "processType": {
      //     "process": "",
      //     "preferredRestorativeSpace": 0
      //   },
      //   "cementGap": 0,
      //   "needDesignPreview": null,
      //   "designFileNeeded": null,
      //   "preferredSoftware": "",
      //   "constructionFileNeeded": null,
      //   "printableModelRequired": {
      //     "modelRequired": null,
      //     "includeDies": null
      //   },
      //   "restorationLacksOC": "",
      //   "additionalInstructions": ""
      // }
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const orthodonticInitialOrderDetails = {
  expectedDelivery: "",
  serviceObjId: "",
  isPriority: false,
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    missingTeeth: {
      hasMissingTeeth: null,
      teeth: [],
    },
    services: [
      // {
      //   serviceObjId: "",
      //   options: "",
      //   clinicalConditions: [],
      //   printableAligners: "",
      //   archesToTreat: "",
      //   midline: [],
      //   ipr: "",
      //   teethNotToMove: {
      //     needToMove: null,
      //     teeth: [],
      //   },
      //   overjetCorrection: {
      //     option: "",
      //     improveLength: 0,
      //   },
      //   overbiteCorrection: {
      //     option: "",
      //     improveLength: 0,
      //   },
      //   crossbiteCorrection: "",
      //   attachmentsAllowed: null,
      //   attachmentsAllowedOn: "",
      //   retainerRequired: null,
      //   preferredSoftware: "",
      //   molarOcclusalGoals: "",
      //   additionalInstructions: "",
      // },
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const removablesInitialOrderDetails = {
  expectedDelivery: "",
  serviceObjId: "",
  isPriority: false,
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    missingTeeth: {
      hasMissingTeeth: null,
      teeth: [],
    },
    services: [
      // {
      //   "serviceObjId": "",
      //   "impressionTrayFor": "",
      //   "teethNeedToExtract": [],
      //   "implantSite": [],
      //   "needHandle": null,
      //   "holesInImpressionTray": null,
      //   "impressionMaterialHoles": 0,
      //   "preferredSoftware": "",
      //   "intraoralScansFor": "",
      //   "digitalInversionFor": "",
      //   "biteRegistrationDesired": null,
      //   "dentureBaseProcessing": "",
      //   "teethProcessing": "",
      //   "monoblocPrintDesired": null,
      //   "dentureSetupTeeth": [],
      //   "partialDentureSetupTeeth": [],
      //   "noClaspDesignTeeth": [],
      //   "splintExtendToTeeth": [],
      //   "recontouringGuidesTeeth": [],
      //   "deprogrammerTeeth": [],
      //   "dentureThickness": 0,
      //   "deprogrammerThickness": 0,
      //   "finalLevelDistance": 0,
      //   "copyDentureDesired": null,
      //   "majorConnector": [],
      //   "splintProcessing": "",
      //   "recontouringGuideProcessing": "",
      //   "softTissueReductionGuideFor": "",
      //   "hardTissueReductionGuideFor": "",
      //   "preferredDeprogrammerDesign": "",
      //   "archDeprorammerOn": "",
      //   "occlusionSetup": "",
      //   "toothMouldShape": "",
      //   "archNightguardOn": "",
      //   "archSplintOn": "",
      //   "preferredSplintDesign": "",
      //   "teethSetupStyle": "",
      //   "teethSize": "",
      //   "crossbiteCreated": "",
      //   "dentureForPhotoScan": null
      // }
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const smileInitialOrderDetails = {
  expectedDelivery: "",
  serviceObjId: "",
  isPriority: false,
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    missingTeeth: {
      hasMissingTeeth: null,
      teeth: [],
    },
    services: [
      // {
      //   "serviceObjId": "",
      //   "waxupSimulationTeeth": [],
      //   "deprogrammerTeeth": [],
      //   "needDesignPreview": null,
      //   "designFileNeeded": null,
      //   "preferredSoftware": "",
      //   "needPrintableModelFile": null,
      //   "needTwoPrintableModelFile": null,
      //   "needPrintableEggshellFiles": null,
      //   "additionalInstructions": "Test",
      //   "archNightguardOn": "",
      //   "nightguardThickness": 0,
      //   "deprogrammerThickness": 0,
      //   "softTissueReductionGuideFor": "",
      //   "hardTissueReductionGuideFor": "",
      //   "recontouringGuidesTeeth": [],
      //   "finalLevelDistance": 0,
      //   "recontouringGuideProcessing": "",
      //   "deprogrammerProcessing": "",
      //   "preferredDeprogrammerDesign": "",
      //   "archDeprorammerOn": ""
      // }
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const modelInitialOrderDetails = {
  expectedDelivery: "",
  serviceObjId: "",
  isPriority: false,
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    missingTeeth: {
      hasMissingTeeth: null,
      teeth: [],
    },
    services: [
      // {
      //   serviceObjId: "",
      //   baseThickness: 0,
      //   cutForDiesTeeth: [],
      //   needArticulationAttachments: null,
      //   articulationAttachmentsNeeded: "",
      //   modelFile: "",
      //   needEngravedText: null,
      //   needDrainHoles: null,
      //   drainHolesNeeded: "",
      //   preferredSoftware: "",
      //   additionalInstructions: "",
      // },
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const surgicalInitialOrderDetails = {
  expectedDelivery: "",
  serviceObjId: "",
  isPriority: false,
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    missingTeeth: {
      hasMissingTeeth: null,
      teeth: [],
    },
    services: [
      // {
      //   serviceObjId: "",
      //   implantSiteTeeth: [],
      //   teethToExtract: {
      //     teethToExtract: null,
      //     teeth: [],
      //   },
      //   anyTeethMobile: {
      //     anyTeethMobile: null,
      //     teeth: [],
      //   },
      //   guideType: "",
      //   surgeryDate: "",
      //   preferredImplantCompany: "",
      //   implantLine: "",
      //   preferredPinCompany: "",
      //   pinLine: "",
      //   implantSurgicalKit: "",
      //   densahBursUsed: null,
      //   densahBurGuideDesired: null,
      //   densahBursTeeth: [],
      //   flapBeRaised: null,
      //   additionalGuideOptions: [
      //     { option: "", position: "" },
      //     { option: "", position: "" },
      //   ],
      //   guidanceLevel: "",
      //   servicesRequired: [],
      //   boneReductionInPlan: null,
      //   conversionDentureAvailable: null,
      //   digitalConversionDenture: null,
      //   abutmentPreferences: "",
      //   endodonticAccessTeeth: [],
      //   graftingSiteTeeth: [],
      //   guidedAccessDrillBrand: "",
      //   drillShankDiameter: "",
      //   additionalInstructions: "",
      // },
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};
