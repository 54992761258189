export const TeethSizes = {
  FDI18: 33,
  UTN1: 33,
  FDI17: 41,
  UTN2: 41,
  FDI16: 54,
  UTN3: 54,
  FDI15: 30,
  UTN4: 30,
  FDI14: 31,
  UTN5: 31,
  FDI13: 31,
  UTN6: 31,
  FDI12: 27,
  UTN7: 27,
  FDI11: 31,
  UTN8: 31,
  FDI21: 31,
  UTN9: 31,
  FDI22: 28,
  UTN10: 28,
  FDI23: 31,
  UTN11: 31,
  FDI24: 31,
  UTN12: 31,
  FDI25: 30,
  UTN13: 30,
  FDI26: 38,
  UTN14: 38,
  FDI27: 41,
  UTN15: 41,
  FDI28: 33,
  UTN16: 33,
  FDI48: 33,
  UTN32: 33,
  FDI47: 38,
  UTN31: 38,
  FDI46: 45,
  UTN30: 45,
  FDI45: 31,
  UTN29: 31,
  FDI44: 29,
  UTN28: 29,
  FDI43: 27,
  UTN27: 27,
  FDI42: 25,
  UTN26: 25,
  FDI41: 24,
  UTN25: 24,
  FDI31: 23,
  UTN24: 23,
  FDI32: 24,
  UTN23: 24,
  FDI33: 28,
  UTN22: 28,
  FDI34: 29,
  UTN21: 29,
  FDI35: 31,
  UTN20: 31,
  FDI36: 46,
  UTN19: 46,
  FDI37: 38,
  UTN18: 38,
  FDI38: 34,
  UTN17: 34,
};
