import bell from "../../Assets/images/bellIcon.svg";
import React, { useState } from "react";
import { requestForToken, onMessageListener } from "../../services/firebase";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../../reducers/PersonalDetailsReducer";

const Notification = () => {
  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state.personalDetails);
  const [notificationModalIsOpen, setNotificationModalIsOpen] = useState(false);

  console.log("notification", notification);

  requestForToken();

  onMessageListener()
    .then((payload) => {
      dispatch(addNotification({ title: payload?.notification?.title, body: payload?.notification?.body }));
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <>
      <div className="relative h-fit" onClick={() => setNotificationModalIsOpen(true)}>
        <img src={bell} alt="" className="cursor-pointer" />
        <span className="bg-[#FF7E00] h-4 px-1 flex justify-center items-center absolute right-0 bottom-0 rounded-full text-white text-sm translate-x-[50%] translate-y-[50%]">{notification?.length}</span>
      </div>
      <Modal
        isOpen={notificationModalIsOpen}
        onRequestClose={() => {
          setNotificationModalIsOpen(false);
        }}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full text-right" onClick={() => setNotificationModalIsOpen(false)}>
              <AiOutlineClose className="ml-auto text-white" />
            </button>
            <p className="text-[26px] font-medium text-white leading-8 mb-6">Notifications</p>
            <div className="MODAL-TAB-SECTION flex-1 flex flex-col">
              {notification?.length > 0 ? (
                <ul>
                  {notification?.map((item, index) => {
                    return (
                      <li key={"notification-item"+index} className="w-full bg-[#252729] rounded-md text-white px-4 py-1.5 text-base mb-2">
                        <p className="font-medium">{item?.title}</p>
                        <p className="text-[#c2c2c2]">{item?.body}</p>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className="text-center text-white my-auto">No notifications available</p>
              )}
              <div className="flex items-center justify-end gap-4 pt-4 mt-auto">
                <button onClick={() => setNotificationModalIsOpen(false)} className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl">
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default Notification;
