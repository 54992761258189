import React, { useEffect, useState } from "react";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import { TeethV2 } from "../Teeth-Comps/TeethV2";
import RangeInputField from "../InputField-Comps/RangeInputField";
import classes from "../Teeth-Comps/Teeth.module.scss";
import { findExtraElement, updateTeethMapping } from "../../Utils/utility";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";
import { useGetSoftwareCategoryByNameMutation } from "../../services/softwareCategory";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useDeleteOrderMissingTeethMutation,
  useSaveOrderMissingTeethMutation,
} from "../../services/orders";

function ModelForPrintingCaseDetailsSection({
  setActiveTab,
  orderCaseData,
  orderByData,
  setOrderUpdated,
  serviceObjId,
  submitOrderData,
  selectedServiceCategory,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [saveOrderMissingTeeth] = useSaveOrderMissingTeethMutation();
  const [deleteOrderMissingTeeth] = useDeleteOrderMissingTeethMutation();

  const [midline, setMidline] = useState(orderCaseData?.midline || []);
  const [molarOcclusalGoals, setMolarOcclusalGoals] = useState(
    orderCaseData?.molarOcclusalGoals || ""
  );
  const [selectedValue, setSelectedValue] = useState(orderCaseData?.archesToTreat || []);

  const [optionsValue, setOptionsValue] = useState(orderCaseData?.options || "");
  const [clinicalValues, setClinicalValues] = useState(orderCaseData?.clinicalConditions || []);
  const [printableAligners, setPrintableAligners] = useState(
    orderCaseData?.printableAligners || ""
  );
  const [needArticulationAttachments, setNeedArticulationAttachments] = useState(
    orderCaseData?.needArticulationAttachments === null
      ? ""
      : orderCaseData?.needArticulationAttachments
      ? "Yes"
      : "No"
  );
  const [articulationAttachmentsNeeded, setArticulationAttachmentsNeeded] = useState(
    orderCaseData?.articulationAttachmentsNeeded || ""
  );
  const [needArticulationAttachments1, setNeedArticulationAttachments1] = useState(
    orderCaseData?.needArticulationAttachments1 === null
      ? ""
      : orderCaseData?.needArticulationAttachments1
      ? "Yes"
      : "No"
  );
  const [articulationAttachmentsNeeded1, setArticulationAttachmentsNeeded1] = useState(
    orderCaseData?.articulationAttachmentsNeeded1 || ""
  );

  const [hasMissingTeeth, setHasMissingTeeth] = useState(
    orderCaseData?.missingTeeth?.hasMissingTeeth === null
      ? ""
      : orderCaseData?.missingTeeth?.hasMissingTeeth
      ? "Yes"
      : "No"
  );

  const [baseThickness, setBaseThickness] = useState(orderCaseData?.baseThickness || 0);
  const [missingTeethSelectedTeeth, setMissingTeethSelectedTeeth] = useState(
    orderCaseData?.missingTeeth?.teeth || []
  );
  const [teethNotToBeMoved, setTeethNotToBeMoved] = useState(
    orderCaseData?.teethNeedToMove?.needToMove === null
      ? ""
      : orderCaseData?.teethNeedToMove?.needToMove
      ? "Yes"
      : "No"
  );
  const [teethNotToBeMovedSelectedTeeth, setTeethNotToBeMovedSelectedTeeth] = useState(
    orderCaseData?.teethNeedToMove?.teeth || []
  );
  const [attachmentsAllowed, setAttachmentsAllowed] = useState(
    orderCaseData?.attachmentsAllowed === null
      ? ""
      : orderCaseData?.attachmentsAllowed
      ? "Yes"
      : "No"
  );
  const [needEngravedText, setNeedEngravedText] = useState(
    orderCaseData?.needEngravedText === null ? "" : orderCaseData?.needEngravedText ? "Yes" : "No"
  );
  const [retainerRequired, setRetainerRequired] = useState(
    orderCaseData?.retainerRequired === null ? "" : orderCaseData?.retainerRequired ? "Yes" : "No"
  );

  const [preferredSoftwares, setPreferredSoftwares] = useState([]);

  const [iPRValue, setIPRValue] = useState(orderCaseData?.ipr || "");
  const [overjetCorrection, setOverjetCorrection] = useState(
    orderCaseData?.overjetCorrection?.option || ""
  );
  const [overjetCorrectionImprove, setOverjetCorrectionImprove] = useState(
    orderCaseData?.overjetCorrection?.improveLength || 0
  );
  const [overbiteCorrection, setOverbiteCorrection] = useState(
    orderCaseData?.overbiteCorrection?.option || ""
  );
  const [overbiteCorrectionImprove, setOverbiteCorrectionImprove] = useState(
    orderCaseData?.overbiteCorrection?.improveLength || 0
  );

  const [crossBiteValue, setCrossBiteValue] = useState(orderCaseData?.crossbiteCorrection || "");
  const [specialInstructions, setSpecialInstructions] = useState(
    orderCaseData?.additionalInstructions || ""
  );

  const [selectedService, setSelectedService] = useState("");
  const [restorativeTypeTeethSelected, setRestorativeTypeTeethSelected] = useState(
    orderCaseData?.services?.reduce(
      (acc, curr) => ({ ...acc, [curr?.serviceObjId]: curr?.teeth }),
      {}
    ) ?? {}
  );

  const [preferredDesignSoftware, setPreferredDesignSoftware] = useState(
    orderCaseData?.preferredSoftware || ""
  );

  const handlePreferredDesignSoftwareCheckboxChange = (event) => {
    setPreferredDesignSoftware(event.target.value);
  };

  console.log("restorativeTypeTeethSelected", restorativeTypeTeethSelected);

  const [notation, setNotation] = useState("FDI");

  const [getSoftwareCategoryByName] = useGetSoftwareCategoryByNameMutation();
  const readSoftwareCategoryInfo = async (accessToken) => {
    const response = await getSoftwareCategoryByName({
      accessToken,
      softwareCategoryName: "Orthodontic Treatment Planning",
    }).unwrap();
    if (response?.data) {
      const softwaresList = response?.data;
      if (softwaresList?.length > 0) {
        // setPreferredSoftwares(softwaresList[0]?.softwares);
        setPreferredSoftwares([
          {
            softwareName: "Exocad Model Creator",
          },
          {
            softwareName: "3Shape Model Maker",
          },
          {
            softwareName: "inLab Model ",
          },
          {
            softwareName: "Medit Model Builder",
          },
        ]);
      } else setPreferredSoftwares([]);
    }
  };

  const readSoftwareCategoriesInfoDeatails = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await readSoftwareCategoryInfo(accessToken);
  };

  useEffect(() => {
    readSoftwareCategoriesInfoDeatails();
  }, []);

  const getRestrictedTeeth = (serviceObjId) => {
    const restrictedTeethData = [];
    Object.keys(restorativeTypeTeethSelected)
      .filter((d) => d !== serviceObjId)
      .forEach((d) => {
        restrictedTeethData.push(...restorativeTypeTeethSelected[d]);
      });
    return restrictedTeethData;
  };

  console.log("Categories1", selectedServiceCategory?.catservices);

  const prepareServicesElements = () => {
    if (selectedServiceCategory?.catservices?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      selectedServiceCategory?.catservices?.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            selectedIcon={
              restorativeTypeTeethSelected.hasOwnProperty(eachService?.serviceObjId) &&
              restorativeTypeTeethSelected[eachService?.serviceObjId]?.length > 0
            }
            handleSelectedIcon={() => {
              console.log("restorativeTypeTeethSelected", restorativeTypeTeethSelected);
              setRestorativeTypeTeethSelected((prev) => {
                const copy = JSON.parse(JSON.stringify(prev));
                delete copy[eachService?.serviceObjId];
                console.log("eachService?.serviceObjId", eachService?.serviceObjId);
                console.log("copy", copy);
                console.log("prev", prev);
                return copy;
              });
            }}
            id={serviceObjId}
            active={selectedService?.serviceObjId === eachService?.serviceObjId}
            image={eachService?.serviceIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serviceName}
            serviceDescription={eachService?.serviceDescription}
            clickHandler={() => setSelectedService(eachService)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  const handleNotationChange = (event) => {
    setNotation(event.target.value);
    setMissingTeethSelectedTeeth(updateTeethMapping(missingTeethSelectedTeeth, event.target.value));
    setTeethNotToBeMovedSelectedTeeth(
      updateTeethMapping(teethNotToBeMovedSelectedTeeth, event.target.value)
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "specialInstructions") setSpecialInstructions(value);
    if (name === "overjetCorrectionImprove") setOverjetCorrectionImprove(value);
    if (name === "overbiteCorrectionImprove") setOverbiteCorrectionImprove(value);
    if (name === "baseThickness") setBaseThickness(value);
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = ["specialInstructions", "baseThickness"];

    return requiredFields.includes(fieldName);
  };

  const arraysHaveSameValues = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };

  const handleAllCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setMidline((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
      setMidline((prevSelectedValues) =>
        prevSelectedValues.filter((selectedValue) => selectedValue !== value)
      );
    }
  };

  const handleCheckboxChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleHasMissingTeethChange = (event) => {
    setHasMissingTeeth(event.target.value);
  };

  const handleteethNotToBeMovedChange = (event) => {
    setTeethNotToBeMoved(event.target.value);
  };

  const handleAttachmentsAllowedChange = (event) => {
    setAttachmentsAllowed(event.target.value);
  };
  const handleNeedEngravedTextCheckboxChange = (event) => {
    setNeedEngravedText(event.target.value);
  };

  const handleRetainerRequiredChange = (event) => {
    setRetainerRequired(event.target.value);
  };

  const handleIPRValueCheckboxChange = (event) => {
    setIPRValue(event.target.value);
  };

  const handleCrossBiteValueCheckboxChange = (event) => {
    setCrossBiteValue(event.target.value);
  };

  const handleOverbiteCorrectionCheckboxChange = (event) => {
    setOverbiteCorrection(event.target.value);
  };

  const handleOverjetCorrectionCheckboxChange = (event) => {
    setOverjetCorrection(event.target.value);
  };

  const validateNumber = (value) => {
    return Number(value) ? true : false;
  };

  const submitTreatmentGoals = async () => {
    submitOrderData({
      options: optionsValue,
      clinicalConditions: clinicalValues,
      archesToTreat: selectedValue,
      molarOcclusalGoals: molarOcclusalGoals,
      midline: midline,
      missingTeeth: {
        hasMissingTeeth: hasMissingTeeth ? hasMissingTeeth === "Yes" : null,
        teeth: missingTeethSelectedTeeth,
      },
      teethNeedToMove: {
        needToMove: teethNotToBeMoved ? teethNotToBeMoved === "Yes" : null,
        teeth: teethNotToBeMovedSelectedTeeth,
      },
      ipr: iPRValue,
      overjetCorrection: {
        option: overjetCorrection,
        improveLength: overjetCorrectionImprove,
      },
      overbiteCorrection: {
        option: overbiteCorrection,
        improveLength: overbiteCorrectionImprove,
      },
      crossbiteCorrection: crossBiteValue,
      attachmentsAllowed: attachmentsAllowed ? attachmentsAllowed === "Yes" : null,
      retainerRequired: retainerRequired ? retainerRequired === "Yes" : null,
      additionalInstructions: specialInstructions,
    });
    setActiveTab("Upload Files");
  };

  const handleOptionsCheckboxChange = (event) => {
    setOptionsValue(event.target.value);
  };

  const handlePrintableAlignersChange = (event) => {
    setPrintableAligners(event.target.value);
  };

  const handleNeedArticulationAttachmentsChange = (event) => {
    setNeedArticulationAttachments(event.target.value);
  };
  const handleArticulationAttachmentsNeededChange = (event) => {
    setArticulationAttachmentsNeeded(event.target.value);
  };

  const handleNeedArticulationAttachments1Change = (event) => {
    setNeedArticulationAttachments1(event.target.value);
  };
  const handleArticulationAttachmentsNeeded1Change = (event) => {
    setArticulationAttachmentsNeeded1(event.target.value);
  };

  const handleClinicalValuesCheckboxChange = (event) => {
    const { value, checked } = event.target;
    console.log(value, checked);
    if (checked) {
      setClinicalValues((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
      setClinicalValues((prevSelectedValues) =>
        prevSelectedValues.filter((selectedValue) => selectedValue !== value)
      );
    }
  };

  const handleMolarOcclusalGoalsCheckboxChange = (event) => {
    setMolarOcclusalGoals(event.target.value);
  };

  const handleMissingTeethSelection = async (value) => {
    console.log("value", value);
    const actualMissingTeeth = structuredClone(missingTeethSelectedTeeth);
    let result = value(actualMissingTeeth || []);
    setMissingTeethSelectedTeeth(result);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let teethNo;
    if (result?.length > actualMissingTeeth?.length) {
      teethNo = findExtraElement(actualMissingTeeth, result);
      saveOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    } else if (result?.length < actualMissingTeeth?.length) {
      teethNo = findExtraElement(result, actualMissingTeeth);
      deleteOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="w-10/12">
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            What tooth numbering system do you prefer?
            <span className="text-red-500">*</span>
          </p>
          <div className="flex">
            <div className={classes.radioButtons}>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="UTN"
                  checked={notation === "UTN"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                UTN
              </label>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="FDI"
                  checked={notation === "FDI"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                FDI
              </label>
            </div>
          </div>
          <p className="text-[16px] text-[#777] mb-4">
            The Universal tooth numbering (UTN) system (1-32) and Fédération Dentaire Internationale
            (FDI) system (11-48) are both internationally recognised numbering systems used in
            different parts of the world. Please select a preference that will then apply to all the
            tooth charts in this form.
          </p>
        </div>
        <div className="text-white mb-8">
          <p className="font-medium text-[17px] text-white mb-2">
            Please advise us if the patient has any missing teeth (This helps us to assess whether
            your intraoral scans are complete enough for a model print){" "}
            <span className="text-red-500">*</span>
          </p>
          <div className="flex">
            <div className="flex items-center mr-5">
              <CircularCheckbox
                value="Yes"
                checked={hasMissingTeeth === "Yes"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px] my-3">The patient has missing teeth</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={hasMissingTeeth === "No"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">The patient has no missing teeth</p>
            </div>
          </div>
          {hasMissingTeeth === "Yes" && (
            <div className="mx-5 mt-4">
              <p className="text-white text-[16px] mb-2">
                Please select missing teeth
                {isFieldRequired("missingTeethSelectedTeeth") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <TeethV2
                allTeethData={[]}
                selectedTeeth={missingTeethSelectedTeeth}
                setSelectedTeeth={handleMissingTeethSelection}
                defaultNotation={notation}
                key={`missingTeethSelectedTeeth${notation}`}
                notationVisible={false}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <p className="font-medium text-[24px] text-white mb-4">
            Models for Printing Service Type
          </p>
          <p className="text-[16px] text-[#777] mb-8">
            Please choose a Models for Printing service type below. For Die models alone, you will
            be able to choose the teeth that apply for that service type to identify specific teeth
            that need to be 'cut' for dies.{" "}
            <span className="!text-white">
              Multiple model types (for example: for different arches or different model types) can
              also be selected.
            </span>{" "}
            If you prefer not to use our charting system for Die models, you can also provide
            written instructions in the Additional Instructions column below.
          </p>
          {selectedServiceCategory?._id &&
            Object.keys(restorativeTypeTeethSelected).length !== "" && (
              <>
                <div className="CAROUSEL mb-8">
                  <div className="md:block hidden">
                    <CustomCarousel
                      rows={1}
                      cols={6}
                      gapY={20}
                      gapX={20}
                      completeSliders={prepareServicesElements()}
                      numberOfCardsShownInSingleSlide={6}
                    />
                  </div>
                  <div className="md:hidden block">
                    <CustomCarousel
                      rows={1}
                      cols={2}
                      gapY={20}
                      gapX={20}
                      completeSliders={prepareServicesElements()}
                      numberOfCardsShownInSingleSlide={2}
                    />
                  </div>
                </div>
              </>
            )}
        </div>
        {selectedService?.serviceObjId && (
          <>
            {["Die models for cosmetic restorations"].includes(selectedService?.serviceName) && (
              <div>
                <div className="mb-8">
                  <p className="text-white text-[17px] mb-2">
                    Please select all teeth that need to be 'cut' for dies
                    {isFieldRequired("teethExtractionSelectedTeeth") ? (
                      <span className="text-red-500">*</span>
                    ) : (
                      ""
                    )}
                  </p>
                  <div className="">
                    <TeethV2
                      allTeethData={[]}
                      selectedTeeth={teethNotToBeMovedSelectedTeeth}
                      setSelectedTeeth={setTeethNotToBeMovedSelectedTeeth}
                      defaultNotation={notation}
                      key={`teethNotToBeMovedSelectedTeeth${notation}`}
                      notationVisible={false}
                      missingTeeth={missingTeethSelectedTeeth}
                      restrictedTeeth={getRestrictedTeeth(selectedService?.serviceObjId)}
                      restrictedTeethMessage={
                        "This tooth is already selected in other restorative type"
                      }
                      disabledTeeths={
                        ["Clear Aligners anterior", "Clear Aligners anterior refinement"].includes(
                          selectedService?.serviceName
                        )
                          ? notation === "FDI"
                            ? [
                                14, 15, 16, 17, 18, 24, 25, 26, 27, 28, 44, 45, 46, 47, 48, 34, 35,
                                36, 37, 38,
                              ]
                            : [
                                1, 2, 3, 4, 5, 12, 13, 14, 15, 16, 28, 29, 30, 31, 32, 17, 18, 19,
                                20, 21,
                              ]
                          : []
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className="mb-8">
                <div className="w-full">
                  <RangeInputField
                    labelColor={"#777"}
                    labelText={"Would you prefer a specific base thickness? (mm)"}
                    subtitle={
                      "If you are unsure what thickness to request, please leave slider at 0 mm. A suitable setting for the height of your digital file will then apply."
                    }
                    errorMessage={"Please enter a valid value"}
                    placeholder={""}
                    htmlFor={"baseThickness"}
                    name={"baseThickness"}
                    value={baseThickness}
                    handleInputChange={handleInputChange}
                    validationFunctionName={validateNumber}
                    isSubmitted={isFieldRequired}
                    isFieldRequired={isFieldRequired("baseThickness")}
                    unit="mm"
                    min={0}
                    step={1}
                    max={6}
                  />
                </div>
              </div>
              <div className="mb-8">
                <p className="text-white my-4">
                  Would you like articulation attachments ? (applicable if you are printing upper
                  and lower models only, otherwise please select No){" "}
                  <span className="text-red-500">*</span>
                </p>
                <div className="text-white flex">
                  <div className="flex items-center mr-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={needArticulationAttachments === "Yes"}
                      onChange={handleNeedArticulationAttachmentsChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={needArticulationAttachments === "No"}
                      onChange={handleNeedArticulationAttachmentsChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
              </div>
              {needArticulationAttachments === "Yes" && (
                <div className="mb-8">
                  <p className="text-white my-4">
                    How many articulation attachments would you like?{" "}
                    <span className="text-red-500">*</span>
                  </p>

                  <div className="text-white flex gap-8">
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="1 Hinge type connector"
                        checked={articulationAttachmentsNeeded === "1 Hinge type connector"}
                        onChange={handleArticulationAttachmentsNeededChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">1 Hinge type connector</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="2 Arms on the sides"
                        checked={articulationAttachmentsNeeded === "2 Arms on the sides"}
                        onChange={handleArticulationAttachmentsNeededChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">2 Arms on the sides</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="3 Arms with one in the midline"
                        checked={articulationAttachmentsNeeded === "3 Arms with one in the midline"}
                        onChange={handleArticulationAttachmentsNeededChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">3 Arms with one in the midline</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="4 Arms on the sides"
                        checked={articulationAttachmentsNeeded === "4 Arms on the sides"}
                        onChange={handleArticulationAttachmentsNeededChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">4 Arms on the sides</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mb-8">
              <p className="text-white mb-4">
                What type of model file would you like? <span className="text-red-500">*</span>
              </p>
              <div className="flex md:gap-32 gap-4 items-center">
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Hollow"
                    checked={selectedValue === "Hollow"}
                    onChange={handleCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Hollow</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Solid"
                    checked={selectedValue === "Solid"}
                    onChange={handleCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Solid</p>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <p className="text-white mb-4">
                Would you like engraved text (we do not provide embossed) on your models? If Yes,
                please name your files that you upload with the text you want on your models.{" "}
                <span className="text-red-500">*</span>
              </p>
              <div className="flex md:gap-32 gap-4 items-center">
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Yes"
                    checked={needEngravedText === "Yes"}
                    onChange={handleNeedEngravedTextCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={needEngravedText === "No"}
                    onChange={handleNeedEngravedTextCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <p className="text-white my-4">
                Would you like drain holes on your models? <span className="text-red-500">*</span>
              </p>
              <div className="text-white flex">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={needArticulationAttachments1 === "Yes"}
                    onChange={handleNeedArticulationAttachments1Change}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={needArticulationAttachments1 === "No"}
                    onChange={handleNeedArticulationAttachments1Change}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            </div>
            {needArticulationAttachments1 === "Yes" && (
              <div className="mb-8">
                <p className="text-white my-4">
                  How many drain holes would you like? <span className="text-red-500">*</span>
                </p>

                <div className="text-white grid grid-cols-8">
                  <div className="flex items-center mr-3">
                    <CircularCheckbox
                      value="4"
                      checked={articulationAttachmentsNeeded1 === "4"}
                      onChange={handleArticulationAttachmentsNeeded1Change}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">4</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="6"
                      checked={articulationAttachmentsNeeded1 === "6"}
                      onChange={handleArticulationAttachmentsNeeded1Change}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">6</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="8"
                      checked={articulationAttachmentsNeeded1 === "8"}
                      onChange={handleArticulationAttachmentsNeeded1Change}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">8</p>
                  </div>
                  <div className="flex items-center col-span-2">
                    <CircularCheckbox
                      value="No Preference"
                      checked={articulationAttachmentsNeeded1 === "No Preference"}
                      onChange={handleArticulationAttachmentsNeeded1Change}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No Preference</p>
                  </div>
                </div>
              </div>
            )}
            {preferredSoftwares?.length > 0 && (
              <div className="text-white flex flex-col mb-8">
                <p className="text-white mb-4">
                  Preferred model design software?{" "}
                  {isFieldRequired("preferredDesignSoftware") ? (
                    <span className="text-red-500">*</span>
                  ) : (
                    ""
                  )}
                </p>
                <div className="grid grid-cols-4 gap-y-3">
                  {preferredSoftwares?.map((item) => {
                    return (
                      <div className="flex items-center mr-3" key={item?.softwareObjId}>
                        <CircularCheckbox
                          value={item?.softwareName}
                          checked={preferredDesignSoftware === item?.softwareName}
                          onChange={handlePreferredDesignSoftwareCheckboxChange}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{item?.softwareName}</p>
                      </div>
                    );
                  })}
                  <div className="flex items-center mr-3 col-span-1">
                    <CircularCheckbox
                      value="No Preferance"
                      checked={preferredDesignSoftware === "No Preferance"}
                      onChange={handlePreferredDesignSoftwareCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No Preference</p>
                  </div>
                </div>
              </div>
            )}
            <div className="mb-6">
              <TextAreaInputField
                labelColor={"#777"}
                labelText={"Additional Instructions"}
                errorMessage={"This field is required"}
                placeholder={"Enter Additional Instructions"}
                isFieldRequired={isFieldRequired("specialInstructions")}
                htmlFor={"specialInstructions"}
                name={"specialInstructions"}
                value={specialInstructions}
                handleInputChange={handleInputChange}
                // validationFunctionName={validatePatientConcern}
                // isSubmitted={isPatientInitialFormSubmitted}
                rows={4}
                cols={5}
              />
              {specialInstructions?.trim()?.length > 0 ? (
                <></>
              ) : (
                <p className="text-sm text-red-500">This field is required</p>
              )}
            </div>
          </>
        )}
      </div>
      <div className="BOTTOM-SECTION mt-8">
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab("Patient Details");
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              submitTreatmentGoals();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModelForPrintingCaseDetailsSection;
