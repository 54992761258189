import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import assignedIcon from "../../Assets/icons/orderAssignedIcon.svg";
import assignSuccess from "../../Assets/icons/assignSuccess.svg";
import { useSaveServiceProviderOrdersMutation, useUpdateServiceProviderOrdersMutation } from "../../services/orders";
import { useAuth0 } from "@auth0/auth0-react";
import { GetToast } from "../Toast-Comps/GetToast";

function ServiceProviderCard({
  isOrderAssigned,
  setIsOrderAssigned,
  setIsOrderConfirm,
  isOrderConfirm,
  crowdHireId,
  serviceDescription,
  specialisation,
  actualPrice,
  currency,
  offerPrice,
  portalPrice,
  user_id,
  serviceProviderOrdersData,
  orderObjId,
  setIsUpdated,
  userServiceObjId,
  firstName,
  lastName
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [saveServiceProviderOrders] = useSaveServiceProviderOrdersMutation();
  const [updateServiceProviderOrders] = useUpdateServiceProviderOrdersMutation()

  console.log(
    offerPrice,
    currency,
    "detadfnjefnejf"
  )

  const assignOrderHandler = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (serviceProviderOrdersData.length === 0) {
      const resp = await saveServiceProviderOrders({
        accessToken,
        spuserAuthId: user_id,
        orderObjId,
        orderValue: offerPrice,
        portalValue: portalPrice,
        orderCurrency: currency,
        userServiceObjId
      });
      GetToast(resp.data["message"]);
      setIsOrderAssigned(true);
      setIsUpdated(true);

    } else {
      const resp = await updateServiceProviderOrders({
        accessToken,
        orderObjId,
        portalValue: portalPrice,
        orderValue: offerPrice,
        orderCurrency: currency
      });
      GetToast(resp.data["message"]);
      setIsOrderAssigned(true);
      setIsUpdated(true);
    }
  };
  return (
    <>
      <div className="bg-[#1a1321] p-4 rounded-lg">
        <div className="flex flex-col justify-between items-start mb-4">
          <div className="flex-col items-start justify-start mb-2">
            <p className="text-[#777] text-[12px]">Service Provider Name</p>
            <p className="text-white text-left font-medium">{firstName}{" "}{lastName}</p>
          </div>
          <div className="flex-col items-start justify-start">
            <p className="text-[#777] text-[12px]">Service Provider ID</p>
            <p className="text-white text-left font-medium">{crowdHireId}</p>
          </div>
          {/* <div>stars</div> */}
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <p className="text-[#777] text-[12px] text-left mb-2">
              Service Description
            </p>
            <p className="text-white text-[17px] text-left">
              {serviceDescription}
            </p>
          </div>
          {
            specialisation ?
              <div>
                <p className="text-[#777] text-[12px] text-left mb-2">
                  Service specialisation
                </p>
                <div className="text-white text-[17px] text-left"
                  dangerouslySetInnerHTML={{ __html: specialisation }} />
              </div>
              :
              <>
              </>
          }
          <div className="flex flex-wrap w-full gap-2">
            <div className="bg-[#E2D7ED] text-[12px] py-1 px-3 rounded-full">
              Total Orders Delivered: <span className="font-bold">0</span>
            </div>
            <div className="bg-[#E2D7ED] text-[12px] py-1 px-3 rounded-full">
              Last Deliver: <span className="font-bold">0 Day ago</span>
            </div>
          </div>
          <div className="flex flex-row justify-between flex-wrap gap-4">
            <div className="flex flex-col gap-2 text-left">
              <p className="text-[#777] text-[12px]">Service price</p>
              <p className="text-[#777] text-[17px] relative w-fit">
                $ {actualPrice} / Order
                <div className="bg-[#777] h-[1px] w-full top-1/2 -translate-y-1/2 left-0 absolute"></div>
              </p>
              <p className=" text-white text-[38px] font-bold">
                ${offerPrice} <span className="text-lg font-normal">/ Order</span>{" "}
              </p>
              <p className="text-[#fefefe] font-semibold text-[12px] bg-primary px-3 py-1 w-fit rounded-full">
                Savings {(((actualPrice - offerPrice) / actualPrice) * 100).toFixed(2)}%
              </p>
            </div>
            <div className="flex flex-col gap-2 text-left">
              <p className="text-[#777] text-[12px]">Dentist Price</p>
              {/* <p className="text-[#777] text-[17px] relative w-fit">
                --
                <div className="bg-[#777] h-[1px] w-full top-1/2 -translate-y-1/2 left-0 absolute hidden"></div>
              </p> */}
              <p className=" text-white text-[38px] font-bold">
                ${portalPrice} <span className="text-lg font-normal">/ Order</span>{" "}
              </p>
            </div>
          </div>
          <div className="w-full flex items-center justify-center">
            <button
              onClick={serviceProviderOrdersData?.length > 0 ? null : () => setModalIsOpen(true)}
              className="w-fit bg-transparent border hover:shadow-md border-[#FF7E00] rounded-lg hover:bg-[#FF7E00] text-[#FF7E00] hover:text-white py-2 px-4 font-medium"
            >
              {serviceProviderOrdersData?.length > 0
                ? "Waiting for Service Provider Confirmation"
                : "Assign & Confirm"}
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button
                  className="w-full text-right"
                  onClick={() => setModalIsOpen(false)}
                >
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-white leading-8 mb-0">
                  Service Provider Confirmation
                </p>
              </div>
              {isOrderAssigned ? (
                <div className="flex-1 flex flex-col items-center justify-center text-white">
                  <img src={assignSuccess} alt="" />
                  <p className="text-2xl font-medium">Successful!</p>
                  <p className="text-center my-4">
                    You have successfully Assigned this order to Service
                    provider ({crowdHireId}) for
                    <span className="text-xl text-primary">${offerPrice}</span>
                  </p>
                </div>
              ) : (
                <div className="flex-1 flex flex-col items-center justify-center text-white">
                  <img src={assignedIcon} alt="" />

                  <p className="text-center my-4">
                    This Order will be assigned to Service provider ({crowdHireId})
                    for
                    <span className="text-xl text-primary">${offerPrice}</span>
                  </p>
                </div>
              )}

              <div className="flex items-center justify-end gap-4">
                <button
                  onClick={() => setModalIsOpen(false)}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>
                <button
                  onClick={assignOrderHandler}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default ServiceProviderCard;
