import React from "react";

// import { useDispatch } from "react-redux";
// import { readData } from "../../actions/tableService";
import graph from "../../Assets/images/graph.svg";
import CardOne from "../../Components/Dashboard-Comps/CardOne";
import iconOne from "../../Assets/images/frame.svg";
import fileIcon from "../../Assets/images/fileCardIcon.svg";
import searchIcon from "../../Assets/images/searchInputIcon.svg";
import QuickOrderCard from "../../Components/Dashboard-Comps/QuickOrderCard";
import toothPic from "../../Assets/images/toothPic.svg";
import { IoIosArrowForward } from "react-icons/io";
// import CustomTabs from "../../Components/Dashboard-Comps/CustomTabs-Dep";
import bell from "../../Assets/images/bellIcon.svg";
import greenTick from "../../Assets/images/greenTick.svg";
import hut from "../../Assets/images/hut.svg";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import logo from "../../Assets/images/LOGO.svg";

import OrderHistoryCard from "../../Components/Dashboard-Comps/OrderHistoryCard";

// import CustomTabsNew from "../../Components/Dashboard-Comps/";
import CustomTabsDashboard from "../../Components/Dashboard-Comps/CustomTabsDashboard";
import { LoginButton } from "../Login/Login";
import { useAuth0 } from "@auth0/auth0-react";
import {
  iconArray,
  superAdminIconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
} from "../../constants/NavbarRoutesArray";

import CustomCarousel from "../../Components/UserManagement-Comps/CustomCarousel";
import { Teeth } from "../../Components/Teeth-Comps/Teeth";
import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";

function Dashboard({ userRoles, userInfo }) {
  const { user, isAuthenticated } = useAuth0();
  const [userRole, setUserRole] = React.useState(userRoles);
  const personalDetails = useSelector((state)=> state.personalDetails);

  // eslint-disable-next-line no-unused-vars
  const roles = user?.["https://localhost:3000/roles"] || [];

  // for quick order section
  const arrayOfCards = [
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
  ];

  // for order history section
  const arrayOfTilesOne = [
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
  ];
  const arrayOfTilesTwo = [
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
  ];

  // const dataState = useSelector((state) => state.data);
  // const dispatch = useDispatch();

  // effect to retrieve the accessToken from the server
  // useEffect(() => {
  //   dispatch(readData());
  //   if (isAuthenticated) {
  //     const storeToken = async () => {
  //       try {
  //         const accessToken = await getAccessTokenSilently();
  //         localStorage.setItem("access_token", accessToken);
  //       } catch (error) {
  //         console.log(
  //           "An error occurred while getting the access token:",
  //           error
  //         );
  //       }
  //     };
  //     storeToken();
  //   }
  // }, [isAuthenticated, getAccessTokenSilently, dispatch]);

  return (
    <>
      <div className="flex bg-black">
        <Navbar
          iconArray={userRole === "89uij7" ? superAdminIconArray : userRole === "676dza2" ? iconArray : spIconArray}
          navHoverCss={navHoverCss}
          navActiveCss={navActiveCss}
          middleSection={false}
          middleTabTitleArray={[""]}
          middleTabContentObject={{}}
          userRole={userRole}
          active={'Dashboard'}
        />
        <div className="MAIN-DASH bg-background p-4 w-full h-full">
          <div className="NAVBAR flex justify-between items-center mb-4 ">
            <p className="text-[15px] text-[#999999] flex justify-between items-center">
              <img
                src={logo}
                className="mx-auto w-[50px] h-[40px] mr-4 block md:hidden"
                alt=""
              />
              <span>
                <img src={hut} alt="" />
              </span>
              <span className="ml-3">Dashboard</span>
            </p>
            <div className="flex gap-4 items-center">
              {isAuthenticated ? (
                <div className="text-[15px] justify-center items-center hidden md:flex text-white">
                  Hello, {personalDetails.data[0]?.firstName ?? user.name}
                </div>
              ) : (
                <LoginButton />
              )}
              {/* <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                CH00001
                <img
                  className="relative top-[4px] right-0"
                  src={greenTick}
                  alt=""
                />
              </button> */}
              {/* <img src={bell} alt="" /> */}
              <Notification />
            </div>
          </div>
          <div className="SEARCH-BAR grid grid-cols-5 grid-rows-1 mb-8 gap-12">
            <div className="col-span-5 md:col-span-3 flex">
              <p className="font-medium text-[34px] leading-7 mr-8 text-textP">
                Dashboard
              </p>
            </div>
            <div className="md:block hidden col-span-2"></div>
          </div>
          <div className="grid grid-cols-1 grid-rows-3 md:grid-cols-5 md:grid-rows-2 gap-y-8 md:gap-12">
            <div className="CARD-GRID col-span-3 grid lg:grid-cols-3 md:grid-cols-2, gap-4">
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={iconOne}
                bigBox={true}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Orders Delivered"}
                count={0}
                circles={true}
              />
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"New Dentist"}
                count={0}
              />

              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Orders to Review"}
                count={0}
              />
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Payments Exceptions"}
                count={0}
              />
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Payment Dispute"}
                count={0}
              />
            </div>
            <div className="SIDE-INFO-BAR rounded-lg row-span-1 md:row-span-2 col-span-2 bg-secondary p-4">
              {/* <div className="flex justify-between items-center mb-4">
                <p className="font-semibold text-xl text-[#555]">Quick Order</p>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Find Service"
                    className="placeholder:text-[#99] placeholder:text-[16px] text-[16px] pl-8 w-36 bg-[#F3F3FC] bg-opacity-30 focus:outline-none"
                  />
                  <img
                    className="absolute top-1 left-1"
                    src={searchIcon}
                    alt=""
                  />
                </div>
              </div>
              <div className="CAROUSEL">
                <CustomCarousel
                  // rows and columns you want per slide with x and y gaps in pixels
                  rows={2}
                  cols={3}
                  gapX={20}
                  gapY={20}
                  completeSliders={arrayOfCards}
                  numberOfCardsShownInSingleSlide={5}
                  handleCardOnClick={(card) => {
                    console.log("clicked", card);
                  }}
                />
              </div> */}
              <div className="flex justify-between items-center my-4 ">
                <p className="font-semibold text-xl text-white">
                  Order
                </p>
                <p className="text-[16px] text-white leading-4 font-medium flex items-center cursor-pointer">
                  View full History{"  "} <IoIosArrowForward />
                </p>
              </div>
              <div className="TABS">
                <CustomTabsDashboard
                  tabsTitleArray={["Crown and Bridge", "Dental Implant"]}
                  tabsContentObject={{
                    titleOne: arrayOfTilesOne,
                    titleTwo: arrayOfTilesTwo,
                  }}
                />
              </div>
            </div>
            <div className="CHART col-span-3 row-span-1 bg-secondary rounded-md overflow-hidden">
              <img src={graph} className="h-full w-full" />
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{ marginLeft: 95, maxWidth: 650 }}>
        <Teeth />
      </div> */}
    </>
  );
}

// export default withAuthenticationRequired(Dashboard);
export default Dashboard;
