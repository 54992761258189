import React, { useEffect, useState } from "react";
import classes from "./Teeth.module.scss";
import {
  Teeth18,
  Teeth17,
  Teeth16,
  Teeth15,
  Teeth14,
  Teeth13,
  Teeth12,
  Teeth11,
  // right section import starts
  Teeth21,
  Teeth22,
  Teeth23,
  Teeth24,
  Teeth25,
  Teeth26,
  Teeth27,
  Teeth28,
  // bottom left section import starts
  Teeth48,
  Teeth47,
  Teeth46,
  Teeth45,
  Teeth44,
  Teeth43,
  Teeth42,
  Teeth41,
  // bottom right section import starts
  Teeth31,
  Teeth32,
  Teeth33,
  Teeth34,
  Teeth35,
  Teeth36,
  Teeth37,
  Teeth38,
} from "../../Assets/icons/teethNormal/index";

import {
  Teeth18Hover,
  Teeth17Hover,
  Teeth16Hover,
  Teeth15Hover,
  Teeth14Hover,
  Teeth13Hover,
  Teeth12Hover,
  Teeth11Hover,
  // right section import starts
  Teeth21Hover,
  Teeth22Hover,
  Teeth23Hover,
  Teeth24Hover,
  Teeth25Hover,
  Teeth26Hover,
  Teeth27Hover,
  Teeth28Hover,
  // bottom left section import starts
  Teeth48Hover,
  Teeth47Hover,
  Teeth46Hover,
  Teeth45Hover,
  Teeth44Hover,
  Teeth43Hover,
  Teeth42Hover,
  Teeth41Hover,
  // bottom right section import starts
  Teeth31Hover,
  Teeth32Hover,
  Teeth33Hover,
  Teeth34Hover,
  Teeth35Hover,
  Teeth36Hover,
  Teeth37Hover,
  Teeth38Hover,
  TeethFilter,
} from "../../Assets/icons/teethHover/index";

import {
  Teeth11Crown,
  Teeth12Crown,
  Teeth13Crown,
  Teeth14Crown,
  Teeth15Crown,
  Teeth16Crown,
  Teeth17Crown,
  Teeth18Crown,
  Teeth21Crown,
  Teeth22Crown,
  Teeth23Crown,
  Teeth24Crown,
  Teeth25Crown,
  Teeth26Crown,
  Teeth27Crown,
  Teeth28Crown,
  Teeth31Crown,
  Teeth32Crown,
  Teeth33Crown,
  Teeth34Crown,
  Teeth35Crown,
  Teeth36Crown,
  Teeth37Crown,
  Teeth38Crown,
  Teeth41Crown,
  Teeth42Crown,
  Teeth43Crown,
  Teeth44Crown,
  Teeth45Crown,
  Teeth46Crown,
  Teeth47Crown,
  Teeth48Crown,
} from "../../Assets/icons/teethCrowns/index";

import {
  Teeth11Disabled,
  Teeth12Disabled,
  Teeth13Disabled,
  Teeth14Disabled,
  Teeth15Disabled,
  Teeth16Disabled,
  Teeth17Disabled,
  Teeth18Disabled,
  Teeth21Disabled,
  Teeth22Disabled,
  Teeth23Disabled,
  Teeth24Disabled,
  Teeth25Disabled,
  Teeth26Disabled,
  Teeth27Disabled,
  Teeth28Disabled,
  Teeth31Disabled,
  Teeth32Disabled,
  Teeth33Disabled,
  Teeth34Disabled,
  Teeth35Disabled,
  Teeth36Disabled,
  Teeth37Disabled,
  Teeth38Disabled,
  Teeth41Disabled,
  Teeth42Disabled,
  Teeth43Disabled,
  Teeth44Disabled,
  Teeth45Disabled,
  Teeth46Disabled,
  Teeth47Disabled,
  Teeth48Disabled,
} from "../../Assets/icons/teethDisabled/index";

import guideDownIcon from "../../Assets/images/guideDownIcon.png";
import guideDownIconHover from "../../Assets/images/guideDownIconHover.png";
import guideIcon from "../../Assets/images/guideIcon.png";
import guideIconHover from "../../Assets/images/guideIconHover.png";
import { GetToast } from "../Toast-Comps/GetToast";
import { TeethSizes } from "../../constants/TeethSizes";

export const TeethV2 = ({
  allTeethData,
  selectedTeeth,
  hideSelected,
  setSelectedTeeth,
  defaultNotation,
  notationVisible = true,
  title,
  missingTeeth,
  restrictedTeeth = [],
  restrictedTeethMessage,
  otherToothRestrictedMessage,
  missingTeethSelectable = false,
  screwTeethSelectable = false,
  selectedScrewTeeth = [],
  showHoveredIconOnSelect = false,
  allowCrownSelect = false,
  disabledTeeths = [],
  disabledTeethMessage,
}) => {
  // const [hoveredTeeth, setHoveredTeeth] = useState(null);
  const [notation, setNotation] = useState(defaultNotation ?? "UTN");

  const [teethNo11, setTeethNo11] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 11)[0] || {
      toothNumber: notation === "FDI" ? 11 : 8,
      _id: selectedTeeth?.includes(notation === "FDI" ? 11 : 8) ? "Checked" : "",
    }
  );
  const [teethNo12, setTeethNo12] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 12)[0] || {
      toothNumber: notation === "FDI" ? 12 : 7,
      _id: selectedTeeth?.includes(notation === "FDI" ? 12 : 7) ? "Checked" : "",
    }
  );
  const [teethNo13, setTeethNo13] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 13)[0] || {
      toothNumber: notation === "FDI" ? 13 : 6,
      _id: selectedTeeth?.includes(notation === "FDI" ? 13 : 6) ? "Checked" : "",
    }
  );
  const [teethNo14, setTeethNo14] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 14)[0] || {
      toothNumber: notation === "FDI" ? 14 : 5,
      _id: selectedTeeth?.includes(notation === "FDI" ? 14 : 5) ? "Checked" : "",
    }
  );
  const [teethNo15, setTeethNo15] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 15)[0] || {
      toothNumber: notation === "FDI" ? 15 : 4,
      _id: selectedTeeth?.includes(notation === "FDI" ? 15 : 4) ? "Checked" : "",
    }
  );
  const [teethNo16, setTeethNo16] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 16)[0] || {
      toothNumber: notation === "FDI" ? 16 : 3,
      _id: selectedTeeth?.includes(notation === "FDI" ? 16 : 3) ? "Checked" : "",
    }
  );
  const [teethNo17, setTeethNo17] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 17)[0] || {
      toothNumber: notation === "FDI" ? 17 : 2,
      _id: selectedTeeth?.includes(notation === "FDI" ? 17 : 2) ? "Checked" : "",
    }
  );
  const [teethNo18, setTeethNo18] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 18)[0] || {
      toothNumber: notation === "FDI" ? 18 : 1,
      _id: selectedTeeth?.includes(notation === "FDI" ? 18 : 1) ? "Checked" : "",
    }
  );
  const [teethNo21, setTeethNo21] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 21)[0] || {
      toothNumber: notation === "FDI" ? 21 : 9,
      _id: selectedTeeth?.includes(notation === "FDI" ? 21 : 9) ? "Checked" : "",
    }
  );
  const [teethNo22, setTeethNo22] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 22)[0] || {
      toothNumber: notation === "FDI" ? 22 : 10,
      _id: selectedTeeth?.includes(notation === "FDI" ? 22 : 10) ? "Checked" : "",
    }
  );
  const [teethNo23, setTeethNo23] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 23)[0] || {
      toothNumber: notation === "FDI" ? 23 : 11,
      _id: selectedTeeth?.includes(notation === "FDI" ? 23 : 11) ? "Checked" : "",
    }
  );
  const [teethNo24, setTeethNo24] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 24)[0] || {
      toothNumber: notation === "FDI" ? 24 : 12,
      _id: selectedTeeth?.includes(notation === "FDI" ? 24 : 12) ? "Checked" : "",
    }
  );
  const [teethNo25, setTeethNo25] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 25)[0] || {
      toothNumber: notation === "FDI" ? 25 : 13,
      _id: selectedTeeth?.includes(notation === "FDI" ? 25 : 13) ? "Checked" : "",
    }
  );
  const [teethNo26, setTeethNo26] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 26)[0] || {
      toothNumber: notation === "FDI" ? 26 : 14,
      _id: selectedTeeth?.includes(notation === "FDI" ? 26 : 14) ? "Checked" : "",
    }
  );
  const [teethNo27, setTeethNo27] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 27)[0] || {
      toothNumber: notation === "FDI" ? 27 : 15,
      _id: selectedTeeth?.includes(notation === "FDI" ? 27 : 15) ? "Checked" : "",
    }
  );
  const [teethNo28, setTeethNo28] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 28)[0] || {
      toothNumber: notation === "FDI" ? 28 : 16,
      _id: selectedTeeth?.includes(notation === "FDI" ? 28 : 16) ? "Checked" : "",
    }
  );
  const [teethNo31, setTeethNo31] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 31)[0] || {
      toothNumber: notation === "FDI" ? 31 : 24,
      _id: selectedTeeth?.includes(notation === "FDI" ? 31 : 24) ? "Checked" : "",
    }
  );
  const [teethNo32, setTeethNo32] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 32)[0] || {
      toothNumber: notation === "FDI" ? 32 : 23,
      _id: selectedTeeth?.includes(notation === "FDI" ? 32 : 23) ? "Checked" : "",
    }
  );
  const [teethNo33, setTeethNo33] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 33)[0] || {
      toothNumber: notation === "FDI" ? 33 : 22,
      _id: selectedTeeth?.includes(notation === "FDI" ? 33 : 22) ? "Checked" : "",
    }
  );
  const [teethNo34, setTeethNo34] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 34)[0] || {
      toothNumber: notation === "FDI" ? 34 : 21,
      _id: selectedTeeth?.includes(notation === "FDI" ? 34 : 21) ? "Checked" : "",
    }
  );
  const [teethNo35, setTeethNo35] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 35)[0] || {
      toothNumber: notation === "FDI" ? 35 : 20,
      _id: selectedTeeth?.includes(notation === "FDI" ? 35 : 20) ? "Checked" : "",
    }
  );
  const [teethNo36, setTeethNo36] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 36)[0] || {
      toothNumber: notation === "FDI" ? 36 : 19,
      _id: selectedTeeth?.includes(notation === "FDI" ? 36 : 19) ? "Checked" : "",
    }
  );
  const [teethNo37, setTeethNo37] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 37)[0] || {
      toothNumber: notation === "FDI" ? 37 : 18,
      _id: selectedTeeth?.includes(notation === "FDI" ? 37 : 18) ? "Checked" : "",
    }
  );
  const [teethNo38, setTeethNo38] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 38)[0] || {
      toothNumber: notation === "FDI" ? 38 : 17,
      _id: selectedTeeth?.includes(notation === "FDI" ? 38 : 17) ? "Checked" : "",
    }
  );
  const [teethNo41, setTeethNo41] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 41)[0] || {
      toothNumber: notation === "FDI" ? 41 : 25,
      _id: selectedTeeth?.includes(notation === "FDI" ? 41 : 25) ? "Checked" : "",
    }
  );
  const [teethNo42, setTeethNo42] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 42)[0] || {
      toothNumber: notation === "FDI" ? 42 : 26,
      _id: selectedTeeth?.includes(notation === "FDI" ? 42 : 26) ? "Checked" : "",
    }
  );
  const [teethNo43, setTeethNo43] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 43)[0] || {
      toothNumber: notation === "FDI" ? 43 : 27,
      _id: selectedTeeth?.includes(notation === "FDI" ? 43 : 27) ? "Checked" : "",
    }
  );
  const [teethNo44, setTeethNo44] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 44)[0] || {
      toothNumber: notation === "FDI" ? 44 : 28,
      _id: selectedTeeth?.includes(notation === "FDI" ? 44 : 28) ? "Checked" : "",
    }
  );
  const [teethNo45, setTeethNo45] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 45)[0] || {
      toothNumber: notation === "FDI" ? 45 : 29,
      _id: selectedTeeth?.includes(notation === "FDI" ? 45 : 29) ? "Checked" : "",
    }
  );
  const [teethNo46, setTeethNo46] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 46)[0] || {
      toothNumber: notation === "FDI" ? 46 : 30,
      _id: selectedTeeth?.includes(notation === "FDI" ? 46 : 30) ? "Checked" : "",
    }
  );
  const [teethNo47, setTeethNo47] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 47)[0] || {
      toothNumber: notation === "FDI" ? 47 : 31,
      _id: selectedTeeth?.includes(notation === "FDI" ? 47 : 31) ? "Checked" : "",
    }
  );
  const [teethNo48, setTeethNo48] = useState(
    allTeethData?.filter((tooth) => tooth.toothNumber === 48)[0] || {
      toothNumber: notation === "FDI" ? 48 : 32,
      _id: selectedTeeth?.includes(notation === "FDI" ? 48 : 32) ? "Checked" : "",
    }
  );

  const arrayOfLeftTeethData = [
    {
      initialState: teethNo18,
      setState: setTeethNo18,
      image: Teeth18,
      hoverImage: Teeth18Hover,
      crownImage: Teeth18Crown,
      disabledIcon: Teeth18Disabled,
      teethNumber: 18,
      teethBottomNumber: 1,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo17,
      setState: setTeethNo17,
      image: Teeth17,
      hoverImage: Teeth17Hover,
      crownImage: Teeth17Crown,
      disabledIcon: Teeth17Disabled,
      teethNumber: 17,
      teethBottomNumber: 2,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo16,
      setState: setTeethNo16,
      image: Teeth16,
      hoverImage: Teeth16Hover,
      crownImage: Teeth16Crown,
      disabledIcon: Teeth16Disabled,
      teethNumber: 16,
      teethBottomNumber: 3,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo15,
      setState: setTeethNo15,
      image: Teeth15,
      hoverImage: Teeth15Hover,
      crownImage: Teeth15Crown,
      disabledIcon: Teeth15Disabled,
      teethNumber: 15,
      teethBottomNumber: 4,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo14,
      setState: setTeethNo14,
      image: Teeth14,
      hoverImage: Teeth14Hover,
      crownImage: Teeth14Crown,
      disabledIcon: Teeth14Disabled,
      teethNumber: 14,
      teethBottomNumber: 5,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo13,
      setState: setTeethNo13,
      image: Teeth13,
      hoverImage: Teeth13Hover,
      crownImage: Teeth13Crown,
      disabledIcon: Teeth13Disabled,
      teethNumber: 13,
      teethBottomNumber: 6,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo12,
      setState: setTeethNo12,
      image: Teeth12,
      hoverImage: Teeth12Hover,
      crownImage: Teeth12Crown,
      disabledIcon: Teeth12Disabled,
      teethNumber: 12,
      teethBottomNumber: 7,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo11,
      setState: setTeethNo11,
      image: Teeth11,
      hoverImage: Teeth11Hover,
      crownImage: Teeth11Crown,
      disabledIcon: Teeth11Disabled,
      teethNumber: 11,
      teethBottomNumber: 8,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
  ];

  const arrayOfRightTeethData = [
    {
      initialState: teethNo21,
      setState: setTeethNo21,
      image: Teeth21,
      hoverImage: Teeth21Hover,
      crownImage: Teeth21Crown,
      disabledIcon: Teeth21Disabled,
      teethNumber: 21,
      teethBottomNumber: 9,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo22,
      setState: setTeethNo22,
      image: Teeth22,
      hoverImage: Teeth22Hover,
      crownImage: Teeth22Crown,
      disabledIcon: Teeth22Disabled,
      teethNumber: 22,
      teethBottomNumber: 10,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo23,
      setState: setTeethNo23,
      image: Teeth23,
      hoverImage: Teeth23Hover,
      crownImage: Teeth23Crown,
      disabledIcon: Teeth23Disabled,
      teethNumber: 23,
      teethBottomNumber: 11,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo24,
      setState: setTeethNo24,
      image: Teeth24,
      hoverImage: Teeth24Hover,
      crownImage: Teeth24Crown,
      disabledIcon: Teeth24Disabled,
      teethNumber: 24,
      teethBottomNumber: 12,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo25,
      setState: setTeethNo25,
      image: Teeth25,
      hoverImage: Teeth25Hover,
      crownImage: Teeth25Crown,
      disabledIcon: Teeth25Disabled,
      teethNumber: 25,
      teethBottomNumber: 13,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo26,
      setState: setTeethNo26,
      image: Teeth26,
      hoverImage: Teeth26Hover,
      crownImage: Teeth26Crown,
      disabledIcon: Teeth26Disabled,
      teethNumber: 26,
      teethBottomNumber: 14,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo27,
      setState: setTeethNo27,
      image: Teeth27,
      hoverImage: Teeth27Hover,
      crownImage: Teeth27Crown,
      disabledIcon: Teeth27Disabled,
      teethNumber: 27,
      teethBottomNumber: 15,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
    {
      initialState: teethNo28,
      setState: setTeethNo28,
      image: Teeth28,
      hoverImage: Teeth28Hover,
      crownImage: Teeth28Crown,
      disabledIcon: Teeth28Disabled,
      teethNumber: 28,
      teethBottomNumber: 16,
      guideImage: guideDownIcon,
      guideImageHover: guideDownIconHover,
    },
  ];

  const arrayOfBottomRightTeethData = [
    {
      initialState: teethNo31,
      setState: setTeethNo31,
      image: Teeth31,
      hoverImage: Teeth31Hover,
      crownImage: Teeth31Crown,
      disabledIcon: Teeth31Disabled,
      teethNumber: 31,
      teethBottomNumber: 24,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo32,
      setState: setTeethNo32,
      image: Teeth32,
      hoverImage: Teeth32Hover,
      crownImage: Teeth32Crown,
      disabledIcon: Teeth32Disabled,
      teethNumber: 32,
      teethBottomNumber: 23,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo33,
      setState: setTeethNo33,
      image: Teeth33,
      hoverImage: Teeth33Hover,
      crownImage: Teeth33Crown,
      disabledIcon: Teeth33Disabled,
      teethNumber: 33,
      teethBottomNumber: 22,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo34,
      setState: setTeethNo34,
      image: Teeth34,
      hoverImage: Teeth34Hover,
      crownImage: Teeth34Crown,
      disabledIcon: Teeth34Disabled,
      teethNumber: 34,
      teethBottomNumber: 21,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo35,
      setState: setTeethNo35,
      image: Teeth35,
      hoverImage: Teeth35Hover,
      crownImage: Teeth35Crown,
      disabledIcon: Teeth35Disabled,
      teethNumber: 35,
      teethBottomNumber: 20,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo36,
      setState: setTeethNo36,
      image: Teeth36,
      hoverImage: Teeth36Hover,
      crownImage: Teeth36Crown,
      disabledIcon: Teeth36Disabled,
      teethNumber: 36,
      teethBottomNumber: 19,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo37,
      setState: setTeethNo37,
      image: Teeth37,
      hoverImage: Teeth37Hover,
      crownImage: Teeth37Crown,
      disabledIcon: Teeth37Disabled,
      teethNumber: 37,
      teethBottomNumber: 18,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo38,
      setState: setTeethNo38,
      image: Teeth38,
      hoverImage: Teeth38Hover,
      crownImage: Teeth38Crown,
      disabledIcon: Teeth38Disabled,
      teethNumber: 38,
      teethBottomNumber: 17,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
  ];

  const arrayOfBottomLeftTeethData = [
    {
      initialState: teethNo48,
      setState: setTeethNo48,
      image: Teeth48,
      hoverImage: Teeth48Hover,
      crownImage: Teeth48Crown,
      disabledIcon: Teeth48Disabled,
      teethNumber: 48,
      teethBottomNumber: 32,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo47,
      setState: setTeethNo47,
      image: Teeth47,
      hoverImage: Teeth47Hover,
      crownImage: Teeth47Crown,
      disabledIcon: Teeth47Disabled,
      teethNumber: 47,
      teethBottomNumber: 31,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo46,
      setState: setTeethNo46,
      image: Teeth46,
      hoverImage: Teeth46Hover,
      crownImage: Teeth46Crown,
      disabledIcon: Teeth46Disabled,
      teethNumber: 46,
      teethBottomNumber: 30,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo45,
      setState: setTeethNo45,
      image: Teeth45,
      hoverImage: Teeth45Hover,
      crownImage: Teeth45Crown,
      disabledIcon: Teeth45Disabled,
      teethNumber: 45,
      teethBottomNumber: 29,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo44,
      setState: setTeethNo44,
      image: Teeth44,
      hoverImage: Teeth44Hover,
      crownImage: Teeth44Crown,
      disabledIcon: Teeth44Disabled,
      teethNumber: 44,
      teethBottomNumber: 28,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo43,
      setState: setTeethNo43,
      image: Teeth43,
      hoverImage: Teeth43Hover,
      crownImage: Teeth43Crown,
      disabledIcon: Teeth43Disabled,
      teethNumber: 43,
      teethBottomNumber: 27,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo42,
      setState: setTeethNo42,
      image: Teeth42,
      hoverImage: Teeth42Hover,
      crownImage: Teeth42Crown,
      disabledIcon: Teeth42Disabled,
      teethNumber: 42,
      teethBottomNumber: 26,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
    {
      initialState: teethNo41,
      setState: setTeethNo41,
      image: Teeth41,
      hoverImage: Teeth41Hover,
      crownImage: Teeth41Crown,
      disabledIcon: Teeth41Disabled,
      teethNumber: 41,
      teethBottomNumber: 25,
      guideImage: guideIcon,
      guideImageHover: guideIconHover,
    },
  ];

  const handleNotationChange = (event) => {
    setNotation(event.target.value);
  };

  const handleTeethClickChange = (teeth) => {
    console.log("teeth click data", teeth);
    if (teeth.initialState._id === "") {
      //save
      //resp =>

      teeth.setState((preState) => ({ ...preState, _id: "Checked" }));
      setSelectedTeeth((prevTeeth) => {
        console.log("prevTeeth", prevTeeth);
        console.log(
          "teethNumber",
          defaultNotation === "FDI" ? teeth.teethNumber : teeth.teethBottomNumber
        );
        if (
          prevTeeth.includes(
            defaultNotation === "FDI" ? teeth.teethNumber : teeth.teethBottomNumber
          )
        ) {
          return prevTeeth.filter(
            (t) => t !== (defaultNotation === "FDI" ? teeth.teethNumber : teeth.teethBottomNumber)
          );
        } else {
          return [
            ...prevTeeth,
            defaultNotation === "FDI" ? teeth.teethNumber : teeth.teethBottomNumber,
          ];
        }
      });
    } else {
      //delete
      //resp =>
      teeth.setState((preState) => ({ ...preState, _id: "" }));
      setSelectedTeeth((prevTeeth) => {
        console.log("prevTeeth", prevTeeth);
        console.log(
          "teethNumber",
          defaultNotation === "FDI" ? teeth.teethNumber : teeth.teethBottomNumber
        );
        if (
          prevTeeth.includes(
            defaultNotation === "FDI" ? teeth.teethNumber : teeth.teethBottomNumber
          )
        ) {
          return prevTeeth.filter(
            (t) => t !== (defaultNotation === "FDI" ? teeth.teethNumber : teeth.teethBottomNumber)
          );
        } else {
          return [
            ...prevTeeth,
            defaultNotation === "FDI" ? teeth.teethNumber : teeth.teethBottomNumber,
          ];
        }
      });
    }
  };

  const handleTeethClick = (teethNumber) => {
    setSelectedTeeth((prevTeeth) => {
      if (prevTeeth.includes(teethNumber)) {
        return prevTeeth.filter((t) => t !== teethNumber);
      } else {
        return [...prevTeeth, teethNumber];
      }
    });
  };

  // const handleMouseEnter = (teethNumber) => {
  //   setHoveredTeeth(teethNumber);
  // };

  // const handleMouseLeave = () => {
  //   setHoveredTeeth(null);
  // };
  useEffect(() => {}, [selectedTeeth]);
  return (
    <>
      <div className={classes.headerContainer}>
        {title && (
          <div className={classes.headerText}>{title ?? "Tooth Movement Restrictions"}</div>
        )}
        {notationVisible && (
          <div className={classes.radioButtons}>
            <label className="text-white">
              <input
                style={{ marginRight: "10px" }}
                type="radio"
                value="UTN"
                checked={notation === "UTN"}
                onChange={handleNotationChange}
                className="text-white"
              />
              UTN
            </label>
            <label className="text-white">
              <input
                style={{ marginRight: "10px" }}
                type="radio"
                value="FDI"
                checked={notation === "FDI"}
                onChange={handleNotationChange}
                className="text-white"
              />
              FDI
            </label>
          </div>
        )}
      </div>
      <TeethFilter />
      <div className="md:grid relative bg-[#4e80f42d] p-5 w-fit flex flex-col md:grid-cols-2 grid-cols-1 grid-rows-2 text-white">
        <div className={classes.rightText}>R</div>
        <div className={classes.leftText}>L</div>
        <div className={classes.leftSectionWrapper}>
          {arrayOfLeftTeethData.map((item) => {
            return (
              <IndividualTeeth
                notation={notation}
                restrictedTeeth={restrictedTeeth}
                restrictedTeethMessage={restrictedTeethMessage}
                otherToothRestrictedMessage={otherToothRestrictedMessage}
                hideSelected={hideSelected}
                teethNumber={item.teethNumber}
                teethBottomNumber={item.teethBottomNumber}
                Teeth={item.image}
                HoveredTeeth={item.hoverImage}
                CrownImage={item?.crownImage}
                DisabledIcon={item?.disabledIcon}
                GuideImage={item?.guideImage}
                GuideHoverImage={item?.guideImageHover}
                selected={item.initialState._id !== ""}
                onClick={() => handleTeethClickChange(item)}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                missingTeeth={missingTeeth}
                missingTeethSelectable={missingTeethSelectable}
                screwTeethSelectable={screwTeethSelectable}
                selectedScrewTeeth={selectedScrewTeeth}
                showHoveredIconOnSelect={showHoveredIconOnSelect}
                allowCrownSelect={allowCrownSelect}
                disabledTeeths={disabledTeeths}
                disabledTeethMessage={disabledTeethMessage}
              />
            );
          })}
        </div>
        <div className={classes.rightSectionWrapper}>
          {arrayOfRightTeethData.map((item) => {
            return (
              <IndividualTeeth
                notation={notation}
                restrictedTeeth={restrictedTeeth}
                restrictedTeethMessage={restrictedTeethMessage}
                otherToothRestrictedMessage={otherToothRestrictedMessage}
                hideSelected={hideSelected}
                teethNumber={item.teethNumber}
                teethBottomNumber={item.teethBottomNumber}
                Teeth={item.image}
                HoveredTeeth={item.hoverImage}
                CrownImage={item?.crownImage}
                DisabledIcon={item?.disabledIcon}
                GuideImage={item?.guideImage}
                GuideHoverImage={item?.guideImageHover}
                selected={item.initialState._id !== ""}
                onClick={() => handleTeethClickChange(item)}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                missingTeeth={missingTeeth}
                missingTeethSelectable={missingTeethSelectable}
                screwTeethSelectable={screwTeethSelectable}
                selectedScrewTeeth={selectedScrewTeeth}
                showHoveredIconOnSelect={showHoveredIconOnSelect}
                allowCrownSelect={allowCrownSelect}
                disabledTeeths={disabledTeeths}
                disabledTeethMessage={disabledTeethMessage}
              />
            );
          })}
        </div>
        <div className={classes.bottomLeftSectionWrapper}>
          {arrayOfBottomLeftTeethData.map((item) => {
            return (
              <IndividualTeeth
                notation={notation}
                restrictedTeeth={restrictedTeeth}
                restrictedTeethMessage={restrictedTeethMessage}
                otherToothRestrictedMessage={otherToothRestrictedMessage}
                hideSelected={hideSelected}
                teethNumber={item.teethNumber}
                teethBottomNumber={item.teethBottomNumber}
                Teeth={item.image}
                HoveredTeeth={item.hoverImage}
                CrownImage={item?.crownImage}
                DisabledIcon={item?.disabledIcon}
                GuideImage={item?.guideImage}
                GuideHoverImage={item?.guideImageHover}
                selected={item.initialState._id !== ""}
                onClick={() => handleTeethClickChange(item)}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                missingTeeth={missingTeeth}
                missingTeethSelectable={missingTeethSelectable}
                screwTeethSelectable={screwTeethSelectable}
                selectedScrewTeeth={selectedScrewTeeth}
                showHoveredIconOnSelect={showHoveredIconOnSelect}
                allowCrownSelect={allowCrownSelect}
                disabledTeeths={disabledTeeths}
                disabledTeethMessage={disabledTeethMessage}
              />
            );
          })}
        </div>
        <div className={classes.bottomRightSectionWrapper}>
          {arrayOfBottomRightTeethData.map((item) => {
            return (
              <IndividualTeeth
                notation={notation}
                restrictedTeeth={restrictedTeeth}
                restrictedTeethMessage={restrictedTeethMessage}
                otherToothRestrictedMessage={otherToothRestrictedMessage}
                hideSelected={hideSelected}
                teethNumber={item.teethNumber}
                teethBottomNumber={item.teethBottomNumber}
                Teeth={item.image}
                HoveredTeeth={item.hoverImage}
                CrownImage={item?.crownImage}
                DisabledIcon={item?.disabledIcon}
                GuideImage={item?.guideImage}
                GuideHoverImage={item?.guideImageHover}
                selected={item.initialState._id !== ""}
                onClick={() => handleTeethClickChange(item)}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                missingTeeth={missingTeeth}
                missingTeethSelectable={missingTeethSelectable}
                screwTeethSelectable={screwTeethSelectable}
                selectedScrewTeeth={selectedScrewTeeth}
                showHoveredIconOnSelect={showHoveredIconOnSelect}
                allowCrownSelect={allowCrownSelect}
                disabledTeeths={disabledTeeths}
                disabledTeethMessage={disabledTeethMessage}
              />
            );
          })}
          {/* <IndividualTeeth
            notation={notation}
            teethNumber={31}
            teethBottomNumber={24}
            Teeth={Teeth31}
            isBottom={true}
            HoveredTeeth={Teeth31Hover}
            selected={selectedTeeth.includes(31) || hoveredTeeth === 31}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={32}
            teethBottomNumber={23}
            Teeth={Teeth32}
            isBottom={true}
            HoveredTeeth={Teeth32Hover}
            selected={selectedTeeth.includes(32) || hoveredTeeth === 32}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={33}
            teethBottomNumber={22}
            Teeth={Teeth33}
            isBottom={true}
            HoveredTeeth={Teeth33Hover}
            selected={selectedTeeth.includes(33) || hoveredTeeth === 33}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={34}
            teethBottomNumber={21}
            Teeth={Teeth34}
            isBottom={true}
            HoveredTeeth={Teeth34Hover}
            selected={selectedTeeth.includes(34) || hoveredTeeth === 34}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={35}
            teethBottomNumber={20}
            Teeth={Teeth35}
            isBottom={true}
            HoveredTeeth={Teeth35Hover}
            selected={selectedTeeth.includes(35) || hoveredTeeth === 35}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={36}
            teethBottomNumber={19}
            Teeth={Teeth36}
            isBottom={true}
            HoveredTeeth={Teeth36Hover}
            selected={selectedTeeth.includes(36) || hoveredTeeth === 36}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={37}
            teethBottomNumber={18}
            Teeth={Teeth37}
            isBottom={true}
            HoveredTeeth={Teeth37Hover}
            selected={selectedTeeth.includes(37) || hoveredTeeth === 37}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={38}
            teethBottomNumber={17}
            Teeth={Teeth38}
            isBottom={true}
            HoveredTeeth={Teeth38Hover}
            selected={selectedTeeth.includes(38) || hoveredTeeth === 38}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          /> */}
        </div>
      </div>
    </>
  );
};

const IndividualTeeth = ({
  teethNumber,
  hideSelected,
  teethBottomNumber,
  Teeth,
  isBottom,
  HoveredTeeth,
  selected,
  onClick,
  // onMouseEnter,
  // onMouseLeave,
  notation,
  missingTeeth,
  restrictedTeeth,
  restrictedTeethMessage,
  missingTeethSelectable,
  otherToothRestrictedMessage,
  CrownImage,
  GuideImage,
  GuideHoverImage,
  DisabledIcon,
  screwTeethSelectable,
  selectedScrewTeeth,
  showHoveredIconOnSelect,
  allowCrownSelect,
  disabledTeeths,
  disabledTeethMessage,
}) => {
  const handleClick = (e) => {
    const actualTeethNo = notation === "FDI" ? teethNumber : teethBottomNumber;
    if (
      (missingTeeth?.includes(actualTeethNo) && !missingTeethSelectable && !screwTeethSelectable) ||
      (screwTeethSelectable && !selectedScrewTeeth?.includes(actualTeethNo))
    ) {
      return;
    } else if (disabledTeeths?.includes(actualTeethNo)) {
      GetToast(disabledTeethMessage ?? "Tooth is not selectable");
      return;
    } else if (restrictedTeeth?.includes(actualTeethNo)) {
      GetToast(restrictedTeethMessage ?? "Tooth is already selected in other types");
      return;
    } else if (
      missingTeethSelectable &&
      !missingTeeth?.includes(notation === "FDI" ? teethNumber : teethBottomNumber) &&
      !allowCrownSelect
    ) {
      GetToast(otherToothRestrictedMessage ?? "Tooth is not selectable");
      return;
    }
    console.log("check2");
    onClick(teethNumber);
  };

  // const handleMouseEnter = () => {
  //   onMouseEnter(teethNumber);
  // };

  console.log("missingTeeth1", notation === "FDI" ? teethNumber : teethBottomNumber, missingTeeth);
  console.log(
    "screwTeethSelectable1",
    notation === "FDI" ? teethNumber : teethBottomNumber,
    screwTeethSelectable
  );
  console.log(
    "selectedScrewTeeth1",
    notation === "FDI" ? teethNumber : teethBottomNumber,
    selectedScrewTeeth
  );
  console.log("selected1", notation === "FDI" ? teethNumber : teethBottomNumber, selected);

  return (
    <div
      className={classes.individualTeethWrapper}
      style={{ flexDirection: isBottom ? "column-reverse" : "column" }}
      onClick={handleClick}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={onMouseLeave}
    >
      <div
        className={`${
          ((hideSelected && selected) ||
            missingTeeth?.includes(notation === "FDI" ? teethNumber : teethBottomNumber)) &&
          !missingTeethSelectable &&
          !screwTeethSelectable
            ? "invisible"
            : ""
        }`}
      >
        {disabledTeeths?.includes(notation === "FDI" ? teethNumber : teethBottomNumber) ? (
          !missingTeethSelectable ? (
            <DisabledIcon />
          ) : (
            <div
              className="flex justify-center"
              style={{
                width:
                  TeethSizes[notation + (notation === "FDI" ? teethNumber : teethBottomNumber)],
              }}
            >
              <img
                src={
                  screwTeethSelectable &&
                  selected &&
                  selectedScrewTeeth?.includes(notation === "FDI" ? teethNumber : teethBottomNumber)
                    ? GuideHoverImage
                    : GuideImage
                }
                className="max-h-[75px] max-w-[90%] opacity-40"
                alt="guide teeth"
              />
            </div>
          )
        ) : missingTeeth?.includes(notation === "FDI" ? teethNumber : teethBottomNumber) ? (
          (missingTeethSelectable && selected) ||
          (screwTeethSelectable &&
            selectedScrewTeeth?.includes(notation === "FDI" ? teethNumber : teethBottomNumber)) ? (
            <div
              className="flex justify-center"
              style={{
                width:
                  TeethSizes[notation + (notation === "FDI" ? teethNumber : teethBottomNumber)],
              }}
            >
              {selected && showHoveredIconOnSelect ? (
                <HoveredTeeth />
              ) : allowCrownSelect ? (
                <CrownImage />
              ) : (
                <img
                  src={
                    screwTeethSelectable &&
                    selected &&
                    selectedScrewTeeth?.includes(
                      notation === "FDI" ? teethNumber : teethBottomNumber
                    )
                      ? GuideHoverImage
                      : GuideImage
                  }
                  className="max-h-[75px] max-w-[90%]"
                  alt="guide teeth"
                />
              )}
            </div>
          ) : (
            <span className="invisible">
              <Teeth />
            </span>
          )
        ) : selected ? (
          <HoveredTeeth />
        ) : (
          <Teeth />
        )}
      </div>
      {notation === "FDI" ? (
        <div
          className={notation === "FDI" ? classes.teethNumber : classes.teethNumberSelected}
          style={{
            opacity: disabledTeeths?.includes(notation === "FDI" ? teethNumber : teethBottomNumber)
              ? 0.4
              : 1,
          }}
        >
          {teethNumber}
        </div>
      ) : (
        <div
          className={notation === "FDI" ? classes.teethNumberSelected : classes.teethNumber}
          style={{
            opacity: disabledTeeths?.includes(notation === "FDI" ? teethNumber : teethBottomNumber)
              ? 0.4
              : 1,
          }}
        >
          {teethBottomNumber}
        </div>
      )}
    </div>
  );
};
