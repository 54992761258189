import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef, useState } from "react";
import { AxiosPostHook } from "../../hooks/AxiosPostHook";
import DetailTile from "./DetailTile";
import profileIcon from "../../Assets/images/profileIcon.svg";
import TabComponentProfile from "./TabComponentProfile";
import InputFieldsProfile from "./InputFieldsProfile";
import InputFieldsAddress from "./InputFieldsAddress";
import hut from "../../Assets/images/hut.svg";
import { IoIosArrowForward } from "react-icons/io";
import bell from "../../Assets/images/bellIcon.svg";
import CustomTabsProfile from "./CustomTabsProfile";
import { AiFillEdit, AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import { useGetProfileMutation, useReadPersonalDetailsMutation, useUpdateProfilePictureMutation } from "../../services/user";
import { Teeth } from "../Teeth-Comps/Teeth";
import InputFieldsBank from "./InputFieldsBank";
import InputFieldsClinic from "./InputFieldsClinic";
import { LoginButton } from "../../Screens/Login/Login";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import { setUserPersonalDetails, setUserProfilePicture } from "../../reducers/PersonalDetailsReducer";
import { useSelector, useDispatch } from "react-redux";
import ToggleButton from "../UserManagement-Comps/ToggleButton";
import { Tooltip } from "react-tooltip";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useSaveFileMutation } from "../../services/uploadFiles";
import { GetToast } from "../Toast-Comps/GetToast";
import Notification from "../Notification/Notification";

export const ProfileInfoTab = ({ emailFromUserProfile, profileId, setProfileId, userInfo, userRole }) => {
  const dispatch = useDispatch();
  const personalDetailsStore = useSelector((state) => state.personalDetails.data);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [profilePictureUpdateModalIsOpen, setProfilePictureUpdateModalIsOpen] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [profileDetailData, setProfileDetailData] = useState(personalDetailsStore);
  const { getAccessTokenSilently } = useAuth0();
  const [readPersonalDetails] = useReadPersonalDetailsMutation();
  const [saveFile] = useSaveFileMutation();
  const [updateProfilePicture] = useUpdateProfilePictureMutation();
  const [getProfile] = useGetProfileMutation();

  // Update Profile Picture States
  const { userProfilePicture } = useSelector((state) => state.personalDetails);
  const [newProfilePicture, setNewProfilePicture] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState();

  const role = localStorage.getItem("crowdHireUserRole");
  const [crop, setCrop] = useState();
  const {data} = useSelector((state)=> state.personalDetails);

  const getPersonalDetails = async (accessToken) => {
    return await readPersonalDetails(accessToken).unwrap();
  };

  const readProfileInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const response = await readPersonalDetails({ accessToken }).unwrap((res) => res);
    dispatch(setUserPersonalDetails(response?.data));
    setProfileDetailData(response?.data);
    setProfileId(response?.data?.[0]?.["_id"]);
  };

  const loadProfilePicture = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await getProfile(accessToken);
    if (resp && resp?.data?.profilePic) {
      dispatch(setUserProfilePicture(resp?.data?.profilePic));
    }
  };

  useEffect(() => {
    console.log("useEffect profile working --- ");
    if (isProfileUpdated === true) {
      readProfileInfo();
    }
    setIsProfileUpdated(false);
    // debugger
  }, [isProfileUpdated]);

  useEffect(() => {
    if (!personalDetailsStore?.length) {
      readProfileInfo();
    }
    loadProfilePicture();
  }, []);

  const detailTileListOne = [
    <DetailTile heading={"First Name"} subHeading={profileDetailData?.[0] && profileDetailData[0]["firstName"] ? profileDetailData[0]["firstName"] : "---"} icon={profileIcon} />,
    <DetailTile heading={"Last Name"} subHeading={profileDetailData?.[0] && profileDetailData[0]["lastName"] ? profileDetailData[0]["lastName"] : "---"} icon={profileIcon} />,
    <DetailTile heading={"Phone Number"} subHeading={profileDetailData?.[0] && profileDetailData[0]["mobile"] ? profileDetailData[0]["mobile"] : "---"} icon={profileIcon} />,
    <DetailTile heading={"Gender"} subHeading={profileDetailData?.[0] && profileDetailData[0]["gender"] ? profileDetailData[0]["gender"] : "---"} icon={profileIcon} />,
    role !== "89uij7" ? <DetailTile heading={"Time Zone"} subHeading={profileDetailData?.[0] && profileDetailData[0]["timeZone"] ? profileDetailData[0]["timeZone"] : "---"} icon={profileIcon} /> : <></>,
    role === "34fs3" ? <DetailTile heading={"Are you working with a dental laboratory?"} subHeading={profileDetailData?.[0] && profileDetailData[0]["workingWithLaboratory"] ? profileDetailData[0]["workingWithLaboratory"] : "---"} icon={profileIcon} /> : <></>,
    role === "34fs3" ? <DetailTile heading={"Do you provide independent dental design services?"} subHeading={profileDetailData?.[0] && profileDetailData[0]["provideIndependentDesignService"] ? profileDetailData[0]["provideIndependentDesignService"] : "---"} icon={profileIcon} /> : <></>,
    // <DetailTile
    //   heading={"Company Name"}
    //   subHeading={
    //     profileDetailData?.[0]
    //       ? profileDetailData[0]?.clinicalDetails[0]?.regName
    //       : "---"
    //   }
    //   icon={profileIcon}
    // />,
    // role !== "676dza2" ? <DetailTile heading={"Registration Number"} subHeading={profileDetailData?.[0] ? profileDetailData[0]["registrationNumber"] : "---"} icon={profileIcon} /> : <></>,
    // console.log("profileDetailData", profileDetailData),
  ];
  const detailTileListTwo = [
    <DetailTile heading={"Location"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.userAddressDetails[0]?.address : "---"} icon={profileIcon} />, 
    <DetailTile heading={"Country"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.userAddressDetails[0]?.country : "---"} icon={profileIcon} />, 
    <DetailTile heading={"Pincode"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.userAddressDetails[0]?.pincode : "---"} icon={profileIcon} />,
    role === "34fs3" ? <DetailTile heading={"Own or operate or work with a physical dental laboratory?"} subHeading={profileDetailData?.[0] && profileDetailData[0]?.userAddressDetails[0]?.ownPhysicalLaboratory ? profileDetailData[0]?.userAddressDetails[0]?.ownPhysicalLaboratory : "---"} icon={profileIcon} /> : <></>,
    role === "34fs3" && profileDetailData[0]?.userAddressDetails[0]?.ownPhysicalLaboratory === "Yes" ? <DetailTile heading={"Laboratory Location"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.userAddressDetails[0]?.laboratoryLocation : "---"} icon={profileIcon} /> : <></>,
    role === "34fs3" && profileDetailData[0]?.userAddressDetails[0]?.ownPhysicalLaboratory === "Yes" ? <DetailTile heading={"Laboratory Address"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.userAddressDetails[0]?.laboratoryAddress : "---"} icon={profileIcon} /> : <></>,
    role === "34fs3" && profileDetailData[0]?.userAddressDetails[0]?.ownPhysicalLaboratory === "Yes" ? <DetailTile heading={"Laboratory Country"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.userAddressDetails[0]?.laboratoryCountry : "---"} icon={profileIcon} /> : <></>,
    role === "34fs3" && profileDetailData[0]?.userAddressDetails[0]?.ownPhysicalLaboratory === "Yes" ? <DetailTile heading={"Laboratory Pin code"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.userAddressDetails[0]?.laboratoryPinCode : "---"} icon={profileIcon} /> : <></>
  ];
  const detailTileListThree = [<DetailTile heading={"Bank Name"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.bankDetails[0]?.bankName : "---"} icon={profileIcon} />, <DetailTile heading={"Bank Account Number"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.bankDetails[0]?.accountNo : "---"} icon={profileIcon} />, <DetailTile heading={"Account Holder Name"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.bankDetails[0]?.accountHolderName : "---"} icon={profileIcon} />, <DetailTile heading={"Swift Code"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.bankDetails[0]?.swiftCode : "---"} icon={profileIcon} />, <DetailTile heading={"Account Holder Address as Registered with bank"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.bankDetails[0]?.bankAddress : "---"} icon={profileIcon} />, <DetailTile heading={"IBAN/BSB/ABA or other routing number"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.bankDetails[0]?.iBANNumber : "---"} icon={profileIcon} />, <DetailTile heading={"Account Currency"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.bankDetails[0]?.currency : "---"} icon={profileIcon} />];
  const detailTileListFour = [<DetailTile heading={"Registration Number"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.clinicalDetails[0]?.regNumber : "---"} icon={profileIcon} />, <DetailTile heading={"Registration Authority"} subHeading={profileDetailData?.[0] ? profileDetailData[0]?.clinicalDetails[0]?.regAuthority : "---"} icon={profileIcon} />];

  const tileWrapperOne = <TabComponentProfile detailTileList={detailTileListOne} />;
  const tileWrapperTwo = <TabComponentProfile detailTileList={detailTileListTwo} />;
  const tileWrapperThree = <TabComponentProfile detailTileList={detailTileListThree} />;
  const tileWrapperFour = <TabComponentProfile detailTileList={detailTileListFour} />;

  const tabNamesArray = ["Personal Details", "Address Details", role === "34fs3" ? "Bank Details" : role === "676dza2" ? "Clinic Information" : ""];
  const tileObject = {
    "Personal Details": tileWrapperOne,
    "Address Details": tileWrapperTwo,
    "Bank Details": tileWrapperThree,
    "Clinic Information": tileWrapperFour,
  };
  //   list of input fields for modal
  const InputFieldsCompOne = [<InputFieldsProfile emailFromUserProfile={emailFromUserProfile} setModalIsOpen={setModalIsOpen} setIsProfileUpdated={setIsProfileUpdated} profileDetailData={[{ ...profileDetailData[0], picture: user?.picture ?? "" }]} profileId={profileId} readPersonalDetails={getPersonalDetails} role={role} />];
  const InputFieldsCompTwo = [<InputFieldsAddress setModalIsOpen={setModalIsOpen} profileDetailData={profileDetailData} setIsProfileUpdated={setIsProfileUpdated} />];

  const InputFieldsCompThree = [<InputFieldsBank setModalIsOpen={setModalIsOpen} profileDetailData={profileDetailData} setIsProfileUpdated={setIsProfileUpdated} />];

  const InputFieldsCompFour = [<InputFieldsClinic setModalIsOpen={setModalIsOpen} profileDetailData={profileDetailData} setIsProfileUpdated={setIsProfileUpdated} />];

  const tileObjectModal = {
    "Personal Details": InputFieldsCompOne,
    "Address Details": InputFieldsCompTwo,
    "Bank Details": InputFieldsCompThree,
    "Clinic Information": InputFieldsCompFour,
  };

  const canvasPreview = (image, canvas, crop, scale = 1) => {
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("No 2d context");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio;
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

    ctx.restore();
  };

  useEffect(() => {
    const t = setTimeout(() => {
      (async () => {
        if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
          // We use canvasPreview as it's much faster than imgPreview.
          canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 1);
        }
      }).apply(undefined, [completedCrop, 1]);
    }, 500);

    return () => {
      clearTimeout(t);
    };
  }, [completedCrop]);

  const handleCropChange = (_, precentCrop) => {
    setCrop(precentCrop);
  };

  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  };

  const getCroppedProfile = async () => {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY);
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(previewCanvas, 0, 0, previewCanvas.width, previewCanvas.height, 0, 0, offscreen.width, offscreen.height);
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    return blob;
  };

  const handleUpdateProfilePicture = async () => {
    let file = await getCroppedProfile();
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await saveFile({
      accessToken,
      file,
    });
    if (resp && resp?.data?.url) {
      const res = await updateProfilePicture({ accessToken, picture: resp?.data?.url });
      if (res?.data?.type === "1") {
        GetToast("Profile Picture Successfully Updated !!");
        setProfilePictureUpdateModalIsOpen(false);
        setCrop(undefined);
        setCompletedCrop(null);
        setNewProfilePicture(null);
        setProfilePictureUpdateModalIsOpen(false);
        loadProfilePicture();
      } else {
        GetToast("OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.", "error");
      }
    }
  };

  return (
    <>
      <div className="NAVBAR flex justify-between items-center mb-4">
        <p className="text-[15px] text-textP flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">
            Profile <IoIosArrowForward /> <span className="font-medium text-[15px] text-primary"> Profile Info</span>
          </span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? <div className="text-[15px] justify-center items-center hidden md:flex text-white">Hello, {data[0]?.firstName ?? user.name}</div> : <LoginButton />}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div className="flex" data-tooltip-id="status" data-tooltip-content={"Your registration is under review and you will be notified once admin approves your registration."}>
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img className="relative top-[0px] ml-2 right-0 h-5 w-5" src={redCross} alt="" />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}
          <Notification />
        </div>
      </div>
      <p className="HEADING font-medium text-[34px] leading-7 mr-8 md:my-8 my-4 text-white">Profile Info</p>
      {/* <Teeth/>  */}
      <div className="WHITE-SECTION bg-secondary md:p-8 p-4 md:h-[70vh] overflow-auto">
        <div className="flex flex-col gap-4 md:gap-0 md:flex-row md:items-center justify-between mb-8">
          {isAuthenticated ? (
            <div className="relative">
              <img className="rounded-full h-[72px] w-[72px] object-cover" src={userProfilePicture ?? user.picture} alt="" />
              <AiFillEdit
                className="absolute bottom-0 right-0 bg-white rounded-full p-1 text-2xl  cursor-pointer"
                onClick={() => {
                  setProfilePictureUpdateModalIsOpen(true);
                }}
              />
            </div>
          ) : (
            <div className="rounded-full bg-black h-[72px] w-[72px]"></div>
          )}

          <div className="flex-1 md:pl-4">
            {isAuthenticated ? <p className="font-semibold leading-4 pb-2 text-white">{user.name}</p> : <p className="font-semibold leading-4 pb-2">--</p>}
            {isAuthenticated ? <p className="leading-4 text-[#c2c2c2]">{user.email}</p> : <p className="leading-4 text-[#c2c2c2]">Email Not Available</p>}
          </div>
          <div className="text-left md:text-right ">
            <button onClick={() => setModalIsOpen(true)} className="text-[16px] font-medium text-white bg-[#FF7E00] rounded-md px-4 py-2 mb-2">
              Edit Profile
            </button>
            {/* <p className="text-[12px] leading-4 text-[#898989]">
              {user.lastUpdatedDate || "New User"}
            </p> */}
          </div>
        </div>
        <div className="CUSTOM-TAB">
          <CustomTabsProfile tabNamesArray={tabNamesArray} outputObject={tileObject} />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full text-right" onClick={() => setModalIsOpen(false)}>
              <AiOutlineClose className="ml-auto text-white" />
            </button>
            <p className="text-[26px] font-medium text-white leading-8 mb-6">Edit Profile</p>
            <div className="MODAL-TAB-SECTION flex-1">
              <CustomTabsProfile tabNamesArray={tabNamesArray} outputObject={tileObjectModal} />
            </div>
          </div>
        </>
      </Modal>

      <Modal
        isOpen={profilePictureUpdateModalIsOpen}
        onRequestClose={() => {
          setCrop(undefined);
          setCompletedCrop(null);
          setNewProfilePicture(null);
          setProfilePictureUpdateModalIsOpen(false);
        }}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full text-right" onClick={() => setProfilePictureUpdateModalIsOpen(false)}>
              <AiOutlineClose className="ml-auto text-white" />
            </button>
            <p className="text-[26px] font-medium text-white leading-8 mb-6">Update Profile Picture</p>
            <div className="MODAL-TAB-SECTION flex-1 flex flex-col">
              <label htmlFor="profileImage" className="font-medium text-center mb-4 cursor-pointer text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl">
                Select Image
              </label>
              <input
                id="profileImage"
                className="hidden"
                type="file"
                onChange={(e) => {
                  setCrop(undefined);
                  const reader = new FileReader();
                  reader.addEventListener("load", () => setNewProfilePicture(reader.result?.toString() || ""));
                  reader.readAsDataURL(e.target.files[0]);
                }}
              />
              {!!newProfilePicture && (
                <ReactCrop crop={crop} onChange={handleCropChange} onComplete={(c) => setCompletedCrop(c)} aspect={1} minHeight={100}>
                  <img src={newProfilePicture} alt="User Profile" ref={imgRef} onLoad={onImageLoad} />
                </ReactCrop>
              )}
              {!!completedCrop && (
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              )}
              <div className="flex items-center justify-end gap-4 pt-4 mt-auto">
                <button onClick={() => setProfilePictureUpdateModalIsOpen(false)} className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl">
                  Cancel
                </button>
                <button onClick={handleUpdateProfilePicture} type="submit" className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl">
                  Update
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
