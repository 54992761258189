/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSaveSoftwareMutation, useUpdateSoftwareMutation } from "../../services/services";
import { useSaveFileMutation } from "../../services/uploadFiles";
import FileUploadComponent from "../InputField-Comps/FilePondField";
import { GetToast } from "../Toast-Comps/GetToast";
import { BiLoaderAlt } from "react-icons/bi";
import { NumberInputField } from "../Services-Comps/InputFieldsServicesTab";
import {
  useSaveSoftwaresCategoryMutation,
  useUpdateSoftwaresCategoryMutation,
} from "../../services/softwareCategory";

const InputFieldSoftwareTab = ({
  setModalIsOpen,
  isEditModal,
  editingServicesRow,
  options,
  setIsSoftwareUpdated,
  handleUpdateCallback,
  softwareCategoryID = "",
  softwareCategoryName = "",
  onlyCategory = false,
  getData,
}) => {
  const [updateSoftware] = useUpdateSoftwareMutation();
  const [saveSoftware] = useSaveSoftwareMutation();
  const [saveSoftwaresCategory] = useSaveSoftwaresCategoryMutation();
  const [updateSoftwaresCategory] = useUpdateSoftwaresCategoryMutation();
  const [saveFile] = useSaveFileMutation();
  const [categoryOptions, setCategoryOptions] = useState(options);
  const [optionLabels, setOptionsLabels] = useState(options.map((item) => item.label));

  console.log(optionLabels, "optionLabels");
  const { getAccessTokenSilently } = useAuth0();

  // const [selectedService, setSelectedService] = useState("");

  const initialSoftwareDetails = {
    _id: "",
    softwareName: "",
    softwareDescription: "",
    softwareIcon: "",
    sortOrder: "",
    softwareCategoryID: softwareCategoryID || "",
    softwareCategoryName: softwareCategoryName || "",
  };

  const [softwareDetails, setSoftwareDetails] = useState(initialSoftwareDetails);
  const [softwareCategoryText, setSoftwareCategoryText] = useState("");
  const [isCategorySave, setIsCategorySave] = useState(false);
  const [softwareIconLoading, setSoftwareIconLoading] = useState(false);

  const validateSortOrder = (value) => {
    // Your name validation logic goes here
    // Return true if the value is valid, false otherwise
    // For example:
    return Number(value) ? true : false;
  };

  const handleIconFileUpload = async (uploadedFiles) => {
    setSoftwareIconLoading(true);
    const file = uploadedFiles[0];
    // Access and use the uploaded files here
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await saveFile({
      accessToken,
      file,
    });
    if (resp) {
      if (resp?.data?.url) {
        setSoftwareDetails((prev) => ({
          ...prev,
          softwareIcon: resp?.data?.url,
        }));
      }
    }
    setSoftwareIconLoading(false);

    // Perform any further operations with the files, such as saving to state, sending to the server, etc.
  };

  const handleChange = (event) => {
    const { name, value, files } = event.target;

    setSoftwareDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleComboBoxChange = (value) => {
    console.log(value, "Tesgwgjwngjwkg");
    setSoftwareDetails((prev) => ({
      ...prev,
      _id: value,
    }));
  };

  const handleSubmit = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    // const updatePayload = {
    //   accessToken: accessToken,
    //   softwareCategoryName: softwareDetails.softwareCategoryName,
    // };

    if (isEditModal) {
      const softResp = onlyCategory
        ? await updateSoftwaresCategory({
            accessToken: accessToken,
            _id: softwareDetails?._id,
            softwareCategoryName: softwareDetails?.softwareCategoryName,
            serCategroyIcon: softwareDetails?.softwareIcon,
            sortOrder: softwareDetails?.sortOrder,
          })
        : await updateSoftware({
            accessToken: accessToken,
            _id: softwareCategoryID || softwareDetails?._id,
            softwareObjId: softwareDetails?.softwareObjId,
            softwareName: softwareDetails?.softwareName,
            softwareImageUrl: softwareDetails?.softwareIcon,
            softwareDescription: softwareDetails?.softwareDescription,
            sortOrder: softwareDetails?.sortOrder,
          });

      console.log("softResp", softResp);

      if (softResp?.data?.type === 1) {
        GetToast(
          onlyCategory
            ? "Software Category Successfully Updated !!"
            : "Software Successfully Updated !!"
        );
        handleUpdateCallback();
      } else {
        GetToast(softResp?.data?.message, "error");
        setModalIsOpen(false);
      }
    } else {
      const softResp = onlyCategory
        ? await saveSoftwaresCategory({
            accessToken: accessToken,
            softwareCategoryName: softwareDetails?.softwareCategoryName,
            serCategroyIcon: softwareDetails?.softwareIcon,
            sortOrder: softwareDetails?.sortOrder,
          })
        : await saveSoftware({
            accessToken: accessToken,
            _id: softwareCategoryID || softwareDetails?._id,
            softwareName: softwareDetails?.softwareName,
            softwareImageUrl: softwareDetails?.softwareIcon,
            softwareDescription: softwareDetails?.softwareDescription,
            sortOrder: softwareDetails?.sortOrder,
          });

      if (softResp?.data?.type === 1) {
        GetToast(
          onlyCategory
            ? "Software Category Successfully Saved !!"
            : "Software Successfully Saved !!"
        );
        handleUpdateCallback();
      } else {
        GetToast(softResp?.data?.message, "error");
        setModalIsOpen(false);
      }
    }
    await getData(accessToken);
  };

  useEffect(() => {
    if (editingServicesRow) {
      setSoftwareDetails({
        softwareCategoryName: editingServicesRow?.softwareCategoryName,
        softwareName:
          editingServicesRow?.softwareName || editingServicesRow?.softwares?.softwareName,
        softwareDescription:
          editingServicesRow?.softwareDescription ||
          editingServicesRow?.softwares?.softwareDescription,
        softwareIcon: null,
        softwareObjId:
          editingServicesRow?.softwareObjId || editingServicesRow?.softwares?.softwareObjId,
        sortOrder: editingServicesRow?.sortOrder,
      });
      setSoftwareCategoryText(editingServicesRow?.softwareCategoryName);
      handleComboBoxChange(editingServicesRow?._id);
    }
  }, [editingServicesRow]);

  console.log('editingServicesRow', editingServicesRow)

  return (
    <div className="h-full">
      <div className=" h-full flex flex-col">
        {onlyCategory ? (
          <div className="flex-1 pt-8 pb-8">
            <div className="relative">
              <ServiceIconInputField
                labelText={"Software Icon"}
                labelColor={"#777"}
                errorMessage={""}
                placeholder={"Select Service Icon"}
                handleIconFileUpload={handleIconFileUpload}
                initialFiles={editingServicesRow?.serCategroyIcon ?? ""}
              />
            </div>
            <div className="relative">
              <TextInputField
                labelText={"Software Category Name*"}
                labelColor={"#777"}
                // errorMessage={"this is error"}
                placeholder={"Enter Software Category Name"}
                name={"softwareCategoryName"}
                value={softwareDetails?.softwareCategoryName}
                handleInputChange={handleChange}
                // isSubmitted={isSubmitted}
                // validateName={validateName}
                // isFieldRequired={isFieldRequired("serviceCode")}
              />
            </div>
            <div className="relative">
              <NumberInputField
                labelText={"Sort Order*"}
                labelColor={"#777"}
                errorMessage={"this is error"}
                placeholder={"Enter Sort Order"}
                name={"sortOrder"}
                value={softwareDetails?.sortOrder}
                handleInputChange={handleChange}
                validateName={validateSortOrder}
                isFieldRequired={true}
              />
            </div>
            {/* {softwareDetails?.softwareCategoryName !== "" && !optionLabels.includes(softwareDetails?.softwareCategoryName) && (
              <div
                onClick={async () => {
                  setSoftwareDetails((prev) => ({
                    ...prev,
                    softwareCategoryName: "",
                  }));
                  setIsCategorySave(true);
                  handleSaveSoftwareCategory();
                }}
                className="font-medium text-[17px] left-[18px] text-white py-2 px-4 bg-[#ff7e00] rounded-lg cursor-pointer mb-4"
              >
                {softwareCategoryLoading ? <AiOutlineLoading3Quarters /> : "+"}
              </div>
            )} */}
          </div>
        ) : (
          <div className="flex-1 pt-8 pb-8">
            {/* <div className="flex-1 pt-8 pb-8">
            <div className="flex gap-4 items-end">
              <div className="w-full">
                <ComboBoxInputField
                  labelClass="text-[16px] leading-4"
                  labelText={"Select Software Category"}
                  placeholder={"Select Category"}
                  labelColor={"#777"}
                  handleInputChange={handleComboBoxChange}
                  name={"softwareCategoryName"}
                  value={softwareDetails?.softwareCategoryName}
                  options={categoryOptions}
                  setSoftwareCategoryText={setSoftwareCategoryText}
                  setSoftwareDetails={setSoftwareDetails}
                />
              </div>
              {softwareDetails?.softwareCategoryName !== "" && !optionLabels.includes(softwareDetails?.softwareCategoryName) && (
                <div
                  onClick={async () => {
                    setSoftwareDetails((prev) => ({
                      ...prev,
                      softwareCategoryName: "",
                    }));
                    setIsCategorySave(true);
                    handleSaveSoftwareCategory();
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-4 bg-[#ff7e00] rounded-lg cursor-pointer mb-4"
                >
                  {softwareCategoryLoading ? <AiOutlineLoading3Quarters /> : "+"}
                </div>
              )}
            </div>
          </div> */}
            <div className="relative">
              <TextInputField
                labelText={"Software Name*"}
                labelColor={"#777"}
                // errorMessage={"this is error"}
                placeholder={"Enter Software Name"}
                name={"softwareName"}
                value={softwareDetails?.softwareName}
                handleInputChange={handleChange}
                // isSubmitted={isSubmitted}
                // validateName={validateName}
                // isFieldRequired={isFieldRequired("serviceCode")}
              />
            </div>
            {/* <div className="relative">
            <TextInputField
              labelText={"Software Description*"}
              labelColor={"#777"}
              // errorMessage={"this is error"}
              placeholder={"Enter Software Description"}
              name={"softwareDescription"}
              value={softwareDetails?.softwareDescription}
              handleInputChange={handleChange}
              // isSubmitted={isSubmitted}
              // validateName={validateName}
              // isFieldRequired={isFieldRequired("serviceCode")}
            />
          </div> */}
            <div className="relative">
              <ServiceIconInputField
                labelText={"Software Icon"}
                labelColor={"#777"}
                errorMessage={""}
                placeholder={"Select Service Icon"}
                handleIconFileUpload={handleIconFileUpload}
                initialFiles={editingServicesRow?.softwares?.softwareImageUrl ?? ""}
              />
            </div>
            <div className="relative">
              <NumberInputField
                labelText={"Sort Order*"}
                labelColor={"#777"}
                errorMessage={"this is error"}
                placeholder={"Enter Sort Order"}
                name={"sortOrder"}
                value={softwareDetails?.sortOrder}
                handleInputChange={handleChange}
                validateName={validateSortOrder}
                isFieldRequired={true}
              />
            </div>
          </div>
        )}

        <div className="flex items-center justify-end gap-4">
          <button
            onClick={() => setModalIsOpen(false)}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={softwareIconLoading}
            onClick={() => {
              if (!softwareIconLoading) handleSubmit();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl h-full"
          >
            {softwareIconLoading ? (
              <BiLoaderAlt className="animate-spin" />
            ) : (
              "Update"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputFieldSoftwareTab;

export function TextInputField({
  labelText,
  labelClass,
  inputClass,
  labelColor,
  errorMessage,
  placeholder,
  isFieldRequired,
  name,
  value,
  handleInputChange,
  isSubmitted,
  validateName,
}) {
  return (
    <>
      <label
        htmlFor="name"
        style={{
          color: `${isSubmitted && !validateName(name) ? "border-red-500" : ""}`,
        }}
        className={`text-[16px] text-white leading-4 ${labelClass ?? ""}`}
      >
        {labelText}
      </label>

      <input
        type="text"
        id="name"
        name={name}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={`border p-2 w-full text-[#fff] bg-black text-[16px] leading-4 mt-2 mb-4 rounded-md h-10  ${
          inputClass ?? ""
        } ${isSubmitted && !validateName(name) ? "border-red-500" : ""}`}
        required={isFieldRequired}
      />
      {isSubmitted && !validateName(name) && isFieldRequired && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">{errorMessage}</p>
      )}
    </>
  );
}

export function ServiceIconInputField({
  labelText,
  labelColor,
  handleIconFileUpload,
  isEditing,
  initialFiles,
}) {
  return (
    <>
      <label htmlFor="categoryIcon" className="text-[16px] text-white leading-4">
        {labelText}
      </label>
      <FileUploadComponent
        maxFiles={1}
        acceptedFileTypes={["image/jpeg", "image/png"]}
        onUpload={handleIconFileUpload}
        allowMultipleFiles={false}
        initialFiles={initialFiles ? [{ source: initialFiles, options: { type: "local" } }] : []}
      />
    </>
  );
}

export function ComboBoxInputField({
  htmlFor,
  labelColor,
  labelClass,
  labelText,
  isFieldRequired,
  name,
  value,
  placeholder,
  handleInputChange,
  options,
  setSoftwareCategoryText,
  setSoftwareDetails,
}) {
  const [isActive, setIsActive] = useState(false);
  const [searchText, setSearchText] = useState(value || "");
  const [selectedOptions, setSelectedOptions] = useState(options);

  console.log("value", value);
  console.log("searchText", searchText);

  useEffect(() => {
    const eventHandler = (e) => {
      let container = document.querySelector(".combobox-container");
      if (container && !container?.contains(e.target)) {
        setIsActive(false);
        setSelectedOptions(options);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => {
      document.removeEventListener("mouseup", eventHandler);
    };
  });

  const searchContent = () => {
    if (searchText) {
      console.log("options", options);
      setSelectedOptions(
        options.filter((d) => d?.label?.toLowerCase()?.includes(searchText?.toLowerCase()))
      );
    } else {
      setSelectedOptions(options);
    }
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      searchContent();
    }, 300);

    return () => clearTimeout(debounce);
  }, [searchText]);

  const handleFocus = () => {
    setIsActive(true);
  };

  return (
    <div className="combobox-container relative text-white">
      <label htmlFor={htmlFor} className={`text-white ${labelClass}`}>
        {labelText}
        {isFieldRequired ? <span className="text-red-500">*</span> : ""}
      </label>
      <div
        className={`relative rounded-md border bg-black text-[16px] leading-4 mt-2 mb-4 px-2 w-full ${
          isActive ? "rounded-b-none" : ""
        }`}
        onClick={handleFocus}
      >
        <input
          type="text"
          id={name}
          name={name}
          value={value}
          onChange={(e) => {
            setSearchText(e.target.value);
            setSoftwareCategoryText(e.target.value);
            setSoftwareDetails((prev) => ({
              ...prev,
              softwareCategoryName: e.target.value,
            }));
          }}
          placeholder={placeholder}
          className={"p-2 w-full bg-transparent outline-none h-10"}
        />
        {isActive && selectedOptions?.length > 0 && (
          <ul className="absolute bottom-0 left-0 border border-t-[1px] w-full translate-y-[100%] z-20">
            {selectedOptions?.map((option) => (
              <li
                key={option.value}
                value={option.value}
                className="px-3 py-3 bg-[#1c1c1c] cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  handleInputChange(option.value);
                  setSearchText(option.label);
                  setSoftwareDetails((prev) => ({
                    ...prev,
                    _id: option.value,
                    softwareCategoryName: option.label,
                  }));
                  setSoftwareCategoryText(option.value);
                  setIsActive(false);
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export function ServiceAssetsInputField({
  labelText,
  labelColor,
  handleAssetsFileUpload,
  isEditing,
  initialFiles,
}) {
  return (
    <>
      <label htmlFor="categoryIcon" style={{ color: labelColor }} className="text-[16px] leading-4">
        {labelText}
      </label>
      <FileUploadComponent
        maxFiles={10}
        acceptedFileTypes={["image/jpeg", "image/png", "video/mp4", "video/quicktime"]}
        onUpload={handleAssetsFileUpload}
        allowMultipleFiles={true}
        initialFiles={
          initialFiles
            ? initialFiles?.map((d) => ({
                source: d,
                options: { type: "local" },
              }))
            : []
        }
      />
    </>
  );
}
