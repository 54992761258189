import React from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import './RangeSlider.css';

const CustomRangeSlider = ({value, setValue}) => {
    return (
        <RangeSlider max={1000} value={value} onInput={setValue} id="range-slider-gradient" className='rangeSliderStyles'/>
    );
}

export default CustomRangeSlider;