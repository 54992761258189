import React from "react";

function OrderHistoryCard({
  avatarBgColor,
  avatarText,
  avatarFontSize,
  avatarColor,
  headingText,
  headingColor,
  headingFontSize,
  subHeadingText,
  subHeadingColor,
  subHeadingFontSize,
  dateHeadingColor,
  dateHeadingFontSize,
  dateHeadingText,
  date,
  dateFontSize,
  dateColor,
  focusBgColor,
  hoverBgColor,
}) {
  return (
    <div>
      <div
        style={{
          ":focus": {
            background: `${focusBgColor}`,
          },
          ":hover": {
            background: `${hoverBgColor}`,
          },
        }}
        className="bg-opacity-40 flex justify-between items-center p-4 rounded-md cursor-pointer"
      >
        <div>
          <div
            style={{
              backgroundColor: `${avatarBgColor}`,
              fontSize: `${avatarFontSize}`,
              color: `${avatarColor}`,
            }}
            className="rounded-full h-[34px] w-[34px] font-bold  leading-4 flex items-center justify-center"
          >
            {avatarText}
          </div>
        </div>
        <div className="flex-1 px-4">
          <p
            style={{ color: `${headingColor}`, fontSize: `${headingFontSize}` }}
            className="font-medium leading-4 mb-1"
          >
            {headingText}
          </p>
          <p
            style={{
              color: `${subHeadingColor}`,
              fontSize: `${subHeadingFontSize}`,
            }}
            className="leading-4"
          >
            {subHeadingText}
          </p>
        </div>
        <div className="">
          <p
            style={{
              color: `${dateHeadingColor}`,
              fontSize: `${dateHeadingFontSize}`,
            }}
            className="font-medium leading-4 text-right mb-1"
          >
            {dateHeadingText}
          </p>
          <p
            style={{
              color: `${dateColor}`,
              fontSize: `${dateFontSize}`,
            }}
            className="leading-4 font-medium"
          >
            {date}
          </p>
        </div>
      </div>
    </div>
  );
}

export default OrderHistoryCard;
