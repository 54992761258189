import { useEffect, useState } from "react";
import { TeethV2 } from "../Teeth-Comps/TeethV2";
import { Checkbox } from "../InputField-Comps/CheckboxField";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import classes from "../Teeth-Comps/Teeth.module.scss";

import interproximalContactsBroad from "../../Assets/images/interproximalContactsBroad.jpg";
import interproximalContactsNormal from "../../Assets/images/interproximalContactsNormal.jpg";
import interproximalContactsPoint from "../../Assets/images/interproximalContactsPoint.jpg";
import occlusalContactsPositive from "../../Assets/images/occlusalContactsPositive.jpg";
import occlusalContactsFoilRelief1 from "../../Assets/images/occlusalContactsFoilRelief1.jpg";
import occlusalContactsFoilRelief2 from "../../Assets/images/occlusalContactsFoilRelief2.jpg";
import occlusalContactsFoilRelief3 from "../../Assets/images/occlusalContactsFoilRelief3.jpg";
import ponticDesignSanitary from "../../Assets/images/ponticDesignSanitary.png";
import ponticDesignSaddle from "../../Assets/images/ponticDesignSaddle.png";
import ponticDesignConical from "../../Assets/images/ponticDesignConical.png";
import ponticDesignModified from "../../Assets/images/ponticDesignModified.png";
import ponticDesignOvate from "../../Assets/images/ponticDesignOvate.png";
import RangeInputField from "../InputField-Comps/RangeInputField";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";
import { useGetSoftwareCategoryByNameMutation } from "../../services/softwareCategory";
import { findExtraElement, updateTeethMapping } from "../../Utils/utility";
import { useDeleteOrderMissingTeethMutation, useSaveOrderMissingTeethMutation } from "../../services/orders";

export const ImplantCaseDetailsSection = ({
  setActiveTab,
  orderCaseData,
  orderByData,
  setOrderUpdated,
  serviceObjId,
  submitOrderData,
  selectedServiceCategory,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [saveOrderMissingTeeth] = useSaveOrderMissingTeethMutation();
  const [deleteOrderMissingTeeth] = useDeleteOrderMissingTeethMutation();

  const [hasMissingTeeth, setHasMissingTeeth] = useState(
    orderCaseData?.missingTeeth?.hasMissingTeeth === null
      ? ""
      : orderCaseData?.missingTeeth?.hasMissingTeeth
      ? "Yes"
      : "No"
  );
  const [missingTeethSelectedTeeth, setMissingTeethSelectedTeeth] = useState(
    orderCaseData?.missingTeeth?.teeth || []
  );
  // eslint-disable-next-line no-unused-vars
  const [additionalInstructions, setSpecialInstructions] = useState(
    orderCaseData?.additionalInstructions || ""
  );
  const [interproximalContacts, setInterproximalContacts] = useState(
    orderCaseData?.interproximalContacts || ""
  );
  const [occlusalContacts, setOcclusalContacts] = useState(orderCaseData?.occlusalContacts || "");
  const [ponticDesign, setPonticDesign] = useState(orderCaseData?.ponticDesign || "");
  const [cementGap, setCementGap] = useState(orderCaseData?.cementGap || 0);
  const [printingCementGap, setPrintingCementGap] = useState(
    orderCaseData?.processType?.preferredRestorativeSpace || 0
  );
  const [optionsValue, setOptionsValue] = useState(orderCaseData?.processType?.process || "");
  const [provideDesignFiles, setProvideDesignFiles] = useState(
    orderCaseData?.designFileNeeded === null ? "" : orderCaseData?.designFileNeeded ? "Yes" : "No"
  );
  const [designPreview, setDesignPreview] = useState(
    orderCaseData?.needDesignPreview === null ? "" : orderCaseData?.needDesignPreview ? "Yes" : "No"
  );
  const [preferredDesignSoftware, setPreferredDesignSoftware] = useState(
    orderCaseData?.preferredSoftware || ""
  );
  const [constructionFileNeeded, setConstructionFileNeeded] = useState(
    orderCaseData?.constructionFileNeeded === null
      ? ""
      : orderCaseData?.constructionFileNeeded
      ? "Yes"
      : "No"
  );
  const [printableModelNeeded, setPrintableModelNeeded] = useState(
    orderCaseData?.printableModelRequired?.modelRequired === null
      ? ""
      : orderCaseData?.printableModelRequired?.modelRequired
      ? "Yes"
      : "No"
  );
  const [singleRestorationDesign, setSingleRestorationDesign] = useState(
    orderCaseData?.singleRestorationDesign || ""
  );
  const [printableModelWithDies, setPrintableModelWithDies] = useState(
    orderCaseData?.printableModelRequired?.includeDies === null
      ? ""
      : orderCaseData?.printableModelRequired?.includeDies
      ? "Yes"
      : "No"
  );
  const [occlusalClearanceAction, setOcclusalClearanceAction] = useState(
    orderCaseData?.restorationLacksOC || ""
  );
  const [preferredSoftwares, setPreferredSoftwares] = useState([]);
  const [typeOfCrown, setTypeOfCrown] = useState(orderCaseData?.crownType || []);
  const [typeOfVeneer, setTypeOfVeneer] = useState(orderCaseData?.veneerType || []);

  const [selectedService, setSelectedService] = useState("");
  const [restorativeTypeTeethSelected, setRestorativeTypeTeethSelected] = useState(
    orderCaseData?.services?.reduce(
      (acc, curr) => ({ ...acc, [curr?.serviceObjId]: curr?.teeth }),
      {}
    )
  );

  const [notation, setNotation] = useState("FDI");

  const handleNotationChange = (event) => {
    setNotation(event.target.value);
    setMissingTeethSelectedTeeth(updateTeethMapping(missingTeethSelectedTeeth, event.target.value));
    if (restorativeTypeTeethSelected[selectedService?.serviceObjId]?.length > 0) {
      setRestorativeTypeTeethSelected({
        ...restorativeTypeTeethSelected,
        [selectedService?.serviceObjId]: updateTeethMapping(
          restorativeTypeTeethSelected[selectedService?.serviceObjId],
          event.target.value
        ),
      });
    }
  };

  const [getSoftwareCategoryByName] = useGetSoftwareCategoryByNameMutation();

  console.log("restorativeTypeTeethSelected", restorativeTypeTeethSelected);
  console.log("selectedService", selectedService);
  console.log("selectedServiceCategory", selectedServiceCategory);
  console.log("preferredSoftwares", preferredSoftwares);

  const readSoftwareCategoryInfo = async (accessToken) => {
    const response = await getSoftwareCategoryByName({
      accessToken,
      softwareCategoryName: "CAD",
    }).unwrap();
    if (response?.data) {
      const softwaresList = response?.data;
      if (softwaresList?.length > 0) setPreferredSoftwares(softwaresList[0]?.softwares);
      else setPreferredSoftwares([]);
    }
  };

  const readSoftwareCategoriesInfoDeatails = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await readSoftwareCategoryInfo(accessToken);
  };

  const prepareServicesElements = () => {
    if (selectedServiceCategory?.catservices?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      selectedServiceCategory?.catservices?.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            selectedIcon={
              restorativeTypeTeethSelected.hasOwnProperty(eachService?.serviceObjId) &&
              restorativeTypeTeethSelected[eachService?.serviceObjId]?.length > 0
            }
            handleSelectedIcon={() => {
              console.log("restorativeTypeTeethSelected", restorativeTypeTeethSelected);
              setRestorativeTypeTeethSelected((prev) => {
                const copy = JSON.parse(JSON.stringify(prev));
                delete copy[eachService?.serviceObjId];
                console.log("eachService?.serviceObjId", eachService?.serviceObjId);
                console.log("copy", copy);
                console.log("prev", prev);
                return copy;
              });
            }}
            id={serviceObjId}
            active={selectedService?.serviceObjId === eachService?.serviceObjId}
            image={eachService?.serviceIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serviceName}
            serviceDescription={eachService?.serviceDescription}
            clickHandler={() => setSelectedService(eachService)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  const getRestrictedTeeth = (serviceObjId) => {
    const restrictedTeethData = [];
    Object.keys(restorativeTypeTeethSelected)
      .filter((d) => d !== serviceObjId)
      .forEach((d) => {
        restrictedTeethData.push(...restorativeTypeTeethSelected[d]);
      });
    return restrictedTeethData;
  };

  useEffect(() => {
    readSoftwareCategoriesInfoDeatails();
  }, []);

  const validateInput = (value) => {
    return value?.trim().length > 0;
  };

  const validateNumber = (value) => {
    return Number(value) ? true : false;
  };

  const handleHasMissingTeethChange = (event) => {
    setHasMissingTeeth(event.target.value);
  };

  const handlePrintableModelWithDiesCheckboxChange = (event) => {
    setPrintableModelWithDies(event.target.value);
  };
  const handleOcclusalClearanceActionCheckboxChange = (event) => {
    setOcclusalClearanceAction(event.target.value);
  };
  const handlePrintableModelNeededCheckboxChange = (event) => {
    setPrintableModelNeeded(event.target.value);
  };
  const handleSingleRestorationCheckboxChange = (event) => {
    setSingleRestorationDesign(event.target.value);
  };
  const handleProvideDesignFilesCheckboxChange = (event) => {
    setProvideDesignFiles(event.target.value);
  };
  const handleDesignPreviewCheckboxChange = (event) => {
    setDesignPreview(event.target.value);
  };
  const handleConstructionFileNeededCheckboxChange = (event) => {
    setConstructionFileNeeded(event.target.value);
  };
  const handlePreferredDesignSoftwareCheckboxChange = (event) => {
    setPreferredDesignSoftware(event.target.value);
  };

  const handleInterproximalContactsCheckboxChange = (event) => {
    setInterproximalContacts(event.target.value);
  };

  const handleOcclusalContactsCheckboxChange = (event) => {
    setOcclusalContacts(event.target.value);
  };

  const handlePonticContactsCheckboxChange = (event) => {
    setPonticDesign(event.target.value);
  };

  const handleOptionsCheckboxChange = (event) => {
    setOptionsValue(event.target.value);
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      "additionalInstructions",
      "hasMissingTeeth",
      "interproximalContacts",
      "occlusalContacts",
      "designPreview",
      "optionsValue",
      "printingCementGap",
      "provideDesignFiles",
      "preferredDesignSoftware",
      "constructionFileNeeded",
      "printableModelNeeded",
      "printableModelWithDies",
      "occlusalClearanceAction",
    ];

    return requiredFields.includes(fieldName);
  };

  const handleTypeOfCrownCheckbox = (value, checked) => {
    if (checked) {
      setTypeOfCrown((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
      setTypeOfCrown((prevSelectedValues) =>
        prevSelectedValues.filter((selectedValue) => selectedValue !== value)
      );
    }
  };

  const handleTypeOfVeneerCheckbox = (value, checked) => {
    if (checked) {
      setTypeOfVeneer((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
      setTypeOfVeneer((prevSelectedValues) =>
        prevSelectedValues.filter((selectedValue) => selectedValue !== value)
      );
    }
  };

  const arraysHaveSameValues = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "additionalInstructions") setSpecialInstructions(value);
    if (name === "cementGap") setCementGap(value);
    if (name === "printingCementGap") setPrintingCementGap(value);
  };
  const submitCaseDetailsForm = async () => {
    // eslint-disable-next-line no-unused-vars
    // if (toothData?.length > 0) {
    // const overBiteSelectOptionsObj = {
    //   overBiteCorrtOptimize: false,
    //   overBiteCorrtMaintain: false,
    //   overBiteCorrtImprove1mm: false,
    //   overBiteCorrtImprove2mm: false,
    //   overBiteCorrtImprove3mm: false,
    //   overBiteCorrtImprove4mm: false,
    // };
    // const overjetSelectOptionsObj = {
    //   overJetCorrtOptimize: false,
    //   overJetCorrtMaintain: false,
    //   overJetCorrtImprove1mm: false,
    //   overJetCorrtImprove2mm: false,
    //   overJetCorrtImprove3mm: false,
    //   overJetCorrtImprove4mm: false,
    // };
    // for (const key of toothData) {
    //   if (overBiteSelectOptionsObj.hasOwnProperty(key)) {
    //     overBiteSelectOptionsObj[key] = true;
    //   }
    // }
    // for (const key of toothData) {
    //   if (overjetSelectOptionsObj.hasOwnProperty(key)) {
    //     overjetSelectOptionsObj[key] = true;
    //   }
    // }
    // const isUpdated = arraysHaveSameValues(initialToothData, toothData);
    // const data = {
    //teeth: selectedTeeth,
    // iPRValue:
    //   iPRValue === "iprPosterior"
    //     ? { iprPosterior: true, iprAnterior: false }
    //     : { iprPosterior: false, iprAnterior: true },
    // overjetSelectOptions: overjetSelectOptionsObj,
    // overBiteSelectOptions: overBiteSelectOptionsObj,
    // crossBiteValue:
    //   crossBiteValue === "crossBiteCorrtMaintain"
    //     ? { crossBiteCorrtMaintain: true, crossBiteCorrtOptimize: false }
    //     : { crossBiteCorrtMaintain: false, crossBiteCorrtOptimize: true },
    // additionalInstructions: additionalInstructions,
    // };
    // const accessToken = await getAccessTokenSilently({
    //   authorizationParams: {
    //     audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
    //   },
    // });
    // if (orderByData?.ordertoothmovement?.length === 0) {
    //   const resp = await saveOrderToothMovData({
    //     accessToken,
    //     orderObjId: orderByData?._id,
    //     toothMovementData: data,
    //   });
    //   const respTeeth = await saveOrderTeethData({
    //     accessToken,
    //     orderObjId: orderByData?._id,
    //     // teeth: crownSelectedTeeth,
    //   });
    //   setOrderUpdated(true);
    //   setActiveTab("Upload Files");
    // } else {
    //   if (!isUpdated) {
    //     const resp = await updateOrderToothMovData({
    //       accessToken,
    //       _id: orderByData?.ordertoothmovement[0]?._id,
    //       toothMovementData: data,
    //     });
    //     if (resp?.data?.type === 1) {
    //       GetToast("Success", "");
    //       setOrderUpdated(true);
    //       setActiveTab("Upload Files");
    //     } else {
    //       GetToast("Error", "error");
    //     }
    //   } else {
    //     setActiveTab("Upload Files");
    //   }
    // }
    // }

    submitOrderData({
      missingTeeth: {
        hasMissingTeeth: hasMissingTeeth ? hasMissingTeeth === "Yes" : null,
        teeth: missingTeethSelectedTeeth,
      },
      services: Object.keys(restorativeTypeTeethSelected)?.map((item) => ({
        serviceObjId: item,
        teeth: restorativeTypeTeethSelected[item],
      })),
      crownType: typeOfCrown,
      veneerType: typeOfVeneer,
      cementGap: Number(cementGap),
      interproximalContacts: interproximalContacts,
      occlusalContacts: occlusalContacts,
      ponticDesign: ponticDesign,
      needDesignPreview: designPreview ? designPreview === "Yes" : null,
      processType: {
        process: optionsValue,
        preferredRestorativeSpace: Number(printingCementGap),
      },
      designFileNeeded: provideDesignFiles ? provideDesignFiles === "Yes" : null,
      preferredSoftware: preferredDesignSoftware,
      constructionFileNeeded: constructionFileNeeded ? constructionFileNeeded === "Yes" : null,
      printableModelRequired: {
        modelRequired: printableModelNeeded ? printableModelNeeded === "Yes" : null,
        includeDies: printableModelWithDies ? printableModelWithDies === "Yes" : null,
      },
      restorationLacksOC: occlusalClearanceAction,
      additionalInstructions: additionalInstructions,
    });
    setActiveTab("Upload Files");
  };

  const handleMissingTeethSelection = async (value) => {
    console.log("value", value);
    const actualMissingTeeth = structuredClone(missingTeethSelectedTeeth);
    let result = value(actualMissingTeeth || []);
    setMissingTeethSelectedTeeth(result);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let teethNo;
    if (result?.length > actualMissingTeeth?.length) {
      teethNo = findExtraElement(actualMissingTeeth, result);
      saveOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    } else if (result?.length < actualMissingTeeth?.length) {
      teethNo = findExtraElement(result, actualMissingTeeth);
      deleteOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    }
  };

  return (
    <div>
      <div className="w-10/12">
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            What tooth numbering system do you prefer?
            <span className="text-red-500">*</span>
          </p>
          <div className="flex">
            <div className={classes.radioButtons}>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="UTN"
                  checked={notation === "UTN"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                UTN
              </label>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="FDI"
                  checked={notation === "FDI"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                FDI
              </label>
            </div>
          </div>
          <p className="text-[16px] text-[#777] mb-8">
            The Universal tooth numbering (UTN) system (1-32) and Fédération Dentaire Internationale
            (FDI) system (11-48) are both internationally recognised numbering systems used in
            different parts of the world. Please select a preference that will then apply to all the
            tooth charts in this form.
          </p>
        </div>
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            Please advise us if the patient has any missing teeth{" "}
            {isFieldRequired("hasMissingTeeth") ? <span className="text-red-500">*</span> : ""}
          </p>
          <div className="flex">
            <div className="flex items-center mr-5">
              <CircularCheckbox
                value="Yes"
                checked={hasMissingTeeth === "Yes"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px] my-3">The patient has missing teeth</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={hasMissingTeeth === "No"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">The patient has no missing teeth</p>
            </div>
          </div>
          <div className="mx-5 mt-4">
            {hasMissingTeeth === "Yes" && (
              <>
                <p className="text-white text-[16px] mb-2">Please select missing teeth</p>
                <TeethV2
                  key={`missingTeethSelectedTeeth${notation}`}
                  allTeethData={[]}
                  selectedTeeth={missingTeethSelectedTeeth}
                  setSelectedTeeth={handleMissingTeethSelection}
                  defaultNotation={notation}
                  notationVisible={false}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="font-medium text-[24px] text-white mb-4">
            Implant Restoration Service Type
          </p>
          <p className="text-[16px] text-[#777] mb-8">
            Please click an implant restoration service type below and choose the implant sites that
            apply for that service type in the chart that appears.{" "}
            <span className="!text-white">
              Multiple implant restoration service types can be selected and multiple implant sites
              can be selected on each service type's charting system.
            </span>{" "}
            If you prefer not to use our charting system, you can also provide written instructions
            in the Additional Instructions column below.
          </p>
          {selectedServiceCategory?._id &&
            Object.keys(restorativeTypeTeethSelected).length !== "" && (
              <>
                <div className="CAROUSEL mb-8">
                  <div className="md:block hidden">
                    <CustomCarousel
                      rows={1}
                      cols={6}
                      gapY={20}
                      gapX={20}
                      completeSliders={prepareServicesElements()}
                      numberOfCardsShownInSingleSlide={6}
                    />
                  </div>
                  <div className="md:hidden block">
                    <CustomCarousel
                      rows={1}
                      cols={2}
                      gapY={20}
                      gapX={20}
                      completeSliders={prepareServicesElements()}
                      numberOfCardsShownInSingleSlide={2}
                    />
                  </div>
                </div>
              </>
            )}
        </div>
        {selectedService?.serviceObjId && (
          <>
            {restorativeTypeTeethSelected && (
              <>
                <p className="text-white text-[16px] mb-2">
                  Please select all implant sites as applicable
                </p>
                <TeethV2
                  key={
                    selectedService?.serviceObjId +
                    restorativeTypeTeethSelected[selectedService?.serviceObjId]?.length +
                    notation
                  }
                  allTeethData={[]}
                  disabledTeeths={getRestrictedTeeth(selectedService?.serviceObjId)}
                  disabledTeethMessage={"This tooth is already selected in other restorative type"}
                  selectedTeeth={restorativeTypeTeethSelected[selectedService?.serviceObjId] || []}
                  setSelectedTeeth={(value) => {
                    console.log("value", value);
                    let result = value(
                      restorativeTypeTeethSelected[selectedService?.serviceObjId] || []
                    );
                    console.log("result", result);
                    console.log("restorativeTypeTeethSelected", restorativeTypeTeethSelected);
                    setRestorativeTypeTeethSelected({
                      ...restorativeTypeTeethSelected,
                      [selectedService?.serviceObjId]: result,
                    });
                  }}
                  defaultNotation={notation}
                  notationVisible={false}
                  missingTeeth={missingTeethSelectedTeeth}
                  missingTeethSelectable={true}
                />
              </>
            )}
            <div className="py-4 flex gap-4">
              {[
                "Single or multiple implant crowns (screw retained)",
                "Single or multiple implant crowns (cemented)",
                "Implant bridge (screw retained)",
                "Implant bridge (cemented)",
                "Implant bridge with gingiva (screw retained)",
              ].includes(selectedService?.serviceName) && (
                <div className="w-full flex flex-col">
                  <p className="font-medium text-[17px] text-white mb-4">
                    {[
                      "Implant bridge (screw retained)",
                      "Implant bridge (cemented)",
                      "Implant bridge with gingiva (screw retained)",
                    ].includes(selectedService?.serviceName)
                      ? "Type of Bridge Retainer and Pontic"
                      : "Type of Crown"}{" "}
                    <small>(Optional)</small>
                  </p>
                  {selectedService?.serviceName ===
                    "Single or multiple implant crowns (cemented)" && (
                    <p className="text-[16px] text-[#777] mb-4">
                      All our cementable abutment restorations are designed as tissue level
                      abutments on a Ti base with a separate Ti base, abutment and restoration
                      depending on your selection below
                    </p>
                  )}
                  {selectedService?.serviceName === "Implant bridge (cemented)" && (
                    <p className="text-[16px] text-[#777] mb-4">
                      All our cementable abutment restorations are designed as tissue level
                      abutments on Ti bases with separate Ti bases, abutments and the restoration
                      depending on your selection below
                    </p>
                  )}
                  <div className="grid md:grid-flow-row grid-cols-1 grid-rows-1 md:gap-4 gap-4 text-white text-[16px] mx-3">
                    <Checkbox
                      label="Full Contour Design"
                      value="Full Contour Design"
                      checked={typeOfCrown.includes("Full Contour Design")}
                      onChange={handleTypeOfCrownCheckbox}
                    />
                    <Checkbox
                      label="Cut Back Design"
                      value="Cut Back Design"
                      checked={typeOfCrown.includes("Cut Back Design")}
                      onChange={handleTypeOfCrownCheckbox}
                    />

                    <Checkbox
                      label="Coping Design Only"
                      value="Coping Design Only"
                      checked={typeOfCrown.includes("Coping Design Only")}
                      onChange={handleTypeOfCrownCheckbox}
                    />
                  </div>
                </div>
              )}
              {[].includes(selectedService?.serviceName) && (
                <div className="w-1/2 flex flex-col">
                  <p className="font-medium text-[17px] text-white mb-4">
                    Type of Veneer <small>(Optional)</small>
                  </p>
                  <div className="grid md:grid-flow-row grid-cols-1 grid-rows-1 md:gap-4 gap-4 text-white text-[16px] mx-3">
                    <Checkbox
                      label="Full Contour Design"
                      value="Full Contour Design"
                      checked={typeOfVeneer.includes("Full Contour Design")}
                      onChange={handleTypeOfVeneerCheckbox}
                    />
                    <Checkbox
                      label="Cut Back Design"
                      value="Cut Back Design"
                      checked={typeOfVeneer.includes("Cut Back Design")}
                      onChange={handleTypeOfVeneerCheckbox}
                    />
                  </div>
                </div>
              )}
            </div>

            {[
              "Single or multiple implant crowns (screw retained)",
              "Single or multiple implant crowns (cemented)",
              "Implant bridge (screw retained)",
              "Implant bridge (cemented)",
              "Implant bridge with gingiva (screw retained)",
            ].includes(selectedService?.serviceName) && (
              <p className="text-[#777] mb-5 text-[16px]">
                (Indicate surfaces for cutback, if any, in your notes below)
              </p>
            )}

            {[
              "Single or multiple implant crowns (screw retained)",
              "Implant bridge (screw retained)",
              "Implant bridge with gingiva (screw retained)",
            ].includes(selectedService?.serviceName) && (
              <div className="text-white flex flex-col mb-8">
                <p className="mb-3">
                  {[
                    "Implant bridge (screw retained)",
                    "Implant bridge with gingiva (screw retained)",
                  ].includes(selectedService?.serviceName)
                    ? "Would you like a one piece restoration design (Ti bases and restoration) or a split restoration design (Ti bases and tissue level abutments and restoration)?"
                    : "Would you like a single restoration design (Ti base and restoration) or a split restoration design (Ti base and tissue level abutment and restoration)?"}
                  {isFieldRequired("singleRestorationDesign") ? (
                    <span className="text-red-500">*</span>
                  ) : (
                    ""
                  )}
                </p>
                <div className="flex">
                  {[
                    "Implant bridge (screw retained)",
                    "Implant bridge with gingiva (screw retained)",
                  ].includes(selectedService?.serviceName) ? (
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="One piece restoration"
                        checked={singleRestorationDesign === "One piece restoration"}
                        onChange={handleSingleRestorationCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">One piece restoration</p>
                    </div>
                  ) : (
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Single restoration"
                        checked={singleRestorationDesign === "Single restoration"}
                        onChange={handleSingleRestorationCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Single restoration</p>
                    </div>
                  )}
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="Split restoration"
                      checked={singleRestorationDesign === "Split restoration"}
                      onChange={handleSingleRestorationCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Split restoration</p>
                  </div>
                </div>
              </div>
            )}

            {[
              "Custom abutments",
              "Single or multiple implant crowns (screw retained)",
              "Single or multiple implant crowns (cemented)",
              "Implant bridge (screw retained)",
              "Implant bridge (cemented)",
              "Implant bridge with gingiva (screw retained)",
              "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
              "All on X hybrid with prep style posts for crowns",
              "All on X custom framework",
            ].includes(selectedService?.serviceName) && (
              <>
                <div className="w-1/2">
                  <RangeInputField
                    labelColor={"#fff"}
                    labelText={"Cement Gap (µ microns)"}
                    subtitle="If you are unsure what cement gap to request, please leave slider at 0µ. A default setting will then apply."
                    errorMessage={"Please enter a valid cement gap value"}
                    placeholder={""}
                    htmlFor={"cementGap"}
                    name={"cementGap"}
                    value={cementGap}
                    handleInputChange={handleInputChange}
                    validationFunctionName={validateNumber}
                    isSubmitted={isFieldRequired}
                    isFieldRequired={isFieldRequired("serviceSpecilization")}
                    unit="µ"
                    min={0}
                    max={200}
                  />
                </div>
              </>
            )}
            <div className="flex flex-col">
              {[
                "Single or multiple implant crowns (screw retained)",
                "Single or multiple implant crowns (cemented)",
                "Implant bridge (screw retained)",
                "Implant bridge (cemented)",
                "Implant bridge with gingiva (screw retained)",
                "All on X hybrid with prep style posts for crowns",
              ].includes(selectedService?.serviceName) && (
                <>
                  <p className="font-medium text-[17px] text-white mb-2">
                    Interproximal Contacts{" "}
                    {isFieldRequired("interproximalContacts") ? (
                      <span className="text-red-500">*</span>
                    ) : (
                      ""
                    )}
                  </p>
                  <div className="flex-1 mt-4 mb-4 grid grid-cols-5 md:gap-12 gap-4 mx-3">
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value="Broad"
                        checked={interproximalContacts === "Broad"}
                        onChange={handleInterproximalContactsCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={interproximalContactsBroad}
                        alt="interproximalContactsBroad"
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value="Normal"
                        checked={interproximalContacts === "Normal"}
                        onChange={handleInterproximalContactsCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={interproximalContactsNormal}
                        alt="interproximalContactsBroad"
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value="Point"
                        checked={interproximalContacts === "Point"}
                        onChange={handleInterproximalContactsCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={interproximalContactsPoint}
                        alt="interproximalContactsBroad"
                      />
                    </div>
                  </div>
                </>
              )}
              {[
                "Single or multiple implant crowns (screw retained)",
                "Single or multiple implant crowns (cemented)",
                "Implant bridge (screw retained)",
                "Implant bridge (cemented)",
                "Implant bridge with gingiva (screw retained)",
                "Implant bar substructure for over denture",
                "All on X Hybrid (monolithic zirconia)",
                "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                "All on X hybrid with prep style posts for crowns",
                "All on X custom framework",
              ].includes(selectedService?.serviceName) && (
                <>
                  <p className="font-medium text-[17px] text-white mb-1 mt-3">
                    Occlusal Contacts{" "}
                    {isFieldRequired("occlusalContacts") ? (
                      <span className="text-red-500">*</span>
                    ) : (
                      ""
                    )}
                  </p>
                  <div className="flex-1 mt-4 mb-4 grid grid-cols-5 md:gap-12 gap-4 mx-3">
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value="Foil Relief 1"
                        checked={occlusalContacts === "Foil Relief 1"}
                        onChange={handleOcclusalContactsCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={occlusalContactsFoilRelief1}
                        alt="Foil Relief 1"
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value="Foil Relief 2"
                        checked={occlusalContacts === "Foil Relief 2"}
                        onChange={handleOcclusalContactsCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={occlusalContactsFoilRelief2}
                        alt="Foil Relief 2"
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value="Foil Relief 3"
                        checked={occlusalContacts === "Foil Relief 3"}
                        onChange={handleOcclusalContactsCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={occlusalContactsFoilRelief3}
                        alt="Foil Relief 3"
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value="Positive"
                        checked={occlusalContacts === "Positive"}
                        onChange={handleOcclusalContactsCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={occlusalContactsPositive}
                        alt="Positive"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {[
              "Implant bridge (screw retained)",
              "Implant bridge (cemented)",
              "Implant bridge with gingiva (screw retained)",
              "Implant bar substructure for over denture",
              "All on X Hybrid (monolithic zirconia)",
              "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
              "All on X hybrid with prep style posts for crowns",
              "All on X custom framework",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-[17px] text-white mb-1 mt-5">
                  {[
                    "Implant bar substructure for over denture",
                    "All on X Hybrid (monolithic zirconia)",
                    "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                    "All on X hybrid with prep style posts for crowns",
                    "All on X custom framework",
                  ].includes(selectedService?.serviceName)
                    ? "Tissue Interface Design"
                    : "Pontic Design"}{" "}
                  <small>(Optional)</small>
                </p>
                <div className="flex-1 mt-4 mb-8 grid grid-cols-5 md:gap-12 gap-4 mx-3">
                  <div className="flex flex-col items-center">
                    <CircularCheckbox
                      value="Sanitary/Hygenic"
                      checked={ponticDesign === "Sanitary/Hygenic"}
                      onChange={handlePonticContactsCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <img
                      className="mt-1 rounded-md overflow-hidden"
                      src={ponticDesignSanitary}
                      alt="interproximalContactsBroad"
                    />
                    {/* <p className="text-[16px] text-white">Sanitary/Hygenic</p> */}
                  </div>
                  <div className="flex flex-col items-center">
                    <CircularCheckbox
                      value="Saddle-ridge-lap"
                      checked={ponticDesign === "Saddle-ridge-lap"}
                      onChange={handlePonticContactsCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <img
                      className="mt-1 rounded-md overflow-hidden"
                      src={ponticDesignSaddle}
                      alt="interproximalContactsBroad"
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <CircularCheckbox
                      value="Conical"
                      checked={ponticDesign === "Conical"}
                      onChange={handlePonticContactsCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <img
                      className="mt-1 rounded-md overflow-hidden"
                      src={ponticDesignConical}
                      alt="interproximalContactsBroad"
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <CircularCheckbox
                      value="Modified-ridge-lap"
                      checked={ponticDesign === "Modified-ridge-lap"}
                      onChange={handlePonticContactsCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <img
                      className="mt-1 rounded-md overflow-hidden"
                      src={ponticDesignModified}
                      alt="interproximalContactsBroad"
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <CircularCheckbox
                      value="Ovate"
                      checked={ponticDesign === "Ovate"}
                      onChange={handlePonticContactsCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <img
                      className="mt-1 rounded-md overflow-hidden"
                      src={ponticDesignOvate}
                      alt="interproximalContactsBroad"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="text-white flex mb-8">
              ● Do you need to see a design preview?{" "}
              {isFieldRequired("designPreview") ? <span className="text-red-500">*</span> : ""}
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="Yes"
                  checked={designPreview === "Yes"}
                  onChange={handleDesignPreviewCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="No"
                  checked={designPreview === "No"}
                  onChange={handleDesignPreviewCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>
            {[
              "Single or multiple implant crowns (screw retained)",
              "Single or multiple implant crowns (cemented)",
              "Implant bridge (screw retained)",
              "Implant bridge (cemented)",
              "Implant bridge with gingiva (screw retained)",
              "Implant bar substructure for over denture",
              "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
              "All on X hybrid with prep style posts for crowns",
              "All on X custom framework",
            ].includes(selectedService?.serviceName) && (
              <div className="mb-4">
                <div className="mb-4">
                  <p className="text-[17px] text-white mb-3">
                    ●{" "}
                    {[
                      "Implant bar substructure for over denture",
                      "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                      "All on X custom framework",
                    ].includes(selectedService?.serviceName)
                      ? "The substructure is always designed to be milled in our designs. With reference to the superstructure, will you be"
                      : selectedService?.serviceName ===
                        "All on X hybrid with prep style posts for crowns"
                      ? "The substructure is always designed to be milled in our designs. With reference to the crowns, will you be"
                      : "Will you be"}
                    {isFieldRequired("optionsValue") ? <span className="text-red-500">*</span> : ""}
                  </p>
                  <div className="flex gap-y-4 gap-x-8 text-white">
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Milling"
                        checked={optionsValue === "Milling"}
                        onChange={handleOptionsCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Milling</p>
                    </div>
                    <span>or</span>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Printing"
                        checked={optionsValue === "Printing"}
                        onChange={handleOptionsCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]"> Printing</p>
                    </div>
                  </div>
                </div>
                {optionsValue === "Printing" && (
                  <div className="w-1/2">
                    <RangeInputField
                      labelColor={"#777"}
                      labelText={
                        "If you are printing what restorative space do you prefer? (µ microns)"
                      }
                      subtitle={
                        "If you are unsure what restorative space to request, please leave slider at 0µ. A default setting will then apply."
                      }
                      errorMessage={"Please enter a valid value"}
                      placeholder={""}
                      htmlFor={"printingCementGap"}
                      name={"printingCementGap"}
                      value={printingCementGap}
                      handleInputChange={handleInputChange}
                      validationFunctionName={validateNumber}
                      isSubmitted={isFieldRequired}
                      isFieldRequired={isFieldRequired("printingCementGap")}
                      unit="µ"
                      min={0}
                      max={200}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="text-white flex mb-2">
              ● Design file to be provided?{" "}
              {isFieldRequired("provideDesignFiles") ? <span className="text-red-500">*</span> : ""}
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="Yes"
                  checked={provideDesignFiles === "Yes"}
                  onChange={handleProvideDesignFilesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="No"
                  checked={provideDesignFiles === "No"}
                  onChange={handleProvideDesignFilesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>
            <p className="text-[#777] text-[16px] mb-8">
              This is a file in the original design software's format and you will need the
              appropriate design software to open it. Please do not request it unless you have the
              software and know how to manipulate the file.
            </p>
            {preferredSoftwares?.length > 0 && (
              <div className="text-white flex flex-col mb-8">
                <p>
                  ● Preferred design software?{" "}
                  {isFieldRequired("preferredDesignSoftware") ? (
                    <span className="text-red-500">*</span>
                  ) : (
                    ""
                  )}
                </p>
                <div className="grid grid-cols-5 gap-y-3 mt-1">
                  {preferredSoftwares
                    ?.filter(
                      (d) =>
                        !["MeditCAD", "Dentbird", "Clinux", "Hypsocad"].includes(d?.softwareName)
                    )
                    ?.map((item) => {
                      return (
                        <div className="flex items-center mx-3" key={item?.softwareObjId}>
                          <CircularCheckbox
                            value={item?.softwareName}
                            checked={preferredDesignSoftware === item?.softwareName}
                            onChange={handlePreferredDesignSoftwareCheckboxChange}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">{item?.softwareName}</p>
                        </div>
                      );
                    })}
                  <div className="flex items-center mx-3 col-span-1">
                    <CircularCheckbox
                      value="No Preferance"
                      checked={preferredDesignSoftware === "No Preferance"}
                      onChange={handlePreferredDesignSoftwareCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No Preference</p>
                  </div>
                </div>
              </div>
            )}
            {[
              "Custom abutments",
              "Single or multiple implant crowns (screw retained)",
              "Single or multiple implant crowns (cemented)",
              "Implant bridge (screw retained)",
              "Implant bridge (cemented)",
              "Implant bridge with gingiva (screw retained)",
              "Implant bar substructure for over denture",
              "All on X Hybrid (monolithic zirconia)",
              "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
              "All on X hybrid with prep style posts for crowns",
              "All on X custom framework",
            ].includes(selectedService?.serviceName) && (
              <div className="text-white flex flex-col mb-8">
                <p className="mb-3">
                  ●{" "}
                  {[
                    "Implant bar substructure for over denture",
                    "All on X Hybrid (monolithic zirconia)",
                    "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                    "All on X hybrid with prep style posts for crowns",
                    "All on X custom framework",
                  ].includes(selectedService?.serviceName)
                    ? "Restorations, superstructures and substructures are normally provided as .STLs, Please advise if a construction.info file is desired."
                    : "Restorations are normally provided as .STLs, Please advise if a construction.info file is desired."}{" "}
                  {isFieldRequired("constructionFileNeeded") ? (
                    <span className="text-red-500">*</span>
                  ) : (
                    ""
                  )}
                </p>
                <div className="flex">
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={constructionFileNeeded === "Yes"}
                      onChange={handleConstructionFileNeededCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={constructionFileNeeded === "No"}
                      onChange={handleConstructionFileNeededCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
              </div>
            )}
            <div className="mb-6">
              <div className="text-white flex flex-col mb-4">
                <p className="mb-3">
                  ● Is a printable model desired?{" "}
                  <span className="text-[16px]">(additional cost)</span>
                  {isFieldRequired("printableModelNeeded") ? (
                    <span className="text-red-500">*</span>
                  ) : (
                    ""
                  )}
                </p>
                <div className="flex">
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={printableModelNeeded === "Yes"}
                      onChange={handlePrintableModelNeededCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={printableModelNeeded === "No"}
                      onChange={handlePrintableModelNeededCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
              </div>
              {printableModelNeeded === "Yes" && (
                <div className="text-white flex mb-4 mx-3">
                  Should the crown and bridge model include dies?{" "}
                  <span className="text-[16px]">(additional cost)</span>
                  {isFieldRequired("printableModelWithDies") ? (
                    <span className="text-red-500">*</span>
                  ) : (
                    ""
                  )}
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={printableModelWithDies === "Yes"}
                      onChange={handlePrintableModelWithDiesCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={printableModelWithDies === "No"}
                      onChange={handlePrintableModelWithDiesCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
              )}
            </div>
            <div className="text-white flex flex-col mb-8">
              <p className="mb-3">
                ● If a certain restoration lacks occlusal clearance? Please advise your preferred
                course of action:{" "}
                {isFieldRequired("occlusalClearanceAction") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <div className="flex flex-col mx-3">
                <div className="flex items-center mb-2">
                  <CircularCheckbox
                    value="Will reduce antagonist later, no advise needed"
                    checked={
                      occlusalClearanceAction === "Will reduce antagonist later, no advise needed"
                    }
                    onChange={handleOcclusalClearanceActionCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">
                    Will reduce antagonist later, no advise needed
                  </p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Reduce model and provide printable reduction guide STL file"
                    checked={
                      occlusalClearanceAction ===
                      "Reduce model and provide printable reduction guide STL file"
                    }
                    onChange={handleOcclusalClearanceActionCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">
                    Reduce model and provide printable reduction guide STL file (additional cost)
                  </p>
                </div>
              </div>
            </div>
            <div>
              <TextAreaInputField
                labelColor={"#777"}
                labelText={"Additional Instructions"}
                errorMessage={"This field is required"}
                placeholder={"Please enter additional instructions here"}
                isFieldRequired={isFieldRequired("additionalInstructions")}
                htmlFor={"additionalInstructions"}
                name={"additionalInstructions"}
                value={additionalInstructions}
                handleInputChange={handleInputChange}
                // validationFunctionName={validatePatientConcern}
                // isSubmitted={isPatientInitialFormSubmitted}
                rows={4}
                cols={5}
              />
              {additionalInstructions?.trim()?.length > 0 ? (
                <></>
              ) : (
                <p className="text-sm text-red-500">This field is required</p>
              )}
            </div>
          </>
        )}
      </div>
      <div>
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab("Patient Details");
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              submitCaseDetailsForm();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
};
