import React, { useEffect, useState } from "react";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import { TeethV2 } from "../Teeth-Comps/TeethV2";
import RangeInputField from "../InputField-Comps/RangeInputField";
import classes from "../Teeth-Comps/Teeth.module.scss";
import { findExtraElement, updateTeethMapping } from "../../Utils/utility";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";
import { useGetSoftwareCategoryByNameMutation } from "../../services/softwareCategory";
import { useAuth0 } from "@auth0/auth0-react";
import { useDeleteOrderMissingTeethMutation, useSaveOrderMissingTeethMutation } from "../../services/orders";

function OrthodonticCaseDetailsSection({
  setActiveTab,
  orderCaseData,
  orderByData,
  setOrderUpdated,
  serviceObjId,
  submitOrderData,
  selectedServiceCategory,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [saveOrderMissingTeeth] = useSaveOrderMissingTeethMutation();
  const [deleteOrderMissingTeeth] = useDeleteOrderMissingTeethMutation();

  const [midline, setMidline] = useState(orderCaseData?.midline || []);
  const [molarOcclusalGoals, setMolarOcclusalGoals] = useState(
    orderCaseData?.molarOcclusalGoals || ""
  );
  const [selectedValue, setSelectedValue] = useState(orderCaseData?.archesToTreat || "");

  const [optionsValue, setOptionsValue] = useState(orderCaseData?.options || "");
  const [clinicalValues, setClinicalValues] = useState(orderCaseData?.clinicalConditions || []);
  const [printableAligners, setPrintableAligners] = useState(
    orderCaseData?.printableAligners || ""
  );
  const [printableRetainer, setPrintableRetainer] = useState(
    orderCaseData?.printableRetainer || ""
  );

  const [hasMissingTeeth, setHasMissingTeeth] = useState(
    orderCaseData?.missingTeeth?.hasMissingTeeth === null
      ? ""
      : orderCaseData?.missingTeeth?.hasMissingTeeth
      ? "Yes"
      : "No"
  );
  const [missingTeethSelectedTeeth, setMissingTeethSelectedTeeth] = useState(
    orderCaseData?.missingTeeth?.teeth || []
  );
  const [teethNotToBeMoved, setTeethNotToBeMoved] = useState(
    orderCaseData?.teethNeedToMove?.needToMove === null
      ? ""
      : orderCaseData?.teethNeedToMove?.needToMove
      ? "Yes"
      : "No"
  );
  const [teethNotToBeMovedSelectedTeeth, setTeethNotToBeMovedSelectedTeeth] = useState(
    orderCaseData?.teethNeedToMove?.teeth || []
  );
  const [attachmentsAllowed, setAttachmentsAllowed] = useState(
    orderCaseData?.attachmentsAllowed === null
      ? ""
      : orderCaseData?.attachmentsAllowed
      ? "Yes"
      : "No"
  );
  const [retainerRequired, setRetainerRequired] = useState(
    orderCaseData?.retainerRequired === null ? "" : orderCaseData?.retainerRequired ? "Yes" : "No"
  );

  const [preferredSoftwares, setPreferredSoftwares] = useState([]);

  const [iPRValue, setIPRValue] = useState(orderCaseData?.ipr || "");
  const [attachmentsAllowedOn, setAttachmentsAllowedOn] = useState(
    orderCaseData?.attachmentsAllowedOn || ""
  );
  const [overjetCorrection, setOverjetCorrection] = useState(
    orderCaseData?.overjetCorrection?.option || ""
  );
  const [overjetCorrectionImprove, setOverjetCorrectionImprove] = useState(
    orderCaseData?.overjetCorrection?.improveLength || 0
  );
  const [overbiteCorrection, setOverbiteCorrection] = useState(
    orderCaseData?.overbiteCorrection?.option || ""
  );
  const [overbiteCorrectionImprove, setOverbiteCorrectionImprove] = useState(
    orderCaseData?.overbiteCorrection?.improveLength || 0
  );

  const [crossBiteValue, setCrossBiteValue] = useState(orderCaseData?.crossbiteCorrection || "");
  const [specialInstructions, setSpecialInstructions] = useState(
    orderCaseData?.additionalInstructions || ""
  );

  const [selectedService, setSelectedService] = useState("");
  const [restorativeTypeTeethSelected, setRestorativeTypeTeethSelected] = useState(
    orderCaseData?.services?.reduce(
      (acc, curr) => ({ ...acc, [curr?.serviceObjId]: curr?.teeth }),
      {}
    ) ?? {}
  );

  const [preferredDesignSoftware, setPreferredDesignSoftware] = useState(
    orderCaseData?.preferredSoftware || ""
  );

  const handlePreferredDesignSoftwareCheckboxChange = (event) => {
    setPreferredDesignSoftware(event.target.value);
  };

  console.log("restorativeTypeTeethSelected", restorativeTypeTeethSelected);

  const [notation, setNotation] = useState("FDI");

  const [getSoftwareCategoryByName] = useGetSoftwareCategoryByNameMutation();
  const readSoftwareCategoryInfo = async (accessToken) => {
    const response = await getSoftwareCategoryByName({
      accessToken,
      softwareCategoryName: "Orthodontic Treatment Planning",
    }).unwrap();
    if (response?.data) {
      const softwaresList = response?.data;
      if (softwaresList?.length > 0) setPreferredSoftwares(softwaresList[0]?.softwares);
      else setPreferredSoftwares([]);
    }
  };

  const readSoftwareCategoriesInfoDeatails = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await readSoftwareCategoryInfo(accessToken);
  };

  useEffect(() => {
    readSoftwareCategoriesInfoDeatails();
  }, []);

  const getRestrictedTeeth = (serviceObjId) => {
    const restrictedTeethData = [];
    Object.keys(restorativeTypeTeethSelected)
      .filter((d) => d !== serviceObjId)
      .forEach((d) => {
        restrictedTeethData.push(...restorativeTypeTeethSelected[d]);
      });
    return restrictedTeethData;
  };

  console.log("Categories1", selectedServiceCategory?.catservices);

  const prepareServicesElements = () => {
    if (selectedServiceCategory?.catservices?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      selectedServiceCategory?.catservices?.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            selectedIcon={
              restorativeTypeTeethSelected.hasOwnProperty(eachService?.serviceObjId) &&
              restorativeTypeTeethSelected[eachService?.serviceObjId]?.length > 0
            }
            handleSelectedIcon={() => {
              console.log("restorativeTypeTeethSelected", restorativeTypeTeethSelected);
              setRestorativeTypeTeethSelected((prev) => {
                const copy = JSON.parse(JSON.stringify(prev));
                delete copy[eachService?.serviceObjId];
                console.log("eachService?.serviceObjId", eachService?.serviceObjId);
                console.log("copy", copy);
                console.log("prev", prev);
                return copy;
              });
            }}
            id={serviceObjId}
            active={selectedService?.serviceObjId === eachService?.serviceObjId}
            image={eachService?.serviceIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serviceName}
            serviceDescription={eachService?.serviceDescription}
            clickHandler={() => setSelectedService(eachService)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  const handleNotationChange = (event) => {
    setNotation(event.target.value);
    setMissingTeethSelectedTeeth(updateTeethMapping(missingTeethSelectedTeeth, event.target.value));
    setTeethNotToBeMovedSelectedTeeth(
      updateTeethMapping(teethNotToBeMovedSelectedTeeth, event.target.value)
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "specialInstructions") setSpecialInstructions(value);
    if (name === "overjetCorrectionImprove") setOverjetCorrectionImprove(value);
    if (name === "overbiteCorrectionImprove") setOverbiteCorrectionImprove(value);
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = ["specialInstructions"];

    return requiredFields.includes(fieldName);
  };

  const arraysHaveSameValues = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };

  const handleAllCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setMidline((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
      setMidline((prevSelectedValues) =>
        prevSelectedValues.filter((selectedValue) => selectedValue !== value)
      );
    }
  };

  const handleCheckboxChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleHasMissingTeethChange = (event) => {
    setHasMissingTeeth(event.target.value);
  };

  const handleteethNotToBeMovedChange = (event) => {
    setTeethNotToBeMoved(event.target.value);
  };

  const handleAttachmentsAllowedChange = (event) => {
    setAttachmentsAllowed(event.target.value);
  };

  const handleRetainerRequiredChange = (event) => {
    setRetainerRequired(event.target.value);
  };

  const handleIPRValueCheckboxChange = (event) => {
    setIPRValue(event.target.value);
  };

  const handleAttachmentsAllowedOnCheckboxChange = (event) => {
    setAttachmentsAllowedOn(event.target.value);
  };

  const handleCrossBiteValueCheckboxChange = (event) => {
    setCrossBiteValue(event.target.value);
  };

  const handleOverbiteCorrectionCheckboxChange = (event) => {
    setOverbiteCorrection(event.target.value);
  };

  const handleOverjetCorrectionCheckboxChange = (event) => {
    setOverjetCorrection(event.target.value);
  };

  const validateNumber = (value) => {
    return Number(value) ? true : false;
  };

  const submitTreatmentGoals = async () => {
    submitOrderData({
      options: optionsValue,
      clinicalConditions: clinicalValues,
      archesToTreat: selectedValue,
      molarOcclusalGoals: molarOcclusalGoals,
      midline: midline,
      missingTeeth: {
        hasMissingTeeth: hasMissingTeeth ? hasMissingTeeth === "Yes" : null,
        teeth: missingTeethSelectedTeeth,
      },
      teethNeedToMove: {
        needToMove: teethNotToBeMoved ? teethNotToBeMoved === "Yes" : null,
        teeth: teethNotToBeMovedSelectedTeeth,
      },
      ipr: iPRValue,
      overjetCorrection: {
        option: overjetCorrection,
        improveLength: overjetCorrectionImprove,
      },
      overbiteCorrection: {
        option: overbiteCorrection,
        improveLength: overbiteCorrectionImprove,
      },
      crossbiteCorrection: crossBiteValue,
      attachmentsAllowed: attachmentsAllowed ? attachmentsAllowed === "Yes" : null,
      retainerRequired: retainerRequired ? retainerRequired === "Yes" : null,
      additionalInstructions: specialInstructions,
    });
    setActiveTab("Upload Files");
  };

  const handleOptionsCheckboxChange = (event) => {
    setOptionsValue(event.target.value);
  };

  const handlePrintableAlignersChange = (event) => {
    setPrintableAligners(event.target.value);
  };

  const handlePrintableRetainerChange = (event) => {
    setPrintableRetainer(event.target.value);
  };

  const handleClinicalValuesCheckboxChange = (event) => {
    const { value, checked } = event.target;
    console.log(value, checked);
    if (checked) {
      setClinicalValues((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
      setClinicalValues((prevSelectedValues) =>
        prevSelectedValues.filter((selectedValue) => selectedValue !== value)
      );
    }
  };

  const handleMolarOcclusalGoalsCheckboxChange = (event) => {
    setMolarOcclusalGoals(event.target.value);
  };

  const handleMissingTeethSelection = async (value) => {
    console.log("value", value);
    const actualMissingTeeth = structuredClone(missingTeethSelectedTeeth);
    let result = value(actualMissingTeeth || []);
    setMissingTeethSelectedTeeth(result);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let teethNo;
    if (result?.length > actualMissingTeeth?.length) {
      teethNo = findExtraElement(actualMissingTeeth, result);
      saveOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    } else if (result?.length < actualMissingTeeth?.length) {
      teethNo = findExtraElement(result, actualMissingTeeth);
      deleteOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="w-10/12">
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            What tooth numbering system do you prefer?
            <span className="text-red-500">*</span>
          </p>
          <div className="flex">
            <div className={classes.radioButtons}>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="UTN"
                  checked={notation === "UTN"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                UTN
              </label>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="FDI"
                  checked={notation === "FDI"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                FDI
              </label>
            </div>
          </div>
          <p className="text-[16px] text-[#777] mb-4">
            The Universal tooth numbering (UTN) system (1-32) and Fédération Dentaire Internationale
            (FDI) system (11-48) are both internationally recognised numbering systems used in
            different parts of the world. Please select a preference that will then apply to all the
            tooth charts in this form.
          </p>
        </div>
        <div className="text-white mb-8">
          <p className="font-medium text-[17px] text-white mb-2">
            Please advise us if the patient has any missing teeth{" "}
            <span className="text-red-500">*</span>
          </p>
          <div className="flex">
            <div className="flex items-center mr-5">
              <CircularCheckbox
                value="Yes"
                checked={hasMissingTeeth === "Yes"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px] my-3">The patient has missing teeth</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={hasMissingTeeth === "No"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">The patient has no missing teeth</p>
            </div>
          </div>
          {hasMissingTeeth === "Yes" && (
            <div className="mx-5 mt-4">
              <p className="text-white text-[16px] mb-2">
                Please select missing teeth
                {isFieldRequired("missingTeethSelectedTeeth") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <TeethV2
                allTeethData={[]}
                selectedTeeth={missingTeethSelectedTeeth}
                setSelectedTeeth={handleMissingTeethSelection}
                defaultNotation={notation}
                key={`missingTeethSelectedTeeth${notation}`}
                notationVisible={false}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <p className="font-medium text-[24px] text-white mb-4">
            Orthodontic Planning Service Type
          </p>
          <p className="text-[16px] text-[#777] mb-8">
            Please click an orthodontic planning service type below and choose the teeth that apply
            for that service type in the chart that appears.{" "}
            <span className="!text-white">
              Multiple orthodontic planning service plan types (for example: for different arches)
              can be selected and multiple teeth can be selected on each service type's chart.
            </span>{" "}
            If you prefer not to use our charting system, you can also provide written instructions
            in the Additional Instructions column below.
          </p>
          {selectedServiceCategory?._id &&
            Object.keys(restorativeTypeTeethSelected).length !== "" && (
              <>
                <div className="CAROUSEL mb-8">
                  <div className="md:block hidden">
                    <CustomCarousel
                      rows={1}
                      cols={6}
                      gapY={20}
                      gapX={20}
                      completeSliders={prepareServicesElements()}
                      numberOfCardsShownInSingleSlide={6}
                    />
                  </div>
                  <div className="md:hidden block">
                    <CustomCarousel
                      rows={1}
                      cols={2}
                      gapY={20}
                      gapX={20}
                      completeSliders={prepareServicesElements()}
                      numberOfCardsShownInSingleSlide={2}
                    />
                  </div>
                </div>
              </>
            )}
        </div>
        {selectedService?.serviceObjId && (
          <>
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) &&
              selectedService?.serviceObjId &&
              restorativeTypeTeethSelected && (
                <>
                  <div>
                    <div className="text-white flex mt-4 mb-8">
                      Are there any teeth that should not be moved?{" "}
                      <span className="text-red-500">*</span>
                      <div className="flex items-center mx-3">
                        <CircularCheckbox
                          value="Yes"
                          checked={teethNotToBeMoved === "Yes"}
                          onChange={handleteethNotToBeMovedChange}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Yes</p>
                      </div>
                      <div className="flex items-center">
                        <CircularCheckbox
                          value="No"
                          checked={teethNotToBeMoved === "No"}
                          onChange={handleteethNotToBeMovedChange}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">No</p>
                      </div>
                    </div>
                    {teethNotToBeMoved === "Yes" && (
                      <div className="mx-5 mb-4">
                        <p className="text-white text-[17px] mb-2">
                          Please select any teeth that should not be moved
                          {isFieldRequired("teethExtractionSelectedTeeth") ? (
                            <span className="text-red-500">*</span>
                          ) : (
                            ""
                          )}
                        </p>
                        <div className="">
                          <TeethV2
                            allTeethData={[]}
                            selectedTeeth={teethNotToBeMovedSelectedTeeth}
                            setSelectedTeeth={setTeethNotToBeMovedSelectedTeeth}
                            defaultNotation={notation}
                            key={`teethNotToBeMovedSelectedTeeth${notation}`}
                            notationVisible={false}
                            missingTeeth={missingTeethSelectedTeeth}
                            restrictedTeeth={getRestrictedTeeth(selectedService?.serviceObjId)}
                            restrictedTeethMessage={
                              "This tooth is already selected in other restorative type"
                            }
                            disabledTeeths={
                              [
                                "Clear Aligners anterior",
                                "Clear Aligners anterior refinement",
                              ].includes(selectedService?.serviceName)
                                ? notation === "FDI"
                                  ? [
                                      14, 15, 16, 17, 18, 24, 25, 26, 27, 28, 44, 45, 46, 47, 48,
                                      34, 35, 36, 37, 38,
                                    ]
                                  : [
                                      1, 2, 3, 4, 5, 12, 13, 14, 15, 16, 28, 29, 30, 31, 32, 17, 18,
                                      19, 20, 21,
                                    ]
                                : []
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            <div>
              {["Clear Retainer"].includes(selectedService?.serviceName) && (
                <div>
                  <p className="text-white my-4">
                    Would you like a printable retainer or a model that you will use for
                    thermoforming? <span className="text-red-500">*</span>
                  </p>
                  <div className="text-white flex mb-8">
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Printable retainer"
                        checked={printableRetainer === "Printable retainer"}
                        onChange={handlePrintableRetainerChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Printable retainer</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Model"
                        checked={printableRetainer === "Model"}
                        onChange={handlePrintableRetainerChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Model</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-white mb-4">
                  Options <span className="text-red-500">*</span>
                </p>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-8 mb-6">
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="Orthodontic Treatment Planning (OTP)"
                      checked={optionsValue === "Orthodontic Treatment Planning (OTP)"}
                      onChange={handleOptionsCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Orthodontic Treatment Planning (OTP)</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="OTP + STL modeller for 3D printing"
                      checked={optionsValue === "OTP + STL modeller for 3D printing"}
                      onChange={handleOptionsCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">OTP + STL files for 3D printing</p>
                  </div>
                  {/* <div className="flex items-center">
            <CircularCheckbox
              value="OTP + STL + 3D printing models"
              checked={optionsValue === "OTP + STL + 3D printing models"}
              onChange={handleOptionsCheckboxChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">OTP + STL + 3D printing models</p>
          </div>
          <div className="flex items-center">
            <CircularCheckbox
              value="OTP + STL + 3D printing models + Aligners"
              checked={optionsValue === "OTP + STL + 3D printing models + Aligners"}
              onChange={handleOptionsCheckboxChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">OTP + STLs + 3D printed models + Aligners</p>
          </div> */}
                </div>
              </div>
            )}
            <div>
              {[
                "Clear Aligners anterior",
                "Clear Aligners full arch",
                "Clear Aligners anterior refinement",
                "Clear Aligners full arch refinement",
              ].includes(selectedService?.serviceName) && (
                <div>
                  <p className="text-white my-4">
                    If you have chosen STL files, would you like printable aligners or models that
                    you will use for thermoforming? <span className="text-red-500">*</span>
                  </p>
                  <div className="text-white flex mb-8">
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Printable aligners"
                        checked={printableAligners === "Printable aligners"}
                        onChange={handlePrintableAlignersChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Printable aligners</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Models"
                        checked={printableAligners === "Models"}
                        onChange={handlePrintableAlignersChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Models</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div className="mb-6">
                <p className="font-medium text-white mt-2 mb-4">
                  Clinical Conditions <span className="text-red-500">*</span>
                </p>
                <div className="">
                  <div className="grid md:grid-cols-4 md:grid-rows-4 grid-cols-1  gap-x-8 gap-y-4 text-[16px] text-white">
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Crowding"
                        checked={clinicalValues.includes("Crowding")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Crowding</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Spacing"
                        checked={clinicalValues.includes("Spacing")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Spacing</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Class 2 Division1"
                        checked={clinicalValues.includes("Class 2 Division1")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Class 2 Division1</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Uneven Smile"
                        checked={clinicalValues.includes("Uneven Smile")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Uneven Smile</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Open Bite"
                        checked={clinicalValues.includes("Open Bite")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Open Bite</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Deep Bite"
                        checked={clinicalValues.includes("Deep Bite")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Deep Bite</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Class 2 Division 2"
                        checked={clinicalValues.includes("Class 2 Division 2")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Class 2 Division 2</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Anterior Cross Bite"
                        checked={clinicalValues.includes("Anterior Cross Bite")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Anterior Cross Bite</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Posterior Cross Bite"
                        checked={clinicalValues.includes("Posterior Cross Bite")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Posterior Cross Bite</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Narrow Arch"
                        checked={clinicalValues.includes("Narrow Arch")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Narrow Arch</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Increased Overjet"
                        checked={clinicalValues.includes("Increased Overjet")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Increased Overjet</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Misshapened Teeth"
                        checked={clinicalValues.includes("Misshapened Teeth")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Misshapened Teeth</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Flared Teeth"
                        checked={clinicalValues.includes("Flared Teeth")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Flared Teeth</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Others"
                        checked={clinicalValues.includes("Others")}
                        onChange={handleClinicalValuesCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Others</p>
                    </div>
                  </div>
                  {!(clinicalValues.length > 0) ? (
                    <p className="text-sm text-red-500 py-2">Select at least one</p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
            <div>
              <p className="font-medium text-white mb-4">
                Arches to treat <span className="text-red-500">*</span>
              </p>
              <div className="flex md:gap-32 gap-4 items-center mb-6">
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Upper"
                    checked={selectedValue === "Upper"}
                    onChange={handleCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Upper</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Lower"
                    checked={selectedValue === "Lower"}
                    onChange={handleCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Lower</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Both"
                    checked={selectedValue === "Both"}
                    onChange={handleCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Both</p>
                </div>
              </div>
            </div>
            {["Clear Aligners full arch", "Clear Aligners full arch refinement"].includes(
              selectedService?.serviceName
            ) && (
              <div>
                <p className="font-medium text-white mb-4">
                  Molar Occlusal Goals <span className="text-red-500">*</span>
                </p>
                <div className="font-medium text-white grid grid-rows md:gap-5 gap-4 mb-6">
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="Class I Molar Occulsion"
                      checked={molarOcclusalGoals === "Class I Molar Occulsion"}
                      onChange={handleMolarOcclusalGoalsCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Class I Molar Occulsion</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="Maintain Molar Relationship"
                      checked={molarOcclusalGoals === "Maintain Molar Relationship"}
                      onChange={handleMolarOcclusalGoalsCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Maintain Molar Relationship</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="Canine to Canine correction (Social 6 only)"
                      checked={molarOcclusalGoals === "Canine to Canine correction (Social 6 only)"}
                      onChange={handleMolarOcclusalGoalsCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">
                      Canine to Canine correction (Social 6 only)
                    </p>
                  </div>
                </div>
              </div>
            )}
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div className="mb-6">
                <p className="font-medium text-white mb-4">
                  Midline <span className="text-red-500">*</span>
                </p>
                <div className="">
                  <div className="grid md:grid-cols-4 grid-cols-1 grid-rows-1 md:gap-0 gap-4 text-white text-[16px]">
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Optimize"
                        checked={midline.includes("Optimize")}
                        onChange={handleAllCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Optimize</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Maintain"
                        checked={midline.includes("Maintain")}
                        onChange={handleAllCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Maintain</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Move Upper Only"
                        checked={midline.includes("Move Upper Only")}
                        onChange={handleAllCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Move Upper Only</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Move Lower Only"
                        checked={midline.includes("Move Lower Only")}
                        onChange={handleAllCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Move Lower Only</p>
                    </div>
                  </div>
                  {midline.includes("Optimize") ||
                  midline.includes("Maintain") ||
                  midline.includes("Move Upper Only") ||
                  midline.includes("Move Lower Only") ? (
                    <></>
                  ) : (
                    <p className="text-sm text-red-500 py-2">Select at least one</p>
                  )}
                </div>
              </div>
            )}

            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-white my-4">
                  Inter Proximal Reduction (IPR) <span className="text-red-500">*</span>
                </p>
                <div
                  className={`flex md:gap-36 gap-4 items-center ${
                    iPRValue?.trim().length > 0 ? "mb-6" : ""
                  }`}
                >
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="Anteriors"
                      checked={iPRValue === "Anteriors"}
                      onChange={handleIPRValueCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Anteriors</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="Anteriors and Premolars"
                      checked={iPRValue === "Anteriors and Premolars"}
                      onChange={handleIPRValueCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Anteriors and Premolars</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="All Teeth"
                      checked={iPRValue === "All Teeth"}
                      onChange={handleIPRValueCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">All Teeth</p>
                  </div>
                </div>
                {iPRValue?.trim().length > 0 ? (
                  <></>
                ) : (
                  <p className="text-sm text-red-500 py-2">Select at least one</p>
                )}
              </div>
            )}
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-white mb-4">
                  Overjet Correction <span className="text-red-500">*</span>
                </p>
                <div
                  className={`${
                    overjetCorrection.includes("Optimize") ||
                    overjetCorrection.includes("Maintain") ||
                    overjetCorrection.includes("Improve")
                      ? "mb-6"
                      : ""
                  }`}
                >
                  <div className="grid md:grid-cols-4 grid-cols-1 grid-rows-1 md:gap-4 gap-4 text-white text-[16px]">
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Optimize"
                        checked={overjetCorrection === "Optimize"}
                        onChange={handleOverjetCorrectionCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Optimize</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Maintain"
                        checked={overjetCorrection === "Maintain"}
                        onChange={handleOverjetCorrectionCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Maintain</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Improve"
                        checked={overjetCorrection === "Improve"}
                        onChange={handleOverjetCorrectionCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Improve</p>
                    </div>
                  </div>
                  {overjetCorrection === "Improve" && (
                    <div className="w-1/2 mt-4">
                      <RangeInputField
                        labelColor={"#fff"}
                        labelText={"Select improve length"}
                        errorMessage={"Please enter a valid cement gap value"}
                        placeholder={""}
                        htmlFor={"overjetCorrectionImprove"}
                        name={"overjetCorrectionImprove"}
                        value={overjetCorrectionImprove}
                        handleInputChange={handleInputChange}
                        validationFunctionName={validateNumber}
                        isSubmitted={isFieldRequired}
                        isFieldRequired={isFieldRequired("overjetCorrection")}
                        unit="µ"
                        min={0}
                        max={5}
                        step={0.5}
                      />
                    </div>
                  )}
                  {overjetCorrection.includes("Optimize") ||
                  overjetCorrection.includes("Maintain") ||
                  overjetCorrection.includes("Improve") ? (
                    <></>
                  ) : (
                    <p className="text-sm text-red-500 py-2">Select at least one</p>
                  )}
                </div>
              </div>
            )}
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-white mb-4">
                  Overbite Correction <span className="text-red-500">*</span>
                </p>
                <div className="mb-6">
                  <div className="grid md:grid-cols-4 grid-cols-1 grid-rows-1 md:gap-4 gap-4 text-white text-[16px]">
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Optimize"
                        checked={overbiteCorrection === "Optimize"}
                        onChange={handleOverbiteCorrectionCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Optimize</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Maintain"
                        checked={overbiteCorrection === "Maintain"}
                        onChange={handleOverbiteCorrectionCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Maintain</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Improve"
                        checked={overbiteCorrection === "Improve"}
                        onChange={handleOverbiteCorrectionCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Improve</p>
                    </div>
                  </div>
                  {overbiteCorrection === "Improve" && (
                    <div className="w-1/2 mt-4">
                      <RangeInputField
                        labelColor={"#fff"}
                        labelText={"Select improve length"}
                        errorMessage={"Please enter a valid cement gap value"}
                        placeholder={""}
                        htmlFor={"overbiteCorrectionImprove"}
                        name={"overbiteCorrectionImprove"}
                        value={overbiteCorrectionImprove}
                        handleInputChange={handleInputChange}
                        validationFunctionName={validateNumber}
                        isSubmitted={isFieldRequired}
                        isFieldRequired={isFieldRequired("overbiteCorrection")}
                        unit="µ"
                        min={0}
                        max={5}
                        step={0.5}
                      />
                    </div>
                  )}
                  {overbiteCorrection.includes("Optimize") ||
                  overbiteCorrection.includes("Maintain") ||
                  overbiteCorrection.includes("Improve") ? (
                    <></>
                  ) : (
                    <p className="text-sm text-red-500 py-2">Select at least one</p>
                  )}
                </div>
              </div>
            )}
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-white mb-4">
                  Crossbite Correction <span className="text-red-500">*</span>
                </p>
                <div
                  className={`flex md:gap-36 gap-4 items-center ${
                    crossBiteValue?.trim().length > 0 ? "mb-6" : ""
                  }`}
                >
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="Optimize"
                      checked={crossBiteValue === "Optimize"}
                      onChange={handleCrossBiteValueCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Optimize</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="Maintain"
                      checked={crossBiteValue === "Maintain"}
                      onChange={handleCrossBiteValueCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Maintain</p>
                  </div>
                </div>
                {crossBiteValue?.trim().length > 0 ? (
                  <></>
                ) : (
                  <p className="text-sm text-red-500 py-2">Select at least one</p>
                )}
              </div>
            )}
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <>
                <div
                  className={`text-white flex mt-8 ${
                    attachmentsAllowed === "Yes" ? "mb-4" : "mb-8"
                  }`}
                >
                  Attachments allowed? <span className="text-red-500">*</span>
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={attachmentsAllowed === "Yes"}
                      onChange={handleAttachmentsAllowedChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={attachmentsAllowed === "No"}
                      onChange={handleAttachmentsAllowedChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
                {attachmentsAllowed === "Yes" && (
                  <div>
                    <p className="text-white my-4">
                      Attachments allowed on? <span className="text-red-500">*</span>
                    </p>
                    <div
                      className={`flex md:gap-36 gap-4 items-center ${
                        iPRValue?.trim().length > 0 ? "mb-6" : ""
                      }`}
                    >
                      <div className="flex items-center">
                        <CircularCheckbox
                          value="Anteriors"
                          checked={attachmentsAllowedOn === "Anteriors"}
                          onChange={handleAttachmentsAllowedOnCheckboxChange}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Anteriors</p>
                      </div>
                      <div className="flex items-center">
                        <CircularCheckbox
                          value="Anteriors and Premolars"
                          checked={attachmentsAllowedOn === "Anteriors and Premolars"}
                          onChange={handleAttachmentsAllowedOnCheckboxChange}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Anteriors and Premolars</p>
                      </div>
                      <div className="flex items-center">
                        <CircularCheckbox
                          value="All Teeth"
                          checked={attachmentsAllowedOn === "All Teeth"}
                          onChange={handleAttachmentsAllowedOnCheckboxChange}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">All Teeth</p>
                      </div>
                    </div>
                    {iPRValue?.trim().length > 0 ? (
                      <></>
                    ) : (
                      <p className="text-sm text-red-500 py-2">Select at least one</p>
                    )}
                  </div>
                )}
              </>
            )}

            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div className="text-white flex my-8">
                Retainer Required? <span className="text-red-500">*</span>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={retainerRequired === "Yes"}
                    onChange={handleRetainerRequiredChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={retainerRequired === "No"}
                    onChange={handleRetainerRequiredChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            )}
            {preferredSoftwares?.length > 0 && (
              <div className="text-white flex flex-col mb-8">
                <p className="font-medium text-white mb-4">
                  Preferred design software?{" "}
                  {isFieldRequired("preferredDesignSoftware") ? (
                    <span className="text-red-500">*</span>
                  ) : (
                    ""
                  )}
                </p>
                <div className="grid grid-cols-5 gap-y-3 mt-1">
                  {preferredSoftwares?.map((item) => {
                    return (
                      <div className="flex items-center mr-3" key={item?.softwareObjId}>
                        <CircularCheckbox
                          value={item?.softwareName}
                          checked={preferredDesignSoftware === item?.softwareName}
                          onChange={handlePreferredDesignSoftwareCheckboxChange}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{item?.softwareName}</p>
                      </div>
                    );
                  })}
                  <div className="flex items-center mx-3 col-span-1">
                    <CircularCheckbox
                      value="No Preferance"
                      checked={preferredDesignSoftware === "No Preferance"}
                      onChange={handlePreferredDesignSoftwareCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No Preference</p>
                  </div>
                </div>
              </div>
            )}
            <div className="mb-6">
              <TextAreaInputField
                labelColor={"#777"}
                labelText={"Additional Instructions"}
                errorMessage={"This field is required"}
                placeholder={"Enter Additional Instructions"}
                isFieldRequired={isFieldRequired("specialInstructions")}
                htmlFor={"specialInstructions"}
                name={"specialInstructions"}
                value={specialInstructions}
                handleInputChange={handleInputChange}
                // validationFunctionName={validatePatientConcern}
                // isSubmitted={isPatientInitialFormSubmitted}
                rows={4}
                cols={5}
              />
              {specialInstructions?.trim()?.length > 0 ? (
                <></>
              ) : (
                <p className="text-sm text-red-500">This field is required</p>
              )}
            </div>
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <p className="text-base text-white">
                For cases involving complex tooth movements and surgical Orthodontics, we do not
                recommend using in house fabricated aligners.
              </p>
            )}
          </>
        )}
      </div>
      <div className="BOTTOM-SECTION mt-8">
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab("Patient Details");
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              submitTreatmentGoals();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrthodonticCaseDetailsSection;
