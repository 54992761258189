import React from 'react'

const DeliveryTracker = () => {
    return (
        <div className='flex justify-between'>
            <div className='p-4'>
                <div className='relative'>
                    <ul className='list-none'>
                        <li className='flex items-center pb-6 gap-4'>
                            <div className='h-12 w-12 rounded-full bg-blue-200 z-50'>
                            </div>
                            <div>
                                <p className='text-[13px] text-gray-400'>4 days ago</p>
                                <p className='font-bold text-xl'>Order Created</p>
                            </div>
                        </li>
                        <li className='flex items-center pl-3 py-6 gap-7'>
                            <div className='flex justify-center items-center h-6 w-6 rounded-full bg-blue-200 z-50'>
                                <div className='h-3 w-3 rounded-full bg-blue-500'>
                                </div>
                            </div>
                            <div>
                                <p>First Draft Created</p>
                            </div>

                        </li>
                        <li className='flex items-center pl-3 py-6 gap-7'>
                            <div className='flex justify-center items-center h-6 w-6 rounded-full bg-blue-200 z-50'>
                                <div className='h-3 w-3 rounded-full bg-blue-500'>
                                </div>
                            </div>
                            <div>
                                <p>First Draft Created</p>
                            </div>
                        </li>
                        <li className='flex items-center py-6 gap-4'>
                            <div className='h-12 w-12 rounded-full bg-blue-200 z-50'>
                            </div>
                            <div>
                                <p className='text-[13px] text-gray-400'>4 days ago</p>
                                <p className='font-bold text-xl'>Order Created</p>
                            </div>
                        </li>
                        <li className='flex items-center pl-3 py-6 gap-7'>
                            <div className='flex justify-center items-center h-6 w-6 rounded-full bg-blue-200 z-50'>
                                <div className='h-3 w-3 rounded-full bg-blue-500'>
                                </div>
                            </div>
                            <div>
                                <p>First Draft Created</p>
                            </div>

                        </li>
                        <li className='flex items-center pl-3 py-6 gap-7'>
                            <div className='flex justify-center items-center h-6 w-6 rounded-full bg-blue-200 z-50'>
                                <div className='h-3 w-3 rounded-full bg-blue-500'>
                                </div>
                            </div>
                            <div>
                                <p>First Draft Created</p>
                            </div>
                        </li>
                        <li className='flex items-center pl-3 pt-6 gap-7'>
                            <div className='flex justify-center items-center h-6 w-6 rounded-full bg-blue-200 z-50'>
                                <div className='animate-pulse h-3 w-3 rounded-full bg-blue-500'>
                                </div>
                            </div>
                            <div>
                                <p>First Draft Created</p>
                            </div>
                        </li>
                    </ul>
                    <div className='h-full w-[1px] bg-blue-200 absolute top-0 left-[23px]'>
                    </div>
                </div>
            </div>
            <div className='p-5 bg-[#edeff4] rounded-xl w-[400px]'>
                <p className='pb-4 pt-1 text-xl font-black'>Tracking History</p>
                <div className='relative'>
                    <ul className='list-none'>
                        <li className='flex items-start pb-6 gap-4'>
                            <div className='flex justify-center items-center h-4 w-4 rounded-full bg-green-500 z-50'>
                            </div>
                            <div>
                                <p className='font-bold text-base'>Draft</p>
                                <p className='text-gray-400 text-sm'>09/03/2023</p>
                            </div>
                        </li>
                        <li className='flex items-start pb-6 gap-4'>
                            <div className='flex justify-center items-center h-4 w-4 rounded-full bg-green-500 z-50'>
                            </div>
                            <div>
                                <p className='font-bold text-base'>Payment Completed</p>
                                <p className='text-gray-400 text-sm'>09/03/2023</p>
                            </div>
                        </li>
                        <li className='flex items-start pb-6 gap-4'>
                            <div className='flex justify-center items-center h-4 w-4 rounded-full bg-green-500 z-50'>
                            </div>
                            <div>
                                <p className='font-bold text-base'>Waiting for Confirmation</p>
                                <p className='text-gray-400 text-sm'>Today</p>
                            </div>
                        </li>
                        <li className='flex items-start pb-6 gap-4'>
                            <div className='flex justify-center items-center h-4 w-4 rounded-full bg-gray-300 z-50'>
                            </div>
                            <div>
                                <p className='text-base'>Order In Review</p>
                                <p className='text-gray-400 text-sm'>11:20 AM</p>
                            </div>
                        </li>
                        <li className='flex items-start pb-6 gap-4'>
                            <div className='flex justify-center items-center h-4 w-4 rounded-full bg-gray-300 z-50'>
                            </div>
                            <div>
                                <p className='text-base'>Order In Received</p>
                                <p className='text-gray-400 text-sm'>12:00 PM</p>
                            </div>
                        </li>
                        <li className='flex items-start pb-6 gap-4'>
                            <div className='flex justify-center items-center h-4 w-4 rounded-full bg-gray-300 z-50'>
                            </div>
                            <div>
                                <p className='text-base'>Order In Progress</p>
                                <p className='text-gray-400 text-sm'>01:20 PM</p>
                            </div>
                        </li>
                        <li className='flex items-start pb-6 gap-4'>
                            <div className='flex justify-center items-center h-4 w-4 rounded-full bg-gray-300 z-50'>
                            </div>
                            <div>
                                <p className='text-base'>Order Shipped</p>
                                <p className='text-gray-400 text-sm'>11:20 AM</p>
                            </div>
                        </li>
                        <li className='flex items-start gap-4'>
                            <div className='flex justify-center items-center h-4 w-4 rounded-full bg-gray-300 z-50'>
                            </div>
                            <div>
                                <p className='text-base'>Order Closed</p>
                                <p className='text-gray-400 text-sm'>04:20 PM</p>
                            </div>
                        </li>
                    </ul>
                    <div className='h-full w-[1px] bg-[#d6d6d6] absolute top-0 left-[7px]'>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeliveryTracker