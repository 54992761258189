import React from "react";

function TabComponentProfile({ detailTileList }) {
  return (
    <div className="py-8  flex flex-col h-[50vh] md:h-auto   md:grid  md:grid-cols-3 grid-rows-2 gap-16 overflow-y-auto">
      {detailTileList.map((component, index) => (
        // this key method is wrong input correct key as per data set
        <div key={index}>{component}</div>
      ))}
    </div>
  );
}

export default TabComponentProfile;
