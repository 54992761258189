/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useDeleteFileMutation, useSaveFileMutation } from "../../services/uploadFiles";
import { GetToast } from "../Toast-Comps/GetToast";
import { CategoryTextAreaField } from "./InputFieldsCategory";
import FileUploadComponent from "../InputField-Comps/FilePondField";

import {
  useSaveServicesCategoryMutation,
  useUpdateServicesCategoryMutation,
} from "../../services/servicesCategory";
import { BiLoaderAlt } from "react-icons/bi";

const InputFieldsServicesCategoryTab = ({
  closeCategoryModal,
  isEditModal,
  editingServicesRow,
  getData,
}) => {
  const [saveServicesCategory] = useSaveServicesCategoryMutation();
  const [updateServicesCategory] = useUpdateServicesCategoryMutation();
  const [saveFile] = useSaveFileMutation();
  const [deleteFile] = useDeleteFileMutation();

  const { getAccessTokenSilently } = useAuth0();

  const [serCategoryIcon, setSerCategoryIcon] = useState("");
  const [serCategoryIconLoading, setSerCategoryIconLoading] = useState(false);
  const [serCategoryName, setSerCategoryName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [serCategoryDescription, setSerCategoryDescription] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleIconFileUpload = async (uploadedFiles) => {
    setSerCategoryIconLoading(true);
    const file = uploadedFiles[0];
    // Access and use the uploaded files here
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await saveFile({
      accessToken,
      file,
    });
    if (resp) {
      if (resp?.data?.url) {
        setSerCategoryIcon(resp?.data?.url);
      }
    }
    setSerCategoryIconLoading(false);

    // Perform any further operations with the files, such as saving to state, sending to the server, etc.
  };

  const handleServiceNameChange = (event) => {
    const { value } = event.target;
    setSerCategoryName(value);
  };

  const handleSortOrderChange = (event) => {
    const { value } = event.target;
    setSortOrder(value);
  };
  const handleServiceDescriptionChange = (event) => {
    const { value } = event.target;
    setSerCategoryDescription(value);
  };

  React.useEffect(() => {
    if (isEditModal && editingServicesRow) {
      setSerCategoryName(editingServicesRow.serCategoryName);
      setSortOrder(editingServicesRow.sortOrder);
      setSerCategoryDescription(editingServicesRow.serCategoryDescription);
    }
  }, [editingServicesRow, isEditModal]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Perform validation for each input
    // const isSelectedServiceValid = !isFieldRequired("name") || validateName(selectedService);
    // const isServiceIconValid = !isFieldRequired("name") || validateName(serCategoryIcon);
    const isServiceNameValid = !isFieldRequired("serCategoryName") || validateName(serCategoryName);
    const isServiceOrderValid = !isFieldRequired("sortOrder") || validateSortOrder(sortOrder);
    const isServiceDescriptionValid =
      !isFieldRequired("serCategoryDescription") || validateName(serCategoryDescription);

    setIsSubmitted(true);

    // if (isSelectedServiceValid && isServiceIconValid && isServiceCodeValid && isServiceNameValid && isServiceDescriptionValid && isPhoneValid) {
    if (isServiceNameValid && isServiceDescriptionValid && isServiceOrderValid) {
      // Submit the form or perform any other action
      // calling any kind of submission api here
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      if (isEditModal) {
        let updatePayload = {
          accessToken,
          _id: editingServicesRow._id,
          serCategoryName,
          sortOrder,
          serCategoryDescription,
          serCategoryIcon,
        };
        const resp = await updateServicesCategory(updatePayload);

        if (resp) {
          console.log("resp", resp);
        }

        if (resp?.data?.type === 1) {
          GetToast("Service Category Successfully Updated !!");
        } else {
          GetToast(
            "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
            "error"
          );
        }
      } else {
        const resp = await saveServicesCategory({
          accessToken,
          serCategoryName,
          sortOrder,
          serCategoryIcon,
          serCategoryDescription,
        }).unwrap();
        if (resp) {
          console.log("resp", resp);
        }
        if (resp?.type === 1) {
          GetToast("Service Category Successfully Saved !!");
        } else if (resp?.type === 2) {
          // Show error message from response if type is 2
          GetToast(resp?.message, "error");
        } else {
          GetToast(
            "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
            "error"
          );
        }
      }
      await getData(accessToken);
      closeCategoryModal();
    }
  };

  const validateName = (value) => {
    // Your name validation logic goes here
    // Return true if the value is valid, false otherwise
    // For example:
    return value?.trim().length > 0;
  };

  const validateSortOrder = (value) => {
    // Your name validation logic goes here
    // Return true if the value is valid, false otherwise
    // For example:
    return Number(value) ? true : false;
  };

  const isFieldRequired = (fieldName) => {
    // Implement your logic to determine if a field is required or not
    // For example, you can have a list of required fields and check if the fieldName exists in that list
    const requiredFields = ["serCategoryName", "serCategoryDescription", "sortOrder"];
    return requiredFields.includes(fieldName);
  };

  return (
    <div className="h-full">
      <form onSubmit={handleSubmit} className=" h-full flex flex-col">
        <div className="flex-1 pt-8 pb-8">
          <div className="relative">
            <ServiceCategoryIconInputField
              labelText={"Category Icon"}
              labelColor={"#777"}
              errorMessage={""}
              placeholder={"Select Category Icon"}
              handleIconFileUpload={handleIconFileUpload}
              initialFiles={isEditModal ? editingServicesRow?.serCategroyIcon ?? "" : ""}
            />
          </div>
          <div className="relative">
            <TextInputField
              labelText={"Category Name*"}
              labelColor={"#777"}
              errorMessage={"this is error"}
              placeholder={"Enter Category Name"}
              name={"serCategoryName"}
              value={serCategoryName}
              handleInputChange={handleServiceNameChange}
              isSubmitted={isSubmitted}
              validateName={validateName}
              isFieldRequired={isFieldRequired("serCategoryName")}
            />
          </div>
          <div className="relative">
            <CategoryTextAreaField
              labelText={"Category Description*"}
              labelColor={"#777"}
              errorMessage={"this is error"}
              placeholder={"Enter Your Description Here"}
              categoryTextArea={serCategoryDescription}
              handleInputChange={handleServiceDescriptionChange}
              isSubmitted={isSubmitted}
              validateName={validateName}
              validateCategoryTextArea={validateName}
              isFieldRequired={isFieldRequired("serCategoryDescription")}
            />
          </div>
          <div className="relative">
            <NumberInputField
              labelText={"Sort Order*"}
              labelColor={"#777"}
              errorMessage={"this is error"}
              placeholder={"Enter Sort Order"}
              name={"sortOrder"}
              value={sortOrder}
              handleInputChange={handleSortOrderChange}
              isSubmitted={isSubmitted}
              validateName={validateSortOrder}
              isFieldRequired={isFieldRequired("sortOrder")}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-4">
          <button
            onClick={closeCategoryModal}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={serCategoryIconLoading}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl h-full"
          >
            {serCategoryIconLoading ? <BiLoaderAlt className="animate-spin"/> : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputFieldsServicesCategoryTab;

export function TextInputField({
  labelText,
  labelClass,
  inputClass,
  labelColor,
  errorMessage,
  placeholder,
  isFieldRequired,
  name,
  value,
  handleInputChange,
  isSubmitted,
  validateName,
}) {
  return (
    <>
      <label
        htmlFor="name"
        style={{
          color: `${isSubmitted && !validateName(name) ? "border-red-500" : ""}`,
        }}
        className={`text-[16px] text-white leading-4 ${labelClass ?? ""}`}
      >
        {labelText}
      </label>

      <input
        type="text"
        id="name"
        name={name}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={`border p-2 w-full text-white bg-black text-[16px] leading-4 mt-2 mb-4 rounded-md h-10  ${
          inputClass ?? ""
        } ${isSubmitted && !validateName(name) ? "border-red-500" : ""}`}
        required={isFieldRequired}
      />
      {isSubmitted && !validateName(name) && isFieldRequired && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">{errorMessage}</p>
      )}
    </>
  );
}
export function NumberInputField({
  labelText,
  errorMessage,
  labelColor,
  placeholder,
  name,
  value,
  handleInputChange,
  isSubmitted,
  validateName,
  isFieldRequired,
}) {
  return (
    <>
      <label
        htmlFor="phone"
        style={{
          color: `${isSubmitted && !validateName(value) ? "border-red-500" : ""}`,
        }}
        className="text-[16px] text-[#777] text-white leading-4"
      >
        {labelText}
      </label>
      <input
        type="number"
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleInputChange}
        className={`border p-2 w-full text-white bg-black text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 ${
          isSubmitted && !validateName(value) ? "border-red-500" : ""
        }`}
        required={isFieldRequired}
      />
      {isSubmitted && !validateName(value) && isFieldRequired && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px] ">{errorMessage}</p>
      )}
    </>
  );
}
export function ServiceCategoryIconInputField({
  labelText,
  labelColor,
  handleIconFileUpload,
  isEditing,
  initialFiles,
}) {
  return (
    <>
      <label htmlFor="categoryIcon" className="text-[16px] text-white leading-4 mb-5">
        {labelText}
      </label>
      <FileUploadComponent
        maxFiles={1}
        acceptedFileTypes={["image/jpeg", "image/png"]}
        onUpload={handleIconFileUpload}
        allowMultipleFiles={false}
        initialFiles={initialFiles ? [{ source: initialFiles, options: { type: "local" } }] : []}
      />
    </>
  );
}
