import React, { useState, useRef } from "react";
import { AiOutlineArrowUp } from "react-icons/ai";
import {
    useSaveFileMutation
} from "../../services/uploadFiles";
import { useAuth0 } from "@auth0/auth0-react";
import { useSaveOrderScansMutation, useUpdateOrderScansMutation } from "../../services/orders";

const AttachUpload = ({ placeholderImage, imageUrl, setImageUrl, title, id, fileFor, fileType, orderObjId }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [saveFile] = useSaveFileMutation();
    const [saveOrderScans] = useSaveOrderScansMutation();
    const [updateOrderScans] = useUpdateOrderScansMutation();
    const [isHovered, setIsHovered] = useState(false);
    const fileInputRef = useRef(null);

    const handleImageUpload = async (event) => {
        // debugger
        const file = event.target.files[0];
        // Access and use the uploaded files here
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
            },
        });
        const resp = await saveFile({
            accessToken,
            file,
        });
        if (resp) {
            if (resp.data.url) {
                setImageUrl(resp.data.url);
            }
        }
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    console.log(imageUrl, "imageUrl");
    return (
        <div className="relative h-full w-full flex flex-col gap-2 items-center">
            <div
                className="image-container relative h-full w-full border rounded-md"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {isHovered && (
                    <div className="upload-button h-32 absolute w-full top-0 left-0 backdrop-blur-[2px] backdrop-contrast-50 backdrop-brightness-50  cursor-pointer  z-20 rounded-md">
                        <button
                            className="absolute flex items-center gap-2  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-[12px] font-medium px-4 py-2 rounded-full border border-white"
                            onClick={handleUploadButtonClick}
                        >
                            <AiOutlineArrowUp />
                            <span>Upload</span>
                        </button>
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            onChange={handleImageUpload}
                            style={{ display: "none" }}
                        />
                    </div>
                )}
                {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt="Uploaded"
                        className="absolute top-0 z-10 h-32 w-full object-contain overflow-hidden"
                    />
                ) : (
                    <div className="h-32 bg-gray-100 flex justify-center items-center text-gray-500 text-base">
                        <AiOutlineArrowUp style={{ marginRight: '5px' }} /> Upload
                    </div>
                )}
            </div>
            {title && <div className="text-[#555] text-base">{title}</div>}
        </div>
    );
};

export default AttachUpload;
