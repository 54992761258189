import React from "react";
import { MdCheckCircle, MdDelete, MdEdit } from "react-icons/md";

function QuickOrderCard({
  bgColor,
  image,
  title,
  textColor,
  shadow,
  active,
  clickHandler,
  handleDeleteIconClick,
  handleEditIconClick,
  selectedIcon,
  handleSelectedIcon,
}) {
  return (
    <>
      <div
        style={{
          background: `${bgColor}`,
          backgroundImage: `url(${image})`,
          boxShadow: `${shadow ? "0px 10px 40px rgba(103, 103, 103, 0.09)" : "none"}`,
          backgroundSize: "cover",
        }}
        className={`w-auto flex flex-col justify-end items-center rounded-xl relative pt-[100%] border ${
          active ? "border-primary" : "border-primary/5"
        }`}
        onClick={(e) => {
          if (clickHandler) {
            clickHandler(e);
          }
        }}
      >
        {/* <img className=" h-[40px] w-[60px] object-contain mx-auto mb-2" src={image} alt="" /> */}
        <div className="absolute left-0 h-full flex justify-between py-3 flex-col">
          {handleEditIconClick && (
            <button
              className="px-1.5 py-1 mb-2 rounded-md bg-[#FF7E00] "
              onClick={(e) => {
                e.stopPropagation();
                handleEditIconClick();
              }}
            >
              <MdEdit className="text-sm text-white" />
            </button>
          )}
          {handleDeleteIconClick && (
            <button
              className="px-1.5 py-1 mb-2 rounded-md bg-[#FF7E00] "
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteIconClick();
              }}
            >
              <MdDelete className="text-sm text-white" />
            </button>
          )}
        </div>
        {selectedIcon && (
          <button
            className="p-1 rounded-full bg-primary absolute right-1 top-1"
            onClick={(e) => {
              e.stopPropagation();
              handleSelectedIcon();
            }}
          >
            <MdCheckCircle className="text-xs text-white" />
          </button>
        )}
      </div>
      <p
        // style={{ color: `${textColor}` }}
        className=" leading-[12px] text-[12px] mt-2 mx-auto text-center text-white"
      >
        {title}
      </p>
    </>
  );
}

export default QuickOrderCard;
