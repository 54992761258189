import { useEffect, useState } from "react";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import { useNavigate } from "react-router-dom";
import {
  useDeleteOrderMissingTeethMutation,
  useSaveOrderMissingTeethMutation,
} from "../../services/orders";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import { TextInputField } from "../Services-Comps/InputFieldsServicesTab";
import DateInputFieldV2 from "../InputField-Comps/DateInputFieldV2";
import { TeethV2 } from "../Teeth-Comps/TeethV2";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";
import classes from "../Teeth-Comps/Teeth.module.scss";
import {
  findExtraElement,
  implantCompanyList,
  stackableGuideOptionsList1,
  stackableGuideOptionsList2,
  updateTeethMapping,
} from "../../Utils/utility";

import Option1Image01 from "../../Assets/images/surgicalGuides/Option1Image01.jpg";
import Option1Image02 from "../../Assets/images/surgicalGuides/Option1Image02.jpg";
import Option1Image03 from "../../Assets/images/surgicalGuides/Option1Image03.jpg";
import Option1Image04 from "../../Assets/images/surgicalGuides/Option1Image04.jpg";
import Option1Image05 from "../../Assets/images/surgicalGuides/Option1Image05.jpg";
import Option2Image01 from "../../Assets/images/surgicalGuides/Option2Image01.jpg";
import Option2Image02 from "../../Assets/images/surgicalGuides/Option2Image02.jpg";
import Option2Image03 from "../../Assets/images/surgicalGuides/Option2Image03.jpg";
import Option2Image04 from "../../Assets/images/surgicalGuides/Option2Image04.jpg";
import Option2Image05 from "../../Assets/images/surgicalGuides/Option2Image05.jpg";
import EndodonticGuide from "../../Assets/images/icons109.jpg";
import DropdownField from "../InputField-Comps/DropdownField";

export const SurgicalCaseDetailsSection = ({
  setActiveTab,
  orderCaseData,
  orderByData,
  setOrderUpdated,
  serviceObjId,
  submitOrderData,
  selectedServiceCategory,
}) => {
  // debugger
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [saveOrderMissingTeeth] = useSaveOrderMissingTeethMutation();
  const [deleteOrderMissingTeeth] = useDeleteOrderMissingTeethMutation();

  // eslint-disable-next-line no-unused-vars
  const [additonalInstructions, setSpecialInstructions] = useState(
    orderCaseData?.additionalInstructions || ""
  );
  const [preferredImplantCompany, setPreferredImplantCompany] = useState(
    orderCaseData?.preferredImplantCompany || ""
  );
  const [preferredPinCompany, setPreferredPinCompany] = useState(
    orderCaseData?.preferredPinCompany || ""
  );
  const [preferredGuidedAccess, setPreferredGuidedAccess] = useState(
    orderCaseData?.preferredGuidedAccess || ""
  );
  const [guidedAccessDrillShank, setGuidedAccessDrillShank] = useState(
    orderCaseData?.guidedAccessDrillShank || ""
  );
  const [pinLine, setPinLine] = useState(orderCaseData?.pinLine || "");
  const [implantLine, setImplantLine] = useState(orderCaseData?.implantLine || "");
  const [implantSurgicalKit, setImplantSurgicalKit] = useState(
    orderCaseData?.implantSurgicalKit?.option || ""
  );
  const [guidanceLevel, setGuidanceLevel] = useState(orderCaseData?.guidanceLevel || []);
  const [stackableGuideOptions, setStackableGuideOptions] = useState(
    orderCaseData?.guideType?.additionalStackableOptions || []
  );
  const [stackableGuideOptionsSubOption, setStackableGuideOptionsSubOption] = useState(
    orderCaseData?.guideType?.additionalStackableOptionsSubOption || ""
  );
  const [servicesRequired, setServicesRequired] = useState(orderCaseData?.servicesRequired || []);
  const [densahBursUsed, setDensahBursUsed] = useState(
    orderCaseData?.implantSurgicalKit?.densahBursUsed === null
      ? ""
      : orderCaseData?.implantSurgicalKit?.densahBursUsed
      ? "Yes"
      : "No"
  );
  const [densahBurGuideRequired, setDensahBurGuideRequired] = useState(
    orderCaseData?.implantSurgicalKit?.densahBurGuideDesired === null
      ? ""
      : orderCaseData?.implantSurgicalKit?.densahBurGuideDesired
      ? "Yes"
      : "No"
  );
  const [flapBeRaised, setFlapBeRaised] = useState(
    orderCaseData?.implantSurgicalKit?.flapBeRaised === null
      ? ""
      : orderCaseData?.implantSurgicalKit?.flapBeRaised
      ? "Yes"
      : "No"
  );
  const [boneReductionNecessary, setBoneReductionNecessary] = useState(
    orderCaseData?.guideType?.incorporateBoneReduction === null
      ? ""
      : orderCaseData?.guideType?.incorporateBoneReduction
      ? "Yes"
      : "No"
  );
  const [conversionDentureAvailable, setConversionDentureAvailable] = useState(
    orderCaseData?.guideType?.conversionDentureAvailable === null
      ? ""
      : orderCaseData?.guideType?.conversionDentureAvailable
      ? "Yes"
      : "No"
  );
  const [digitalConversionDenture, setDigitalConversionDenture] = useState(
    orderCaseData?.guideType?.digitalConversionDentureRequired === null
      ? ""
      : orderCaseData?.guideType?.digitalConversionDentureRequired
      ? "Yes"
      : "No"
  );
  const [prosthesisBeUsed, setProsthesisBeUsed] = useState(
    orderCaseData?.guideType?.prosthesisBeUsed === null
      ? ""
      : orderCaseData?.guideType?.prosthesisBeUsed
      ? "Yes"
      : "No"
  );
  const [abutmentPreferences, setAbutmentPreferences] = useState(
    orderCaseData?.guideType?.abutmentPreferences || ""
  );

  const [surgeryDate, setSurgeryDate] = useState(orderCaseData?.surgeryDate ?? "");
  const [guideTypeValue, setGuideTypeValue] = useState(orderCaseData?.guideType?.option || "");
  const [hasMissingTeeth, setHasMissingTeeth] = useState(
    orderCaseData?.missingTeeth?.hasMissingTeeth === null
      ? ""
      : orderCaseData?.missingTeeth?.hasMissingTeeth
      ? "Yes"
      : "No"
  );
  const [missingTeethSelectedTeeth, setMissingTeethSelectedTeeth] = useState(
    orderCaseData?.missingTeeth?.teeth || []
  );
  const [anyTeethMobile, setAnyTeethMobile] = useState(
    orderCaseData?.anyTeethMobile?.anyTeethMobile === null
      ? ""
      : orderCaseData?.anyTeethMobile?.anyTeethMobile
      ? "Yes"
      : "No"
  );
  const [mobileTeeth, setMobileTeeth] = useState(orderCaseData?.anyTeethMobile?.teeth || []);
  const [intendToExtractTeeth, setIntendToExtractTeeth] = useState(
    orderCaseData?.teethToExtract?.teethToExtract === null
      ? ""
      : orderCaseData?.teethToExtract?.teethToExtract
      ? "Yes"
      : "No"
  );
  const [teethExtractionSelectedTeeth, setTeethExtractionSelectedTeeth] = useState(
    orderCaseData?.teethToExtract?.teeth || []
  );
  const [selectedService, setSelectedService] = useState("");
  const [guideTypeTeethSelected, setGuideTypeTeethSelected] = useState(
    orderCaseData?.services?.reduce(
      (acc, curr) => ({ ...acc, [curr?.serviceObjId]: curr?.teeth }),
      {}
    )
  );
  const [densahBursTeethSelected, setDensahBursTeethSelected] = useState(
    orderCaseData?.services?.reduce(
      (acc, curr) => ({ ...acc, [curr?.serviceObjId]: curr?.teeth }),
      {}
    )
  );

  const [notation, setNotation] = useState("FDI");

  const handleNotationChange = (event) => {
    setNotation(event.target.value);
    setMobileTeeth(updateTeethMapping(mobileTeeth, event.target.value));
    setMissingTeethSelectedTeeth(updateTeethMapping(missingTeethSelectedTeeth, event.target.value));
    setTeethExtractionSelectedTeeth(
      updateTeethMapping(teethExtractionSelectedTeeth, event.target.value)
    );
    if (guideTypeTeethSelected[selectedService?.serviceObjId]?.length > 0) {
      setGuideTypeTeethSelected({
        ...guideTypeTeethSelected,
        [selectedService?.serviceObjId]: updateTeethMapping(
          guideTypeTeethSelected[selectedService?.serviceObjId]
        ),
      });
    }
    if (densahBursTeethSelected[selectedService?.serviceObjId]?.length > 0) {
      setDensahBursTeethSelected({
        ...densahBursTeethSelected,
        [selectedService?.serviceObjId]: updateTeethMapping(
          densahBursTeethSelected[selectedService?.serviceObjId]
        ),
      });
    }
  };

  console.log("guideTypeTeethSelected", guideTypeTeethSelected);

  const handleGuidanceLevelCheckboxChange = (event) => {
    setGuidanceLevel(event.target.value);
  };

  const handleStackableGuideOptionsCheckboxChange = (event) => {
    const { value } = event.target;
    if (stackableGuideOptions.includes(value)) {
      // If the checkbox is checked, add the value to the array
      setStackableGuideOptions(stackableGuideOptions.filter((d) => d !== value));
    } else {
      setStackableGuideOptions([...stackableGuideOptions, value]);
    }
  };

  const handleStackableGuideOptionsSubOptionCheckboxChange = (event, stackableMainOption) => {
    const { value } = event.target;
    setStackableGuideOptionsSubOption((prev) => ({
      ...prev,
      [stackableMainOption]: value,
    }));
  };

  console.log("servicesRequired", servicesRequired);

  const handleservicesRequiredCheckboxChange = (event) => {
    const { value } = event.target;
    if (servicesRequired.includes(value)) {
      // If the checkbox is checked, add the value to the array
      setServicesRequired(servicesRequired.filter((d) => d !== value));
    } else {
      setServicesRequired([...servicesRequired, value]);
    }
  };

  const handleHasMissingTeethChange = (event) => {
    setHasMissingTeeth(event.target.value);
  };

  const handleIntendToExtractTeethCheckboxChange = (event) => {
    setIntendToExtractTeeth(event.target.value);
  };

  const handleAnyTeethMobileCheckboxChange = (event) => {
    setAnyTeethMobile(event.target.value);
  };

  const handleGuideCheckboxChange = (event) => {
    setGuideTypeValue(event.target.value);
  };

  const validateSurgeryDate = (value) => {
    return value ? true : false;
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      "additonalInstructions",
      "hasMissingTeeth",
      "missingTeethSelectedTeeth",
      "intendToExtractTeeth",
      "guideType",
      "mobileTeeth",
      "preferredImplantCompany",
      "preferredPinCompany",
      "pinLine",
      "implantLine",
      "servicesRequired",
      "abutmentPreferences",
      "guidanceLevel",
      "flapBeRaised",
      "densahBursUsed",
      "densahBurGuideRequired",
    ];

    return requiredFields.includes(fieldName);
  };

  const arraysHaveSameValues = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };

  const handleSurgicalKitCheckboxChange = (event) => {
    setImplantSurgicalKit(event.target.value);
  };
  const handleDensahBursUsedCheckboxChange = (event) => {
    setDensahBursUsed(event.target.value);
  };
  const handleDensahBurGuideRequiredCheckboxChange = (event) => {
    setDensahBurGuideRequired(event.target.value);
  };
  const handleFlapBeRaisedCheckboxChange = (event) => {
    setFlapBeRaised(event.target.value);
  };
  const handleBoneReductionNecessaryCheckboxChange = (event) => {
    setBoneReductionNecessary(event.target.value);
  };
  const handleConversionDentureAvailableCheckboxChange = (event) => {
    setConversionDentureAvailable(event.target.value);
  };
  const handleDigitalConversionDentureCheckboxChange = (event) => {
    setDigitalConversionDenture(event.target.value);
  };
  const handleProsthesisBeUsedCheckboxChange = (event) => {
    setProsthesisBeUsed(event.target.value);
  };

  const validateTextField = (value) => {
    return value.trim().length > 0;
  };

  const getRestrictedTeeth = (serviceObjId) => {
    const restrictedTeethData = [];
    Object.keys(guideTypeTeethSelected)
      .filter((d) => d !== serviceObjId)
      .forEach((d) => {
        restrictedTeethData.push(...guideTypeTeethSelected[d]);
      });
    return restrictedTeethData;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value, event.target);
    if (name === "additonalInstructions") setSpecialInstructions(value);
    if (name === "abutmentPreferences") setAbutmentPreferences(value);
    if (name === "implantLine") setImplantLine(value);
    if (name === "preferredImplantCompany") setPreferredImplantCompany(value);
    if (name === "surgeryDate") setSurgeryDate(value);
    if (name === "preferredPinCompany") setPreferredPinCompany(value);
    if (name === "pinLine") setPinLine(value);
    if (name === "preferredGuidedAccess") setPreferredGuidedAccess(value);
    if (name === "guidedAccessDrillShank") setGuidedAccessDrillShank(value);
  };
  const submitToothMovementForm = async () => {
    // eslint-disable-next-line no-unused-vars
    // if (toothData?.length > 0) {
    // const overBiteSelectOptionsObj = {
    //   overBiteCorrtOptimize: false,
    //   overBiteCorrtMaintain: false,
    //   overBiteCorrtImprove1mm: false,
    //   overBiteCorrtImprove2mm: false,
    //   overBiteCorrtImprove3mm: false,
    //   overBiteCorrtImprove4mm: false,
    // };
    // const overjetSelectOptionsObj = {
    //   overJetCorrtOptimize: false,
    //   overJetCorrtMaintain: false,
    //   overJetCorrtImprove1mm: false,
    //   overJetCorrtImprove2mm: false,
    //   overJetCorrtImprove3mm: false,
    //   overJetCorrtImprove4mm: false,
    // };
    // for (const key of toothData) {
    //   if (overBiteSelectOptionsObj.hasOwnProperty(key)) {
    //     overBiteSelectOptionsObj[key] = true;
    //   }
    // }
    // for (const key of toothData) {
    //   if (overjetSelectOptionsObj.hasOwnProperty(key)) {
    //     overjetSelectOptionsObj[key] = true;
    //   }
    // }
    // const isUpdated = arraysHaveSameValues(initialToothData, toothData);
    // const data = {
    //   //teeth: selectedMissingTeeth,
    //   iPRValue:
    //     iPRValue === "iprPosterior"
    //       ? { iprPosterior: true, iprAnterior: false }
    //       : { iprPosterior: false, iprAnterior: true },
    //   overjetSelectOptions: overjetSelectOptionsObj,
    //   overBiteSelectOptions: overBiteSelectOptionsObj,
    //   crossBiteValue:
    //     crossBiteValue === "crossBiteCorrtMaintain"
    //       ? { crossBiteCorrtMaintain: true, crossBiteCorrtOptimize: false }
    //       : { crossBiteCorrtMaintain: false, crossBiteCorrtOptimize: true },
    //   additonalInstructions: additonalInstructions,
    // };
    // const accessToken = await getAccessTokenSilently({
    //   authorizationParams: {
    //     audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
    //   },
    // });
    // if (orderByData?.ordertoothmovement?.length === 0) {
    //   const resp = await saveOrderToothMovData({
    //     accessToken,
    //     orderObjId: orderByData?._id,
    //     toothMovementData: data,
    //   });
    //   const respTeeth = await saveOrderTeethData({
    //     accessToken,
    //     orderObjId: orderByData?._id,
    //     teeth: selectedMissingTeeth,
    //   });
    //   setOrderUpdated(true);
    //   setActiveTab("Upload Files");
    // } else {
    //   if (!isUpdated) {
    //     const resp = await updateOrderToothMovData({
    //       accessToken,
    //       _id: orderByData?.ordertoothmovement[0]?._id,
    //       toothMovementData: data,
    //     });
    //     if (resp?.data?.type === 1) {
    //       GetToast("Success", "");
    //       setOrderUpdated(true);
    //       setActiveTab("Upload Files");
    //     } else {
    //       GetToast("Error", "error");
    //     }
    //   } else {
    //     setActiveTab("Upload Files");
    //   }
    // }
    // // }

    console.log(
      "densahBursTeethSelected[selectedService?.serviceObjId]",
      densahBursTeethSelected[selectedService?.serviceObjId]
    );

    submitOrderData({
      missingTeeth: {
        hasMissingTeeth: hasMissingTeeth ? hasMissingTeeth === "Yes" : null,
        teeth: missingTeethSelectedTeeth,
      },
      teethToExtract: {
        teethToExtract: intendToExtractTeeth ? intendToExtractTeeth === "Yes" : null,
        teeth: teethExtractionSelectedTeeth,
      },
      anyTeethMobile: {
        anyTeethMobile: anyTeethMobile ? anyTeethMobile === "Yes" : null,
        teeth: mobileTeeth,
      },
      services: Object.keys(guideTypeTeethSelected)?.map((item) => ({
        serviceObjId: item,
        teeth: guideTypeTeethSelected[item],
      })),
      guideType: {
        option: guideTypeValue,
        incorporateBoneReduction: boneReductionNecessary ? boneReductionNecessary === "Yes" : null,
        additionalStackableOptions: stackableGuideOptions,
        conversionDentureAvailable: conversionDentureAvailable
          ? conversionDentureAvailable === "Yes"
          : null,
        digitalConversionDentureRequired: digitalConversionDenture
          ? digitalConversionDenture === "Yes"
          : null,
        prosthesisBeUsed: prosthesisBeUsed ? prosthesisBeUsed === "Yes" : null,
        abutmentPreferences: abutmentPreferences,
      },
      surgeryDate: surgeryDate,
      preferredImplantCompany: preferredImplantCompany,
      implantLine: implantLine,
      implantSurgicalKit: {
        option: implantSurgicalKit,
        densahBursUsed: densahBursUsed ? densahBursUsed === "Yes" : null,
        densahBurGuideDesired: densahBurGuideRequired ? densahBurGuideRequired === "Yes" : null,
        flapBeRaised: flapBeRaised ? flapBeRaised === "Yes" : null,
      },
      guidanceLevel: guidanceLevel,
      servicesRequired: servicesRequired,
      additionalInstructions: additonalInstructions,
    });
    setActiveTab("Upload Files");
  };

  const prepareServicesElements = () => {
    if (selectedServiceCategory?.catservices?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      selectedServiceCategory?.catservices?.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            selectedIcon={
              guideTypeTeethSelected.hasOwnProperty(eachService?.serviceObjId) &&
              guideTypeTeethSelected[eachService?.serviceObjId]?.length > 0
            }
            handleSelectedIcon={() => {
              console.log("guideTypeTeethSelected", guideTypeTeethSelected);
              setGuideTypeTeethSelected((prev) => {
                const copy = JSON.parse(JSON.stringify(prev));
                delete copy[eachService?.serviceObjId];
                console.log("eachService?.serviceObjId", eachService?.serviceObjId);
                console.log("copy", copy);
                console.log("prev", prev);
                return copy;
              });
            }}
            id={serviceObjId}
            active={selectedService?.serviceObjId === eachService?.serviceObjId}
            image={eachService?.serviceIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serviceName}
            serviceDescription={eachService?.serviceDescription}
            clickHandler={() => setSelectedService(eachService)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  const getGuideHelperCards = (type) => {
    const arrayOfCards = [];
    if (type === 1) {
      // Create HTML elements based on servicesData
      [Option1Image01, Option1Image02, Option1Image03, Option1Image04, Option1Image05]?.map(
        (eachOption) =>
          arrayOfCards.push(
            <QuickOrderCard
              id={serviceObjId}
              image={eachOption}
              bgColor={"#171818"}
              textColor={"gray"}
              title={""}
              serviceDescription={""}
            />
          )
      );
      return arrayOfCards;
    } else {
      [Option2Image01, Option2Image02, Option2Image03, Option2Image04, Option2Image05]?.map(
        (eachOption) =>
          arrayOfCards.push(
            <QuickOrderCard
              id={serviceObjId}
              image={eachOption}
              bgColor={"#171818"}
              textColor={"gray"}
              title={""}
              serviceDescription={""}
            />
          )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  const handleMissingTeethSelection = async (value) => {
    console.log("value", value);
    const actualMissingTeeth = structuredClone(missingTeethSelectedTeeth);
    let result = value(actualMissingTeeth || []);
    setMissingTeethSelectedTeeth(result);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let teethNo;
    if (result?.length > actualMissingTeeth?.length) {
      teethNo = findExtraElement(actualMissingTeeth, result);
      saveOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    } else if (result?.length < actualMissingTeeth?.length) {
      teethNo = findExtraElement(result, actualMissingTeeth);
      deleteOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    }
  };

  return (
    <div className="">
      <div className="w-10/12">
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            What tooth numbering system do you prefer?
            <span className="text-red-500">*</span>
          </p>
          <div className="flex">
            <div className={classes.radioButtons}>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="UTN"
                  checked={notation === "UTN"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                UTN
              </label>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="FDI"
                  checked={notation === "FDI"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                FDI
              </label>
            </div>
          </div>
          <p className="text-[16px] text-[#777] mb-8">
            The Universal tooth numbering (UTN) system (1-32) and Fédération Dentaire Internationale
            (FDI) system (11-48) are both internationally recognised numbering systems used in
            different parts of the world. Please select a preference that will then apply to all the
            tooth charts in this form.
          </p>
        </div>
        <div className="text-white flex flex-col">
          <p className="font-medium text-[17px] text-white mb-2">
            Please advise us if the patient has any missing teeth{" "}
            {isFieldRequired("hasMissingTeeth") ? <span className="text-red-500">*</span> : ""}
          </p>
          <div className="flex">
            <div className="flex items-center mr-5">
              <CircularCheckbox
                value="Yes"
                checked={hasMissingTeeth === "Yes"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px] my-3">The patient has missing teeth</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={hasMissingTeeth === "No"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">The patient has no missing teeth</p>
            </div>
          </div>
          {hasMissingTeeth === "Yes" && (
            <div className="mx-5 mt-4">
              <p className="text-white text-[16px] mb-2">
                Please select missing teeth
                {isFieldRequired("missingTeethSelectedTeeth") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <TeethV2
                allTeethData={[]}
                selectedTeeth={missingTeethSelectedTeeth}
                setSelectedTeeth={handleMissingTeethSelection}
                defaultNotation={notation}
                key={`missingTeethSelectedTeeth${notation}`}
                notationVisible={false}
              />
            </div>
          )}
        </div>
        <p className="text-[16px] text-[#777] mt-8">
          If there are any teeth you intend to extract, these extractions need to be digitally
          simulated before surgical/endodontic guides or regenerative meshes are requested. Please
          advise if you intend to extract ANY teeth BEFORE the date of surgery. Planned extractions
          on the day of surgery DO NOT need to be charted but may be advised in Additional
          Instructions, ONLY when regenerative meshes are requested. It is also extremely important
          that NO TEETH are restored in any manner AFTER intraoral scans have been obtained for
          surgical/endodontic guides or regenerative meshes. ALL restorations must be completed
          before intraoral scans are obtained or must be scheduled only after intended surgery.
        </p>
        <div className="text-white flex mb-8 mt-3">
          Do you intend to extract any teeth?{" "}
          {isFieldRequired("intendToExtractTeeth") ? <span className="text-red-500">*</span> : ""}
          <div className="flex items-center mx-3">
            <CircularCheckbox
              value="Yes"
              checked={intendToExtractTeeth === "Yes"}
              onChange={handleIntendToExtractTeethCheckboxChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">Yes</p>
          </div>
          <div className="flex items-center">
            <CircularCheckbox
              value="No"
              checked={intendToExtractTeeth === "No"}
              onChange={handleIntendToExtractTeethCheckboxChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">No</p>
          </div>
        </div>
        {intendToExtractTeeth === "Yes" && (
          <div className="mx-5">
            <p className="text-white text-[17px] mb-2">
              Please select any teeth that you intend to extract{" "}
              {isFieldRequired("teethExtractionSelectedTeeth") ? (
                <span className="text-red-500">*</span>
              ) : (
                ""
              )}
            </p>
            <div className="">
              <TeethV2
                allTeethData={[]}
                selectedTeeth={teethExtractionSelectedTeeth}
                setSelectedTeeth={setTeethExtractionSelectedTeeth}
                defaultNotation={notation}
                key={`teethExtractionSelectedTeeth${notation}`}
                notationVisible={false}
                missingTeeth={missingTeethSelectedTeeth}
              />
            </div>
          </div>
        )}
        <div className="mb-8">
          <p className="text-[16px] text-[#777] mt-8">
            Mobile teeth can affect the fit of a guide. We will attempt to avoid designing a guide
            that sits on any teeth that you identify as mobile. You could also consider extracting
            all mobile teeth prior to obtaining intraoral scans. Alternatively, you could consider
            splinting mobile teeth before intraoral scans are obtained.
          </p>
          <div className="text-white flex mt-3">
            Are any of the remaining teeth mobile?
            {isFieldRequired("anyTeethMobile") ? <span className="text-red-500">*</span> : ""}
            <div className="flex items-center mx-3">
              <CircularCheckbox
                value="Yes"
                checked={anyTeethMobile === "Yes"}
                onChange={handleAnyTeethMobileCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Yes</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={anyTeethMobile === "No"}
                onChange={handleAnyTeethMobileCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">No</p>
            </div>
          </div>
          {anyTeethMobile === "Yes" && (
            <div className="mx-5">
              <p className="text-white text-[16px] mb-2">
                Please select remaining mobile teeth{" "}
                {isFieldRequired("mobileTeeth") ? <span className="text-red-500">*</span> : ""}
              </p>
              <TeethV2
                allTeethData={[]}
                selectedTeeth={mobileTeeth}
                setSelectedTeeth={setMobileTeeth}
                defaultNotation={notation}
                key={`mobileTeeth${notation}`}
                notationVisible={false}
                missingTeeth={[...missingTeethSelectedTeeth, ...teethExtractionSelectedTeeth]}
              />
            </div>
          )}
        </div>
        <div className="mb-3 mt-6">
          <p className="font-medium text-[24px] text-white mb-4">Surgical Guide Service Type</p>
          <p className="text-[16px] text-[#777] my-3">
            Please click a surgical guide service type below and choose the teeth that apply for
            that service type in the chart that appears.{" "}
            <span className="!text-white">
              Multiple surgical guide service types can be selected and multiple teeth can be
              selected on each service type's chart.
            </span>{" "}
            If you prefer not to use our charting system, you can also choose to only provide
            written instructions in the Additional Instructions column below.
          </p>
          <p className="text-[17px] text-[#777] mb-8">
            Please choose your surgical guide case type correctly and then choose the type of
            surgical guide you require.
          </p>
          {selectedServiceCategory?._id && Object.keys(guideTypeTeethSelected).length !== "" && (
            <>
              <div className="CAROUSEL mb-8">
                <div className="md:block hidden">
                  <CustomCarousel
                    rows={1}
                    cols={6}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements()}
                    numberOfCardsShownInSingleSlide={6}
                  />
                </div>
                <div className="md:hidden block">
                  <CustomCarousel
                    rows={1}
                    cols={2}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements()}
                    numberOfCardsShownInSingleSlide={2}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {selectedService?.serviceObjId && (
          <>
            {[
              "Single or multiple single implant surgical guide",
              "Bone regeneration surgical mesh",
              "Implant bridge surgical guide",
              "Soft tissue based surgical guide for Locators/Ball attachments or Bar",
              "Soft tissue based surgical guide for All on X",
              "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-[24px] text-white mt-4 mb-2">
                  Specific Guide Type{" "}
                  {isFieldRequired("guideType") ? <span className="text-red-500">*</span> : ""}
                </p>
                {["Implant bridge surgical guide"].includes(selectedService?.serviceName) && (
                  <p className="text-[17px] text-[#777] mb-8">
                    Most situations in this category are tooth supported guides. Occasionally, we
                    encounter guides that are part tooth supported and part mucosa supported, for
                    eg. distal extension guides. Please feel free to select both options if
                    required.
                  </p>
                )}
                {[
                  "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
                ].includes(selectedService?.serviceName) && (
                  <p className="text-[17px] text-[#777] mb-8">
                    We offer two options with our stackable guides. Our Option 1 is a routine set of
                    stackable guides. Option 2 includes an additional half arch drilling guide that
                    allows you to retain teeth on one side of the arch in either or both jaws. This
                    allows an additional set of interim mid surgery intraoral scans that enables
                    occlusal alignment with preoperative intraoral scans AND final postoperative
                    intraoral scans. A stackable temporary prothesis is always part of both options
                    as we always design our restorative wax ups in CAD software to guide implant
                    positions in any case. A temporary prosthesis is an automatic outcome of this
                    design.
                  </p>
                )}
                <div
                  className={`grid md:grid-cols-${
                    [
                      "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
                      "Bone regeneration surgical mesh",
                    ].includes(selectedService?.serviceName)
                      ? 1
                      : 3
                  } grid-cols-1 gap-x-8`}
                >
                  {[
                    "Single or multiple single implant surgical guide",
                    "Implant bridge surgical guide",
                  ].includes(selectedService?.serviceName) && (
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Tooth Supported Guide"
                        checked={guideTypeValue.includes("Tooth Supported Guide")}
                        onChange={(e) => {
                          if (selectedService?.serviceName === "Implant bridge surgical guide") {
                            const { value } = e.target;
                            if (guideTypeValue.includes(value)) {
                              // If the checkbox is checked, add the value to the array
                              setGuideTypeValue(guideTypeValue?.filter((d) => d !== value));
                            } else {
                              setGuideTypeValue([...guideTypeValue, value]);
                            }
                          } else {
                            handleGuideCheckboxChange(e);
                          }
                        }}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Tooth Supported Guide</p>
                    </div>
                  )}
                  {["Bone regeneration surgical mesh"].includes(selectedService?.serviceName) && (
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Bone supported regeneration surgical mesh"
                        checked={guideTypeValue.includes(
                          "Bone supported regeneration surgical mesh"
                        )}
                        onChange={(e) => {
                          if (selectedService?.serviceName === "Implant bridge surgical guide") {
                            const { value } = e.target;
                            if (guideTypeValue.includes(value)) {
                              // If the checkbox is checked, add the value to the array
                              setGuideTypeValue(guideTypeValue?.filter((d) => d !== value));
                            } else {
                              setGuideTypeValue([...guideTypeValue, value]);
                            }
                          } else {
                            handleGuideCheckboxChange(e);
                          }
                        }}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">
                        Bone supported regeneration surgical mesh
                      </p>
                    </div>
                  )}
                  {[
                    "Implant bridge surgical guide",
                    "Soft tissue based surgical guide for Locators/Ball attachments or Bar",
                    "Soft tissue based surgical guide for All on X",
                  ].includes(selectedService?.serviceName) && (
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Mucosa Supported Guide"
                        checked={guideTypeValue.includes("Mucosa Supported Guide")}
                        onChange={(e) => {
                          if (selectedService?.serviceName === "Implant bridge surgical guide") {
                            const { value } = e.target;
                            if (guideTypeValue.includes(value)) {
                              // If the checkbox is checked, add the value to the array
                              setGuideTypeValue(guideTypeValue?.filter((d) => d !== value));
                            } else {
                              setGuideTypeValue([...guideTypeValue, value]);
                            }
                          } else {
                            handleGuideCheckboxChange(e);
                          }
                        }}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Mucosa Supported Guide</p>
                    </div>
                  )}
                  {[
                    "Soft tissue based surgical guide for Locators/Ball attachments or Bar",
                    "Soft tissue based surgical guide for All on X",
                  ].includes(selectedService?.serviceName) && (
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="Bone Supported Guide"
                        checked={guideTypeValue.includes("Bone Supported Guide")}
                        onChange={handleGuideCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Bone Supported Guide</p>
                    </div>
                  )}
                  {[
                    "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
                  ].includes(selectedService?.serviceName) && (
                    <>
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <CircularCheckbox
                            value="Stackable guides Option 1"
                            checked={guideTypeValue.includes("Stackable guides Option 1")}
                            onChange={handleGuideCheckboxChange}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">
                            Stackable guides Option 1 (Tooth borne pin and base placement guide used
                            prior to extractions + Base guide with or without bone reduction +
                            Implant placement drilling (pilot or fully guided) guide AND Guided
                            stackable temporary printable prosthesis)
                          </p>
                        </div>
                        <div className="CAROUSEL mb-8 mt-4">
                          <div className="md:block hidden">
                            <CustomCarousel
                              rows={1}
                              cols={6}
                              gapY={20}
                              gapX={20}
                              completeSliders={getGuideHelperCards(1)}
                              numberOfCardsShownInSingleSlide={6}
                            />
                          </div>
                          <div className="md:hidden block">
                            <CustomCarousel
                              rows={1}
                              cols={2}
                              gapY={20}
                              gapX={20}
                              completeSliders={getGuideHelperCards(1)}
                              numberOfCardsShownInSingleSlide={2}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <CircularCheckbox
                            value="Stackable guides Option 2"
                            checked={guideTypeValue.includes("Stackable guides Option 2")}
                            onChange={handleGuideCheckboxChange}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">
                            Stackable guides Option 2 (Tooth borne pin and base placement guide used
                            prior to extractions + Base guide with or without bone reduction +
                            Implant placement drilling guide (pilot or fully guided) for half arch +
                            Implant placement drilling (pilot or fully guided) guide for full arch
                            AND Guided stackable temporary printable prosthesis)
                          </p>
                        </div>
                        <div className="CAROUSEL mb-8 mt-4">
                          <div className="md:block hidden">
                            <CustomCarousel
                              rows={1}
                              cols={6}
                              gapY={20}
                              gapX={20}
                              completeSliders={getGuideHelperCards(2)}
                              numberOfCardsShownInSingleSlide={6}
                            />
                          </div>
                          <div className="md:hidden block">
                            <CustomCarousel
                              rows={1}
                              cols={2}
                              gapY={20}
                              gapX={20}
                              completeSliders={getGuideHelperCards(2)}
                              numberOfCardsShownInSingleSlide={2}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {(guideTypeValue === "Bone Supported Guide" ||
                  [
                    "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
                  ].includes(selectedService?.serviceName)) && (
                  <div className="text-white mt-5">
                    <div className="flex">
                      If bone reduction is necessary and you have not requested it, can this be
                      incorporated into the plan?{" "}
                      <div className="flex items-center mx-3">
                        <CircularCheckbox
                          value="Yes"
                          checked={boneReductionNecessary === "Yes"}
                          onChange={handleBoneReductionNecessaryCheckboxChange}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Yes</p>
                      </div>
                      <div className="flex items-center">
                        <CircularCheckbox
                          value="No"
                          checked={boneReductionNecessary === "No"}
                          onChange={handleBoneReductionNecessaryCheckboxChange}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">No</p>
                      </div>
                    </div>
                    <p className="text-[#777]">
                      (If the Yes option is selected, a bone reduction guide will automatically be
                      provided as one of the stackable guides.)
                    </p>
                  </div>
                )}
              </div>
            )}
            {guideTypeTeethSelected && (
              <div className="mt-10 mb-7">
                <p className="text-white text-[16px] mb-2">
                  {selectedService?.serviceName === "Bone regeneration surgical mesh"
                    ? "Please select grafting site(s) where the mesh is required"
                    : selectedService?.serviceName === "Endodontic access or microsurgery guide"
                    ? "Please select teeth for endodontic access and/or microsurgery"
                    : "Please select implant site(s)"}
                </p>
                <TeethV2
                  key={
                    selectedService?.serviceObjId +
                    guideTypeTeethSelected[selectedService?.serviceObjId]?.length +
                    notation
                  }
                  allTeethData={[]}
                  disabledTeeths={getRestrictedTeeth(selectedService?.serviceObjId)}
                  disabledTeethMessage={"Tooth is not selectable"}
                  restrictedTeethMessage={"This tooth is already selected in other case type"}
                  selectedTeeth={guideTypeTeethSelected[selectedService?.serviceObjId] || []}
                  setSelectedTeeth={(value) => {
                    let result = value(guideTypeTeethSelected[selectedService?.serviceObjId] || []);
                    setGuideTypeTeethSelected({
                      ...guideTypeTeethSelected,
                      [selectedService?.serviceObjId]: result,
                    });
                  }}
                  defaultNotation={notation}
                  notationVisible={false}
                  missingTeeth={[...missingTeethSelectedTeeth, ...teethExtractionSelectedTeeth]}
                  missingTeethSelectable={true}
                  otherToothRestrictedMessage="Tooth is not selectable"
                />
              </div>
            )}
            {[
              "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
            ].includes(selectedService?.serviceName) && (
              <>
                <div>
                  <p className="font-medium text-white mt-6 mb-2">
                    What additional stackable guide options are desired? <small>(Optional)</small>
                  </p>
                  <div className="ml-6">
                    <div className="grid md:grid-rows-3 grid-rows-1  gap-x-8 gap-y-2 text-[16px] text-white">
                      {stackableGuideOptionsList1.map((d) => {
                        return (
                          <div className="flex items-center">
                            <CircularCheckbox
                              value={d}
                              checked={stackableGuideOptions.includes(d)}
                              onChange={handleStackableGuideOptionsCheckboxChange}
                              borderColor={"#FF8503"}
                              backgroundColor={"#FF8503"}
                            />
                            <div className="text-white text-[16px] flex gap-10">
                              {d}{" "}
                              {stackableGuideOptions.includes(d) && (
                                <div className="flex gap-6">
                                  <div className="flex items-center">
                                    <CircularCheckbox
                                      value={"Upper"}
                                      checked={stackableGuideOptionsSubOption?.[d]?.includes(
                                        "Upper"
                                      )}
                                      onChange={(e) => {
                                        handleStackableGuideOptionsSubOptionCheckboxChange(e, d);
                                      }}
                                      borderColor={"#FF8503"}
                                      backgroundColor={"#FF8503"}
                                    />
                                    <p className="text-white text-[16px]">Upper</p>
                                  </div>
                                  <div className="flex items-center">
                                    <CircularCheckbox
                                      value={"Lower"}
                                      checked={stackableGuideOptionsSubOption?.[d] === "Lower"}
                                      onChange={(e) => {
                                        handleStackableGuideOptionsSubOptionCheckboxChange(e, d);
                                      }}
                                      borderColor={"#FF8503"}
                                      backgroundColor={"#FF8503"}
                                    />
                                    <p className="text-white text-[16px]">Lower</p>
                                  </div>
                                  <div className="flex items-center">
                                    <CircularCheckbox
                                      value={"Both"}
                                      checked={stackableGuideOptionsSubOption?.[d] === "Both"}
                                      onChange={(e) => {
                                        handleStackableGuideOptionsSubOptionCheckboxChange(e, d);
                                      }}
                                      borderColor={"#FF8503"}
                                      backgroundColor={"#FF8503"}
                                    />
                                    <p className="text-white text-[16px]">Both</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="text-white flex flex-col my-6">
                  If no provisional denture has been selected, is a conversion denture available?
                  Dentures are highly recommended as back up provisional options and for recording
                  vertical dimension and bite for photogrammetry cases
                  <div className="flex">
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Yes"
                        checked={conversionDentureAvailable === "Yes"}
                        onChange={handleConversionDentureAvailableCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Yes</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="No"
                        checked={conversionDentureAvailable === "No"}
                        onChange={handleConversionDentureAvailableCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">No</p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {["Soft tissue based surgical guide for All on X or Bar overdenture"].includes(
              selectedService?.serviceName
            ) && (
              <>
                <div className="mb-8">
                  <p className="font-medium text-white mt-6 mb-4">
                    What additional guide options are desired?{" "}
                    <small>(Optional at additional cost)</small>
                  </p>
                  <div className="ml-6">
                    <div className="grid md:grid-rows-3 grid-rows-1  gap-x-8 gap-y-2 text-[16px] text-white">
                      {stackableGuideOptionsList2.map((d) => {
                        return (
                          <div className="flex items-center">
                            <CircularCheckbox
                              value={d}
                              checked={stackableGuideOptions.includes(d)}
                              onChange={handleStackableGuideOptionsCheckboxChange}
                              borderColor={"#FF8503"}
                              backgroundColor={"#FF8503"}
                            />
                            <div className="text-white text-[16px] flex gap-10">
                              {d}{" "}
                              {stackableGuideOptions.includes(d) && (
                                <div className="flex gap-6">
                                  <div className="flex items-center">
                                    <CircularCheckbox
                                      value={"Upper"}
                                      checked={stackableGuideOptionsSubOption?.[d] === "Upper"}
                                      onChange={(e) => {
                                        handleStackableGuideOptionsSubOptionCheckboxChange(e, d);
                                      }}
                                      borderColor={"#FF8503"}
                                      backgroundColor={"#FF8503"}
                                    />
                                    <p className="text-white text-[16px]">Upper</p>
                                  </div>
                                  <div className="flex items-center">
                                    <CircularCheckbox
                                      value={"Lower"}
                                      checked={stackableGuideOptionsSubOption?.[d] === "Lower"}
                                      onChange={(e) => {
                                        handleStackableGuideOptionsSubOptionCheckboxChange(e, d);
                                      }}
                                      borderColor={"#FF8503"}
                                      backgroundColor={"#FF8503"}
                                    />
                                    <p className="text-white text-[16px]">Lower</p>
                                  </div>
                                  <div className="flex items-center">
                                    <CircularCheckbox
                                      value={"Both"}
                                      checked={stackableGuideOptionsSubOption?.[d] === "Both"}
                                      onChange={(e) => {
                                        handleStackableGuideOptionsSubOptionCheckboxChange(e, d);
                                      }}
                                      borderColor={"#FF8503"}
                                      backgroundColor={"#FF8503"}
                                    />
                                    <p className="text-white text-[16px]">Both</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}
            {[
              "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
              "Soft tissue based surgical guide for All on X",
            ].includes(selectedService?.serviceName) &&
              !stackableGuideOptions.includes("Guided temporary Prosthesis") &&
              conversionDentureAvailable === "No" && (
                <div className="mb-3">
                  <div className="text-white flex mb-7">
                    A digital conversion denture can also be provided. Is this required?
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Yes"
                        checked={digitalConversionDenture === "Yes"}
                        onChange={handleDigitalConversionDentureCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Yes</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="No"
                        checked={digitalConversionDenture === "No"}
                        onChange={handleDigitalConversionDentureCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">No</p>
                    </div>
                  </div>
                  <div className="text-white flex flex-col">
                    <TextAreaInputField
                      labelColor={"#fff"}
                      labelText={"Please indicate abutment preferences"}
                      errorMessage={"This field is required"}
                      placeholder={"Enter here"}
                      isFieldRequired={isFieldRequired("abutmentPreferences")}
                      htmlFor={"abutmentPreferences"}
                      name={"abutmentPreferences"}
                      value={abutmentPreferences}
                      handleInputChange={handleInputChange}
                      validationFunctionName={validateTextField}
                      rows={4}
                      cols={5}
                    />
                  </div>
                </div>
              )}
            <div className=" flex md:flex-row flex-col gap-8">
              <DateInputFieldV2
                labelColor={"#777"}
                labelText={"Surgery Date"}
                errorMessage={""}
                placeholder={"DD-MM-YYYY"}
                isFieldRequired={isFieldRequired("surgeryDate")}
                htmlFor={"surgeryDate"}
                name={"surgeryDate"}
                value={surgeryDate}
                handleInputChange={setSurgeryDate}
                validationFunctionName={validateSurgeryDate}
              />
            </div>
            {selectedService?.serviceName === "Bone regeneration surgical mesh" && (
              <p className="text-[16px] text-[#777] mt-6">
                Your choice of a preferred implant company and implant line help us to simulate your
                implant placement and determine your volumetric grafting requirements. Please
                therefore choose what you propose to actually use in this specific patient.
              </p>
            )}
            <div className="grid grid-cols-12 gap-8 mt-5">
              {selectedService?.serviceName === "Endodontic access or microsurgery guide" ? (
                <>
                  <div className="col-span-5">
                    <TextInputField
                      labelColor={"#fff"}
                      labelText={"Preferred Guided Access drill brand"}
                      errorMessage={""}
                      placeholder={"Enter here"}
                      isFieldRequired={isFieldRequired("preferredGuidedAccess")}
                      htmlFor={"preferredGuidedAccess"}
                      name={"preferredGuidedAccess"}
                      value={preferredGuidedAccess}
                      handleInputChange={handleInputChange}
                      validationFunctionName={validateTextField}
                      margin={"0px"}
                    />
                  </div>
                  <div className="col-span-5">
                    <TextInputField
                      labelColor={"#fff"}
                      labelText={"Guided Access drill shank diameter (mm)"}
                      errorMessage={""}
                      placeholder={"Enter here"}
                      isFieldRequired={isFieldRequired("guidedAccessDrillShank")}
                      htmlFor={"guidedAccessDrillShank"}
                      name={"guidedAccessDrillShank"}
                      value={guidedAccessDrillShank}
                      handleInputChange={handleInputChange}
                      validationFunctionName={validateTextField}
                      margin={"0px"}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-span-6">
                    <DropdownField
                      labelText={"Preferred Implant Company"}
                      placeholder={"Select Implant Company"}
                      name={"preferredImplantCompany"}
                      labelClass={"text-[16px] leding-4"}
                      handleInputChange={handleInputChange}
                      labelColor={"#777"}
                      value={preferredImplantCompany}
                      options={implantCompanyList?.map((d) => ({ label: d, value: d })) || []}
                      isFieldRequired={isFieldRequired("preferredImplantCompany")}
                    />
                  </div>
                  <div className="col-span-5">
                    <TextInputField
                      labelColor={"#fff"}
                      labelText={"Implant Line"}
                      errorMessage={""}
                      placeholder={"Enter here"}
                      isFieldRequired={isFieldRequired("implantLine")}
                      htmlFor={"implantLine"}
                      name={"implantLine"}
                      value={implantLine}
                      handleInputChange={handleInputChange}
                      validationFunctionName={validateTextField}
                      margin={"0px"}
                    />
                  </div>
                </>
              )}
            </div>
            {[
              "Bone regeneration surgical mesh",
              "Endodontic access or microsurgery guide",
            ].includes(selectedService?.serviceName) ? null : (
              <>
                <div className="grid grid-cols-12 gap-8 mt-5">
                  <div className="col-span-6">
                    <DropdownField
                      labelText={"Preferred Pin Company"}
                      placeholder={"Select Pin Company"}
                      name={"preferredPinCompany"}
                      labelClass={"text-[16px] leding-4"}
                      handleInputChange={handleInputChange}
                      labelColor={"#777"}
                      value={preferredPinCompany}
                      options={implantCompanyList?.map((d) => ({ label: d, value: d })) || []}
                      isFieldRequired={isFieldRequired("preferredPinCompany")}
                    />
                  </div>
                  <div className="col-span-5">
                    <TextInputField
                      labelColor={"#fff"}
                      labelText={"Pin Line"}
                      errorMessage={""}
                      placeholder={"Enter here"}
                      isFieldRequired={isFieldRequired("pinLine")}
                      htmlFor={"pinLine"}
                      name={"pinLine"}
                      value={pinLine}
                      handleInputChange={handleInputChange}
                      validationFunctionName={validateTextField}
                      margin={"0px"}
                    />
                  </div>
                </div>
                <div className="my-4">
                  <p className="font-medium text-[24px] text-white mb-4">
                    Implant Surgical Kit <span className="text-red-500">*</span>
                  </p>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-x-8 w-10/12">
                    <div className="flex items-start">
                      <CircularCheckbox
                        value="Guided Kit"
                        checked={implantSurgicalKit === "Guided Kit"}
                        onChange={handleSurgicalKitCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Guided Kit</p>
                    </div>
                    <div className="">
                      <div className="flex items-start">
                        <CircularCheckbox
                          value="Regular Kit"
                          checked={implantSurgicalKit === "Regular Kit"}
                          onChange={handleSurgicalKitCheckboxChange}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Regular Kit </p>
                      </div>
                      <p className="text-[16px] text-[#777] my-3">
                        (If choosing a regular kit, please ensure that you only select a pilot drill
                        guide option below)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="text-white flex mb-2">
                    Will Densah burs be used?{" "}
                    {isFieldRequired("densahBursUsed") ? (
                      <span className="text-red-500">*</span>
                    ) : (
                      ""
                    )}
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Yes"
                        checked={densahBursUsed === "Yes"}
                        onChange={handleDensahBursUsedCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Yes</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="No"
                        checked={densahBursUsed === "No"}
                        onChange={handleDensahBursUsedCheckboxChange}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">No</p>
                    </div>
                  </div>
                  {densahBursUsed === "Yes" && (
                    <>
                      <div className="text-white flex mb-2">
                        Is a Densah bur guide desired?{" "}
                        {isFieldRequired("densahBurGuideRequired") ? (
                          <span className="text-red-500">*</span>
                        ) : (
                          ""
                        )}
                        <div className="flex items-center mx-3">
                          <CircularCheckbox
                            value="Yes"
                            checked={densahBurGuideRequired === "Yes"}
                            onChange={handleDensahBurGuideRequiredCheckboxChange}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">Yes</p>
                        </div>
                        <div className="flex items-center">
                          <CircularCheckbox
                            value="No"
                            checked={densahBurGuideRequired === "No"}
                            onChange={handleDensahBurGuideRequiredCheckboxChange}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">No</p>
                        </div>
                      </div>
                      <p className="text-[16px] text-[#777] mb-3">
                        Please choose the Densah bur guided option only if you have the Densah
                        guided surgery kit from Versah. This additional guide is also an additional
                        cost.
                      </p>
                    </>
                  )}
                </div>
                {densahBursUsed === "Yes" && densahBurGuideRequired === "Yes" && (
                  <div className="mt-6 mb-7">
                    <p className="text-white text-[16px] mb-2">
                      Please select sites at which Densah burs will be used
                    </p>
                    <TeethV2
                      key={
                        selectedService?.serviceObjId +
                        densahBursTeethSelected[selectedService?.serviceObjId]?.length +
                        notation
                      }
                      allTeethData={[]}
                      restrictedTeeth={getRestrictedTeeth(selectedService?.serviceObjId)}
                      restrictedTeethMessage={"This tooth is already selected in other case type"}
                      selectedTeeth={densahBursTeethSelected[selectedService?.serviceObjId] || []}
                      setSelectedTeeth={(value) => {
                        let result = value(
                          densahBursTeethSelected[selectedService?.serviceObjId] || []
                        );
                        setDensahBursTeethSelected({
                          ...densahBursTeethSelected,
                          [selectedService?.serviceObjId]: result,
                        });
                      }}
                      // defaultNotation={"FDI"}
                      // notationVisible={false}
                      missingTeeth={[...missingTeethSelectedTeeth, ...teethExtractionSelectedTeeth]}
                      screwTeethSelectable={true}
                      selectedScrewTeeth={
                        guideTypeTeethSelected[selectedService?.serviceObjId] || []
                      }
                      otherToothRestrictedMessage="Tooth is not selectable"
                      defaultNotation={notation}
                    />
                  </div>
                )}
              </>
            )}
            {selectedService?.serviceName === "Bone regeneration surgical mesh" ? null : (
              <div className="mb-8">
                {["Endodontic access or microsurgery guide"].includes(
                  selectedService?.serviceName
                ) && (
                  <p className="text-[16px] text-[#777] my-3">
                    Flaps MAY be raised under our endodontic access guides as they are tooth
                    supported. If using an endodontic guide for microsurgical access however, we
                    need to know if you intend to raise a flap. If a flap is being raised, the guide
                    will be designed to fit over bone rather than over mucosa. An access drill brand
                    that will be used to drill into bone will still have to be chosen even for
                    microsurgical guides. Microsurgical guides can also be effectively used for
                    intraosseous anesthesia.
                  </p>
                )}
                <div className="text-white flex mt-3">
                  Will a flap be raised?{" "}
                  {isFieldRequired("flapBeRaised") ? <span className="text-red-500">*</span> : ""}
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={flapBeRaised === "Yes"}
                      onChange={handleFlapBeRaisedCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={flapBeRaised === "No"}
                      onChange={handleFlapBeRaisedCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
                {!["Endodontic access or microsurgery guide"].includes(
                  selectedService?.serviceName
                ) && (
                  <p className="text-[16px] text-[#777] mb-3">
                    Flaps MAY be raised only under our tooth supported guides. Flaps MAY be raised
                    sometimes with our mucosa supported guides AFTER implants have been placed.
                    Flaps are almost ALWAYS raised for our stackable guides.
                  </p>
                )}
              </div>
            )}
            {[
              "Bone regeneration surgical mesh",
              "Endodontic access or microsurgery guide",
            ].includes(selectedService?.serviceName) ? null : (
              <>
                <div className="mb-4">
                  <p className="font-medium text-[24px] text-white mt-6 mb-4">
                    Choose Level of Guidance Desired{" "}
                    {isFieldRequired("guidanceLevel") ? (
                      <span className="text-red-500">*</span>
                    ) : (
                      ""
                    )}
                  </p>
                  <div>
                    <div className="grid md:grid-rows-2 grid-rows-1  gap-x-8 gap-y-4 text-[16px] text-white">
                      <div className="flex items-center mx-3">
                        <CircularCheckbox
                          value="Pilot drill guide"
                          checked={guidanceLevel === "Pilot drill guide"}
                          onChange={handleGuidanceLevelCheckboxChange}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Pilot drill guide</p>
                      </div>
                      <div className="flex items-center mx-3">
                        <CircularCheckbox
                          value="Fully guided"
                          checked={guidanceLevel === "Fully guided"}
                          onChange={handleGuidanceLevelCheckboxChange}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Fully guided</p>
                      </div>
                    </div>
                    {!(guidanceLevel.length > 0) ? (
                      <p className="text-sm text-red-500 py-2">Select at least one</p>
                    ) : (
                      <></>
                    )}
                  </div>

                  {guidanceLevel.includes("Fully guided with Implant placement") &&
                    guideTypeTeethSelected["65bfcd82a03607278060f727"]?.length > 0 && (
                      <div className="ml-6 my-3">
                        <div className="text-white flex">
                          ● Will the prosthesis be used as a morphological template for the guide?
                          <div className="flex items-center mx-3">
                            <CircularCheckbox
                              value="Yes"
                              checked={prosthesisBeUsed === "Yes"}
                              onChange={handleProsthesisBeUsedCheckboxChange}
                              borderColor={"#FF8503"}
                              backgroundColor={"#FF8503"}
                            />
                            <p className="text-white text-[16px]">Yes</p>
                          </div>
                          <div className="flex items-center">
                            <CircularCheckbox
                              value="No"
                              checked={prosthesisBeUsed === "No"}
                              onChange={handleProsthesisBeUsedCheckboxChange}
                              borderColor={"#FF8503"}
                              backgroundColor={"#FF8503"}
                            />
                            <p className="text-white text-[16px]">No</p>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </>
            )}
            {["Endodontic access or microsurgery guide"].includes(
              selectedService?.serviceName
            ) ? null : (
              <div className="">
                <p className="font-medium text-[24px] text-white mt-6">
                  Choose Services Required{" "}
                  {isFieldRequired("servicesRequired") ? (
                    <span className="text-red-500">*</span>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-[16px] text-[#777] mb-4">
                  {selectedService?.serviceName === "Bone regeneration surgical mesh"
                    ? "(Custom designed printed titanium bone regeneration surgical meshes are currently available only in specific countries. Charges for printed titanium bone regeneration surgical meshes vary depending on the country. While we do define the choice of placement screws for the mesh, we do not supply bone screws or bone graft material. Shipping charges will be additional.)"
                    : "(Printed surgical guide services are currently available only in the USA, Australia and New Zealand. Charges for printed guides vary depending on the country and all implant/pin sleeves for an implant system will be charged additionally. Shipping charges will also be additional.)"}
                </p>
                <div>
                  <div
                    className={`grid md:grid-cols-3 md:grid-rows-1 grid-cols-1  gap-x-8 gap-y-4 text-[16px] text-white mb-8`}
                  >
                    {selectedService?.serviceName === "Bone regeneration surgical mesh" ? (
                      <>
                        <div className="flex items-center">
                          <CircularCheckbox
                            value="Regeneration mesh design only"
                            checked={servicesRequired.includes("Regeneration mesh design only")}
                            onChange={handleservicesRequiredCheckboxChange}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">Regeneration mesh design only</p>
                        </div>
                        <div className="flex items-center">
                          <CircularCheckbox
                            value="Printed titanium regeneration mesh"
                            checked={servicesRequired.includes(
                              "Printed titanium regeneration mesh"
                            )}
                            onChange={handleservicesRequiredCheckboxChange}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">
                            Printed titanium regeneration mesh
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex items-center">
                          <CircularCheckbox
                            value="Implant treatment planning only"
                            checked={servicesRequired.includes("Implant treatment planning only")}
                            onChange={handleservicesRequiredCheckboxChange}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">Implant treatment planning only</p>
                        </div>
                        <div className="flex items-center">
                          <CircularCheckbox
                            value="Surgical guide fabrication"
                            checked={servicesRequired.includes("Surgical guide fabrication")}
                            onChange={handleservicesRequiredCheckboxChange}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">Surgical guide fabrication</p>
                        </div>
                      </>
                    )}
                    {![
                      "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
                      "Soft tissue based surgical guide for All on X",
                      "Bone regeneration surgical mesh",
                    ].includes(selectedService?.serviceName) && (
                      <div className="flex items-center">
                        <CircularCheckbox
                          value="Abutment planning"
                          checked={servicesRequired.includes("Abutment planning")}
                          onChange={handleservicesRequiredCheckboxChange}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Abutment planning</p>
                      </div>
                    )}
                  </div>
                  {!(servicesRequired.length > 0) ? (
                    <p className="text-sm text-red-500 py-2">Select at least one</p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
            {[
              "Bone regeneration surgical mesh",
              "Endodontic access or microsurgery guide",
            ].includes(selectedService?.serviceName) ? null : (
              <p className="text-[16px] text-[#777] mb-8">
                Please choose the abutment planning option only if you need to see the path of
                emergence of the restorative screw in angled abutments. Screw emergence is otherwise
                routinely indicated on implant reports for all zero degree restorative solutions.
              </p>
            )}
            <div>
              <TextAreaInputField
                labelColor={"#777"}
                labelText={"Additional Instructions"}
                errorMessage={"This field is required"}
                placeholder={"Enter Additional Instructions"}
                isFieldRequired={isFieldRequired("additonalInstructions")}
                htmlFor={"additonalInstructions"}
                name={"additonalInstructions"}
                value={additonalInstructions}
                handleInputChange={handleInputChange}
                // validationFunctionName={validatePatientConcern}
                // isSubmitted={isPatientInitialFormSubmitted}
                rows={4}
                cols={5}
              />
              {additonalInstructions?.trim()?.length > 0 ? (
                <></>
              ) : (
                <p className="text-sm text-red-500">This field is required</p>
              )}
            </div>
          </>
        )}
      </div>
      <div>
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab("Patient Details");
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              submitToothMovementForm();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
};
