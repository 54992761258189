import { useState } from "react";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import hut from "../../Assets/images/hut.svg";
import logo from "../../Assets/images/LOGO.svg";
import { FaAngleRight } from "react-icons/fa";
import { AiOutlineCheck } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../Login/Login";
import { Tooltip } from "react-tooltip";
import { useEffect } from "react";
import {
  useGetOrdersForDentistMutation,
  useSaveOrderDisclaimerMutation,
  useSaveOrderMutation,
  useSaveOrderPatientMutation,
  useUpdateOrderDisclaimerMutation,
  useUpdateOrderMutation,
  useUpdateOrderPatientMutation,
} from "../../services/orders";
import Notification from "../../Components/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { DisclaimerSection } from "../../Components/Order-Comps/DisclaimerSection";
import { crownTermsAndConditions } from "../../constants/OrderFormsDisclaimers";
import UploadPhotoGraphsSection from "../../Components/Order-Comps/UploadPhotoGraphsSection";
import { PatientDetailsSection } from "../../Components/Order-Comps/PatientDetailsSection";
import { setDentalImplantOrderDetails } from "../../reducers/orderFormReducer";
import { dentalImplantInitialOrderDetails } from "../../Utils/orderFormInitialDetails";
import { useGetServicesCategoriesMutation } from "../../services/servicesCategory";
import { ImplantCaseDetailsSection } from "../../Components/Order-Comps/ImplantCaseDetailsSection";
import ImplantOrderConfirmation from "../OrderConfirmation/ImplantConfirmation";
import { scrollToTop } from "../../Utils/utility";

function ImplantOrderDetails({ userRole, userInfo }) {
  const location = useLocation();
  return (
    <div className="flex bg-black">
      <Navbar
        iconArray={
          userRole === "89uij7"
            ? superAdminIconArray
            : userRole === "676dza2"
            ? iconArray
            : spIconArray
        }
        navHoverCss={navHoverCss}
        navActiveCss={navActiveCss}
        middleSection={false}
        middleTabTitleArray={[""]}
        middleTabContentObject={{}}
        userRole={userRole}
        active="Orders"
      />
      <OrderDetailsTab
        serviceObjId={location.state.serviceObjId ? location.state.serviceObjId : ""}
        orderByIdData={location.state.orderByData}
        userInfo={userInfo}
        userRole={userRole}
      />
    </div>
  );
}

export default ImplantOrderDetails;

export const OrderDetailsTab = ({ userRole, userInfo, serviceObjId, orderByIdData }) => {
  const dispatch = useDispatch();
  const [updateOrder] = useUpdateOrderMutation();
  const [saveOrder] = useSaveOrderMutation();
  const [saveOrderPatient] = useSaveOrderPatientMutation();
  const [updateOrderPatient] = useUpdateOrderPatientMutation();
  const [getOrdersForDentist] = useGetOrdersForDentistMutation();
  const [saveOrderDisclaimer] = useSaveOrderDisclaimerMutation();
  const [updateOrderDisclaimer] = useUpdateOrderDisclaimerMutation();
  const [orderByData, setorderByData] = useState(orderByIdData);
  const personalDetails = useSelector((state) => state.personalDetails);
  const { dentalImplantOrderDetails } = useSelector((state) => state.orderFormDetails);

  console.log("dentalImplantOrderDetails", dentalImplantOrderDetails);

  useEffect(() => {
    if (orderByData?._id) {
      dispatch(
        setDentalImplantOrderDetails({
          ...dentalImplantOrderDetails,
          serviceObjId: serviceObjId,
          _id: orderByData?._id,
        })
      );
    }
  }, [orderByData]);

  // const [orderUpdated, setOrderUpdated] = useState(false);

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isDisclaimerAccepted, setIsDisclaimerAccepted] = useState(false);
  const [orderForService, setOrderForService] = useState({ serviceObjId: serviceObjId });
  const [selectedServiceCategory, setSelectedServiceCategory] = useState({});

  const tabNamesArray = ["Patient Details", "Case Details", "Upload Files", "Approvals"];

  const [activeTab, setActiveTab] = useState(tabNamesArray[0]);

  const [getServicesCategories] = useGetServicesCategoriesMutation();
  const getServicesCategoriesData = async (accessToken) => {
    const resp = await getServicesCategories(accessToken).unwrap();
    if (resp?.data && serviceObjId) {
      resp?.data?.forEach((d) => {
        if (d?._id === serviceObjId) {
          setSelectedServiceCategory(d);
        }
      });
    }
  };

  const readServicesInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getServicesCategoriesData(accessToken);
  };

  useEffect(() => {
    readServicesInfo();

    return () => dispatch(setDentalImplantOrderDetails(dentalImplantInitialOrderDetails));
  }, []);

  const fetchOrderDetails = async (accessToken) => {
    const newresp = await getOrdersForDentist({
      accessToken,
      data: { _id: orderByData?._id },
    });
    setorderByData(newresp?.data?.data?.[0]);
  };

  const submitedPatientDetails = async (data) => {
    const { isPriority, expectedDelivery, ...patientDetails } = data;
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let resp;
    if (dentalImplantOrderDetails?._id) {
      updateOrder({
        accessToken,
        data: {
          _id: dentalImplantOrderDetails?._id,
          isPriority: isPriority,
          expectedDelivery: expectedDelivery,
        },
      }).unwrap();
      if (orderByIdData?.patientDetails) {
        await updateOrderPatient({
          accessToken,
          _id: dentalImplantOrderDetails?._id,
          patientInitials: patientDetails?.patientInitials,
          detailsAnonymized: patientDetails?.detailsAnonymized,
          dob: patientDetails?.dob,
          gender: patientDetails?.gender,
          address: patientDetails?.address,
          country: patientDetails?.country,
          pinCode: patientDetails?.pinCode,
          latitude: patientDetails?.location?.lat,
          longitude: patientDetails?.location?.lng,
        });
      } else {
        await saveOrderPatient({
          accessToken,
          _id: dentalImplantOrderDetails?._id,
          patientInitials: patientDetails?.patientInitials,
          detailsAnonymized: patientDetails?.detailsAnonymized,
          dob: patientDetails?.dob,
          gender: patientDetails?.gender,
          address: patientDetails?.address,
          country: patientDetails?.country,
          pinCode: patientDetails?.pinCode,
          latitude: patientDetails?.location?.lat,
          longitude: patientDetails?.location?.lng,
        });
      }
      dispatch(
        setDentalImplantOrderDetails({
          ...dentalImplantOrderDetails,
          isPriority: isPriority,
          expectedDelivery: expectedDelivery,
          patientDetails: patientDetails,
        })
      );
      fetchOrderDetails(accessToken);
    } else {
      resp = await saveOrder({
        accessToken,
        data: {
          isPriority: isPriority,
          expectedDelivery: expectedDelivery,
          serviceObjId: dentalImplantOrderDetails?.serviceObjId,
        },
      }).unwrap();
      await saveOrderPatient({
        accessToken,
        _id: resp?.data?.data?.[0]?._id,
        patientInitials: patientDetails?.patientInitials,
        detailsAnonymized: patientDetails?.detailsAnonymized,
        dob: patientDetails?.dob,
        gender: patientDetails?.gender,
        address: patientDetails?.address,
        country: patientDetails?.country,
        pinCode: patientDetails?.pinCode,
        latitude: patientDetails?.location?.lat,
        longitude: patientDetails?.location?.lng,
      });
      dispatch(
        setDentalImplantOrderDetails({
          ...dentalImplantOrderDetails,
          _id: resp?.data?.data?.[0]?._id,
          isPriority: isPriority,
          expectedDelivery: expectedDelivery,
          patientDetails: patientDetails,
        })
      );
      fetchOrderDetails(accessToken);
    }
  };

  const submitDisclaimerData = async (data) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (dentalImplantOrderDetails?.tncDocument) {
      await updateOrderDisclaimer({
        accessToken: accessToken,
        _id: dentalImplantOrderDetails?._id,
        isTncChecked: data?.isTncChecked,
        tncDocument: data?.tncDocument,
      });
    } else {
      await saveOrderDisclaimer({
        accessToken: accessToken,
        _id: dentalImplantOrderDetails?._id,
        isTncChecked: data?.isTncChecked,
        tncDocument: data?.tncDocument,
      });
    }
    dispatch(
      setDentalImplantOrderDetails({
        ...dentalImplantOrderDetails,
        disclaimers: data,
      })
    );
    setIsDisclaimerAccepted(true);
  };

  const submitedCaseDetails = (data) => {
    dispatch(
      setDentalImplantOrderDetails({
        ...dentalImplantOrderDetails,
        caseDetails: data,
      })
    );
  };

  const submitedUploadData = (data) => {
    dispatch(
      setDentalImplantOrderDetails({
        ...dentalImplantOrderDetails,
        uploadFiles: data,
      })
    );
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = ["intraOralScans"];

    return requiredFields.includes(fieldName);
  };

  const handleTabSuper = (tabName) => {
    scrollToTop();
    setActiveTab(tabName);
  };

  const outputObject = {
    "Patient Details": (
      <PatientDetailsSection
        orderObjId={orderByData?._id}
        orderByData={orderByData}
        serviceObjId={serviceObjId}
        setActiveTab={handleTabSuper}
        orderPatientData={{
          ...dentalImplantOrderDetails?.patientDetails,
          expectedDelivery: dentalImplantOrderDetails?.expectedDelivery,
          isPriority: dentalImplantOrderDetails?.isPriority,
        }}
        submitOrderData={submitedPatientDetails}
      />
    ),
    "Case Details": (
      <ImplantCaseDetailsSection
        orderObjId={orderByData?._id}
        orderByData={orderByData}
        serviceObjId={serviceObjId}
        setActiveTab={handleTabSuper}
        orderCaseData={dentalImplantOrderDetails?.caseDetails}
        submitOrderData={submitedCaseDetails}
        selectedServiceCategory={selectedServiceCategory}
      />
    ),
    "Upload Files": (
      <UploadPhotoGraphsSection
        orderObjId={orderByData?._id}
        orderByData={orderByData}
        serviceObjId={serviceObjId}
        setActiveTab={handleTabSuper}
        orderUploadData={dentalImplantOrderDetails?.uploadFiles}
        submitOrderData={submitedUploadData}
        isFieldRequired={isFieldRequired}
      />
    ),
    Approvals: (
      <DisclaimerSection
        orderObjId={orderByData?._id}
        orderByData={orderByData}
        serviceObjId={serviceObjId}
        submitOrderData={submitDisclaimerData}
        setActiveTab={handleTabSuper}
        tncList={crownTermsAndConditions}
      />
    ),
  };

  // useEffect(() => {
  //   if (orderUpdated === true) {
  //     const getOrderData = async () => {
  //       const accessToken = await getAccessTokenSilently({
  //         authorizationParams: {
  //           audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
  //         },
  //       });
  //       const resp = await getOrderById({
  //         accessToken,
  //         orderId: orderId,
  //       });
  //       if (resp?.data) {
  //         setorderByData(resp.data.data[0]);
  //       }
  //     };
  //     getOrderData();
  //   }
  // }, [orderUpdated]);

  return (
    <>
      {isDisclaimerAccepted ? (
        <ImplantOrderConfirmation
          userInfo={userInfo}
          userRole={userRole}
          orderObjId={orderByData?._id}
          orderFor={orderForService}
          setActiveTab={handleTabSuper}
          selectedServiceCategory={selectedServiceCategory}
          setIsDisclaimerAccepted={setIsDisclaimerAccepted}
        />
      ) : (
        <div className="MAIN-DASH bg-black md:p-4 px-4 pt-4 pb-20 w-full min-h-screen h-fit flex flex-col">
          <div className="NAVBAR flex justify-between items-center mb-4 ">
            <p className="text-[15px] text-[#999999] flex justify-between items-center">
              <img src={logo} className="mx-auto w-[50px] h-[40px] mr-4 block md:hidden" alt="" />
              <span>
                <img src={hut} alt="" />
              </span>
              <span className="ml-3">Dashboard</span>
            </p>
            <div className="flex gap-4 items-center">
              {isAuthenticated ? (
                <div className="text-[15px] justify-center items-center hidden md:flex text-white">
                  Hello, {personalDetails.data[0]?.firstName ?? user.name}
                </div>
              ) : (
                <LoginButton />
              )}
              {userRole !== "89uij7" ? (
                userInfo?.length > 0 ? (
                  <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                    {userInfo[0]?.active === 1 ? (
                      <>
                        {userInfo[0]?.crowdHireId}
                        <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                      </>
                    ) : (
                      <div
                        className="flex"
                        data-tooltip-id="status"
                        data-tooltip-content={
                          "Your registration is under review and you will be notified once admin approves your registration."
                        }
                      >
                        <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                        <img
                          className="relative top-[0px] ml-2 right-0 h-5 w-5"
                          src={redCross}
                          alt=""
                        />
                        <Tooltip id="status" place="bottom" />
                      </div>
                    )}
                  </button>
                ) : (
                  <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                    Account Not Active
                    <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
                  </button>
                )
              ) : (
                <></>
              )}

              <Notification />
            </div>
          </div>
          <p className="font-medium text-[34px] text-white">Implant Restoration Order</p>
          <div className="ORDER-DETAIL-COMPONENT flex-1 flex flex-col h-full rounded-xl">
            <div className="bg-backgroundV2 flex flex-row flex-wrap items-center gap-4 md:justify-around justify-between md:px-0 px-4 py-4 mb-4">
              <div className="flex items-center gap-4">
                <div className="bg-[#8ED259] h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
                  <AiOutlineCheck className=" " />
                </div>
                <p className="font-semibold text-white md:block hidden ">Service Details</p>
              </div>
              <div className="md:w-[200px] h-[20px] ">
                <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
              </div>
              <div className="flex items-center gap-4">
                <div className="bg-orange-500 h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
                  2
                </div>
                <p className="text-white font-semibold md:block hidden ">Order Details</p>
              </div>
              <div className="md:w-[200px] h-[20px] md:rotate-0 ">
                <FaAngleRight className="mx-auto text-white " />
              </div>
              <div className="flex items-center gap-4">
                <div className="bg-white h-8 w-8 rounded-full border text-[#999] font-medium flex items-center justify-center">
                  3
                </div>
                <p className="text-white md:block hidden ">Confirmation</p>
              </div>
            </div>
            <div className="TAB-SECTION flex-1 flex md:flex-row flex-col p-4 h-full bg-secondary">
              <ul className="NAV md:pr-8 flex flex-col items-start md:gap-4 gap-0 text-[16px] leading-4 font-medium md:border-r border-b border-[#222026] border-opacity-10">
                {tabNamesArray.map((title, i) => {
                  return (
                    <li
                      key={i}
                      className="relative w-full"
                      //onClick={() => handleTabSuper(title)}
                    >
                      <div
                        className={
                          activeTab === title
                            ? "text-white bg-primary w-full p-4 rounded-lg"
                            : "hover:text-white hover:bg-primary cursor-pointer text-white font-normal w-full p-4 rounded-lg"
                        }
                      >
                        {title}
                      </div>
                    </li>
                  );
                })}
              </ul>

              <div className="outlet flex flex-col flex-1 overflow-visible w-full md:py-4 md:pl-8  md:pr-4 p-2 ">
                {Object.keys(outputObject).map((key) => {
                  if (tabNamesArray.includes(key)) {
                    return <>{activeTab === key ? <>{outputObject[key]}</> : null}</>;
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
