// MyStatefulEditor.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RichTextEditor, { EditorValue } from 'react-rte';
import { Tooltip } from 'react-tooltip';
import { AiFillInfoCircle } from 'react-icons/ai';

const MyStatefulEditor = ({
    labelText,
    labelColor,
    errorMessage,
    placeholder,
    isFieldRequired,
    htmlFor,
    name,
    value,
    handleInputChange,
    isSubmitted,
    validationFunctionName,
    margin,
    labelTooltipId, 
    labelTooltipContent,
    labelTooltipPosition
}) => {

    const [innerValue, setInnerValue] = useState(RichTextEditor.createValueFromString(value, 'html'));

    const [ifInvalid, setIfInvalid] = useState(false);

    useEffect(() => {
        if (innerValue?.length > 0 && validationFunctionName(innerValue) === true) {
            setIfInvalid(false);
        }
    }, [validationFunctionName, innerValue]);

    

    const handleChange = (newValue) => {
        setInnerValue(newValue); // Update the value state
        if (handleInputChange) {
            // Pass the correct parameters to the parent's handleInputChange function
            handleInputChange({
                target: {
                    name: name,
                    value: newValue.toString('markdown'), // Assuming you want to pass the content as HTML
                },
            });
        }
    };

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['BLOCK_TYPE_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };

    return (
        <div className="relative">
            <label
                htmlFor={htmlFor}
                className="text-[16px] leading-4 text-white flex items-center my-1"
                data-tooltip-id={labelTooltipId ?? ""} 
                data-tooltip-content={labelTooltipContent ?? ""} 
            >
                {labelText}
                {isFieldRequired ? <span className="text-red-500">*</span> : ""}
                {labelTooltipId && (
                    <span data-tooltip-id={labelTooltipId ?? ""} data-tooltip-content={labelTooltipContent ?? ""} data-tooltip-place={labelTooltipPosition ?? "bottom"}>
                    <AiFillInfoCircle className="ml-2" />
                    <Tooltip id={labelTooltipId ?? ""} />
                    </span>
                )}
            </label>
            <RichTextEditor
                id={name}
                name={name}
                value={innerValue}
                onChange={handleChange}
                placeholder={placeholder}
                style={{ marginBottom: `${margin}` }}
                toolbarConfig={toolbarConfig}
            />
            {isSubmitted && !validationFunctionName(innerValue) && isFieldRequired && (
                <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
                    {errorMessage}
                </p>
            )}
            {ifInvalid && (
                <p className="text-red-500  relative text-[15px] left-0 top-[-10px] ">
                    {errorMessage}
                </p>
            )}
        </div>
    );
};

MyStatefulEditor.propTypes = {
    labelText: PropTypes.string,
    labelColor: PropTypes.string,
    errorMessage: PropTypes.string,
    placeholder: PropTypes.string,
    isFieldRequired: PropTypes.bool,
    htmlFor: PropTypes.string,
    name: PropTypes.string.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    isSubmitted: PropTypes.bool,
    validationFunctionName: PropTypes.string,
    margin: PropTypes.string,
};

MyStatefulEditor.defaultProps = {
    isSubmitted: false,
    margin: '10px', // You can set a default value for margin if not provided
};


export default MyStatefulEditor;