import { useEffect, useState } from "react";
export default function NumberInputField({
  labelText,
  errorMessage,
  labelColor,
  placeholder,
  name,
  value,
  htmlFor,
  handleInputChange,
  isSubmitted,
  validationFunctionName,
  isFieldRequired,
}) {
  const [ifInvalid, setIfInvalid] = useState(false);
  useEffect(() => {
    if (value?.length > 0) {
      setIfInvalid(false);
    }
  }, [value]);
  return (
    <div className="relative">
      <label
        htmlFor={htmlFor}
        className="text-[16px] text-white leading-4"
      >
        {labelText}
        {isFieldRequired ? <span className="text-red-500">*</span> : ""}
      </label>
      <input
        type="tel"
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleInputChange}
        onInvalid={(e) => {
          e.preventDefault();
          setIfInvalid(true);
          e.target.setCustomValidity("");
        }}
        onInput={(e) => {
          if (
            isSubmitted &&
            !validationFunctionName(value) &&
            isFieldRequired
          ) {
            setIfInvalid(false);
          }
        }}
        className={`border p-2 w-full text-white bg-black text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 ${
          (isSubmitted && !validationFunctionName(value) && isFieldRequired) ||
          ifInvalid
            ? "border-red-500"
            : ""
        }`}
        required={isFieldRequired}
      />
      {isSubmitted && !validationFunctionName(value) && isFieldRequired && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px] ">
          {errorMessage}
        </p>
      )}
      {ifInvalid && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
          {errorMessage}
        </p>
      )}
    </div>
  );
}
